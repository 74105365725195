import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import BackButton from "components/mobile-components/Common/BackButton";
import Banner from "components/mobile-components/ProductDetails/Banner";
import ProductDetailsPreview from "components/mobile-components/ProductDetails/ProductDetailsPreview";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Product from "services/Product";

export default function ProductDetailsPreviewPage() {
	let { id } = useParams();
	let url = window.location.href;
	let urlParsen = qs.parse(url);
	let [loading, setLoading] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [product, setProduct] = useState({});
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	//get suppliers data
	let productData = async () => {
		setLoading(true);
		let response =
			urlParsen?.type === "offers"
				? await Product.singleProductDetailsOld(id)
				: await Product.singleProductDetails(id);
		if (response.status === 200) {
			urlParsen?.type === "offers"
				? setProduct(response.data)
				: setProduct(response.results);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		productData();
	}, []);
	let defaultImageMokeUp = () => {
		if (product.type === "variant") {
			return "/assets/images/varient_big_default.png";
		} else if (product.type === "pack") {
			return "/assets/images/pack_big_default.png";
		} else if (product.type === "offer") {
			return "/assets/images/offer_big_default.png";
		}
	};

	let slideData = product?.images?.length
		? [...product.images]
		: [`${defaultImageMokeUp()}`];

	const contentRef = useRef();
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}>
					<BackButton sticky={initStickyClass} />
					{loading ? (
						<IonLoading
							isOpen={loading}
							message={ekkLanguage.orderList.loader}
						/>
					) : (
						<>
							{" "}
							<Banner
								slideData={slideData}
								serverImsge={product.images?.length}
							/>
							<ProductDetailsPreview
								product={product}
								type={urlParsen?.type}
							/>
						</>
					)}
				</IonContent>
				{/* <NavFooter /> */}
			</IonPage>
		</IonApp>
	);
}

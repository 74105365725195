import {
    IonApp,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrderDriverDeliverySummary from "components/mobile-components/OrderDriverDeliverySummary/OrderDriverDeliverySummary";

export default function OrderDriverDeliverySummaryPage() {
    const ekkLanguage = useSelector((state) => state.lan.lan);
    const dispatch = useDispatch();





    return (
        <IonApp>
            <IonPage>
                <IonContent>
                    <BackButtonTitle title={ekkLanguage.orderSummary.driverDeliveryPageTitle} />
                    <OrderDriverDeliverySummary />
                </IonContent>
            </IonPage>
        </IonApp>
    );
}

import { IonCol } from "@ionic/react";
import React from "react";
import Search from "../Common/Search";
import SingleBrand from "./SingleBrand";

export default function AllBrands({
	ekkLanguage,
	brandList,
	searchQ,
	setSearchQ,
	singleBrandSearch,
	setHasMoreData,
	setReachedEnd,
}) {
	return (
		<div className='mt-[10px]'>
			<div className='px-4 pb-5'>
				<Search
					placeholder={ekkLanguage.brandList.searchBrand}
					search={searchQ}
					setSearch={setSearchQ}
					singleSearch={singleBrandSearch}
					setHasMoreData={setHasMoreData}
					setReachedEnd={setReachedEnd}
				/>
			</div>

			<div>
				{brandList.length > 0 ? (
					brandList.map((el) => {
						return <SingleBrand item={el} from='allBrand' />;
					})
				) : (
					<>
						<IonCol size='2'></IonCol>
						<IonCol size='8'>
							<div
								className='text-center flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</IonCol>
					</>
				)}
			</div>
		</div>
	);
}

import { createSlice } from "@reduxjs/toolkit";

const businessSettingsSlice = createSlice({
	name: "businessSettings",
	initialState: {
		data: {},
	},
	reducers: {
		setTradeLicense: (state, action) => {
			state.data = action.payload;
		},
		setNid: (state, action) => {
			state.data = action.payload;
		},
		setProperty: (state, action) => {
			state.data = action.payload;
		},
		setKYC: (state, action) => {
			state.data = action.payload;
		},
		setNewBusiness: (state, action) => {
			state.newBusiness = action.payload;
		},
		emptyBusiness: (state, action) => {
			state.newBusiness = {};
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = businessSettingsSlice;

export const setTradeLicense = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setTradeLicense(data));
};

export const setNid = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setNid(data));
};

export const setPropertyData = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setProperty(data));
};
export const setKYC = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setKYC(data));
};
export const emptyKYC = () => async (dispatch) => {
	dispatch(actions.setKYC({}));
};
export const setNewBusiness = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.newBusiness, ...values };
	dispatch(actions.setNewBusiness(data));
};
export const emptyBusiness = () => async (dispatch) => {
	dispatch(actions.emptyBusiness());
};
// Export the reducer, either as a default or named export
export default reducer;

import React, { useState } from "react";

import Men from "assets/images/icon/svg/Men";
import Lock from "assets/images/icon/svg/Lock";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/container/authSlice";
import RightArrow from "assets/images/icon/svg/RightArrow";
import { Link, useNavigate } from "react-router-dom";
import Business from "services/Business";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";

export default function SettingsCard({ businessData }) {
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [isOpen, setIsOpen] = useState(false);
	const [warningMsg, setWarningMsg] = useState(
		ekkLanguage.businessProfile.deleteWarning
	);
	const [dueAmount, setDueAmount] = useState(false);
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const onOpenModal = () => {
		setDueAmount(false);
		setWarningMsg(ekkLanguage.businessProfile.deleteWarning);
		setIsOpen(true);
	};
	// const onCloseModal = () => setIsOpen(false);
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const optSend = async function () {
		setLoader(true);
		let response = await Business.businessDeleteOtp();
		if (response.status === 200) {
			setLoader(false);
			navigate("/business-delete-otp");
			toast.success(ekkLanguage.password.otpSendLabel);
		} else {
			setLoader(false);
			setWarningMsg(response.message);
			setDueAmount(true);
		}
	};
	return (
		<div className='px-4 profile-settings-card'>
			<div className='text-center bg-white rounded-[30px]'>
				<div className='py-6 px-5'>
					<ul>
						{/* <li className='mb-4'>
							<Link
								to='/business-profile-settings'
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<Men />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.letNav.businessProfileMenuLabel}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li> */}
						{/* <li className='mb-4'>
							<Link
								to='/contact'
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<Men />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.contactMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li> */}
						<li className='mb-4'>
							<Link
								to='/otp-send'
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<Lock />
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.passwordMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to='/change-language'
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											viewBox='0 0 20 20'
											fill='none'>
											<g clipPath='url(#clip0_4780_475)'>
												<path
													d='M4.89596 7.83398C4.8643 7.83398 4.8368 7.85565 4.83013 7.88648L4.1543 10.7507H5.61846L4.96263 7.88648C4.95513 7.85565 4.92763 7.83398 4.89596 7.83398Z'
													fill='#222222'
												/>
												<path
													d='M15.834 3.33398H10.834V16.6673H15.834C18.1315 16.6673 20.0007 14.7982 20.0007 12.5007V7.50065C20.0007 5.20315 18.1315 3.33398 15.834 3.33398ZM18.334 8.03148C18.334 8.31482 18.104 8.54482 17.8207 8.54482H17.5573C17.4565 9.60732 17.0432 10.8207 16.2182 11.7665C16.6698 12.0407 17.2115 12.2365 17.8715 12.299C18.134 12.324 18.334 12.5457 18.334 12.8098V12.8273C18.334 13.1323 18.0707 13.3673 17.7673 13.3382C16.8198 13.2473 16.0515 12.9307 15.4273 12.4865C14.7998 12.9348 14.0223 13.249 13.0673 13.3382C12.764 13.3665 12.5015 13.1315 12.5015 12.8273V12.8098C12.5015 12.5423 12.7082 12.3232 12.9748 12.2982C13.6315 12.2357 14.1732 12.0423 14.6223 11.769C14.3465 11.4532 14.1165 11.1073 13.9282 10.7465C13.7507 10.4065 14.0023 9.99982 14.3865 9.99982H14.3948C14.5832 9.99982 14.759 10.1015 14.8457 10.269C14.9998 10.5623 15.1923 10.8448 15.4232 11.104C16.1048 10.3373 16.4165 9.34732 16.509 8.54482H13.0165C12.7332 8.54482 12.5032 8.31482 12.5032 8.03148V8.01398C12.5032 7.73065 12.7332 7.50065 13.0165 7.50065H14.8973V7.18065C14.8973 6.89732 15.1273 6.66732 15.4107 6.66732H15.4282C15.7115 6.66732 15.9415 6.89732 15.9415 7.18065V7.50065H17.8223C18.1057 7.50065 18.3357 7.73065 18.3357 8.01398V8.03148H18.334Z'
													fill='#222222'
												/>
												<path
													d='M9.16667 3.33398H4.16667C1.865 3.33398 0 5.19982 0 7.50065V12.5007C0 14.8023 1.865 16.6673 4.16667 16.6673H9.16667V3.33398ZM6.675 13.334C6.40333 13.334 6.1675 13.1465 6.10667 12.8815L5.88583 11.9173H3.87833L3.65 12.8848C3.5875 13.1482 3.35333 13.334 3.0825 13.334C2.70667 13.334 2.42917 12.9832 2.515 12.6173L3.6925 7.62315C3.86 6.90148 4.64 6.45148 5.40583 6.77232C5.77167 6.92565 6.0175 7.27732 6.1075 7.66398L7.24333 12.6207C7.32667 12.9857 7.05 13.334 6.675 13.334Z'
													fill='#222222'
												/>
											</g>
											<defs>
												<clipPath id='clip0_4780_475'>
													<rect
														width='20'
														height='20'
														fill='white'
													/>
												</clipPath>
											</defs>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.changeLanguageMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to={`/pick-location/${businessData.id}`}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='18'
											viewBox='0 0 16 18'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M0.916016 7.59748C0.916016 3.76426 4.11925 0.666016 7.99389 0.666016C11.8794 0.666016 15.0827 3.76426 15.0827 7.59748C15.0827 9.52909 14.3802 11.3224 13.2239 12.8423C11.9484 14.5189 10.3761 15.9797 8.60647 17.1264C8.20144 17.3914 7.83591 17.4114 7.39139 17.1264C5.61163 15.9797 4.03942 14.5189 2.77477 12.8423C1.61767 11.3224 0.916016 9.52909 0.916016 7.59748ZM5.66211 7.81373C5.66211 9.09786 6.70997 10.1078 7.99479 10.1078C9.28045 10.1078 10.3384 9.09786 10.3384 7.81373C10.3384 6.5396 9.28045 5.48047 7.99479 5.48047C6.70997 5.48047 5.66211 6.5396 5.66211 7.81373Z'
												fill='#222222'
											/>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.businessProfile.setLocation}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to={`/payment-terms-settings`}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='18'
											height='18'
											viewBox='0 0 18 18'
											fill='none'>
											<g clipPath='url(#clip0_4843_491)'>
												<path
													d='M10.4992 9C10.4992 9.82725 9.8265 10.5 8.99925 10.5C8.172 10.5 7.49925 9.82725 7.49925 9C7.49925 8.17275 8.172 7.5 8.99925 7.5C9.8265 7.5 10.4992 8.17275 10.4992 9ZM18 11.661C18 13.2083 17.025 14.619 15.5745 15.1718C14.5688 15.5558 13.6185 15.75 12.7485 15.75C11.523 15.75 10.425 15.4508 9.36375 15.1613C8.35275 14.8853 7.39875 14.625 6.37425 14.625C5.196 14.625 4.45275 14.7008 3.81 14.8868C2.9055 15.147 1.9545 14.9715 1.2 14.4037C0.43725 13.83 0 12.954 0 12V6.339C0 4.79175 0.97425 3.381 2.42475 2.82825C3.4305 2.44425 4.3815 2.25 5.25075 2.25C6.47625 2.25 7.5735 2.54925 8.6355 2.83875C9.6465 3.11475 10.6005 3.375 11.625 3.375C12.8025 3.375 13.5465 3.29925 14.1893 3.11325C15.0953 2.853 16.0462 3.0285 16.8 3.59625C17.5627 4.17 18 5.046 18 6V11.661ZM4.5 10.5C4.5 10.086 4.164 9.75 3.75 9.75C3.336 9.75 3 10.086 3 10.5C3 10.914 3.336 11.25 3.75 11.25C4.164 11.25 4.5 10.914 4.5 10.5ZM4.5 6C4.5 5.586 4.164 5.25 3.75 5.25C3.336 5.25 3 5.586 3 6C3 6.414 3.336 6.75 3.75 6.75C4.164 6.75 4.5 6.414 4.5 6ZM12 9C12 7.3455 10.6545 6 9 6C7.3455 6 6 7.3455 6 9C6 10.6545 7.3455 12 9 12C10.6545 12 12 10.6545 12 9ZM15 12C15 11.586 14.664 11.25 14.25 11.25C13.836 11.25 13.5 11.586 13.5 12C13.5 12.414 13.836 12.75 14.25 12.75C14.664 12.75 15 12.414 15 12ZM15 7.5C15 7.086 14.664 6.75 14.25 6.75C13.836 6.75 13.5 7.086 13.5 7.5C13.5 7.914 13.836 8.25 14.25 8.25C14.664 8.25 15 7.914 15 7.5Z'
													fill='#222222'
												/>
											</g>
											<defs>
												<clipPath id='clip0_4843_491'>
													<rect
														width='18'
														height='18'
														fill='white'
													/>
												</clipPath>
											</defs>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.businessProfile.paymentTermsSettings}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to={`/added-bank-list`}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='18'
											height='18'
											viewBox='0 0 18 18'
											fill='none'>
											<g clipPath='url(#clip0_4843_489)'>
												<path
													d='M0.217506 6.41319C0.0558973 6.11343 -0.0189735 5.77457 0.00129585 5.43463C0.0215652 5.09468 0.136172 4.76712 0.332256 4.48869C0.652699 4.01853 1.08184 3.63261 1.58326 3.36369L7.20901 0.435687C7.76195 0.14816 8.37602 -0.00195313 8.99926 -0.00195312C9.62249 -0.00195313 10.2366 0.14816 10.7895 0.435687L16.4145 3.36594C16.9159 3.63486 17.3451 4.02078 17.6655 4.49094C17.8616 4.76937 17.9762 5.09693 17.9965 5.43688C18.0167 5.77682 17.9419 6.11568 17.7803 6.41544C17.6063 6.74456 17.3453 7.01964 17.0258 7.21073C16.7063 7.40182 16.3405 7.50159 15.9683 7.49919H2.03026C1.65762 7.50152 1.29151 7.40147 0.971847 7.20996C0.652184 7.01846 0.39125 6.74284 0.217506 6.41319V6.41319ZM17.2493 16.4992C17.2493 16.1014 17.0912 15.7198 16.8099 15.4385C16.5286 15.1572 16.1471 14.9992 15.7493 14.9992V8.99919H14.2493V14.9992H11.9993V8.99919H10.4993V14.9992H7.49926V8.99919H5.99926V14.9992H3.74926V8.99919H2.24926V14.9992C1.85143 14.9992 1.4699 15.1572 1.1886 15.4385C0.907291 15.7198 0.749256 16.1014 0.749256 16.4992C0.550343 16.4992 0.359578 16.5782 0.218925 16.7189C0.0782732 16.8595 -0.000744472 17.0503 -0.000744472 17.2492C-0.000744472 17.4481 0.0782732 17.6389 0.218925 17.7795C0.359578 17.9202 0.550343 17.9992 0.749256 17.9992H17.2493C17.4482 17.9992 17.6389 17.9202 17.7796 17.7795C17.9202 17.6389 17.9993 17.4481 17.9993 17.2492C17.9993 17.0503 17.9202 16.8595 17.7796 16.7189C17.6389 16.5782 17.4482 16.4992 17.2493 16.4992Z'
													fill='#222222'
												/>
											</g>
											<defs>
												<clipPath id='clip0_4843_489'>
													<rect
														width='18'
														height='18'
														fill='white'
													/>
												</clipPath>
											</defs>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.addedBankList.pageTitle}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<Link
								to={`/select-upgrade-plan`}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<ion-icon name='receipt'></ion-icon>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.subscriptionPlan}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>

						<li className='mb-4'>
							<Link
								to={`/kyc-details`}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='20'
											viewBox='0 0 16 20'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M7.75092 19.0862C7.85243 19.1393 7.96594 19.1662 8.07945 19.1653C8.19296 19.1644 8.30555 19.1366 8.40798 19.0826L11.6785 17.3343C12.606 16.8399 13.3323 16.2871 13.8989 15.6435C15.1309 14.2412 15.8036 12.4515 15.7917 10.6061L15.7529 4.51885C15.7492 3.81772 15.2887 3.19209 14.6076 2.96467L8.52334 0.923298C8.15697 0.799252 7.75553 0.801949 7.39562 0.929591L1.33439 3.04377C0.65702 3.28018 0.204827 3.9103 0.208519 4.61233L0.247278 10.6951C0.259275 12.5432 0.955099 14.3248 2.2074 15.7127C2.77956 16.3473 3.5123 16.892 4.44991 17.3783L7.75092 19.0862ZM6.88504 11.9315C7.02162 12.0627 7.1988 12.1275 7.37599 12.1257C7.55318 12.1248 7.72944 12.0582 7.86417 11.9252L11.4383 8.40157C11.7069 8.1364 11.7041 7.71033 11.4328 7.44875C11.1606 7.18717 10.7222 7.18897 10.4537 7.45414L7.36584 10.4978L6.10154 9.28248C5.8293 9.0209 5.39188 9.0236 5.12241 9.28877C4.85386 9.55394 4.85663 9.98002 5.12887 10.2416L6.88504 11.9315Z'
												fill='#130F26'
											/>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.kycMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
						<li className='mb-4'>
							<div
								onClick={onOpenModal}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='14'
											height='16'
											viewBox='0 0 14 16'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M13.2153 2.93223C13.5071 2.93223 13.75 3.17447 13.75 3.48272V3.76772C13.75 4.06846 13.5071 4.31821 13.2153 4.31821H0.78539C0.492895 4.31821 0.25 4.06846 0.25 3.76772V3.48272C0.25 3.17447 0.492895 2.93223 0.78539 2.93223H2.97217C3.41639 2.93223 3.80298 2.61648 3.90291 2.17098L4.01743 1.65949C4.1954 0.962745 4.78112 0.5 5.45145 0.5H8.54855C9.21158 0.5 9.80387 0.962745 9.97528 1.62274L10.0978 2.17023C10.197 2.61648 10.5836 2.93223 11.0286 2.93223H13.2153ZM12.1047 13.3511C12.333 11.2234 12.7327 6.16843 12.7327 6.11743C12.7473 5.96293 12.697 5.81668 12.597 5.69894C12.4898 5.58869 12.3542 5.52344 12.2046 5.52344H1.80172C1.65146 5.52344 1.50849 5.58869 1.40929 5.69894C1.30863 5.81668 1.25903 5.96293 1.26633 6.11743C1.26767 6.1268 1.28201 6.30486 1.30599 6.60255C1.41251 7.92499 1.7092 11.6082 1.90092 13.3511C2.03659 14.6351 2.87906 15.4421 4.09937 15.4713C5.04105 15.4931 6.01117 15.5006 7.00317 15.5006C7.93755 15.5006 8.88652 15.4931 9.85737 15.4713C11.12 15.4496 11.9617 14.6568 12.1047 13.3511Z'
												fill='#222222'
											/>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.businessProfile.deleteBusiness}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</div>
						</li>
						<li className='mb-4'>
							<Link
								to='/#!'
								onClick={() => {
									dispatch(logout());
								}}
								className='mb-4 flex items-center justify-between'>
								<div className='flex items-center'>
									<span
										className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
										style={{ height: 40, width: 40 }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='17'
											height='16'
											viewBox='0 0 17 16'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M6.42151 7.4225C6.09339 7.4225 5.83394 7.6775 5.83394 8C5.83394 8.315 6.09339 8.5775 6.42151 8.5775H11V12.1625C11 14 9.48147 15.5 7.60429 15.5H3.88808C2.01853 15.5 0.5 14.0075 0.5 12.17V3.8375C0.5 1.9925 2.02616 0.5 3.89571 0.5H7.61955C9.48147 0.5 11 1.9925 11 3.83V7.4225H6.42151ZM13.7226 5.40515L15.9127 7.58765C16.0252 7.70015 16.0851 7.84265 16.0851 8.00015C16.0851 8.15015 16.0252 8.30015 15.9127 8.40515L13.7226 10.5877C13.6101 10.7002 13.4601 10.7601 13.3176 10.7601C13.1676 10.7601 13.0176 10.7002 12.9051 10.5877C12.6802 10.3626 12.6802 9.99515 12.9051 9.77015L14.1051 8.57765H11.0001V7.42265H14.1051L12.9051 6.23015C12.6802 6.00515 12.6802 5.63765 12.9051 5.41265C13.1301 5.18015 13.4977 5.18015 13.7226 5.40515Z'
												fill='#222222'
											/>
										</svg>
									</span>
									<h6 className='text-14 text-222222 font-semibold'>
										{ekkLanguage.profile.logoutMenuLable}
									</h6>
								</div>
								<div className='bg-white' slot='content'>
									<RightArrow />
								</div>
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>{warningMsg}</h6>

					<div className='flex justify-end'>
						{!dueAmount && (
							<>
								<button
									color='primary'
									className={`${
										loader && "select-none cursor-no-drop"
									} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-primary text-white flex justify-center items-center`}
									onClick={!loader && optSend}>
									{loader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.orderDetails.yesBtn
									)}
								</button>
								<button
									className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-success text-white'
									onClick={onCloseModal}>
									{ekkLanguage.orderDetails.noBtn}
								</button>
							</>
						)}
						{dueAmount && (
							<button
								className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white'
								onClick={onCloseModal}>
								{ekkLanguage.orderDetails.close}
							</button>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
}

import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
// https://dorder.ekkbaz.com/v4/orders/business/list?limit=10&page=1
const Orders = {
	getOrdersList: (page, limit, value = "") => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list-seller?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	getBuyerOrderList: (page, limit, value = "") => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list-buyer?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	orderSearchById: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order_id/${id}`, {
			method: "GET",
		});
	},
	orderListBySorted: (value, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	singleOrder: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/details/${id}`);
	},
	orderDetailsByOrderId: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order_id/${id}`);
	},
	createOrder: (data) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/create/seller`, {
			method: "POST",
			data: data,
		});
		// /v4/orders/create
	},
	createOrderForBot: (data) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/create/buz-ai`, {
			method: "POST",
			data: data,
		});
		// /v4/orders/create
	},
	todayPurchaseSummery: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/my-business/total-order-details?date_start=${start}&date_end=${end}`
		);
	},
	searchOrder: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/order-list/search-eb-ebs?order_id=${id}`
		);
	},
	orderCancel: (id) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: { order_status: "Cancel" },
		});
	},
	orderDelivery: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	orderAmountCollection: (id) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/order-amount-collection/${id}`
		);
	},
	orderAnalytics: () => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/my-business/total-order-analytics`
		);
	},
	orderUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	orderUpdateV5: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order-update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	paymentGaitWayList: (days) => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz/gateways/${days ? `?pay_days=${days}` : ""}`
		);
	},
	// paymentGaitWayList: (days) => {
	// 	return SuperFetch(`${baseUrl.pay}/ekkbaz/gateways/?pay_days=${7}`);
	// },
	paymentTermList: (id, language) => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz_seller/terms/?country=${language}&apps=Ekkbaz Seller&order_type=Primary`
		);
	},

	preOrder: (data) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/pre-order/`, {
			method: "POST",
			data: data,
		});
	},
	// getLoanDetails: (buyerId, orderId) => {
	// 	return SuperFetch(
	// 		`${baseUrl.loan}/loan/buyer-detail/${buyerId}/${orderId}/`
	// 	);
	// },
	getLoanDetails: (orderId) => {
		return SuperFetch(`${baseUrl.loan}/loan/order/loan_details/${orderId}/`);
	},
	sellerLoanPaymentDetails: (lti) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_loan_payment/${lti}/`);
	},
	getFsPromoCode: (businessId) => {
		return SuperFetch(
			`${baseUrl.loan}/loan/fspromo_code/?business_id=${businessId}`
		);
	},
	getTotalPrimaryOrder: () => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/seller/order-total-order-amount`
		);
	},
	getOrderHistoryList: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/seller/all-order-list/?page=${page}&limit=${limit}`
		);
	},
};
export default Orders;

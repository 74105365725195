import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import SupplierList from "components/mobile-components/SupplierList";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Brand from "services/Brand";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";
import MyOrderStatus from "components/mobile-components/MyOrderList/MyOrderStatus";
import SupplierFilter from "components/mobile-components/SupplierList/SupplierFilter";
import Search from "components/mobile-components/Common/Search";
import { setUserOrderType } from "redux/container/orderDetailsSlice";
import CustomerOrderFilter from "components/mobile-components/CustomerOrder/CustomerOrderFilter";
import CustomerList from "components/mobile-components/CustomerOrder/CustomerList";
import Business from "services/Business";
import { getUserBusiness } from "config/user";
import withLocation from "components/HOC/withLocation";

function CustomerListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [customerList, setCustomerList] = useState([]);
	const [myCustomer, setMyCustomer] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [loading, setLoading] = useState(true);
	const [productLastResponse, setProductLastResponse] = useState([]);
	const dispatch = useDispatch();
	const [orderStatus, setOrderStatus] = useState([]);
	const location = useSelector((state) => state.user.locationData);
	const sellerId = getUserBusiness()?.id;
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getMyCustomers = async () => {
		setPageCount(2);
		setCustomerList([]);
		setLoading(true);
		if (searchQ) {
			let response = await Business.getCustomer(
				sellerId,
				location.coordinates[0],
				location.coordinates[1],
				searchQ
			);
			if (response.status === 200) {
				setCustomerList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else {
			let response = await Business.getCustomers(
				sellerId,
				location.coordinates[0],
				location.coordinates[1]
			);
			console.log(response);
			if (response.status === 200) {
				setCustomerList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	// const getAllBrandList = async () => {
	// 	setPageCount(2);
	// 	setBrandList([]);
	// 	setLoading(true);
	// 	if (searchQ) {
	// 		let response = await Brand.getAllBrandListSearch(searchQ);
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setBrandList(response.results);
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	} else {
	// 		let response = await Brand.getAllBrandList();
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setBrandList(response.results);
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	getMyBrandList();
	// }, []);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	let lodeMoreData = async () => {
		if (searchQ) {
			// const response = await Brand.getMySupplierBrandListSearch(
			// 	searchQ,
			// 	pageCount
			// );
			// if (response.status === 200) {
			// 	setPageCount((prev) => prev + 1);
			// 	setBrandList([...brandList, ...response.results]);
			// }
			if (myCustomer) {
				const response = await Brand.getMySupplierBrandListSearch(
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setCustomerList([...customerList, ...response.results]);
				}
			}
			// else {
			// 	const response = await Brand.getAllBrandListSearch(searchQ, pageCount);
			// 	if (response.status === 200) {
			// 		setPageCount((prev) => prev + 1);
			// 		setBrandList([...brandList, ...response.results]);
			// 	}
			// }
		} else {
			// const response = await Brand.getMySupplierBrandList(pageCount);
			// if (response.status === 200) {
			// 	setPageCount((prev) => prev + 1);
			// 	setBrandList([...brandList, ...response.results]);
			// }
			if (myCustomer) {
				const response = await Brand.getMySupplierBrandList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setCustomerList([...customerList, ...response.results]);
				}
			}
			//  else {
			// 	const response = await Brand.getAllBrandList(pageCount);
			// 	if (response.status === 200) {
			// 		setPageCount((prev) => prev + 1);
			// 		setBrandList([...brandList, ...response.results]);
			// 	}
			// }
		}
	};

	let singleBrandSearch = async () => {
		if (myCustomer) {
			let response = await Business.getCustomer(
				sellerId,
				location.coordinates[0],
				location.coordinates[1],
				searchQ
			);
			if (response.status === 200) {
				setCustomerList(response.results);
			}
		} else {
			let res = await dispatch(searchOrder(searchQ, 500, 1));
			if (res.status === 200) {
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	// Order Section
	let orderListPage = useSelector((state) => state.order.page);

	let getInitOrderList = async () => {
		setLoading(true);
		let response = await dispatch(fetchOrder("seller", ""));
		if (response.status === 200) {
			dispatch(setUserOrderType("seller"));
			setLoading(false);
			setProductLastResponse(response.data);
		} else {
			setLoading(false);
		}
	};

	let getOrderList = async (value) => {
		if (hasMoreData) {
			let response = await dispatch(
				fetchOrder("seller", value, orderListPage)
			);
			if (response.status === 200) {
				dispatch(setUserOrderType("seller"));
				setProductLastResponse(response.data);
				if (!response.data.length) {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (myCustomer) {
				getMyCustomers();
			} else {
				getInitOrderList();
			}
		}
	}, [searchQ, myCustomer]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.customerOrder.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						// from='supplierList'
					/>

					<section className='pt-14 px-2'>
						<div className='pt-2'>
							<Search
								placeholder={
									myCustomer
										? ekkLanguage.customerOrder.customerPlaceHolder
										: ekkLanguage.orderDetails.orderSearchLabel
								}
								search={searchQ}
								setSearch={setSearchQ}
								singleSearch={singleBrandSearch}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						</div>
						<CustomerOrderFilter
							setMyBrand={setMyCustomer}
							getAllBrandList={getInitOrderList}
							getMyBrandList={getMyCustomers}
							ekkLanguage={ekkLanguage}
							setSearch={setSearchQ}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}></CustomerOrderFilter>
						{myCustomer ? (
							<CustomerList
								ekkLanguage={ekkLanguage}
								loading={loading}
								customerList={customerList}
							/>
						) : (
							<MyOrderStatus
								buyer={"seller"}
								setOrderStatus={setOrderStatus}
								loading={loading}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						)}
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								getOrderList(orderStatus);
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
export default withLocation(CustomerListPage);

import React from "react";

export default function HeadlinePart({ ekkLanguage }) {
  return (
    <div className="pb-3">
      <div className="pt-4 pb-[5px] flex flex-col items-center justify-center">
        <h4 className="font-bold text-black text-16">
          {ekkLanguage.kycVerification.ebsAgreementPageTitle}
        </h4>
        <div className="flex w-full justify-between items-center py-[5px]">
          <h4 className="font-bold text-black text-14">
            {ekkLanguage.kycVerification.ebCompanyTitle}
          </h4>
          <img
            className="h-[42px] w-[86px]"
            src="/assets/images/eb_full_logo.png"
            alt=""
          />
        </div>
        <h4 className="font-bold text-success text-16 py-[5px]">
          {ekkLanguage.ebsAgreement.headline}
        </h4>
      </div>
    </div>
  );
}

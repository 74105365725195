import { getLanguage } from "config/language";
import React, { useEffect } from "react";
import MainRoutes from "routes/Routes";
import "./styles/index.scss";

function App() {
	return (
		<div>
			<MainRoutes />
		</div>
	);
}

export default App;

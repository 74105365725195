import { agreements } from "data/agreements";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";

export default function AgreementsVN() {
  return (
    <div className="mt-2">
      <p className="text-justify text-222222 text-14 mb-2">
        <span>{digitChanger(1)}.</span> Thỏa thuận tạm thời này sẽ có hiệu lực
        từ và sẽ có hiệu lực cho đến khi bao gồm. Thỏa thuận sẽ tự động được coi
        là hợp lệ cho{" "}
        <span className="font-bold">EBS (Dịch vụ Người bán EkkBaz)</span> sau
        một tháng kể từ khi hoàn thành.
        {/* new  */}
        <span>{digitChanger(1)}.</span> Thỏa thuận tạm thời này sẽ có hiệu lực
        kể từ{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(moment(new Date()).format("DD MMM, YYYY"))
          )}
        </span>{" "}
        và sẽ có hiệu lực giữa các bên cho đến{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(
              moment(new Date()).add(1, "year").format("DD MMM, YYYY")
            )
          )}
        </span>
        . Bên/Nhà phân phối thứ nhất sẽ tự động đủ điều kiện tham gia{" "}
        <span className="font-bold">EBS (Dịch vụ người bán EkkBaz)</span> sau
        khi hoàn thành thời hạn hợp đồng một tháng.
      </p>
      {agreements.map((agreement) => {
        return (
          <p
            key={agreement.id}
            className="text-justify text-222222 text-14 mb-2"
          >
            <span>{digitChanger(agreement.id)}.</span>{" "}
            {agreement.vietnamese_description}
          </p>
        );
      })}
    </div>
  );
}

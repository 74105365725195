import { Button } from "components/global-components/common/button";
import React, { useEffect, useRef, useState } from "react";
import Box from "./Box";
import { parseNumber } from "libphonenumber-js";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { authOtpSend } from "helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage, setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";

export default function PhoneNumberCountry({
	loading,
	phoneNo,
	setPhoneNo,
	countryCode,
	setCountryCode,
	setCountry,
	startLoading,
	stopLoading,
	setBlockIndex,
	country,
	setVerifyNumber,
}) {
	// language call from redux service
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan);
	const language = getLanguage();
	const [isChecked, setIsChecked] = useState(false);
	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		label: val.name,
	}));
	const handleOnChange = (e) => {
		setIsChecked(!isChecked);
	};

	const errorMessage = () => {
		if (phoneNo) {
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			} else {
				if (
					!Object.keys(parseNumber(countryCode + phoneNo)).length &&
					phoneNo.trim().length > 0
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			}
		}
	};
	const handleSelectCountry = (e) => {
		setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	//submit country varificarion handler, it's varify first country and phone no.
	const submitPhoneNumberCountry = async (
		countryCode,
		country,
		phoneNumber
	) => {
		if (country === "Bangladesh") {
			if (
				!(
					phoneNo.trim().length > 10 &&
					phoneNo.trim().length < 12 &&
					phoneNo[0] == 0
				)
			) {
				toast.error(ekkLanguage?.lan?.login.validationLabel);
				return;
			}
		}
		if (isChecked) {
			// let pn = parseNumber(countryCode + phoneNo).phone;
			let req_body = {
				country,
				phone_number: (countryCode + phoneNo).trim(),
			};

			try {
				startLoading();
				let response = await Auth.countryAndUserFilter(req_body);
				if (response.status === 200) {
					setBlockIndex(authBox_Block.PASSWORD_BLOCK);
				} else {
					setVerifyNumber(true);
				}
				stopLoading();
			} catch (e) {
				stopLoading();
				console.log(e);
			}
		} else {
			// toast.error(ekkLanguage?.lan?.login.termsAllow);
		}
	};
	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<Box>
			<div id='phonenumber-country-container'>
				<div>
					<label className='uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{ekkLanguage?.lan?.login?.contryLabel}
					</label>
					<Select
						className='basic-single'
						placeholder='Select Your Country'
						name='color'
						options={countryList}
						onChange={(e) => handleSelectCountry(e)}
						defaultValue={{
							countryCode: "+88",
							country: "Bangladesh",
							value: "+88",
							label: "Bangladesh",
						}}
					/>
				</div>

				<div className='mb-1 mt-4'>
					<label className='uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{ekkLanguage?.lan?.login?.phoneLable}
					</label>
					<div className='flex items-stretch '>
						<span
							value={countryCode}
							placeholder='+88'
							readOnly={true}
							id={"ekk-input"}
							style={{
								borderRadius: ".25rem",
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
							className='appearance-none text-lg text-grey-darker border border-right-0 py-2 px-2 mb-3 leading-tight '>
							{countryCode}
						</span>

						<input
							ref={numberInputRef}
							type='number'
							onKeyPress={_handleKeyPress}
							onChange={(event) => setPhoneNo(event.target.value)}
							placeholder={ekkLanguage?.lan?.login?.phonePlaceHolder}
							id={"ekk-input"}
							style={{
								flex: "flex: 1 0 auto",
								borderRadius: ".25rem",
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
							className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white'
						/>
					</div>
					<p className='text-red text-xs'>
						{errorMessage(countryCode, phoneNo)}
					</p>
				</div>
				<div className='trams-condition'>
					<label className='flex cursor-pointer my-2'>
						<IonCheckbox
							size='small'
							slot='start'
							value='terms'
							className='mr-2'
							checked={isChecked}
							onIonChange={handleOnChange}></IonCheckbox>
						{getLanguage() === "en" ? (
							<p className='flex-1'>
								I agree with the EkkBaz's{" "}
								<a
									href='https://ekkbaz.com/business/terms-and-conditions'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									Terms & Conditions
								</a>
								,{" "}
								<a
									href='https://ekkbaz.com/business/privacy-policy
'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									{" "}
									Privacy Policy{" "}
								</a>{" "}
								and{" "}
								<a
									href='https://ekkbaz.com/business/refund-policy'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									Refund Policy
								</a>
								.
							</p>
						) : (
							<p className='flex-1'>
								আমি EkkBaz এর{" "}
								<a
									href='https://ekkbaz.com/business/terms-and-conditions'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									শর্তাবলী
								</a>
								,{" "}
								<a
									href='https://ekkbaz.com/business/privacy-policy
'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									{" "}
									গোপনীয়তা নীতি{" "}
								</a>{" "}
								এবং{" "}
								<a
									href='https://ekkbaz.com/business/refund-policy'
									className='text-primary'
									target='_blank'
									rel='noreferrer'>
									অর্থ ফেরত নীতির
								</a>{" "}
								সাথে একমত।
							</p>
						)}
					</label>
				</div>
				<div className='flex justify-center'>
					<Button
						// disabled={!isChecked}
						title={ekkLanguage?.lan?.login?.btnLabel}
						loading={loading}
						onClick={() =>
							submitPhoneNumberCountry(countryCode, country, phoneNo)
						}
						propsRef={(input) => {
							clickBtnRef = input;
						}}
						style={
							!isChecked
								? {
										marginBottom: "15px",
										color: "rgba(0, 0, 0, 0.26)",
										backgroundColor: "rgba(0, 0, 0, 0.12)",
								  }
								: { marginBottom: "15px" }
						}
					/>
				</div>
				<div className='text-center'>
					<p className='font-bold text-14'>
						{ekkLanguage?.lan?.login?.langChange}
						<span
							className={`${
								language == "en" ? "text-primary" : "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								language == "bn" ? "text-primary" : "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div>
			</div>
		</Box>
	);
}

import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import IdentityCard from "components/mobile-components/Profile/IdentityCard";
import SettingsCard from "components/mobile-components/Profile/SettingsCard";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "redux/container/userSlice";
import Business from "services/Business";
import User from "services/User";

export default function ProfilePage() {
	let business = getUserBusiness();
	let [businessData, setBusinessData] = useState({});
	let user = getUser();
	let dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const getUserInfo = async () => {
		let response = await User.getBasicInfoUpdate();
		if (response.status === 200) {
			dispatch(setUser(response.data));
		}
	};
	useEffect(() => {
		getUserInfo();
	}, []);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	//get business data
	let getBusiness = async () => {
		let response = await Business.getBusiness(business?.id);
		if (response.status === 200) {
			setLoading(false);
			setBusinessData(response.results);
			setUserBusiness({
				...business,
				business_status: response.results.business_status,
			});
		} else {
			setLoading(false);
		}
	};
	useEffect(() => {
		getBusiness();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.profile.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<section className='pt-14'>
						{loading ? (
							<IonLoading
								isOpen={true}
								message={ekkLanguage.orderList?.loader}></IonLoading>
						) : (
							<>
								<IdentityCard user={user} businessData={businessData} />
								<SettingsCard businessData={businessData} />
							</>
						)}
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { createSlice } from "@reduxjs/toolkit";
// import { shoppingCart } from "helpers/shoppingCart";

export const shoppingCartSlice = createSlice({
	name: "shoppingCart",
	initialState: {
		shopCart: [],
		total: 0,
		subtotal: 0,
		totalTax: 0,
		loanAmount: "",
		fsCoupon: 0,
	},
	reducers: {
		getCartList: (state, action) => {
			state.shopCart = action.payload;
		},

		addToCart: (state, action) => {
			// state.value -= 1;
			console.log(action);
		},
		totalAmount: (state, action) => {
			state.total = action.payload;
		},
		subtotalAmount: (state, action) => {
			state.subtotal = action.payload;
		},
		setTotalTax: (state, action) => {
			state.totalTax = action.payload;
		},
		setLoanAmount: (state, action) => {
			state.loanAmount = action.payload;
		},
		setFsCouponAmount: (state, action) => {
			state.fsCoupon = action.payload;
		},
		clearCart: (state, action) => {
			state.total = 0;
			state.subtotal = 0;
			state.totalTax = 0;
			state.loanAmount = "";
			state.fsCoupon = 0;
		},

		// incrementByAmount: (state, action) => {
		//   state.value += action.payload;
		// },
	},
});

// Action creators are generated for each case reducer function
export const {
	addToCart,
	getCartList,
	totalAmount,
	subtotalAmount,
	setTotalTax,
	setLoanAmount,
	clearCart,
	setFsCouponAmount,
} = shoppingCartSlice.actions;
export const { actions, reducer } = shoppingCartSlice;
export default reducer;

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Search from "assets/images/icon/svg/Search";
import React from "react";
import { useSelector } from "react-redux";

export default function ProductSearch({
	searchQ,
	setSearchQ,
	stickySearch,
	fetchProduct,
	singleProductSearch,
	setSelectedBrand,
	setHasMoreData,
	setReachedEnd,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const deleteQueryHandler = async (e) => {
		setSearchQ("");
		setHasMoreData(true);
		setReachedEnd(false);
	};
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<div className='relative'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<Search color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
							placeholder={ekkLanguage.home.searchLabel}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={searchQ}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									singleProductSearch(searchQ);
								}
							}}
							onChange={(e) => {
								setSearchQ(e.target.value);
								if (!e.target.value) {
									fetchProduct();
								}
							}}></input>
						{searchQ && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}

import {
    IonApp,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderDeliveryAmountSummary from "components/mobile-components/OrderDeliveryAmountSummary/OrderDeliveryAmountSummary";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function OrderDeliveryAmountSummaryPage() {
    const ekkLanguage = useSelector((state) => state.lan.lan);
    const dispatch = useDispatch();





    return (
        <IonApp>
            <IonPage>
                <IonContent>
                    <BackButtonTitle title={ekkLanguage.orderSummary.deliveryAmountPageTitle} />
                    <OrderDeliveryAmountSummary/>
                </IonContent>
            </IonPage>
        </IonApp>
    );
}

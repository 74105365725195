import {
	IonApp,
	IonContent,
	IonPage,
	IonCol,
	IonSpinner,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { digitChanger, numberWithCommas } from "helpers/helper";
import DashBoard from "services/Dashboard";

export default function BuyerSummaryDrillDown() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { item, date } = useSelector(
		(state) => state.productSummery.productData
	);
	const [pageCount, setPageCount] = useState(2);
	let [loading, setLoading] = useState(true);
	const [orderList, setOrderList] = useState({});
	const navigate = useNavigate();

	const getBuyerSummaryDrillDown = async () => {
		setPageCount(2);
		let res = await DashBoard.getBuyerSummaryDrillDown(
			item._id,
			date.start,
			date.end
		);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	let lodeMoreData = async () => {
		const response = await DashBoard.getBuyerSummaryDrillDown(
			item._id,
			date.start,
			date.end,
			pageCount
		);
		if (response.status === 200) {
			setPageCount((prev) => prev + 1);
			setOrderList([...orderList, ...response.data]);
		}
	};

	useEffect(() => {
		getBuyerSummaryDrillDown();
	}, []);
	const handleNavigate = (mid) => {
		navigate(`/order-details/${mid}/seller`);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex text-center items-center justify-between px-4'>
							<h1 className='text-16  text-center font-bold'>
								{item?.buyerName}
							</h1>
							<span className='px-7 py-2 text-14 font-bold border h-9 bg-[#FFF] rounded capitalize'>
								{date?.level}
							</span>
						</div>
						<div className='mt-5'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.orderNo}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.amount}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.length
										? orderList?.map((e, i) => {
												// console.log(e.item);
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border p-1 font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>
														<td
															onClick={() =>
																handleNavigate(e._id)
															}
															className='border text-[#2493c7] p-1 font-semibold text-10'>
															{digitChanger(e?.order_id)}
														</td>

														<td className='border p-1 font-semibold text-10'>
															৳{" "}
															{digitChanger(
																numberWithCommas(e?.total)
															)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							lodeMoreData();
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner='bubbles'></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

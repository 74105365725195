import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import NotificationCard from "./NotificationCard";
import NotificationTypeBtn from "./NotificationTypeBtn";
import SupplierNotificationCard from "./SupplierNotificationCard";

export default function NotificationList({
	loading,
	setLoading,
	notificationType,
	setNotificationType,
	from,
	localLanguage,
	setHasMoreData,
	setReachedEnd,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const supplierUnreadCount = useSelector(
		(state) => state.notification.supplierUnreadCount
	);
	const customerUnreadCount = useSelector(
		(state) => state.notification.customerUnreadCount
	);

	return (
		<div className='px-2'>
			<NotificationTypeBtn
				setNotificationType={setNotificationType}
				notificationType={notificationType}
				supplierUnreadCount={supplierUnreadCount}
				customerUnreadCount={customerUnreadCount}
				setHasMoreData={setHasMoreData}
				setReachedEnd={setReachedEnd}
			/>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : from === "customerNotification" ? (
				<NotificationCard localLanguage={localLanguage} from={from} />
			) : (
				<SupplierNotificationCard
					localLanguage={localLanguage}
					from={from}
				/>
			)}
		</div>
	);
}

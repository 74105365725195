import React from "react";
import Signature from "../Common/Signature";
import { digitChanger } from "helpers/helper";

export default function BothPartiesSignVN({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
	firstPartySignatureImage,
	setFirstPartySignatureImage,
	secondPartySignatureImage,
	setSecondPartySignatureImage,
}) {
	return (
		<div className='border-2 border-[#808080] rounded-md p-2 mt-[10px]'>
			{/* Chữ ký của bên thứ nhất */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					Bên Thứ Nhất
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14'>
				<span className='font-bold'>
					{createdBusinessDetails?.business_name}
				</span>{" "}
				là cơ sở kinh doanh độc quyền được đăng ký tại Bangladesh, có địa
				chỉ tại
				<span className='font-bold ml-1'>
					{createdBusinessDetails?.address_line
						? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
						: `${businessDetails?.address_line}, ${businessDetails?.city}`}
				</span>{" "}
				và số giấy phép thương mại{" "}
				<span className='font-bold ml-1'>
					{digitChanger(kycDetails?.trade_no)}
				</span>
				, thay mặt cho chủ sở hữu/số chứng minh nhân dân{" "}
				<span className='font-bold mx-1'>
					{digitChanger(kycDetails?.nid_no)}
				</span>{" "}
				của cơ sở nói trên.
			</p>
			{/* Chữ ký */}
			<Signature
				signatureImage={firstPartySignatureImage}
				setSignatureImage={setFirstPartySignatureImage}
				placeholder={"Chữ Ký"}
				showDash={true}
				bgColor={"white"}
			/>
			<div className='w-full bg-[#808080] h-[2px] my-[20px]'></div>
			{/* Chữ ký của bên thứ hai */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					Bên Thứ Hai
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14 pb-2'>
				<span className='font-bold'>EkkBaz Private Limited</span> là một
				công ty được đăng ký và quản lý theo Đạo luật công ty của
				Bangladesh, có địa chỉ đăng ký tại{" "}
				<span className='font-bold ml-1'>
					Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore 409051.
				</span>{" "}
				<span className='font-bold'>Zobayeda Sultana</span> thay mặt công ty
				làm Giám đốc điều hành.
			</p>
			{/* Chữ ký */}
		</div>
	);
}

import Chart from "assets/images/icon/svg/Chart";
import Download2 from "assets/images/icon/svg/Download2";
import Employees from "assets/images/icon/svg/Employees";
import CheckBusiness from "components/CheckBusiness";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import DeviceDetector from "device-detector-js";
import { digitChanger, hasActive } from "helpers/helper";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { setPwaDownloadData } from "redux/container/userSlice";
import Scrollbar from "smooth-scrollbar";
import "./styles/_SidebarComponent.scss";
import Product from "assets/images/icon/svg/Product";
import Business from "services/Business";
import { setKYC } from "redux/container/businessSettingsSlice";
import Settings from "assets/images/icon/svg/Settings";
import Supplier from "assets/images/icon/svg/Supplier";
import Bell from "assets/images/icon/svg/Bell";
import NotificationDot from "assets/images/icon/svg/NotificationDot";
import { selectTotalUnreadCount } from "redux/container/notificationSlice";
import Collection from "assets/images/icon/svg/Collection";

const LeftSidebar = ({ isMobile, menuCollapse, menuIconClick }) => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const totalCountNotifications = useSelector(selectTotalUnreadCount);
	const kyc = useSelector((state) => state.businessSettings.data);
	const pwaObject = useSelector((state) => state.user);
	const [isOpen, setIsOpen] = useState(false);
	let user = getUser();
	let userBusiness = getUserBusiness();
	const userData = useSelector((state) => state.user.data);
	const navigate = useNavigate();
	let [businessData, setBusinessData] = useState({});
	let trialPeriod = useSelector(
		(state) => state.subscription?.subscriptionPackage
	);

	let getBusiness = async () => {
		let response = await Business.getBusiness(userBusiness?.id);
		if (response.status === 200) {
			setBusinessData(response.results);
			setUserBusiness({
				...userBusiness,
				business_status: response.results.business_status,
			});
		} else {
		}
	};
	useEffect(() => {
		getBusiness();
	}, []);

	useEffect(() => {
		Modal.setAppElement("body");
		Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handler = (e) => {
		e.preventDefault();
		dispatch(setPwaDownloadData(e));
	};
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, [pathname, handler]);

	const onClick = () => {
		if (!pwaObject.pwaDownloadData) {
			return;
		}
		pwaObject.pwaDownloadData.prompt();
		setIsOpen(false);
	};
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const deviceDetector = new DeviceDetector();
	const userAgent = navigator.userAgent;
	const device = deviceDetector.parse(userAgent);
	useEffect(() => {
		if (kyc?.business_status === "Verified") {
			// if (
			// 	(userBusiness.role === "Admin") |
			// ) {
			// 	console.log(userBusiness);
			// 	navigate("/dashboard");
			// } else {
			// 	console.log(userBusiness.role);
			// 	navigate("/select-business", { replace: true });
			// }
		} else if (kyc?.business_status === "Pending") {
			dispatch(
				setKYC({
					kycUpdate: true,
				})
			);
			navigate("/business-success", { replace: true });
		} else if (kyc?.business_status === "Unverified") {
			dispatch(
				setKYC({
					kycUpdate: false,
				})
			);
			navigate("/business-success", { replace: true });
		}
	}, []);
	// if (response.business_info.role === "Admin") {
	// 	navigate("/dashboard", { replace: true });
	// } else {
	// 	toast.error("Your not admin to login to application");
	// }

	return (
		<>
			<CheckBusiness />
			<div
				onClick={menuIconClick}
				className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
					menuCollapse
						? "opacity-0 invisible absolute"
						: "opacity-40 visible fixed"
				}`}></div>
			<div
				className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
				style={
					menuCollapse
						? {
								width: 0,
								zIndex: 1000000,
						  }
						: {
								width: "300px",
								zIndex: 1000000,
						  }
				}>
				<div id='sidebar-scrollbar'>
					<span
						className='absolute top-2 right-2 bg-primary text-white p-1 rounded-[5px]'
						style={{ zIndex: 1111, lineHeight: 1 }}
						onClick={menuIconClick}>
						<i className='material-icons'>close</i>
					</span>
					<nav className='sidebar-menu'>
						<ProSidebar collapsed={menuCollapse} className='relative'>
							{!menuCollapse && (
								<SidebarHeader>
									<Link to='/profile'>
										<div
											className='logo-box flex items-center pt-[30px] px-5 pb-5'
											// style={{
											// 	background:
											// 		"url(assets/images/sidebarBg.png)",
											// 	position: "relative",
											// }}
										>
											{businessData &&
											Array.isArray(businessData.images) ? (
												<img
													src={businessData?.images[0]}
													alt='avatar'
													className='m-auto h-24 w-24 rounded-full object-cover'
												/>
											) : (
												<img
													src='assets/images/avatar.png'
													alt='avatar'
													className='h-24 w-24 rounded-full object-cover'
												/>
											)}
											<div className='user-info'>
												<p className='text-16 font-semibold text-222222 pb-[5px]'>
													{businessData?.business_name}
													{/* {user?.full_name} */}
												</p>
												<p className='text-10 pb-[5px]'>
													{businessData?.phone_number}
													{/* {digitChanger(user?.PhoneNumber)} */}
												</p>
												<p className='text-10 pb-[5px]'>
													{user?.full_name}
												</p>
												<span className='text-12'>
													{userBusiness?.business_type?.title}
													{" - "}
													<span
														className={
															userBusiness?.business_status ===
															"Verified"
																? "text-success"
																: "text-primary"
														}>
														{userBusiness?.business_status}
													</span>
													{" - "}
													{userBusiness?.role}
												</span>
												{trialPeriod?.is_trial && (
													<div>
														<span className='text-12 text-primary'>
															Trial version
														</span>
													</div>
												)}
											</div>{" "}
										</div>
									</Link>
								</SidebarHeader>
							)}
							<SidebarContent>
								<Menu iconShape='square'>
									{/* <MenuItem
										icon={<i className='material-icons'>home</i>}
										className={hasActive("/home", pathname)}>
										{ekkLanguage.letNav.homeMenuLabel}
										<NavLink to={`/home`} />
									</MenuItem> */}
									<MenuItem
										className={hasActive("/dashboard", pathname)}
										icon={
											<img src='assets/images/fav.png' alt='icon' />
										}>
										Baz AI
										<NavLink to={`/baz-ai`} />
									</MenuItem>
									{/* <MenuItem
										className={hasActive("/supplier-list", pathname)}
										icon={<Supplier />}>
										{ekkLanguage.letNav.supplier}
										<NavLink to={`/supplier-list`} />
									</MenuItem>

									<MenuItem
										className={hasActive("/product-list", pathname)}
										icon={<Product />}>
										{ekkLanguage.letNav.product}
										<NavLink to={`/product-list`} />
									</MenuItem> */}
									{/* <MenuItem
										className={hasActive("/customer-list", pathname)}
										icon={
											<img src='assets/images/store1.png' alt='' />
										}>
										{ekkLanguage.letNav.orderMenuLabel}
										<NavLink to={`/customer-list`} />
									</MenuItem>

									<MenuItem
										className={hasActive(
											"/deposit-collection",
											pathname
										)}
										icon={<Collection />}>
										{ekkLanguage.letNav.collectionDeposit}
										<NavLink to={`/deposit-collection-list`} />
									</MenuItem> */}
									{/* <MenuItem
										className={hasActive("/employees-list", pathname)}
										icon={<Employees />}>
										{ekkLanguage.letNav.myEmployeesMenuLabel}
										<NavLink to={`/employees-list`} />
									</MenuItem>
									<MenuItem
										className={hasActive("/customer-list", pathname)}
										icon={
											<img
												src='assets/images/learning.png'
												alt='icon'
											/>
										}>
										{ekkLanguage.letNav.learning}
										<NavLink to={`/learning`} />
									</MenuItem> */}
									{/* <MenuItem
                    className={hasActive("/notifications", pathname)}
                    icon={<Bell />}
                  >
                    <span className="flex">
                      <span className="mr-[3px]">
                        {" "}
                        {ekkLanguage.notification.pageTitle}
                      </span>{" "}
                      {totalCountNotifications ? (
                        <span className="pt-1">
                          <NotificationDot width={7} height={7} />
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <NavLink to={`/notifications`} />
                  </MenuItem> */}
								</Menu>
							</SidebarContent>
							{/* <SidebarFooter>
								{pwaObject.pwaDownloadData !== undefined ? (
									<div
										className={`text-center cursor-pointer p-4  flex justify-center items-center`}
										onClick={() => onClick()}>
										<Download2 color='#F94B41' />
										<p
											className={`text-primary text-14 font-semibold pl-3 pt-1`}>
											Download EkkBaz App
										</p>
									</div>
								) : (
									<p
										className={`text-primary text-14 text-center font-semibold pl-3 pt-3 py-3`}>
										Installed{" "}
										{device?.device === "desktop"
											? device?.device?.brand
											: device?.device?.model}
									</p>
								)}
							</SidebarFooter> */}
						</ProSidebar>
					</nav>
				</div>
			</div>
		</>
	);
};

export default LeftSidebar;

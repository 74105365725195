import React from "react";

export default function SupplierBell() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<g id='bell (1) 1'>
				<g id='01 align center'>
					<path
						id='Vector'
						d='M22.4546 15.8994L20.0403 7.19777C19.5248 5.33637 18.4014 3.7009 16.8491 2.55165C15.2967 1.4024 13.4046 0.805413 11.4738 0.855704C9.54294 0.905995 7.68442 1.60067 6.194 2.82918C4.70358 4.05768 3.6669 5.74942 3.24896 7.63513L1.3816 16.0387C1.29125 16.4458 1.29345 16.868 1.38805 17.2742C1.48265 17.6804 1.66724 18.0601 1.92819 18.3854C2.18914 18.7107 2.5198 18.9733 2.89577 19.1537C3.27174 19.3341 3.68343 19.4279 4.10046 19.428H7.44982C7.66294 20.4775 8.23235 21.4211 9.06158 22.0989C9.8908 22.7767 10.9288 23.1469 11.9998 23.1469C13.0708 23.1469 14.1088 22.7767 14.9381 22.0989C15.7673 21.4211 16.3367 20.4775 16.5498 19.428H19.772C20.2011 19.4278 20.6243 19.3285 21.0087 19.1378C21.3931 18.9471 21.7283 18.6701 21.988 18.3286C22.2478 17.9871 22.4251 17.5901 22.5062 17.1687C22.5873 16.7474 22.57 16.313 22.4555 15.8994H22.4546ZM11.9998 21.2851C11.4257 21.2827 10.8664 21.1031 10.3983 20.7706C9.93025 20.4382 9.57632 19.9693 9.38496 19.428H14.6147C14.4233 19.9693 14.0694 20.4382 13.6013 20.7706C13.1333 21.1031 12.5739 21.2827 11.9998 21.2851ZM20.5102 17.2041C20.4235 17.3189 20.3112 17.4119 20.1823 17.4756C20.0533 17.5393 19.9112 17.5719 19.7673 17.5708H4.10046C3.96142 17.5708 3.82415 17.5396 3.6988 17.4794C3.57345 17.4192 3.46321 17.3317 3.37622 17.2232C3.28923 17.1147 3.22771 16.9881 3.1962 16.8527C3.1647 16.7173 3.164 16.5765 3.19417 16.4408L5.06153 8.0372C5.39057 6.55725 6.20489 5.22976 7.37503 4.26576C8.54516 3.30176 10.004 2.75657 11.5195 2.71687C13.0351 2.67716 14.5204 3.14522 15.7395 4.04663C16.9585 4.94804 17.8412 6.23108 18.2472 7.69177L20.6615 16.3934C20.7009 16.531 20.7078 16.6759 20.6815 16.8166C20.6553 16.9573 20.5966 17.09 20.5102 17.2041Z'
						fill='#333'
					/>
				</g>
			</g>
		</svg>
	);
}

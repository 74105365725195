import {
	IonApp,
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductList from "components/mobile-components/SelectProduct/ProductList";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import SelectProduct from "components/mobile-components/SelectProduct/SelectProduct";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { shoppingListItem } from "helpers/shoppingCart";
import Product from "services/Product";
import { getProductSeller, getUserBusiness } from "config/user";
import ProductFilter from "components/mobile-components/SelectProduct/ProductFilter";
import { debounce } from "lodash";
import Search from "components/mobile-components/Common/Search";
import { useParams } from "react-router-dom";

export default function SelectProductPage() {
	const { type } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [productList, setProductList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [selectedItem, setSelectedItem] = useState({});
	const [loading, setLoading] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getProductList = async () => {
		setPageCount(2);
		if (searchQ) {
			let response = await await Product.singleProductSearch(searchQ);
			if (response.status === 200) {
				setProductList(response.results);
			}
		} else {
			let response = await Product.productList();
			if (response.status === 200) {
				setProductList(response.results);
			}
		}
	};

	useEffect(() => {
		getProductList();
	}, []);

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				const response = await Product.singleProductSearch(
					searchQ,
					"all",
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				const response = await Product.productList("variant", pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getProductList();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonHeader>
					<BackButtonTitle title={ekkLanguage.selectProduct.pageTitle} />
				</IonHeader>
				<IonContent>
					<section className='pt-14'>
						<div className='px-4 py-5'>
							<Search
								ekkLanguage={ekkLanguage}
								placeholder={ekkLanguage.productList.searchYourProduct}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						</div>

						<ProductList
							loading={loading}
							productList={productList}
							setProductList={setProductList}
						/>
						<SelectProduct type={type} />
					</section>

					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import Menu from "assets/images/icon/svg/Menu";
import Phone from "assets/images/icon/svg/Phone";
import { getLanguage, setLanguage } from "config/language";
import { digitChanger } from "helpers/helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLan } from "redux/container/languageSlice";
import "./style.scss";

export default function Navigation({ blogPost, scrolling, bg }) {
	const dispatch = useDispatch();
	const appLanguage = getLanguage();
	const [promptInstall, setPromptInstall] = useState(null);
	const [supportsPWA, setSupportsPWA] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const options = [
		{ value: "en", label: ekkLanguage.landing.english },
		{ value: "bn", label: ekkLanguage.landing.bangla },
	];
	let customStyles = {
		option: (provided, state) => ({
			...provided,
			zIndex: 10,
			paddingLeft: 30,
		}),
		singleValue: (provided, state) => ({
			...provided,
			borderWidth: 0,
			height: 35,
			borderRadius: 0,
			display: "flex",
			fontSize: 20,
		}),
		control: (provided, state) => ({
			...provided,
			borderRadius: 0,
			borderWidth: 0,
			outLine: "none",
			height: 35,
			fontSize: 20,
			borderBottomLeftRadius: 5,
			borderTopLeftRadius: 5,
			background: "transparent",
			width: 122,
		}),
	};
	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			console.log("we are being triggered :D");
			setSupportsPWA(true);
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const languageSubmitHandler = (e) => {
		setLanguage(e.value);
		dispatch(selectLan());
	};

	const onClickPWADownload = (evt) => {
		evt.preventDefault();
		if (!promptInstall) {
			return;
		}
		promptInstall.prompt();
	};
	// if (!supportsPWA) {
	// 	return null;
	// }

	return (
		<>
			<div>
				<div className={`absolute z-50 w-full `}>
					<div className={`container hidden ${"mt-9"} lg:flex`}>
						<div>
							<Link to='/'>
								<img
									src='/assets/images/ai.png'
									alt='logo'
									className='w-[100px] mr-[80px] sm:w-[145px]'
								/>
							</Link>
						</div>
						<div className='flex items-center justify-between w-full'>
							<ul className='flex items-center header-menu-items'>
								{/* <li className='mr-4 xl:mr-10'>
									<a
										href='https://ekkbaz.com/'
										className={`${!blogPost && "text-white"}  text-22`}
									>
										{ekkLanguage.landing.retail}
									</a>
								</li>
								<li className=''>
									<a
										href='https://seller.ekkbaz.com/'
										className={`${!blogPost && "text-white"}  text-22`}
									>
										{ekkLanguage.landing.manufacture}
									</a>
								</li> */}
							</ul>
							<ul className='flex items-center'>
								{/* <li>
									<a
										href='https://forms.office.com/r/RdhTRDjq75'
										target='_blank'
										rel='noreferrer'>
										<button
											// onClick={onClickPWADownload}
											className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn flex items-center`}>
											{ekkLanguage.landing.apiBtn}{" "}
										</button>
									</a>
								</li> */}
								{/* <li className='flex items-center xl:pr-10 pr-4'>
									<Phone />
									<span className='px-3 text-20 font-bold xl:block hidden'>
										{ekkLanguage.landing.hotline}
									</span>
									<a
										href='tel:01701898144'
										className='text-18 text-primary font-bold'
									>
										{digitChanger("01701898144")}
									</a>
								</li> */}
								{/* <li>
									<a
										href='https://play.google.com/store/apps/details?id=ekkbaz.com&hl=en&gl=US'
										target='_blank'
										rel='noreferrer'>
										<button
											// onClick={onClickPWADownload}
											className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn flex items-center`}>
											<img
												src='/assets/images/googlePlay.png'
												alt=''
												className='mr-2'
											/>
											{ekkLanguage.landing.downloadBtn}{" "}
										</button>
									</a>
								</li> */}
								{/* <li className='flex items-center xl:pl-10 pl-4'>
									<World />
									<Select
										styles={customStyles}
										options={options}
										isSearchable={false}
										defaultValue={options.find(
											(el) => el.value === appLanguage
										)}
										onChange={(e) => languageSubmitHandler(e)}
										components={{
											IndicatorSeparator: () => null,
										}}
									/>
								</li> */}
							</ul>
						</div>
					</div>

					<div className={`container ${bg ? "mt-0 my-1" : "mt-3"}`}>
						<div
							className={`${
								scrolling ? "mt-0 py-2" : "mt-0"
							} justify-between flex lg:hidden`}>
							<div>
								<Link to='/home'>
									<img
										src='/assets/images/ai.png'
										alt='logo'
										className='sm:w-[145px] w-[100px] mr-[80px]'
									/>
								</Link>
							</div>
							{/* <div className='flex items-center'>
								<Link to='#!' onClick={() => setShowMenu(!showMenu)}>
									{bg ? (
										<Menu color={`#266666`} />
									) : (
										<Menu
											color={`${scrolling ? "#266666" : "#fff"}`}
										/>
									)}
								</Link>
							</div> */}
						</div>
						<div
							className={`w-full bg-white z-50 lg:hidden block top-[130px] p-[20px] rounded-[5px] transition-all ${
								showMenu ? "block" : "hidden"
							}`}>
							<div>
								<ul className='flex justify-end'>
									{/* <li>
										<a
											href='https://play.google.com/store/apps/details?id=ekkbaz.com&hl=en&gl=US'
											target='_blank'
											rel='noreferrer'>
											<button
												// onClick={onClickPWADownload}
												className={`pointer text-white text-14 sm:text-18 font-extrabold px-5 py-4 rounded-[10px] pwa-download-btn mr-3 flex items-center`}>
												<img
													src='/assets/images/googlePlay.png'
													alt='play'
													className='mr-2'
												/>
												<span className='pl-1'>
													{ekkLanguage.landing.downloadBtn}
												</span>
											</button>
										</a>
									</li> */}
									{/* <li className='flex items-center xl:pl-10 pl-4'>
										<World />
										<Select
											styles={customStyles}
											options={options}
											isSearchable={false}
											defaultValue={options.find(
												(el) => el.value === appLanguage
											)}
											onChange={(e) => languageSubmitHandler(e)}
											components={{
												IndicatorSeparator: () => null,
											}}
										/>
									</li> */}
								</ul>
							</div>
							<div className='py-4'>
								<ul>
									<li className='pb-3'>
										<Link to='#' className='text-16 sm:text-24'>
											{ekkLanguage.landing.retail}
										</Link>
									</li>
									<li>
										<a
											href='https://seller.ekkbaz.com/'
											className='text-16 sm:text-24'>
											{ekkLanguage.landing.manufacture}
										</a>
									</li>
								</ul>
							</div>

							<hr className='mt-3'></hr>
							<div>
								<div className='flex items-center xl:pr-10 pr-4 align-center justify-center pt-3'>
									<Phone />
									<span className='px-3 text-16 font-bold sm:text-20 md:text-20 '>
										{ekkLanguage.landing.hotline}
									</span>
									<a
										href='tel:+8801701898144'
										className='sm:text-20 text-16 text-primary font-bold'>
										+{digitChanger(8801701898144)}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

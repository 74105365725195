import {
  IonApp,
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Banner from "components/mobile-components/VoiceRecord/Banner";
import Recorder from "components/mobile-components/VoiceRecord/Recorder";
import Results from "components/mobile-components/VoiceRecord/Results";
import React, { useState } from "react";
import Product from "services/Product";
import { useSelector } from "react-redux";
import MatchKeyWords from "components/mobile-components/VoiceRecord/MatchKeywords";
import Products from "components/mobile-components/VoiceRecord/Products";

export default function VoiceRecorder() {
  const [loading, setLoading] = useState(false);
  const [productNames, setProductNames] = useState([]);
  const [page, setPage] = useState(2);
  const [productList, setProductList] = useState([]);
  const [searchedKeyWord, setSearchedKeyWord] = useState([]);
  const [speech, setSpeech] = useState("");
  const [hasMoreData, setHasMoreData] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const handleSearchKeyword = async (keyword) => {
    setLoading(true);
    setHasMoreData(true);
    const response = await Product.getProducts(keyword);
    if (response.status === 200) {
      setLoading(false);
      setProductList(response.data);
    } else {
      setLoading(false);
    }
  };

  const loadMoreProduct = async () => {
    if (hasMoreData) {
      const response = await Product.getProducts(productNames, page);
      if (response.status === 200) {
        setPage((page) => page + 1);
        setProductList(response.data);
        setHasMoreData(false);
      } else {
        setHasMoreData(false);
      }
    }
  };

  let settings = {
    nameShow: false,
    titleShow: true,
    skuShow: false,
    priceShow: true,
    oldPriceShow: false,
    distShow: false,
    profitShow: true,
    sellPriceShow: false,
    supplierNameShow: false,
    link: "product-detail",
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.voiceOrder.pageTitle} />
          <section className="pt-20 px-4 relative">
            <IonLoading
              isOpen={loading}
              message={ekkLanguage.orderList.loader}
            ></IonLoading>
            <Banner ekkLanguage={ekkLanguage} />
            <Recorder
              setLoading={setLoading}
              setSpeech={setSpeech}
              setSearchedKeyWord={setSearchedKeyWord}
            />
            {speech && (
              <div className="pt-4 flex justify-between items-center">
                <h4 className="font-bold text-20 text-[#000]">
                  {ekkLanguage.voiceOrder.speechTitle}
                </h4>
              </div>
            )}
            <Results speech={speech} ekkLanguage={ekkLanguage} />

            {searchedKeyWord?.length ? (
              <div className="pb-5 pt-4 flex justify-between items-center">
                <h4 className="font-bold text-20 text-[#000]">
                  {ekkLanguage.voiceOrder.keywordTitle}
                </h4>
              </div>
            ) : (
              ""
            )}
            <MatchKeyWords
              searchedKeyWord={searchedKeyWord}
              setProductNames={setProductNames}
              handleSearchKeyword={handleSearchKeyword}
              setHasMoreData={setHasMoreData}
              productNames={productNames}
              ekkLanguage={ekkLanguage}
            />
            
              {searchedKeyWord?.length && productNames ? (
                <>
                  <div className="pb-2 pt-2 flex justify-between items-center">
                    <h4 className="font-bold text-20 text-[#000]">
                      {ekkLanguage.voiceOrder.productsTitle}
                    </h4>
                  </div>
                  <Products
                    recommendData={productList}
                    settings={settings}
                    setHasMoreData={setHasMoreData}
                  />
                </>
              ) : (
                ""
              )}
           
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreProduct();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 1000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={hasMoreData ? ekkLanguage.orderList.loader : null}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

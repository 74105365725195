import React from "react";
import { useSelector } from "react-redux";
import SingleAMountBox from "./SingleAMountBox";

export default function CustomerDetails({ customer, customerInfo }) {
	console.log(customer);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<div className='h-[230px] bg-white mb-3 flex justify-center items-center pt-14'>
				<img src='/assets/images/shops.png' alt='store' />
			</div>
			<div className='px-4'>
				<div>
					<h4 className='text-black-1000 font-bold text-18 mb-[10px]'>
						{customerInfo?.related_business}
					</h4>
					<h4 className='text-black-500 font-semibold text-14 mb-[10px]'>
						{customerInfo?.related_business_address}
					</h4>
					{/* <h5 className='text-black-1000 font-semibold text-14 mb-[10px]'>
						{ekkLanguage.customerDetails.number}:{" "}
						<span className='text-black-500 font-semibold text-14 mb-[10px]'>
							{customerInfo?.related_business_phone}
						</span>
					</h5> */}
					<h5 className='text-black-1000 font-semibold text-14'>
						{ekkLanguage.customerDetails.businessType}:{" "}
						<span className='text-black-500 font-semibold text-14 mb-[10px]'>
							{customerInfo?.related_business_type}
						</span>
					</h5>
				</div>

				<div className='pt-5'>
					<div className='grid grid-cols-2 gap-4'>
						<SingleAMountBox
							name={ekkLanguage.dashboard.totalOrder}
							value={customer?.total_orders}
							withComma={false}
						/>
						<SingleAMountBox
							name={ekkLanguage.dashboard.totalAmount}
							value={customer?.total_amount}
							withComma={true}
						/>
					</div>
					<div className='grid grid-cols-1 gap-0 my-4'>
						<SingleAMountBox
							name={ekkLanguage.dashboard.monthlyOrder}
							value={customer?.month?.total_orders}
							withComma={true}
						/>
					</div>
					<div className='grid grid-cols-2 gap-4'>
						<SingleAMountBox
							name={ekkLanguage.dashboard.totalPaid}
							value={customer?.total_paid}
							withComma={true}
						/>
						<SingleAMountBox
							name={ekkLanguage.dashboard.totalDue}
							value={customer?.due}
							withComma={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

import {
	IonApp,
	IonButton,
	IonContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import KYCInfo from "components/mobile-components/Profile/KYCInfo";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import User from "services/User";

export default function KYCInfoPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(false);
	const [kycInfo, setKycInfo] = useState(null);
	const business = getUserBusiness();
	const [kycSubmitBTN, setKycSubmitBTN] = useState(false);

	const getKyc = async () => {
		setLoading(true);
		let response = await User.getKyc(business?.id);
		if (response.status === 200) {
			setKycInfo(response.results);
			setLoading(false);
			setKycSubmitBTN(false);
		} else {
			setKycSubmitBTN(true);
			setLoading(false);
			setKycInfo(false);
		}
	};
	useEffect(() => {
		getKyc();
	}, []);

	console.log();
	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.kycInfo.pageTitle} />
					<section className='pt-14 px-2'>
						{loading ? (
							<IonLoading
								isOpen={true}
								message={ekkLanguage.orderList?.loader}></IonLoading>
						) : (
							<>
								{" "}
								{!kycSubmitBTN ? (
									<KYCInfo
										loading={loading}
										kycInfo={kycInfo}
										ekkLanguage={ekkLanguage}
									/>
								) : (
									<>
										<h3 className='pt-12 font-bold text-20 px-10 text-center'>
											{ekkLanguage.businessCreateName.kycInformation}
										</h3>
										<Link
											to='/nid-verification'
											className='w-[calc(100%-30px)] left-[50%] translate-x-[-50%] absolute bottom-[30px]'>
											<IonButton
												className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success mb-5'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.2)",
												}}>
												{ekkLanguage.businessCreateName.kycSubmit}
											</IonButton>
										</Link>
									</>
								)}
							</>
						)}
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";

export default function Player() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='36'
			height='36'
			viewBox='0 0 36 36'
			fill='none'>
			<circle cx='18' cy='18' r='18' fill='white' />
			<path
				d='M24.05 16.8742C24.9167 17.3745 24.9167 18.6255 24.05 19.1258L15.95 23.8024C15.0833 24.3027 14 23.6773 14 22.6765L14 13.3235C14 12.3227 15.0833 11.6973 15.95 12.1976L24.05 16.8742Z'
				fill='#00B224'
			/>
		</svg>
	);
}

import React, { useState } from "react";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { loginFromSignUp } from "redux/container/authSlice";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { IonRadio, IonRadioGroup } from "@ionic/react";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function BannerRegistration() {
	var sSize = window.matchMedia("(max-width: 599px)");
	let [captaValue, setCaptaValue] = useState(null);
	let userInfo = useSelector((state) => state.user);
	const countryCode = userInfo?.createUserData?.countryCode;
	const country = userInfo?.createUserData?.country;
	const phoneNo = userInfo?.createUserData?.phoneNumber;
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState();
	const [error, setError] = useState("");
	const [nameError, setNameError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const history = useNavigate();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [passwordType, setPasswordType] = useState(false);
	const [confirmPasswordType, setConfirmPasswordType] = useState(false);
	const [message, setMessage] = useState("");
	const [birthDay, setBirthDay] = useState({
		year: "",
		month: "",
		day: "",
	});
	const [gender, setGender] = useState("");

	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		// strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
		let verifiedList = Object.values(strengthChecks).filter((value) => value);
		let strengthCheck = () => {
			if (verifiedList.length == 4) {
				return ekkLanguage.registration.strong;
			} else if (verifiedList.length >= 2) {
				return ekkLanguage.registration.medium;
			} else if (verifiedList.length >= 1) {
				return ekkLanguage.registration.weak;
			}
		};
		setPassword(passwordValue);
		setMessage(strengthCheck());
	};

	const getActiveColor = (type) => {
		if (type === ekkLanguage.registration.strong) return "#8BC926";
		if (type === ekkLanguage.registration.medium) return "#FEBD01";
		return "#F94B41";
	};

	let profileCreateHandler = async () => {
		if (name.length > 3) {
			if (password.length > 3 && confirmPassword.length > 3) {
				if (!(password && confirmPassword !== password)) {
					let req_body = {
						phone_number: (countryCode + phoneNo).trim(),
						full_name: name,
						gender: gender,
						dob: moment(
							`${birthDay.year}-${Number(birthDay.month) + 1}-${
								birthDay.day
							}`
						).format("YYYY-MM-DD"),
						email: email,
						password: password,
						country: country,
						user_type: "Business User",
					};
					try {
						const response = await Auth.createNewUser(req_body);
						if (response.status === 200) {
							updateToken(response);
							setLocalUser(response?.data);
							dispatch(loginFromSignUp(response));
							history("/");
							// toast.success(ekkLanguage.registration.successMessage);
							localStorage.setItem("auth", true);
						} else {
						}
					} catch (error) {
						console.log(error);
						toast.error(ekkLanguage.registration.errorMessage);
					}
				} else {
					setError(ekkLanguage.registration.passNotMatch);
				}
			} else {
				setLengthError(ekkLanguage.registration.passLengthValidLabel);
			}
		} else {
			setNameError(ekkLanguage.registration.validationLabel);
		}
	};
	async function onChange(value) {
		setCaptaValue(value);
		if (value) {
			let req_body = {
				country,
				phone_number: (countryCode + phoneNo).trim(),
				captcha_key: value,
			};
			let response = await Auth.userCaptcha(req_body);
			if (response.status === 200) {
				if (response.data?.user?.Country === "Bangladesh") {
					setLanguage("bn");
					dispatch(selectLan());
				} else {
					setLanguage("en");
					dispatch(selectLan());
				}

				updateToken(response);
			}
		}
	}

	return (
		<div className='relative banner-area-wrapper '>
			<div className='pb-[70px] sm:pb-[70px] container m-auto  bg-F2F3F7'>
				<div className='flex items-center md:space-x-0 lg:space-x-10 relative z-10 slider-wrapper'>
					<div className='sm:w-5/12 w-8/12 slider-area'>
						<BannerSlider />
					</div>
					<div className='lg:w-7/12 w-full banner-content pb-[70px] lg:pb-[0]'>
						<>
							{captaValue ? (
								<>
									<div className=' bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md w-full'>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												profileCreateHandler();
											}}>
											<div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
												<div className='pb-3'>
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{ekkLanguage.registration.nameLabel}

														<span
															onClick={() => {
																localStorage.clear();
																window.location.href = "/";
															}}
															className='text-xs text-red cursor-pointer flex items-center lg:hidden'>
															<span className='mr-1'>
																<LeftArrow2 />
															</span>
															{ekkLanguage?.password.backWord}
														</span>
													</label>
													<div className='relative'>
														<input
															onChange={(event) =>
																setName(event.target.value)
															}
															type='text'
															label={
																ekkLanguage.registration
																	.nameLabel
															}
															placeholder={
																ekkLanguage.registration
																	.namePlaceholder
															}
															required
															id={"ekk-input"}
															style={{
																paddingLeft: sSize.matches
																	? 10
																	: 20,
																height: sSize.matches ? 50 : 60,
																flex: "flex: 1 0 auto",
																borderRadius: 15,
																borderWidth: 1,
																border: "1px solid #00000026",
															}}
															className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
														/>
													</div>
													{nameError && (
														<span className='text-primary absolute'>
															{nameError}
														</span>
													)}
												</div>
												{/* Email  */}
												<div className='pb-3'>
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{ekkLanguage.registration.emailLabel}
														<span
															onClick={() => {
																localStorage.clear();
																window.location.href = "/";
															}}
															className='text-xs text-red cursor-pointer items-center hidden lg:flex'>
															<span className='mr-1'>
																<LeftArrow2 />
															</span>
															{ekkLanguage?.password.backWord}
														</span>
													</label>
													<div className='relative'>
														<input
															required
															onChange={(event) =>
																setEmail(event.target.value)
															}
															type='email'
															label={
																ekkLanguage.registration
																	.emailLabel
															}
															placeholder={
																ekkLanguage.registration
																	.emailPlaceholder
															}
															id={"ekk-input"}
															style={{
																paddingLeft: sSize.matches
																	? 10
																	: 20,
																height: sSize.matches ? 50 : 60,
																flex: "flex: 1 0 auto",
																borderRadius: 15,
																borderWidth: 1,
																border: "1px solid #00000026",
															}}
															className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
														/>
													</div>
												</div>
												{/* Gender */}
												<div className='pb-3'>
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{ekkLanguage.registration.gender}
													</label>
													<IonRadioGroup
														className='grid grid-cols-2 gap-2 '
														value={gender}
														onIonChange={(e) => {
															setGender(e.detail.value);
														}}>
														<IonRadio
															className='w-full bg-white text-14 text-gray-darker border rounded-[15px] flex justify-center items-center py-[14px] px-2'
															value='Male'
															justify='start'
															labelPlacement='end'
															color='success'>
															{ekkLanguage.registration.male}
														</IonRadio>
														<IonRadio
															className='w-full bg-white text-14 text-gray-darker border rounded-[15px] flex justify-center items-center py-3 px-2'
															value='Female'
															justify='start'
															labelPlacement='end'
															color='success'>
															{ekkLanguage.registration.female}
														</IonRadio>
														{/* <IonRadio
                className="w-full bg-white text-14 text-gray-darker border rounded-[5px] flex justify-center items-center py-3 px-2"
                value="Others"
                justify="start"
                labelPlacement="end"
                color="success"
              >
                {ekkLanguage.registration.others}
              </IonRadio> */}
													</IonRadioGroup>
												</div>
												{/* Date of Birth */}
												<div className='pb-3'>
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{ekkLanguage.registration.dobLabel}
													</label>
													<div className='grid grid-cols-3 gap-2'>
														<div className='relative'>
															<DayPicker
																defaultValue={
																	ekkLanguage.registration.day
																}
																year={birthDay?.year}
																month={birthDay?.month}
																endYearGiven // mandatory if end={} is given in YearPicker
																required={true}
																value={birthDay?.day}
																onChange={(day) => {
																	setBirthDay({
																		...birthDay,
																		day: day,
																	});
																}}
																id={"day"}
																name={"day"}
																classes={`py-[14px] ${
																	birthDay?.day
																		? "text-gray-darker"
																		: "text-gray-400"
																} text-14 px-4 appearance-none border-[#00000026] border-[1px] rounded-[15px] w-full font-semibold`}
																optionClasses={"option classes"}
															/>
															<img
																className='absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90'
																src='/assets/images/rightArrow.png'
																alt=''
															/>
														</div>
														<div className='relative'>
															{" "}
															<MonthPicker
																defaultValue={
																	ekkLanguage.registration
																		.month
																}
																short
																endYearGiven // mandatory if end={} is given in YearPicker
																year={birthDay?.year}
																required={true}
																value={birthDay?.month}
																onChange={(month) => {
																	setBirthDay({
																		...birthDay,
																		month: month,
																	});
																}}
																id={"month"}
																name={"month"}
																classes={`py-[14px] ${
																	birthDay?.month
																		? "text-gray-darker"
																		: "text-gray-400"
																} text-14 px-4 appearance-none border-[#00000026] border-[1px] rounded-[15px] w-full font-semibold`}
																optionClasses={"option classes"}
															/>
															<img
																className='absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90'
																src='/assets/images/rightArrow.png'
																alt=''
															/>
														</div>
														<div className='relative'>
															<YearPicker
																defaultValue={
																	ekkLanguage.registration.year
																}
																start={1970}
																end={2006}
																reverse
																required={true}
																value={birthDay?.year}
																onChange={(year) => {
																	setBirthDay({
																		...birthDay,
																		year: year,
																	});
																}}
																id={"year"}
																name={"year"}
																classes={`py-[14px] ${
																	birthDay?.year
																		? "text-gray-darker"
																		: "text-gray-400"
																} text-14 px-4 appearance-none border-[#00000026] border-[1px] rounded-[15px] w-full font-semibold`}
																optionClasses={"option classes"}
															/>
															<img
																className='absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90'
																src='/assets/images/rightArrow.png'
																alt=''
															/>
														</div>
													</div>
												</div>

												<div className='pb-3'>
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{
															ekkLanguage.registration
																.passwordLabel
														}
													</label>
													<div className='relative'>
														<input
															onChange={(event) => {
																handlePassword(
																	event.target.value
																);
																setPassword(event.target.value);
															}}
															type={
																passwordType
																	? "text"
																	: "password"
															}
															label={
																ekkLanguage.registration
																	.passwordLabel
															}
															placeholder={
																ekkLanguage.registration
																	.passwordPlaceholder
															}
															required
															id={"ekk-input"}
															style={{
																paddingLeft: sSize.matches
																	? 10
																	: 20,
																height: sSize.matches ? 50 : 60,
																flex: "flex: 1 0 auto",
																borderRadius: 15,
																borderWidth: 1,
																border: "1px solid #00000026",
															}}
															className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
														/>
														<span
															className='absolute right-3 top-[50%]'
															onClick={() => {
																setPasswordType(!passwordType);
															}}
															style={{
																transform: "translateY(-50%)",
															}}>
															{" "}
															{passwordType ? (
																<EyeOn />
															) : (
																<EyeOff />
															)}
														</span>
														<div>
															{password.length > 3 ? (
																<p
																	className='message absolute'
																	style={{
																		color: getActiveColor(
																			message
																		),
																	}}>
																	{
																		ekkLanguage.registration
																			.passwordLength
																	}{" "}
																	{message}
																</p>
															) : null}
														</div>
													</div>

													{lengthError && (
														<span className='text-primary absolute'>
															{lengthError}
														</span>
													)}
												</div>
												<div className='pb-3'>
													{" "}
													<label
														className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
														{
															ekkLanguage.registration
																.confirmPassLabel
														}
													</label>
													<div className='relative'>
														<input
															onChange={(event) =>
																setConfirmPassword(
																	event.target.value
																)
															}
															type={
																confirmPasswordType
																	? "text"
																	: "password"
															}
															label={
																ekkLanguage.registration
																	.confirmPassLabel
															}
															placeholder={
																ekkLanguage.registration
																	.confirmPassPlaceholder
															}
															required
															eyeActive={true}
															setEyeIcon={setConfirmPasswordType}
															eyeIcon={confirmPasswordType}
															id={"ekk-input"}
															style={{
																paddingLeft: sSize.matches
																	? 10
																	: 20,
																height: sSize.matches ? 50 : 60,
																flex: "flex: 1 0 auto",
																borderRadius: 15,
																borderWidth: 1,
																border: "1px solid #00000026",
															}}
															className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
														/>
														<span
															className='absolute right-3 top-[50%]'
															onClick={() => {
																setConfirmPasswordType(
																	!confirmPasswordType
																);
															}}
															style={{
																transform: "translateY(-50%)",
															}}>
															{" "}
															{confirmPasswordType ? (
																<EyeOn />
															) : (
																<EyeOff />
															)}
														</span>
													</div>
													{error && (
														<span className='text-primary absolute text-12 my-1'>
															{error}
														</span>
													)}
												</div>
											</div>

											<div className='flex justify-center'>
												{/* <Button
						title={ekkLanguage.registration.btnLabel}
						loading={loading}
						// onClick={profileCreateHandler}
						propsRef={(input) => input}
						type='submit'
					/> */}

												<button
													onClick={profileCreateHandler}
													// ref={clickBtnRef}
													style={{
														background:
															"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
													}}
													className={`lg:mt-4 mt-5 text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
													{ekkLanguage.registration.btnLabel}
												</button>
											</div>
										</form>
									</div>
								</>
							) : (
								<div className='flex items-center justify-center'>
									<div className='text-center'>
										<h5 className='text-24 font-semibold text-black-1000 mb-10'>
											Verification
										</h5>
										<p className='text-14 font-normal text-black-500 mb-10'>
											We are checking you! Are you human or robot?
										</p>
										<h6 className='text-16 font-bold text-black-1000 mb-6'>
											Please wait a moment.
										</h6>
										{/* <img src='/assets/images/robot.png' className='m-auto' alt='' /> */}
										<></>
										<GoogleReCaptcha
											onVerify={(token) => {
												onChange(token);
											}}
										/>
										{/* <ReCAPTCHA sitekey={baseUrl.sitekey} onChange={onChange} /> */}
									</div>
								</div>
							)}
						</>
					</div>
				</div>
			</div>
		</div>
	);
}

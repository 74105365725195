import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notification from "services/Notification";
import { getUserBusiness } from "config/user";
import { setNotification } from "redux/container/notificationSlice";
import SupplierNotificationCard from "components/mobile-components/Notifications/SupplierNotificationCard";
import { setUserOrderType } from "redux/container/orderDetailsSlice";
import { getLanguage } from "config/language";

export default function SupplierNotificationPage() {
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(2);
  const localLanguage = getLanguage();
  const id = getUserBusiness()?.id;
  const ekkLanguage = useSelector((state) => state.lan.lan);
  useEffect(() => {
    dispatch(setUserOrderType("buyer"));
  }, [dispatch]);

  let getNotificationList = async (value) => {
    let response = await Notification.getNewSupplierNotificationList(
      pageCount,
      id,
      localLanguage === "bn" ? "BD" : "EN"
    );
    if (response.status === 200) {
      setPageCount((prev) => prev + 1);
      dispatch(setNotification([...notificationList, ...response?.results]));
      setNotificationList([...notificationList, ...response?.results]);
    }
  };

  let getInitSupplierNotificationList = async () => {
    setLoading(true);
    setPageCount(2);
    let response = await Notification.getNewSupplierNotificationList(
      1,
      id,
      localLanguage === "bn" ? "BD" : "EN"
    );
    if (response.status === 200) {
      setLoading(false);
      dispatch(setNotification(response?.results));
      setNotificationList(response?.results);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitSupplierNotificationList();
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent scrollEvents={true}>
          <BackButtonTitle title={ekkLanguage.notification.supllierPageTitle} />
          <section>
            <div className="pt-14 px-2">
              {loading ? (
                <IonLoading
                  isOpen={true}
                  message={ekkLanguage.orderList?.loader}
                ></IonLoading>
              ) : (
                <SupplierNotificationCard />
              )}
            </div>
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              getNotificationList();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner="bubbles"
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

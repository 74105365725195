import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import PropertyType from "components/mobile-components/VerifyBusiness/PropertyType";
import React from "react";
import { useSelector } from "react-redux";

export default function PropertyTypePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<PropertyType />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { createSlice } from "@reduxjs/toolkit";

export const paymentMethodSlice = createSlice({
	name: "paymentMethod",
	initialState: {
		deliveryDate: "",
		paymentMethod: "",
		paymentTerm: "",
		paymentTermId: "",
		creditFee: 0,
		paymentTermText: "",
		paymentName: "",
		paymentMethodText: "",
		loanCreditFee: 0,
		paymentMethodDescription: "",
		subTotalAmount: 0,
		hasLoan: false,
		fsPromoAmount: 0,
		loanAmount: 0,
		currency: {},
	},
	reducers: {
		paymentData: (state) => {
			return state;
		},
		deliveryDate: (state, action) => {
			state.deliveryDate = action.payload;
		},
		paymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
		},
		paymentTerm: (state, action) => {
			state.paymentTerm = action.payload;
		},
		paymentTermId: (state, action) => {
			state.paymentTermId = action.payload;
		},
		setPaymentName: (state, action) => {
			state.paymentName = action.payload;
		},
		creditFee: (state, action) => {
			state.creditFee = action.payload;
		},
		setPaymentMethodText: (state, action) => {
			state.paymentMethodText = action.payload;
		},
		setPaymentMethodDescription: (state, action) => {
			state.paymentMethodDescription = action.payload;
		},
		setLoanCreateFee: (state, action) => {
			state.loanCreditFee = action.payload;
		},
		setDownPayment: (state, action) => {
			state.subTotalAmount = action.payload.subTotalAmount;
			state.hasLoan = action.payload.hasLoan;
			state.fsPromoAmount = action.payload.fsPromoAmount;
			state.loanAmount = action.payload.loanAmount;
			state.currency = action.payload.currency;
		},
		clearPaymentCard: (state, action) => {
			state.deliveryDate = "";
			state.paymentMethod = "";
			state.paymentTerm = "";
			state.paymentTermId = "";
			state.creditFee = 0;
			state.paymentTermText = "";
			state.paymentName = "";
			state.paymentMethodText = "";
			state.loanCreditFee = 0;
			state.paymentMethodDescription = "";
			state.subTotalAmount = 0;
			state.hasLoan = false;
			state.fsPromoAmount = 0;
			state.loanAmount = 0;
			state.currency = {};
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	deliveryDate,
	paymentMethod,
	paymentTerm,
	paymentTermId,
	creditFee,
	clearPaymentCard,
	setPaymentName,
	setPaymentMethodText,
	setLoanCreateFee,
	setPaymentMethodDescription,
	setDownPayment,
} = paymentMethodSlice.actions;
export const { actions, reducer } = paymentMethodSlice;

export default reducer;

import Location from "assets/images/icon/svg/Location";
import { getProductSeller } from "config/user";
import { shoppingListItem } from "helpers/shoppingCart";
import { useSelector } from "react-redux";
import Card from "../Common/Card";
let ps = localStorage.getItem("ps");
let psParse = JSON.parse(ps);

export default function SupplierDetailsCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-2 pb-4'>
			<Card title={ekkLanguage.createOrder.sellerDetailsTitle}>
				<div className='pt-4 px-2'>
					<h4 className='text-success text-14 font-bold pb-3'>
						{/* {getUserBusiness()?.business_name} */}
						{shoppingListItem().length !== 0
							? shoppingListItem()[0]?.seller_business_name
								? shoppingListItem()[0]?.seller_business_name
								: psParse?.seller_business_name
							: psParse?.seller_business_name}
						{/* ACI Limited */}
					</h4>

					<div className='block rounded-md'>
						<div className='flex justify-between items-center'>
							<div className='flex'>
								<span>
									<Location />
								</span>
								<p className='text-12 text-000080 ml-2'>
									{/* {shoppingListItem().length !== 0
                    ? shoppingListItem()[0].seller_business_address
                    : ""} */}
									{getProductSeller()?.seller_business_address}

									{/* Dhaka, Bangladesh */}
								</p>
							</div>
							{/* <div>
								<Link
									to='/location'
									className='text-14 font-bold text-primary'>
									View Map
								</Link>
							</div> */}
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

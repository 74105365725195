import React from "react";
import { useNavigate } from "react-router-dom";

export default function OverView({ ekkLanguage }) {
	const navigate = useNavigate();

	return (
		<div className='flex justify-start'>
			<div className='text-center'>
				<div
					onClick={() => navigate("/public-view")}
					className='rounded-full h-[50px] w-[50px] flex m-auto'
					style={{
						background: "rgba(0, 178, 36, 0.15)",
						boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.05)",
					}}>
					<img src='assets/images/eye.png' className='m-auto' alt='icon' />
				</div>
				<p className='text-12 font-semibold mt-[7px] text-262626-1000'>
					{ekkLanguage.dashboard.preview}
				</p>
			</div>
			<div className='text-center ml-10'>
				<div
					onClick={() => navigate("/promo-code-list")}
					className='rounded-full h-[50px] w-[50px] flex m-auto'
					style={{
						background: "rgba(0, 178, 36, 0.15)",
						boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.05)",
					}}>
					<img
						src='assets/images/coupons.png'
						className='m-auto'
						alt='icon'
					/>
				</div>
				<p className='text-12 font-semibold mt-[7px] text-262626-1000'>
					{ekkLanguage.dashboard.promoCode}
				</p>
			</div>
		</div>
	);
}

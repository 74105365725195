import React from "react";

export default function StockOutMini() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='30'
			viewBox='0 0 30 30'
			fill='none'
			style={{ margin: "auto" }}>
			<g id='out of stock 3'>
				<g id='Group'>
					<g id='Group_2'>
						<path
							id='Vector'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.44434 13.7936L14.7459 9.00061L23.5572 13.7936L15.0008 18.7342L6.44434 13.7936Z'
							fill='#D2D3D7'
						/>
						<path
							id='Vector_2'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M23.5572 13.7936V23.6742L15.0008 28.6143L6.44434 23.6742V13.7936L15.0008 18.7342L23.5572 13.7936Z'
							fill='#E8E7E5'
						/>
						<path
							id='Vector_3'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15 18.7342L23.5564 13.7936L26.1328 17.8377L17.5764 22.7772L15 18.7342Z'
							fill='#726A72'
						/>
						<path
							id='Vector_4'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.0008 18.7342V28.6143L6.44434 23.6742V13.7936L15.0008 18.7342Z'
							fill='#919FAB'
						/>
						<path
							id='Vector_5'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15 18.7342L6.44355 13.7936L3.86719 17.8377L12.4236 22.7772L15 18.7342Z'
							fill='#BEBEC2'
						/>
						<path
							id='Vector_6'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.025 14.9192C18.8506 14.9192 21.9713 11.8295 21.9713 8.04204C21.9713 4.25454 18.8506 1.16431 15.025 1.16431C11.1994 1.16431 8.07812 4.25395 8.07812 8.04204C8.07812 11.8301 11.1988 14.9192 15.025 14.9192Z'
							fill='#E8E7E5'
						/>
						<path
							id='Vector_7'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.1992 5.6906C12.0873 5.57869 12.0873 5.39763 12.1992 5.28572C12.3111 5.1738 12.4927 5.1738 12.6046 5.28572L14.9554 7.6365L17.3068 5.28572C17.4187 5.1738 17.5998 5.1738 17.7117 5.28572C17.8236 5.39763 17.8236 5.57869 17.7117 5.6906L15.3609 8.04197L17.7117 10.3927C17.7654 10.4465 17.7956 10.5195 17.7956 10.5955C17.7956 10.6715 17.7654 10.7444 17.7117 10.7982C17.6579 10.8517 17.5851 10.8817 17.5092 10.8817C17.4334 10.8817 17.3606 10.8517 17.3068 10.7982L14.9554 8.44685L12.6046 10.7982C12.5507 10.8517 12.4779 10.8817 12.4019 10.8817C12.326 10.8817 12.2531 10.8517 12.1992 10.7982C12.1454 10.7444 12.1152 10.6715 12.1152 10.5955C12.1152 10.5195 12.1454 10.4465 12.1992 10.3927L14.5505 8.04197L12.1992 5.6906Z'
							fill='#FCE17D'
						/>
						<path
							id='Vector_8'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M11.5358 10.725L14.3377 7.92305L11.5358 5.12109C11.0993 4.68457 11.7631 4.02246 12.1985 4.45781L15.0004 7.25977L17.8024 4.45781C18.2395 4.02246 18.901 4.68516 18.4651 5.12109L15.6631 7.92305L18.4651 10.725C18.9069 11.1656 18.2336 11.8189 17.8024 11.3877L15.0004 8.58574L12.1985 11.3877C11.7649 11.8219 11.0993 11.1604 11.5358 10.725ZM17.7713 22.5035L15.659 19.1883L23.5375 14.6396L25.6498 17.9549L17.7713 22.5035ZM23.2229 23.7744V20.4387L17.8516 23.54C17.6348 23.6637 17.3577 23.5986 17.2217 23.3859L15.0004 19.899L12.7791 23.3859C12.714 23.4876 12.6123 23.5603 12.495 23.589C12.3778 23.6177 12.2539 23.6001 12.1493 23.54L6.77796 20.4387V23.7744L15.0004 28.5211L23.2229 23.7744ZM6.46331 14.6396L14.3418 19.1883L12.2295 22.5035L4.35101 17.9549L6.46331 14.6396ZM15.0004 15.3768C12.7487 15.3768 10.7266 14.373 9.35902 12.7898L7.24671 14.0092L15.0004 18.4857L22.7541 14.0092L20.6418 12.7898C19.2743 14.373 17.2522 15.3768 15.0004 15.3768ZM15.0004 1.40625C11.4069 1.40625 8.48363 4.32949 8.48363 7.92305C8.48363 11.5166 11.4069 14.4393 15.0004 14.4393C18.594 14.4393 21.5172 11.516 21.5172 7.92305C21.5172 4.33008 18.594 1.40625 15.0004 1.40625ZM26.7034 17.8647L24.0871 13.7572C24.046 13.6925 23.9894 13.6389 23.9225 13.6014L21.2131 12.0369C21.9971 10.8568 22.4547 9.44238 22.4547 7.92305C22.4547 3.8127 19.1108 0.46875 15.0004 0.46875C10.8901 0.46875 7.54613 3.8127 7.54613 7.92305C7.54613 9.44238 8.00374 10.8568 8.78773 12.0369L6.07835 13.6014C6.01144 13.6389 5.95488 13.6925 5.9137 13.7572L3.29749 17.8647C3.15335 18.0891 3.22718 18.3891 3.45804 18.5221L5.84046 19.8979V24.0451C5.84046 24.2121 5.93011 24.3668 6.07484 24.4506L14.766 29.4686C14.8374 29.5094 14.9182 29.531 15.0004 29.531C15.0827 29.531 15.1635 29.5094 15.2348 29.4686L23.926 24.4506C24.0707 24.3668 24.1604 24.2121 24.1604 24.0451V19.8979L26.5428 18.5221C26.7725 18.3896 26.8481 18.0908 26.7034 17.8647Z'
							fill='#282828'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

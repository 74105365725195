import { IonButton } from "@ionic/react";
import {
	useStripe,
	useElements,
	PaymentElement,
	Elements,
	CardNumberElement,
	CardElement,
} from "@stripe/react-stripe-js";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Payment from "services/Payment";

const CheckoutForm = ({
	clientSecret,
	customerId,
	getCardList,
	closeCardModel,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState({ error: false, message: "" });
	const business = getUserBusiness();

	useEffect(() => {
		if (!stripe) {
			return;
		}
		const clientSecret = new URLSearchParams(window.location.search).get(
			"setup_intent_client_secret"
		);
		if (!clientSecret) {
			return;
		}
		stripe.retrieveSetupIntent(clientSecret);
		// stripe.retrieveSetupIntent(clientSecret).then((retSetRes) => {
		// 	switch (retSetRes.setupIntent.status) {
		// 		case "succeeded":
		// 			payment
		// 				.updateSetupIntent({
		// 					status: "succeeded",
		// 					intent_id: retSetRes.setupIntent.id,
		// 				})
		// 				.then((payRes) => {
		// 					if (
		// 						payRes.status === 200 &&
		// 						payRes.message === "Success"
		// 					) {
		// 						setMessage({
		// 							error: false,
		// 							message: "Card Add succeeded!",
		// 						});
		// 					} else {
		// 						setMessage({
		// 							error: true,
		// 							message: "Something went wrong.",
		// 						});
		// 					}
		// 				})
		// 				.catch((err) => {
		// 					console.log(err);
		// 					setMessage({
		// 						error: true,
		// 						message: "Something went wrong.",
		// 					});
		// 				});

		// 			setTimeout(() => {
		// 				setMessage({ error: false, message: "" });
		// 			}, 10000);

		// 			break;
		// 		case "processing":
		// 			setMessage({
		// 				error: false,
		// 				message: "Your card add is processing.",
		// 			});

		// 			setTimeout(() => {
		// 				setMessage({ error: false, message: "" });
		// 			}, 10000);
		// 			break;
		// 		case "requires_payment_method":
		// 			setMessage({
		// 				error: true,
		// 				message:
		// 					"Your card was not successful added, please try again.",
		// 			});
		// 			setTimeout(() => {
		// 				setMessage({ error: false, message: "" });
		// 			}, 10000);
		// 			break;
		// 		default:
		// 			setMessage({ error: true, message: "Something went wrong." });
		// 			setTimeout(() => {
		// 				setMessage({ error: false, message: "" });
		// 			}, 10000);
		// 			break;
		// 	}
		// });
	}, [stripe]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			clientSecret,
			confirmParams: {
				return_url: "https://docs.stripe.com/stripe-js/react",
			},
		});
		console.log(result);
		if (result.error) {
			console.log(result.error.message);
		} else {
		}
	};

	const handleSubmitCard = async (data, event) => {
		if (!stripe || !elements) {
			return;
		}
		// toast.success("Card saved successfully");
		// getCardList();
		// closeCardModel();
		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement),
		});
		if (paymentMethod) {
			let payload = {
				seller_id: business.id,
				payment_method_id: paymentMethod.id,
			};
			let response = await Payment.setCard(payload);
			if (response.status === 200) {
				toast.success("Card saved successfully");
				getCardList();
				closeCardModel();
			}
		}
		if (error) {
			toast.warning("Error saving card");
		}
	};
	return (
		<div className='pt-7'>
			{/* <CardNumberElement
				id='card'
				options={{
					style: {
						base: {
							color: "#33333",
							fontSize: "16px",
						},
					},
				}}
			/> */}
			<CardElement />
			{/* <PaymentElement /> */}
			<IonButton
				color='success'
				className='pt-10 w-full'
				// type='submit'

				onClick={handleSubmitCard}
				disabled={!stripe}>
				<span className='text-16 font-bold text-white'>Submit</span>
			</IonButton>
		</div>
	);
};

export default CheckoutForm;

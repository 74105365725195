import React, { useState } from "react";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import Password from "pages/LandingPageV2/Password";
import { useSelector } from "react-redux";

export default function BannerPassword() {
	let userInfo = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);
	const countryCode = userInfo?.createUserData?.countryCode;
	const country = userInfo?.createUserData?.country;
	const phoneNo = userInfo?.createUserData?.phoneNumber;
	var sSize = window.matchMedia("(max-width: 599px)");
	const [password, setPassword] = useState("");
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	var sSize = window.matchMedia("(max-width: 599px)");
	return (
		<div className='relative banner-area-wrapper '>
			<div className='pb-[70px] sm:pb-[70px] container m-auto  bg-F2F3F7'>
				<div className='flex items-center md:space-x-0 lg:space-x-10 relative z-10 slider-wrapper'>
					<div className='sm:w-5/12 w-8/12 slider-area'>
						<BannerSlider />
					</div>
					<div className='lg:w-7/12 w-full banner-content pb-[70px] lg:pb-[0]'>
						<Password
							password={password}
							setPassword={setPassword}
							loading={loading}
							startLoading={startLoading}
							setIsForgotPassword={setIsForgotPassword}
							stopLoading={stopLoading}
							phoneNo={phoneNo}
							country={country}
							countryCode={countryCode}
							sSize={sSize}
							isForgotPassword={isForgotPassword}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

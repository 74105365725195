import { createSlice, createSelector } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		loading: false,
		warning: true,
		data: {},
		location: false,
		locationData: null,
		dashboardBottomSheet: false,
		bottomSheet: false,
		businessBottomSheet: false,
		myEmployees: false,
		editEmployees: false,
		myProduct: false,
		brandList: false,
		brandDetails: false,
		productDetails: false,
		supplierDetails: false,
		supplierList: false,
		bankBottomSheet: false,
		cardBottomSheet: false,
		pwaDownloadData: {},
		mapLocation: {},
		createUserData: {},
		selectedBrands: [],
		countryInfo: {},
		selectedJob: {},
		allInfo: {},
		createCustomerBS: false,
		customerDetails: false,
	},
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload;
		},
		setLocation: (state, action) => {
			state.location = true;
			state.locationData = action.payload;
		},
		setDashboardBottomSheet: (state, action) => {
			state.dashboardBottomSheet = action.payload;
		},
		setBottomSheet: (state, action) => {
			state.bottomSheet = action.payload;
		},
		setBusinessBottomSheet: (state, action) => {
			state.businessBottomSheet = action.payload;
		},
		setMyEmployees: (state, action) => {
			state.myEmployees = action.payload;
		},
		setEditEmployees: (state, action) => {
			state.editEmployees = action.payload;
		},
		setBrandList: (state, action) => {
			state.brandList = action.payload;
		},
		setBrandDetails: (state, action) => {
			state.brandDetails = action.payload;
		},
		setProductDetails: (state, action) => {
			state.productDetails = action.payload;
		},
		setPwaDownloadData: (state, action) => {
			state.pwaDownloadData = action.payload;
		},
		setMapLocation: (state, action) => {
			state.mapLocation = action.payload;
		},
		setCreateUserData: (state, action) => {
			state.createUserData = action.payload;
		},
		setMyProduct: (state, action) => {
			state.myProduct = action.payload;
		},
		setAddBankBottomSheet: (state, action) => {
			state.bankBottomSheet = action.payload;
		},
		setWarning: (state, action) => {
			state.warning = action.payload;
		},
		setSelectedBrands: (state, action) => {
			state.selectedBrands = action.payload;
		},
		setSupplierDetails: (state, action) => {
			state.supplierDetails = action.payload;
		},
		setSupplierList: (state, action) => {
			state.supplierList = action.payload;
		},
		setCardBottomSheet: (state, action) => {
			state.cardBottomSheet = action.payload;
		},

		setCountryInfo: (state, action) => {
			state.countryInfo = action.payload;
		},
		setSelectedJob: (state, action) => {
			state.selectedJob = action.payload;
		},
		setAllInfo: (state, action) => {
			state.allInfo = action.payload;
		},
		createCustomerBS: (state, action) => {
			state.createCustomerBS = action.payload;
		},
		setCustomerDetails: (state, action) => {
			state.customerDetails = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = userSlice;

export const selectors = {
	getLocation: createSelector(
		(state) => state.user,
		(location) => location
	),
};

export const setLocation = (values) => async (dispatch, getState) => {
	dispatch(actions.setLocation(values));
};
export const setUser = (data) => async (dispatch, getState) => {
	dispatch(actions.setUser(data));
};
export const setDashboardBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setDashboardBottomSheet(data));
};
export const setBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBottomSheet(data));
};
export const setBusinessBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBusinessBottomSheet(data));
};
export const setAddBankBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setAddBankBottomSheet(data));
};
export const setMyEmployees = (data) => async (dispatch) => {
	dispatch(actions.setMyEmployees(data));
};
export const setEditEmployees = (data) => async (dispatch) => {
	dispatch(actions.setEditEmployees(data));
};
export const setMyProduct = (data) => async (dispatch) => {
	dispatch(actions.setMyProduct(data));
};
export const setBrandList = (data) => async (dispatch) => {
	dispatch(actions.setBrandList(data));
};
export const setBrandDetails = (data) => async (dispatch) => {
	dispatch(actions.setBrandDetails(data));
};
export const setSupplierList = (data) => async (dispatch) => {
	dispatch(actions.setSupplierList(data));
};
export const setCardBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setCardBottomSheet(data));
};
export const setSupplierDetails = (data) => async (dispatch) => {
	dispatch(actions.setSupplierDetails(data));
};

export const setProductDetails = (data) => async (dispatch) => {
	dispatch(actions.setProductDetails(data));
};

export const setPwaDownloadData = (data) => async (dispatch) => {
	dispatch(actions.setPwaDownloadData(data));
};
export const setMapLocation = (data) => async (dispatch) => {
	dispatch(actions.setMapLocation(data));
};
export const eraseMapLocation = () => async (dispatch) => {
	dispatch(actions.setMapLocation({}));
};
export const setCreateUser = (data) => async (dispatch) => {
	dispatch(actions.setCreateUserData(data));
};
export const setWarning = (data) => async (dispatch) => {
	dispatch(actions.setWarning(data));
};
export const setSelectedBrands = (data) => async (dispatch) => {
	dispatch(actions.setSelectedBrands(data));
};
export const setCountryInfo = (data) => async (dispatch) => {
	dispatch(actions.setCountryInfo(data));
};
export const setSelectedJob = (data) => async (dispatch) => {
	dispatch(actions.setSelectedJob(data));
};
export const setAllInfo = (values) => async (dispatch, getState) => {
	let data = { ...getState().user.allInfo, ...values };
	dispatch(actions.setAllInfo(data));
};
export const setCreateCustomerBS = (data) => async (dispatch) => {
	dispatch(actions.createCustomerBS(data));
};
export const setCustomerDetails = (data) => async (dispatch) => {
	dispatch(actions.setCustomerDetails(data));
};
// Export the reducer, either as a default or named export
export default reducer;

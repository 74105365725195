import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Map from "components/mobile-components/LocationPicker/Map";
import MapFunctional from "components/mobile-components/LocationPicker/MapFunctional";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function BusinessLocationPickerPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [error, setError] = useState();
	const location = useSelector((state) => state.user.mapLocation);
	const myLocation = useSelector(
		(state) => state.user.locationData.coordinates
	);

	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.locationPicker.pageTitle} />
					<div className='pt-14'>
						<MapFunctional
							center={
								{ lat: myLocation[1], lng: myLocation[0] } ?? {
									lat: 23.5204,
									lng: 90.399452,
								}
							}
							height='400px'
							zoom={18}
							location={location}
						/>
						<div
							className='mx-4 mt-5 mb-10'
							style={{
								background: "#FFFFCC",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='rounded-[5px]'>
								<p className='text-808080 text-12 font-semibold px-[10px] py-[6px]'>
									{ekkLanguage.locationPicker.paragraph}
								</p>
							</div>
						</div>
						{location?.lat && location?.lng && (
							<div className='text-center px-4'>
								<IonButton
									color='success'
									className='font-bold h-[50px] w-full'
									onClick={() => {
										if (Object.keys(location).length) {
											navigate("/business-location");
										} else {
											setError(
												ekkLanguage.businessCreateName.errorLabel
											);
										}
									}}>
									<span className="text-white">{ekkLanguage.businessCreateName.btnLabel}</span>
									<span className='pl-2 text-white'>
										<ArrayRight />
									</span>
								</IonButton>
							</div>
						)}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

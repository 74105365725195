import { setLanguage } from "config/language";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectors } from "redux/container/authSlice";
import { getUserBusiness } from "config/user";
import CheckBusiness from "components/CheckBusiness";

const Navigation = lazy(() => import("./Navigation"));
const BannerArea = lazy(() => import("./BannerArea"));
const Benefits = lazy(() => import("./Benefits"));
const FooterStyle2 = lazy(() => import("./FooterStyle2"));

export default function Landing() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const { loginStatus } = useSelector(selectors.getAuth);

	useEffect(() => {
		function onScroll() {
			let currentPosition = document.documentElement.scrollTop;
			if (currentPosition > 150) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	if (localStorage.getItem("lan") === null) {
		setLanguage("en");
	}

	if (loginStatus) {
		let business = getUserBusiness();

		if (business) {
			return <Navigate to='/dashboard' />;
		} else {
			console.log("Not logged in");
			return <CheckBusiness></CheckBusiness>;
		}
	}

	return (
		<section>
			<Suspense
				fallback={
					<div>
						<div className='preloader'>
							<div style={{ textAlign: "center" }}>
								<div className='lds-dual-ring'></div>
							</div>
							<h3 className='text-bold text-20'>Loading...</h3>
						</div>
					</div>
				}>
				<Navigation scrolling={scrolling ? "isScrolled" : ""} />
				<BannerArea />
				<Benefits />
				<FooterStyle2 />
			</Suspense>
		</section>
	);
}

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Admin from "assets/images/icon/svg/Admin";
import Download from "assets/images/icon/svg/Download";
import HeartOutline from "assets/images/icon/svg/HeartOutline";
import Profit from "assets/images/icon/svg/Profit";
import Share from "assets/images/icon/svg/Share";
import Star from "assets/images/icon/svg/Star";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import ProductCard from "./ProductCard";

export default function ProductDetails({ product }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='porduct-details px-4'>
			<div>
				<h5 className='font-semibold text-28 text-black-1000 pt-2 pb-[10px]'>
					{product?.title ?? product?.name}
				</h5>
			</div>

			<div className='flex items-center justify-between pb-4'>
				<h5 className='font-medium text-primary text-24 flex justify-between'>
					{product?.country?.currency_symbol_native}
					<span className='text-24 font-bold text-primary'>
						{digitChanger(numberWithCommas(product?.base_price ?? 0))}
					</span>
					<span className='text-16 text-black-500 self-end ml-1'>
						{" - "}
						{product?.country?.currency_symbol_native}
						{digitChanger(numberWithCommas(product?.purchase_price ?? 0))}
						{/* {" = "} */}
					</span>
					{/* <span className='font-bold text-16 text-success self-end ml-2'>
						{product?.country?.currency_symbol_native}
						{digitChanger(
							numberWithCommas(
								Number(product?.base_price ?? 0) -
									Number(product?.purchase_price ?? 0)
							)
						)}{" "}
						({ekkLanguage.productDetails.profitLabel})
					</span> */}
				</h5>
				{/* <div className='flex items-center'>
					<Star width={15} height={15} color='#F94B41' />
					<div className='pl-2'>
						<p className='text-16' style={{ color: "#00000080" }}>
							4.0
						</p>
					</div>
				</div> */}
			</div>

			<IonCol size='12' className='pb-6'>
				<ul className='flex justify-between'>
					{/* <li>
						<div className='flex items-center'>
							<Star width={15} height={15} color='#F94B41' />
							<Star width={15} height={15} color='#F94B41' />
							<Star width={15} height={15} color='#F94B41' />
							<Star width={15} height={15} color='#F94B41' />
							<Star width={15} height={15} color='#00000030' />
							<div className='pl-2'>
								<p className='text-16' style={{ color: "#00000080" }}>
									4.0
								</p>
							</div>
						</div>
					</li>
					<li>
						<div>
							<p className='text-16 text-000054'>3.2k Sold</p>
						</div>
					</li> */}
					{/* <li>
						<div className='flex items-center justify-between pb-5'>
							{!product?.out_of_stock && (
								<span
									style={{ lineHeight: 1.9 }}
									className={`${
										!product.out_of_stock
											? "text-success border-success"
											: "text-primary border-primary"
									} border-2  rounded-[30px] px-5 font-bold text-12 h-[30px] py-1`}>
									{!product.out_of_stock
										? ekkLanguage.productDetails.inStock
										: ekkLanguage.productDetails.outStock}
								</span>
							)}
						</div>
					</li>
					<li>
						<div>
							<h6 className='text-16 text-black-500'>3.2k Sold</h6>
						</div>
					</li> */}
					{/* <li>
						<div>
							<HeartOutline />
						</div>
					</li>
					<li>
						<div>
							<Share />
						</div>
					</li> */}
					<li className='w-1/2'>
						<div>
							<h6 className='text-14 text-000054 pb-1'>
								{ekkLanguage.productDetails.myProfit}
							</h6>
							<p className='text-success font-bold text-16 flex'>
								<img
									src='/assets/images/profits.png'
									alt='icon'
									className='mr-1 w-22'
								/>
								{product?.country?.currency_symbol_native}
								{digitChanger(
									numberWithCommas(
										Number(product?.base_price ?? 0).toFixed(2) -
											Number(product?.purchase_price ?? 0).toFixed(2)
									)
								)}
							</p>
						</div>
					</li>
					<li>
						<div className='flex items-center justify-between pb-5'>
							{
								<span
									style={{ lineHeight: 1.6 }}
									className={`${
										product?.inventory_quantity > 0
											? "text-success border-success"
											: "text-primary border-primary"
									} border-2  rounded-[30px] px-5 font-bold text-12 h-[30px] py-1`}>
									{product?.inventory_quantity > 0
										? ekkLanguage.productDetails.inStock
										: ekkLanguage.productDetails.outStock}
								</span>
							}
						</div>
					</li>
				</ul>
			</IonCol>
			<IonCol size='12'>
				<div className='flex items-center'>
					<div className='w-1/2'>
						<h6 className='text-14 text-000054 pb-1'>
							{ekkLanguage.productDetails.inventory}
						</h6>
						<p
							className={`text-${
								product?.inventory_quantity > 0 ? "success" : "primary"
							} font-bold text-16 flex`}>
							<img
								src='/assets/images/warehouse.png'
								alt='icon'
								className='mr-1 w-22'
							/>
							{product?.inventory_quantity ?? 0}
						</p>
					</div>
					<div>
						<h6 className='text-14 text-000054 pb-1'>
							{ekkLanguage.productDetails.moqLabel}
						</h6>
						<p className='text-primary font-bold text-16 flex'>
							{" "}
							<img
								src='/assets/images/package.png'
								alt='icon'
								className='pr-1'
							/>
							{digitChanger(product?.minimum_order_quantity ?? 0)}
						</p>
					</div>
				</div>
			</IonCol>
			<IonCol size='12'>
				<div className='flex items-center'>
					<div className='w-1/2'>
						<h6 className='text-14 text-000054 pb-1'>
							{ekkLanguage.productDetails.consumerLabel}
						</h6>
						<p className='text-primary font-bold text-16 flex'>
							<img
								src='/assets/images/user.png'
								alt='icon'
								className='pr-1'
							/>
							{product?.country?.currency_symbol_native}
							{digitChanger(product?.consumer_price)}
							<span className='text-12 text-black-500 font-normal self-end pl-1'>
								{" - "}
								{product?.country?.currency_symbol_native}
								{digitChanger(
									numberWithCommas(product?.base_price ?? 0)
								)}
							</span>
						</p>
					</div>
					<div>
						<h6 className='text-14 text-000054 pb-1'>
							{ekkLanguage.productDetails.buyerProfit}
						</h6>
						<p className='text-success font-bold text-16 flex'>
							<img
								src='/assets/images/buyer-profit.png'
								alt='icon'
								className='mr-1 w-22'
							/>
							{product?.country?.currency_symbol_native}
							{product?.profit &&
								digitChanger(numberWithCommas(product?.profit))}
						</p>
					</div>
				</div>
			</IonCol>

			<IonCol size='12'>
				<SectionTitle
					title={ekkLanguage.productDetails.productDetailTitle}
					innerPage={true}
				/>
				<div className='product-specific-details'>
					<IonRow>
						{product?.type === "offer" && (
							<IonCol size='12'>
								<div className='flex relative pl-2 mb-1'>
									<p className='text-000054 text-12 pr-1'>
										{ekkLanguage.productDetails.offerStart}:
									</p>
									<span className='text-12'>
										{" "}
										{digitChanger(
											moment(product?.offer_start).format(
												"DD-MM-YYYY"
											)
										)}
									</span>
								</div>
							</IonCol>
						)}{" "}
						{product?.type === "offer" && (
							<IonCol size='12'>
								<div className='flex relative pl-2 mb-1'>
									<p className='text-000054 text-12 pr-1'>
										{ekkLanguage.productDetails.offerEnd}:
									</p>
									<span className='text-12'>
										{digitChanger(
											moment(product?.offer_end).format("DD-MM-YYYY")
										)}
									</span>
								</div>
							</IonCol>
						)}
						{product?.expire_date && (
							<IonCol size='12'>
								<div className='flex relative pl-2 mb-1'>
									<p className='text-000054 text-12 pr-1'>
										{ekkLanguage.productDetails.expireData}:
									</p>
									<span className='text-12'>
										{" "}
										{digitChanger(
											moment(product.expire_date).format(
												"DD/MM/YYYY"
											)
										)}
									</span>
								</div>
							</IonCol>
						)}
						{product?.manufacture_date && (
							<IonCol size='12'>
								<div className='flex relative pl-2 mb-1'>
									<p className='text-000054 text-12 pr-1'>
										{ekkLanguage.productDetails.manufactureData}:
									</p>
									<span className='text-12'>
										{" "}
										{digitChanger(
											moment(product.manufacture_date).format(
												"DD/MM/YYYY"
											)
										)}
									</span>
								</div>
							</IonCol>
						)}
						{/* <IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											Brand Name:
										</p>
										<span className='text-12'>
											{product?.seller_business_name}
										</span>
									</div>
								</IonCol> */}
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.productDetailTitle}:
								</p>
								<span className='text-12'> {product?.type}</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.productWeight}:
								</p>
								<span className='text-12'>
									{digitChanger(product?.weight)}{" "}
									{product?.weight_unit}
								</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.packingType}:
								</p>
								<span className='text-12'>{product?.pack_type}</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.skuId}:
								</p>
								<span className='text-12'>
									{product?.ekkbaz_sku_id}
								</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.searchTram}:
								</p>
								<span className='text-12'>{product?.eng_name}</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.importedBy}:
								</p>
								<span className='text-12'>
									{product?.imported_by_business}
								</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.manufacturedBy}:
								</p>
								<span className='text-12'>
									{product?.manufactured_by_business}
								</span>
							</div>
						</IonCol>
						<IonCol size='12'>
							<div className='flex relative pl-2 mb-1'>
								<p className='text-000054 text-12 pr-1'>
									{ekkLanguage.productDetails.country}:
								</p>
								<span className='text-12'>
									{product?.manufacturing_country}
								</span>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonCol>
			{product.type === "offer" && (
				<>
					<SectionTitle
						title={ekkLanguage.productList.pageTitle}
						innerPage={true}
					/>
					<ProductCard
						productItems={product?.offer_items}
						product={product}
					/>
				</>
			)}

			<IonCol size='12' className='pb-10'>
				<SectionTitle
					title={ekkLanguage.productDetails.descriptionLabel}
					innerPage={true}
				/>
				<div className='product-description'>
					<p className='text-12 text-black-500 mb-2'>
						{product?.description}
					</p>
				</div>
			</IonCol>
		</div>
	);
}

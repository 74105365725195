import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "assets/images/icon/svg/Calendar";
import Add from "assets/images/icon/svg/Add";
import { paymentDate } from "redux/container/payEkkbazSlice";

export default function DataTimeCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	let daliveryDate = useSelector((state) => state.payEkkbaz?.paymentDate);
	const [selectedDalieveryDate, setSelectedDalieveryDate] = useState(
		daliveryDate ? daliveryDate : moment(new Date())
	);

	let dateChanger = (e) => {
		setSelectedDalieveryDate(e);
		dispatch(paymentDate(moment(e).format()));
	};
	useEffect(() => {
		dispatch(paymentDate(moment(new Date())._d));
	}, []);

	const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border example-custom-input ${
				daliveryDate ? "border-transparent" : "border-primary"
			} `}
			onClick={onClick}
			style={{
				boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
			}}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<span>
						<Calendar color={daliveryDate ? "#222222" : "#FF564C"} />
					</span>
					<p
						className={`text-12 font-semibold${
							daliveryDate ? "border-success" : "text-primary"
						} ml-2`}>
						{moment(selectedDalieveryDate).format("D-M-YYYY h:mm:ss A")}
					</p>
				</div>
				<div>
					<Add color={daliveryDate ? "#00B224" : "#F94B41"} />
				</div>
			</div>
		</div>
	));

	return (
		<div className='pb-5'>
			<div className=' bg-white'>
				<div
					className='border-b-2 py-[10px] flex justify-between items-center px-4'
					style={{ bordreColor: "#f2f3f7" }}>
					<h3 className='text-black-1000 font-bold text-16'>
						{ekkLanguage.payment.selectTimeDate}
					</h3>
				</div>
				<div>
					<div className='px-4 pb-5 pt-1 payment-date-time'>
						<DatePicker
							selected={
								daliveryDate
									? moment(daliveryDate)._d
									: moment(new Date())._d
							}
							onChange={(date) => dateChanger(date)}
							customInput={<DateCustomInput />}
							withPortal
							showTimeSelect
							timeIntervals={30}
							timeCaption='Time'
							dateFormat='h:mm aa'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

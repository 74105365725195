import React from "react";

export default function Pause() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='36'
			height='36'
			viewBox='0 0 36 36'
			fill='none'>
			<circle cx='18' cy='18' r='18' fill='white' />
			<rect
				x='13.5'
				y='12.75'
				width='3'
				height='10.5'
				rx='1'
				fill='#00B224'
			/>
			<rect
				x='19.5'
				y='12.75'
				width='3'
				height='10.5'
				rx='1'
				fill='#00B224'
			/>
		</svg>
	);
}

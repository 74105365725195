import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoard from "services/Dashboard";
import { getLanguage } from "config/language";

export default function DelayInPaymentDrilldown() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { day, buyerName, buyerId } = useSelector(
		(state) => state.productSummery.productData
	);
	const { product } = useParams();
	let [loading, setLoading] = useState(true);
	const [orderList, setOrderList] = useState({});
	const language = getLanguage();
	const navigate = useNavigate();

	const getDelayInPaymentResult = async (id) => {
		let res = await DashBoard.getDelayInPaymentDrilldown(id);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};
	const getDelayInPaymentDayResult = async (id, day) => {
		let res = await DashBoard.getDelayInPaymentDayDrilldown(id, day);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	useEffect(() => {
		if (day > 0) {
			getDelayInPaymentDayResult(buyerId, day);
		} else {
			getDelayInPaymentResult(buyerId);
		}
	}, []);
	const handleNavigate = (mid) => {
		navigate(`/order-details/${mid}/seller`);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex text-center items-center justify-between px-4'>
							<h1 className='text-16  text-center font-bold'>
								{buyerName}
							</h1>
							<span className='px-7 py-2 text-14 font-bold border h-9 bg-[#FFF] rounded'>
								{day} days
							</span>
						</div>
						<div className='mt-5'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.orderNo}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.dueAmount}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.dayPassed}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.length
										? orderList?.map((e, i) => {
												// console.log(e.item);
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border p-1 font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>
														<td
															onClick={() =>
																handleNavigate(e.mid)
															}
															className='border text-[#2493c7] p-1 font-semibold text-10'>
															{digitChanger(e?.orderId)}
														</td>

														<td className='border p-1 font-semibold text-10'>
															৳
															{digitChanger(
																numberWithCommas(e?.due)
															)}
														</td>
														<td className='border p-1 font-semibold text-10'>
															{digitChanger(e?.day)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

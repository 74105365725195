import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
//commit
const Supplier = {
	getSupplierList: (lon, lat, page = 1, limit = 16) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/seller/nerarby_seller/?longitude=${lon}&latitude=${lat}&page=${page}&limit=${limit}`
		);
	},
	getAllSupplierList: (long, lat, page = 1, limit = 12) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/?longitude=${long}&latitude=${lat}&page=${page}&limit=${limit}`
		);
	},
	singleSupplierDetails: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/sellers/${id}`);
	},
	supplierProducts: (id, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/${id}/products?page=${page}&limit=${limit}`
		);
	},

	getMySupplierList: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/list?page=${page}&limit=${limit}`
		);
	},
	singleMySupplierProductDetails: (id, page = 1, limit = 500) => {
		return SuperFetch(
			`${baseUrl.product}/v4/products/buyer/${id}?page=${page}&limit=${limit}`
		);
	},
	singleMySupplierDetails: (id) => {
		return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`);
	},
	getSelectedSupplierList: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brands/${id}/sellers`);
	},
	supplierSearch: (query = "", lon, lat, page = 1, limit = 16) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/seller/nerarby_seller/search/?longitude=${lon}&latitude=${lat}&page=${page}&limit=${limit}&tearm=${query}`
		);
	},
	allSupplierSearch: (long, lat, query = "", page = 1) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/search?longitude=${long}&latitude=${lat}&term=${query}&page=${page}`
		);
	},
	getSupplier360: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/ekkbaz/total-analytics-ekkbaz?sellerId=${id}`
		);
	},
};
export default Supplier;

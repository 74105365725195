import {
    IonApp,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import { useDispatch } from "react-redux";
import { setUserOrderType } from "redux/container/orderDetailsSlice";
import OrderProductSummary from "components/mobile-components/OrderProductSummary/OrderProductSummary";

export default function OrderProductSummaryPage() {
    const [menuCollapse, setMenuCollapse] = useState(true);
    const [productLastResponse, setProductLastResponse] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const ekkLanguage = useSelector((state) => state.lan.lan);
    let orderListPage = useSelector((state) => state.order.page);
    const dispatch = useDispatch();





    return (
        <IonApp>
            <IonPage>
                <IonContent>
                    <BackButtonTitle title={ekkLanguage.orderSummary.orderProductPageTitle} />
                    <OrderProductSummary />
                </IonContent>
            </IonPage>
        </IonApp>
    );
}

import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoard from "services/Dashboard";
import { getLanguage } from "config/language";

export default function OrderDeliveryAmountDrilldown() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { date, driverId, driver } = useSelector(
		(state) => state.productSummery.productData
	);
	const { product } = useParams();
	let [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState({});
	let viewDate = moment(date).format("DD-MM-yyyy");
	let localTime = moment(date).utc();
	const language = getLanguage();
	const navigate = useNavigate();

	const getOrderStatusResult = async (date, driverId) => {
		let res = await DashBoard.getDeliveryAmountSummaryDrilldown(
			date,
			driverId
		);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	useEffect(() => {
		getOrderStatusResult(viewDate, driverId);
	}, []);
	const handleNavigate = (mid) => {
		navigate(`/order-details/${mid}/seller`);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex border-2 border-b-0 mx-3 py-1  justify-around items-center flex-col'>
							<span className='mt-2 text-center'>
								<span className='font-bold'>
									{ekkLanguage.orderSummary.date} :
								</span>{" "}
								{viewDate}
							</span>
							<span className='mb-5 text-center'>
								<span className='font-bold'>
									{ekkLanguage.orderSummary.driver} :
								</span>{" "}
								{driver}
							</span>
							<table
								className='table-auto w-full bg-[#ffffffbf]'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.totalAmount}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalPaid}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalDue}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
										<td className='border font-bold p-1 text-10'>
											৳
											{digitChanger(
												numberWithCommas(
													orderList?.amountSum?.totalAmountSum
												)
											)}
										</td>

										<td className='border font-bold p-1 text-10'>
											৳
											{digitChanger(
												numberWithCommas(
													orderList?.amountSum?.totalPaidSum
												)
											)}
										</td>
										<td className='border font-bold p-1 text-10'>
											৳
											{digitChanger(
												numberWithCommas(
													orderList?.amountSum?.totalDueSum
												)
											)}
										</td>
										{/* <td className='border  font-semibold text-12'>
    
                                                                    {digitChanger(e.orderId)}
    
                                                                </td> */}
									</tr>
								</tbody>
							</table>
						</div>

						<div className='mt-10'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.orderNo}
										</th>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.totalAmount}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalPaid}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalDue}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.detail?.length
										? orderList?.detail.map((e, i) => {
												// console.log(e.item);
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border  font-semibold text-10 p-1'>
															{digitChanger(i + 1)}
														</td>
														<td
															onClick={() =>
																handleNavigate(e.orderMid)
															}
															className='border font-semibold text-[#0099CC] text-10 p-1'>
															{digitChanger(e?.orderId)}
														</td>
														<td className='border font-semibold text-10 p-1'>
															৳
															{digitChanger(
																numberWithCommas(e?.total)
															)}
														</td>

														<td className='border font-semibold text-10 p-1'>
															৳
															{digitChanger(
																numberWithCommas(e?.total_paid)
															)}
														</td>
														<td className='border font-semibold text-10 p-1'>
															৳
															{digitChanger(
																numberWithCommas(e?.total_due)
															)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import Tik from "assets/images/icon/svg/Tik";
import { setSelectedBrands } from "redux/container/userSlice";

export default function BrandCard({
	brandId,
	brandName,
	imgSource,
	brand,
	selectedBrandList,
}) {
	const dispatch = useDispatch();

	const handleClick = () => {
		const list = selectedBrandList || [];
		const exists = list.some((selectedBrand) => selectedBrand.id === brandId);
		if (exists) {
			dispatch(
				setSelectedBrands(list.filter((brand) => brand.id !== brandId))
			);
		} else {
			dispatch(setSelectedBrands([...list, brand]));
		}
	};

	return (
		<div
			className={`flex justify-between items-center bg-[#F2F3F7] py-5 border-b-[#0000001A] border-b-2 cursor-pointer`}
			onClick={handleClick}>
			<div className='flex items-center'>
				{imgSource ? (
					<div className='h-10 w-10 rounded-full overflow-hidden'>
						<img src={imgSource} alt='logo'></img>
					</div>
				) : (
					<div className='h-10 w-10 bg-black-333 rounded-full'>
						<h3 className='h-10 w-10 flex justify-center items-center font-bold text-24 text-white'>
							{brandName[0].toUpperCase()}
						</h3>
					</div>
				)}
				<p className='font-bold text-16 text-[#222222] uppercase pl-4'>
					{brandName}
				</p>
			</div>

			<div className='flex justify-end mr-6'>
				{selectedBrandList?.length > 0 &&
					selectedBrandList.some(
						(selectedBrand) => selectedBrand.id === brandId
					) && (
						<motion.div
							initial='hidden'
							animate={{ scale: [0.75, 1, 0.75] }}
							transition={{ duration: 0.5, ease: "easeInOut" }}
							className='p-1.5 rounded-full bg-success'>
							<Tik height={20} width={20} color={"white"} />
						</motion.div>
					)}
			</div>
		</div>
	);
}

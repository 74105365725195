import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
} from "@ionic/react";
import LocationPin from "assets/images/icon/svg/LocationPin";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function LocationEnabled() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let dispatch = useDispatch();

	let setLocation = () => {
		if (navigator.geolocation) {
			let location = navigator.geolocation;
			if (location && Object.keys(location).length === 0) {
				navigator.geolocation.getCurrentPosition((p, e) => {
					dispatch(
						setLocation({
							type: "Point",
							coordinates: [p.coords.longitude, p.coords.latitude],
						})
					);
				});
			}
		} else {
			toast.error("Geolocation is not supported by this browser.");
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='px-2 pt-24 relative change-password-screen business-location'>
						<IonGrid>
							<IonRow>
								<IonCol size='12'>
									<div className='text-center'>
										<span className='business-loation-pin'>
											<LocationPin />
										</span>
										<h5 className='text-18 text-black-1000 font-bold pb-3 pt-7'>
											{ekkLanguage.location.title}
										</h5>
										<p className='text-2 text-black-500 font-normal'>
											{ekkLanguage.location.paragraph}
										</p>
									</div>
								</IonCol>
							</IonRow>
							<div className='submit-button-wrapper'>
								<IonRow>
									<IonCol size='12'>
										<div className='text-center'>
											<IonButton
												onClick={() => {
													setLocation();
													// window.location.reload();
												}}
												expand='full'
												className='bg-primary rounded-[7px] font-extrabold text-12'>
												<span className="text-white">{ekkLanguage.location.btnLabel}</span>
											</IonButton>
										</div>
									</IonCol>
								</IonRow>
							</div>
						</IonGrid>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import PayEkkbaz from "components/mobile-components/PayEkkbaz";
// import Payment from "components/mobile-components/Payment";
import React from "react";
import { useSelector } from "react-redux";

export default function PayEkkbazPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage className=''>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.payment.pageTitle} />
					<PayEkkbaz />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

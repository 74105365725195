import React from "react";
import Signature from "../Common/Signature";
import { digitChanger } from "helpers/helper";

export default function BothPartiesSignMY({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
	firstPartySignatureImage,
	setFirstPartySignatureImage,
	secondPartySignatureImage,
	setSecondPartySignatureImage,
}) {
	return (
		<div className='border-2 border-[#808080] rounded-md p-2 mt-[10px]'>
			{/* 1st party's signature */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					Pihak Pertama
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14'>
				<span className='font-bold'>
					{createdBusinessDetails?.business_name}
				</span>{" "}
				ialah sebuah pertubuhan perniagaan milikan tunggal yang berdaftar di
				Bangladesh, dengan alamatnya di{" "}
				<span className='font-bold ml-1'>
					{createdBusinessDetails?.address_line
						? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
						: `${businessDetails?.address_line}, ${businessDetails?.city}`}
				</span>{" "}
				dan nombor lesen perdagangan{" "}
				<span className='font-bold ml-1'>
					{digitChanger(kycDetails?.trade_no)}
				</span>
				, bagi pihak pemilik/nombor kad pengenalan negara{" "}
				<span className='font-bold mx-1'>
					{digitChanger(kycDetails?.nid_no)}
				</span>{" "}
				daripada pertubuhan tersebut.
			</p>
			{/* Signature  */}
			<Signature
				signatureImage={firstPartySignatureImage}
				setSignatureImage={setFirstPartySignatureImage}
				placeholder={"Signature"}
				showDash={true}
				bgColor={"white"}
			/>
			<div className='w-full bg-[#808080] h-[2px] my-[20px]'></div>
			{/* 2nd party's signature */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					Pihak Kedua
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14 pb-2'>
				{/* new */}
				<span className='font-bold'>EkkBaz Private Limited</span> ialah
				syarikat yang didaftarkan dan dikawal selia di bawah Akta Syarikat
				bagi Bangladesh, dengan alamat berdaftarnya di{" "}
				<span className='font-bold'>
					Dataran Paya Lebar #07-54, 60 Paya Lebar Road Singapura 409051.
				</span>
				<span className='font-bold ml-1'>Zobayeda Sultana</span> menjalankan
				tugas sebagai Pengarah Urusan bagi pihak syarikat.
			</p>
			{/* Signature  */}

			{/* <div
        className={`my-2 p-2 h-[200px] bg-transparent rounded-[10px] border-2 border-dashed border-[#00000054]`}
        style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}
      >
        <img
          className="w-full h-full rounded-[10px]"
          src={
            "https://ekkfilesdbt.blob.core.windows.net/userssblobs/f6a298bf-b319-410f-be1b-7c6aa89e5c47image.jpg"
          }
          alt=""
        />
      </div> */}
			{/* <Signature
        signatureImage={secondPartySignatureImage}
        setSignatureImage={setSecondPartySignatureImage}
        placeholder={"Signature"}
        showDash={true}
        bgColor={""}
        hideDeleteButton={true}
      /> */}
		</div>
	);
}

import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import withLocation from "components/HOC/withLocation";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import MapFunctional from "components/mobile-components/LocationPicker/MapFunctional";
import { getCreateUserBusiness, setCreateUserBusiness } from "config/user";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
	eraseMapLocation,
	setBusinessBottomSheet,
} from "redux/container/userSlice";
import Business from "services/Business";

function PickLocationPage() {
	let business = getCreateUserBusiness();
	const { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const location = useSelector((state) => state.user.locationData);
	const myLocation = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	const mapLocation = useSelector((state) => state.user?.mapLocation);

	const dispatch = useDispatch();
	let setLocationHandler = async () => {
		let location = {
			latitude: mapLocation?.lat,
			longitude: mapLocation?.lng,
		};
		let response = await Business.updateBusinessLocation(id, location);
		if (response.status === 200) {
			dispatch(setBusinessBottomSheet(false));
			dispatch(eraseMapLocation());
			setCreateUserBusiness(null);
			toast.success("Location updated successfully");
			navigate(`/profile`);
		}
	};
	console.log(business?.coordinates);
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.locationPicker.pageTitle} />
					<div className='pt-14'>
						<MapFunctional
							center={
								{ lat: myLocation[1], lng: myLocation[0] } ?? {
									lat: 23.5204,
									lng: 90.399452,
								}
							}
							height='400px'
							zoom={18}
							location={location}
							from='pickLocation'
						/>
						<div
							className='mx-4 mt-5 mb-10'
							style={{
								background: "#FFFFCC",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='rounded-[5px]'>
								<p className='text-808080 text-12 font-semibold px-[10px] py-[6px]'>
									{ekkLanguage.locationPicker.paragraph}
								</p>
							</div>
						</div>
						{mapLocation?.lat && mapLocation?.lng && (
							<div className='text-center absolute bottom-3 w-full px-4'>
								<IonButton
									color='primary'
									className='font-bold w-full h-10'
									onClick={() => {
										setLocationHandler();
									}}>
									{ekkLanguage.changeLanguages.btnLabel}
									<span className='pl-2'>
										<ArrayRight />
									</span>
								</IonButton>
							</div>
						)}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default withLocation(PickLocationPage);

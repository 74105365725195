import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Banner({ brandInfo, slideData }) {
	return (
		<div className='banner-wrapper bg-white min-h-[200px] flex justify-center items-center'>
			{slideData?.length ? (
				<Swiper
					modules={[Pagination, Navigation]}
					pagination={{ clickable: true }}
					navigation={true}
					spaceBetween={0}
					slidesPerView={1}
					// onSlideChange={() => console.log("slide change")}
					// onSwiper={(swiper) => console.log(swiper)}
				>
					{slideData?.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<img
									src={el}
									className='w-100 h-[270px] m-auto'
									alt='slideImage'
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			) : (
				<h1 className='text-center' style={{ fontSize: 100 }}>
					{brandInfo?.brand_name &&
						brandInfo?.brand_name[0]?.toUpperCase()}
				</h1>
				// <img
				// 	src={slideData[0]}
				// 	alt='bannerimage'
				// 	className='h-[250px] m-auto'
				// />
			)}
		</div>
	);
}

import React from "react";

export default function Unverify() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M9.50011 0.50084C9.35946 0.360237 9.16873 0.28125 8.96986 0.28125C8.77099 0.28125 8.58025 0.360237 8.43961 0.50084L5.00011 3.94034L1.56061 0.50084C1.41996 0.360237 1.22923 0.28125 1.03036 0.28125C0.831484 0.28125 0.640753 0.360237 0.500108 0.50084C0.359504 0.641486 0.280518 0.832217 0.280518 1.03109C0.280518 1.22996 0.359504 1.42069 0.500108 1.56134L3.93961 5.00084L0.500108 8.44034C0.359504 8.58099 0.280518 8.77172 0.280518 8.97059C0.280518 9.16946 0.359504 9.3602 0.500108 9.50084C0.640753 9.64145 0.831484 9.72043 1.03036 9.72043C1.22923 9.72043 1.41996 9.64145 1.56061 9.50084L5.00011 6.06134L8.43961 9.50084C8.58025 9.64145 8.77099 9.72043 8.96986 9.72043C9.16873 9.72043 9.35946 9.64145 9.50011 9.50084C9.64071 9.3602 9.7197 9.16946 9.7197 8.97059C9.7197 8.77172 9.64071 8.58099 9.50011 8.44034L6.06061 5.00084L9.50011 1.56134C9.64071 1.42069 9.7197 1.22996 9.7197 1.03109C9.7197 0.832217 9.64071 0.641486 9.50011 0.50084Z"
        fill="white"
      />
    </svg>
  );
}

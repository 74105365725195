import React from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import moment from "moment-timezone";
export default function LoanCollectionCard({ collection }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.loanCollectionTitle}>
				<div>
					<table className='table-fixed w-full paymentSettlementLabel'>
						{Array.isArray(collection) && collection?.length !== 0 ? (
							collection?.map((el, i) => {
								return (
									<tbody
										className="before:content-[' '] before:block before:h-2 border-b-2"
										key={i}>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.transactionType}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right capitalize'>
												{el?.trns_type}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.gateway}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.buyer_gateway?.bank?.name}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.accountName}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.buyer_gateway?.account_name}
											</td>
										</tr>

										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.accountNumber}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.buyer_gateway?.account_no}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.transactionNumber}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.buyer_transaction_no}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.date}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{digitChanger(
													moment(el?.payment_date).format(
														"DD-MM-YYYY"
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.amount}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{digitChanger(el?.amount)}
											</td>
										</tr>
									</tbody>
								);
							})
						) : (
							<tbody>
								<tr>
									<td className='text-222222 text-12 font-semibold pt-3'>
										{ekkLanguage.orderDetails.withoutSettelment}
									</td>
								</tr>
							</tbody>
						)}
					</table>
				</div>
			</Card>
		</div>
	);
}

import React, { forwardRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Calendar from "assets/images/icon/svg/Calendar";
import { useDispatch, useSelector } from "react-redux";
import Orders from "services/Orders";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoardService from "services/Dashboard";
import { IonCol, IonLoading, IonSpinner } from "@ionic/react";
import { getSelectedDate } from "redux/container/dashboardSlice";
import Add from "assets/images/icon/svg/Add";

export default function DashboardDetails() {
	const { date, category, cateList } = useParams();
	let retrievedData = JSON.parse(date);
	const [statusDetails, setStatusDetails] = useState({});
	const [selectLevel, setSelectLevel] = useState(retrievedData.level);
	const [loader, setLoader] = useState(true);
	const [startDate, setStartDate] = useState(
		retrievedData.start
			? moment(retrievedData.start).startOf("day")._d
			: moment().startOf("day")._d
	);

	const [endDate, setEndDate] = useState(
		retrievedData.end ? moment(retrievedData.end).endOf("day")._d : null
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let [loading, setLoading] = useState(true);

	const [selectedDate, setSelectedDate] = useState(
		date ? date : moment(new Date())
	);
	const [dayCalender, setDayCalender] = useState(
		selectLevel === "selectDay" ? true : false
	);
	const [rangeCalender, setRangeCalender] = useState(
		selectLevel === "custom" ? true : false
	);

	const dispatch = useDispatch();
	// getAnalyticsDetails;
	const getAnalyticsDetails = async (start, end) => {
		switch (cateList) {
			case "Placed":
			case "Accepted":
			case "Scheduled For Delivery":
			case "Delivery Failed":
			case "Partially_Delivered":
			case "Delivered":
			case "Returned_Items":
			case "Partially_Paid":
			case "Paid":
			case "Cancel":
				let response = await DashBoardService.getStatusDetails(
					start,
					end,
					cateList
				);
				if (response.status === 200) {
					setLoader(false);
					setLoading(false);
					setStatusDetails(response?.data);
				} else {
					setLoading(false);
					setLoader(false);
					setStatusDetails([]);
				}
				break;
			case "order":
			case "direct":
			case "ekkhero":
				let res = await DashBoardService.getAnalyticsDetails(
					start,
					end,
					cateList
				);
				if (res.status === 200) {
					setLoading(false);
					setLoader(false);
					setStatusDetails(res?.data);
				} else {
					setLoading(false);
					setLoader(false);
					setStatusDetails([]);
				}
				break;
			default:
				console.log("It's a weekend day.");
		}
	};

	useEffect(() => {
		let start = retrievedData.start
			? moment(retrievedData.start).toString()
			: moment().startOf("day").toString();

		let end = retrievedData.end
			? moment(retrievedData.end).toString()
			: moment().endOf("day").toString();

		getAnalyticsDetails(start, end);
	}, []);

	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today },
		{ value: "yesterday", label: ekkLanguage.dashboard.yesterday },
		{ value: "week", label: ekkLanguage.dashboard.thisWeek },
		{ value: "month", label: ekkLanguage.dashboard.thisMonth },
		{ value: "year", label: ekkLanguage.dashboard.thisYear },
		{ value: "lastWeek", label: ekkLanguage.dashboard.lastWeek },
		{ value: "lastMonth", label: ekkLanguage.dashboard.lastMonth },
		{ value: "lastYear", label: ekkLanguage.dashboard.lastYear },
		{ value: "custom", label: ekkLanguage.dashboard.custom },
		{ value: "selectDay", label: ekkLanguage.dashboard.selectDay },
	];

	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-3 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));
	const selectDateChanger = (e) => {
		if (e.value === "custom") {
			setRangeCalender(true);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let dateTime = {
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
		} else if (e.value === "selectDay") {
			setDayCalender(true);
			setRangeCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let dateTime = {
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
		} else {
			setRangeCalender(false);
			setDayCalender(false);
			let timeDate = dateTimeFormatter(e.value);
			// Get the analytics for the selected date range.
			getAnalyticsDetails(timeDate.start, timeDate.end);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
		}
	};

	const onChange = (dates) => {
		const [start, end] = dates;
		const endDate = end
			? moment(end).endOf("day")._d
			: moment(start).endOf("day")._d;
		setStartDate(start);
		setEndDate(end);
		let dateTime = { start, end: endDate, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		getAnalyticsDetails(start, endDate);
	};

	const onDayChange = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setStartDate(dates);
		setEndDate(endDate);
		let dateTime = { start: dates, end, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getAnalyticsDetails(dates, end);
	};

	const onRangeStart = (dates) => {
		setStartDate(dates);
		setEndDate(null);
		let dateTime = {
			start: dates,
			end: null,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
	};
	const onRangeEnd = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setEndDate(end);
		let dateTime = {
			start: JSON.parse(selectedDate).start,
			end,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getAnalyticsDetails(JSON.parse(selectedDate).start, end);
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white ml-4
								${
									selectedDate
										? "border-transparent font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{selectedDate ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{selectedDate ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate).start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							Select Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{selectedDate ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const FromDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.start
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.start ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.start ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate).start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							From Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.start ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const ToDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			style={{ width: "calc(100%-12px" }}
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.end
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.end ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.end ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate).end).format("DD-MM-YYYY")}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							To Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.end ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	return (
		<div>
			<IonLoading
				isOpen={loader}
				message={ekkLanguage.createOrder.loader}
				spinner='circles'
			/>
			<div className='py-5 pt-6'>
				<div className='px-2'>
					<div className={dayCalender ? "flex" : "block"}>
						<div className={dayCalender ? "w-[calc(50%-8px)]" : "w-full"}>
							<Select
								isSearchable={false}
								defaultValue={options.filter(
									(el) => el.value === retrievedData.level
								)}
								options={options}
								onChange={selectDateChanger}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										// width: 120,
										height: 40,
										border: "none",
										outLine: "none",
										fontWeight: 600,
										fontSize: 12,
										borderRadius: 5,
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
						</div>
						{dayCalender && (
							<div className='w-[calc(50%-8px)]'>
								<DatePicker
									selected={startDate}
									onChange={onDayChange}
									startDate={startDate}
									endDate={endDate}
									selectsDisabledDaysInRange
									withPortal
									customInput={<CustomInput />}
								/>
							</div>
						)}
					</div>
					<div>
						{rangeCalender && (
							<div className='flex items-center'>
								<div className='mr-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={startDate}
										onChange={onRangeStart}
										startDate={startDate}
										endDate={startDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<FromDateInput />}
									/>
								</div>
								<div className='ml-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={endDate}
										onChange={onRangeEnd}
										startDate={endDate}
										endDate={endDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<ToDateInput />}
									/>
								</div>
							</div>
						)}
					</div>

					{/* <span>
						
						<DatePicker
							selected={startDate}
							onChange={onChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							selectsDisabledDaysInRange
							withPortal
							customInput={<OfferStartDate />}
						/>
					</span> */}
				</div>
			</div>
			<div className='px-2 flex justify-between items-center'>
				<h4 className='font-bold text-20 text-black-1000'>{category}</h4>
				{/* <div className='flex items-center'>
					<Select
						isSearchable={false}
						defaultValue={options.filter(
							(el) => el.value === retrievedData.level
						)}
						options={options}
						onChange={selectDateChanger}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								width: 120,
								height: 40,
								border: "none",
								outLine: "none",
								fontWeight: 600,
								fontSize: 12,
								borderRadius: 5,
							}),
						}}
						theme={(theme) => ({
							...theme,
							borderRadius: 5,
							colors: {
								...theme.colors,
								primary25: "rgb(204, 255, 214)",
								primary: "#00FF33",
							},
						})}
					/>
					<span>
						<DatePicker
							selected={startDate}
							onChange={onChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							selectsDisabledDaysInRange
							withPortal
							customInput={<OfferStartDate />}
						/>
					</span>
				</div> */}
			</div>
			<div>
				<table
					className='table-auto w-full bg-white'
					style={{ boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)" }}>
					<thead>
						<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
							<th className='font-bold text-14'>S/N</th>
							<th className='font-bold text-14'>
								{ekkLanguage.home.orderNumber}
							</th>
							<th className='font-bold text-14'>
								{ekkLanguage.home.amount}
							</th>
						</tr>
					</thead>
					<tbody>
						{statusDetails.length
							? statusDetails.map((el, i) => {
									return (
										<tr
											className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
											key={i}>
											<td className='font-semibold text-12'>
												{digitChanger(i + 1)}
											</td>
											<td className='font-semibold text-12'>
												<Link
													className='text-delivered'
													to={`/order-details/${el?._id}/seller`}>
													{digitChanger(el?.order_id)}
												</Link>
											</td>
											<td className='font-semibold text-12'>
												{digitChanger(
													cateList === "Partially_Paid"
														? numberWithCommas(el?.total_paid)
														: numberWithCommas(el?.total)
												)}
											</td>
										</tr>
									);
							  })
							: ""}
					</tbody>
				</table>
				{loading ? (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				) : (
					""
				)}
				{statusDetails.length === 0 && (
					<>
						<IonCol size='2'></IonCol>
						<IonCol size='8'>
							<div
								className='text-center flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</IonCol>
					</>
				)}
			</div>
		</div>
	);
}

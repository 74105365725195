import { createSlice } from "@reduxjs/toolkit";
// import { shoppingCart } from "helpers/shoppingCart";

export const learningSlice = createSlice({
	name: "singlePost",
	initialState: {
		singlePost: {},
		postList: [],
	},
	reducers: {
		singlePost: (state, action) => {
			state.singlePost = action.payload;
		},
		setPostList: (state, action) => {
			state.postList = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { singlePost, setPostList } = learningSlice.actions;
export const { actions, reducer } = learningSlice;
export default reducer;

import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BrandList from "components/mobile-components/MyBrandList";
import { digitChanger, numberWithCommas } from "helpers/helper";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Brand from "services/Brand";
import Orders from "services/Orders";

export default function OrderHistoryListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [orderHistoryList, setOrderHistoryList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [loading, setLoading] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getOrderHistoryList = async () => {
		setLoading(true);
		setPageCount(2);
		let response = await Orders.getOrderHistoryList();
		if (response.status === 200) {
			setOrderHistoryList(response.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getOrderHistoryList();
	}, []);

	let lodeMoreData = async () => {
		if (hasMoreData) {
			const response = await Orders.getOrderHistoryList(pageCount);
			if (response.status === 200) {
				if (response.data?.length) {
					setPageCount((prev) => prev + 1);
					setOrderHistoryList([...orderHistoryList, ...response.data]);
				} else {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.dashboard.totalOrder} />
					<section className='pt-14'>
						<div className='mt-4 mb-4'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='font-bold text-14'>S/N</th>
										<th className='font-bold text-14'>
											{ekkLanguage.home.orderNumber}
										</th>
										<th className='font-bold text-14'>
											{ekkLanguage.home.amount}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderHistoryList?.length
										? orderHistoryList.map((el, i) => {
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='font-semibold text-12'>
															{digitChanger(i + 1)}
														</td>
														<td className='font-semibold text-12'>
															<Link
																className='text-delivered'
																to={`/order-details/${el?._id}/seller`}>
																{digitChanger(el?.order_id)}
															</Link>
														</td>
														<td className='font-semibold text-12'>
															{digitChanger(
																numberWithCommas(el?.total)
															)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderHistoryList.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								lodeMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

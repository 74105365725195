import {
	IonApp,
	IonContent,
	IonPage,
	IonButton,
	IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import Payment from "services/Payment";
import { useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";

export default function InitPaymentSettings({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [paymentTerms, setPaymentTerms] = useState([]);
	const [paymentTermIds, setPaymentTermsIds] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sellerTerms, setSellerTerms] = useState([]);
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	let getAllPaymentInfo = async function () {
		let results = await Payment.getAllPaymentTerms();
		let paymentTerms = await Payment.sellerPaymentTerms();
		if (results?.status === 200) {
			setSellerTerms(paymentTerms.results);
			setLoading(false);
			let ids = [];
			let finalArray = results?.results.map((item1) => {
				let matchChecker = paymentTerms.results.find(
					(item2) => item2.id === item1.id
				);
				if (matchChecker) {
					ids.push(item1.id);
					return { ...item1, is_active: true };
				} else {
					return { ...item1, is_active: false };
				}
			});
			setPaymentTermsIds(ids);
			setPaymentTerms(finalArray);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAllPaymentInfo();
	}, []);
	const changeCheckHandler = (data) => {
		let updateArray = [...paymentTermIds];
		let checkIndex = updateArray.indexOf(data.id);
		if (checkIndex !== -1) {
			updateArray.splice(checkIndex, 1);
			let updateTerms = paymentTerms.map((term) =>
				term.id === data.id ? { ...term, is_active: false } : term
			);
			setPaymentTerms(updateTerms);
		} else {
			updateArray.push(data.id);
			let updateTerms = paymentTerms.map((term) =>
				term.id === data.id ? { ...term, is_active: true } : term
			);
			setPaymentTerms(updateTerms);
		}
		setPaymentTermsIds(updateArray);
	};
	const handleSaveTerms = async () => {
		setLoader(true);
		if (sellerTerms?.every((term) => term.is_default === true)) {
			// Create API call
			const data = {
				seller_id: businessData?.id,
				pterms_ids: paymentTermIds?.sort((a, b) => a - b),
			};
			let response = await Payment.createPaymentTerms(data);
			if (response.success) {
				setLoader(false);
				navigate("/business-success");
				toast.success("Payment Terms Successfully Created!");
			} else {
				// Update API call
				setLoader(false);
				let res = await Payment.updatePaymentTerms(data);
				if (res.success) {
					setLoader(false);
					navigate("/business-success");
					toast.success("Payment Terms Successfully Updated!");
				}
			}
		} else {
			// Update API call
			const data = {
				seller_id: businessData?.id,
				pterms_ids: paymentTermIds?.sort((a, b) => a - b),
			};
			let response = await Payment.updatePaymentTerms(data);
			if (response.success) {
				navigate("business-success");
				toast.success("Payment Terms Successfully Updated");
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20 px-4'>
						{/* page title  */}
						<h6 className='text-18 font-bold mb-5'>
							{ekkLanguage.paymentSettings.mainTitle}{" "}
						</h6>
						<h2 className='text-14 opacity-50 font-semibold mb-5'>
							{ekkLanguage.paymentSettings.subTitle}{" "}
						</h2>
						{/* payment terms options */}
						<>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								<>
									{paymentTerms?.map((term) => (
										<CustomCheckBox
											el={term}
											key={term.id}
											currentState={term.is_active}
											changeHandler={changeCheckHandler}
											title={term.title}
										/>
									))}
								</>
							)}
						</>

						{/* next button  */}
						<div className='pt-24 text-center pb-10'>
							<IonButton
								disabled={paymentTermIds?.length > 1 ? false : true}
								color={paymentTermIds?.length > 1 ? "success" : "dark"}
								className='font-bold h-[50px] w-full'
								type='submit'
								onClick={() => handleSaveTerms()}>
								{loader ? (
									<IonSpinner></IonSpinner>
								) : (
									<>
										{ekkLanguage.businessCreateName.submit}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</>
								)}
							</IonButton>
						</div>
						{/* <IonButton
							type='submit'
							onClick={() => handleSaveTerms()}
							color='success'
							className='font-bold h-12 text-20 rounded  w-full mt-20 mx-auto'>
							{ekkLanguage.paymentSettings.btnLabel}
						</IonButton> */}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

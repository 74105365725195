import { combineReducers } from "redux";
import auth from "redux/container/authSlice";
import businessSettingsSlice from "redux/container/businessSettingsSlice";
import user from "redux/container/userSlice";
import productSlice from "redux/container/productSlice";
import dashboard from "redux/container/dashboardSlice";
import { counterSlice } from "redux/container/counter/counterSlice";
import orderSlice from "redux/container/orderSlice";
import paymentMethodSlice from "redux/container/paymentMethodSlice";
import payEkkbazSlice from "redux/container/payEkkbazSlice";
import shoppingCartSlice from "redux/container/shoppingCartSlice";
import languageSlice from "redux/container/languageSlice";
import orderDetailsSlice from "redux/container/orderDetailsSlice";
import notificationSlice from "redux/container/notificationSlice";
import ProductSummerySlice from "redux/container/ProductSummerySlice";
import driverSlice from "redux/container/driverSlice";
import addBankSlice from "redux/container/addBankSlice";
import loanSlice from "redux/container/loanSlice";
import buzAiSlice from "redux/container/bazAiSlice";
import learningSlice from "redux/container/learningSlice";
import customerSlice from "redux/container/customerSlice";
import SubscriptionSlice from "redux/container/SubscriptionSlice";

const appReducer = combineReducers({
	auth,
	user,
	cart: shoppingCartSlice,
	counter: counterSlice,
	order: orderSlice,
	payment: paymentMethodSlice,
	payEkkbaz: payEkkbazSlice,
	lan: languageSlice,
	businessSettings: businessSettingsSlice,
	product: productSlice,
	dashboard,
	orderDetails: orderDetailsSlice,
	notification: notificationSlice,
	productSummery: ProductSummerySlice,
	driver: driverSlice,
	bankSettings: addBankSlice,
	loan: loanSlice,
	bazAi: buzAiSlice,
	learning: learningSlice,
	customer: customerSlice,
	subscription: SubscriptionSlice,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logout") state = undefined;
	return appReducer(state, action);
};
export default rootReducer;

import React, { useEffect } from "react";

const MatchKeyWords = ({
	searchedKeyWord,
	setProductNames,
	handleSearchKeyword,
	setHasMoreData,
	productNames,
	ekkLanguage,
}) => {
	useEffect(() => {
		if (searchedKeyWord?.length === 1) {
			setProductNames(searchedKeyWord[0]);
			handleSearchKeyword(searchedKeyWord[0]);
			setHasMoreData(true);
		}
	}, [searchedKeyWord]);
	return (
		<div>
			<ul>
				{searchedKeyWord?.length
					? searchedKeyWord.map((keyword, i) => {
							return (
								<li
									key={i}
									onClick={() => {
										setProductNames(keyword);
										handleSearchKeyword(keyword);
										setHasMoreData(true);
									}}
									className={`px-4 py-2 text-12 font-bold inline-block ${
										keyword === productNames
											? "text-white bg-[#2746F5D9] border-[#2746F533]"
											: "text-[#2746F5B2] bg-[#2746F51A] border-[#2746F533]"
									}  rounded-[15px] mr-2 mb-2 `}>
									{keyword}
								</li>
							);
					  })
					: undefined}
			</ul>
			{searchedKeyWord?.length > 1 && productNames?.length === 0 ? (
				<p className='text-primary text-xs mt-2 px-1'>
					{ekkLanguage.voiceOrder.keywordInfo}
				</p>
			) : (
				""
			)}
		</div>
	);
};
export default MatchKeyWords;

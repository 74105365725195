import Calendar from "assets/images/icon/svg/Calendar";
import React, { forwardRef, useState, useRef } from "react";
import Card from "../Common/Card";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IonButton, IonDatetime, IonPopover } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import { useDispatch, useSelector } from "react-redux";
import { deliveryDate } from "redux/container/paymentMethodSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DeliveryCard() {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let daliveryDate = useSelector((state) => state.payment?.deliveryDate);
	const [selectedDalieveryDate, setSelectedDalieveryDate] = useState(
		daliveryDate ? daliveryDate : moment(new Date()).add(1, "days")
	);

	let dateChanger = (e) => {
		setSelectedDalieveryDate(e);
		dispatch(deliveryDate(moment(e).format()));
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border example-custom-input ${
				daliveryDate ? "border-transparent" : "border-primary"
			} `}
			onClick={onClick}
			style={{
				boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
			}}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<span>
						<Calendar color={daliveryDate ? "#222222" : "#FF564C"} />
					</span>
					<p
						className={`text-12 ${
							daliveryDate ? "border-transparent" : "text-primary"
						} ml-2`}>
						{moment(selectedDalieveryDate).format("DD-MM-YYYY")}
					</p>
				</div>
				<div>
					<Add />
				</div>
			</div>
		</div>
	));
	return (
		<>
			<div className='px-2 pb-3 pt-1'>
				<Card title={ekkLanguage.createOrder.requestDeliveryDate}>
					<DatePicker
						minDate={moment().toDate()}
						selected={
							daliveryDate
								? moment(daliveryDate)._d
								: moment(new Date()).add(1, "days")._d
						}
						onChange={(date) => dateChanger(date)}
						customInput={<ExampleCustomInput />}
						withPortal
					/>
				</Card>
			</div>
		</>
	);
}

import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

export default function ProductFilter({
	productBrands = [],
	getProductsFromBrands,
	fetchProduct,
	setSearchQ,
	setSelectedBrand,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<IonSegment scrollable className='orderBtnWrapper'>
						<IonSegmentButton
							className='relative'
							onClick={() => {
								setSelectedBrand("");
								fetchProduct("all");
								setSearchQ("");
							}}>
							<button className=' font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.createOrder.all}
							</button>
						</IonSegmentButton>
						{productBrands.map((el) => {
							return (
								<IonSegmentButton
									className='relative'
									onClick={() => {
										setSearchQ("");
										getProductsFromBrands(el.id);
									}}>
									<button className=' font-sans p-0 font-normal shadow-none text-14'>
										{el?.brand_name}
									</button>
								</IonSegmentButton>
							);
						})}
					</IonSegment>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}

import React, { useEffect, useState } from "react";
import {
	IonApp,
	IonButton,
	IonCheckbox,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useSelector } from "react-redux";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { useNavigate } from "react-router-dom";
import BothPartiesDetailsBN from "components/mobile-components/EBSAgreementPaper/BothPartiesDetailsBN";
import AgreementsBN from "components/mobile-components/EBSAgreementPaper/AgreementsBN";
import BothPartiesSignBN from "components/mobile-components/EBSAgreementPaper/BothPartiesSignBN";
import HeadlinePart from "components/mobile-components/EBSAgreementPaper/HeadlinePart";
import { getCreateUserBusiness, getUserBusiness } from "config/user";
import User from "services/User";
import moment from "moment";
import Business from "services/Business";
import { getLanguage } from "config/language";
import BothPartiesDetailsVN from "components/mobile-components/EBSAgreementPaper/BothPartiesDetailsVN";
import BothPartiesDetailsEN from "components/mobile-components/EBSAgreementPaper/BothPartiesDetailsEN";
import AgreementsEN from "components/mobile-components/EBSAgreementPaper/AgreementsEN";
import AgreementsVN from "components/mobile-components/EBSAgreementPaper/AgreementsVN";
import BothPartiesSignEN from "components/mobile-components/EBSAgreementPaper/BothPartiesSignEN";
import BothPartiesSignVN from "components/mobile-components/EBSAgreementPaper/BothPartiesSignVN";
import DeviceDetector from "device-detector-js";
import axios from "axios";
import BothPartiesDetailsMY from "components/mobile-components/EBSAgreementPaper/BothPartiesDetailsMY";
import AgreementsMY from "components/mobile-components/EBSAgreementPaper/AgreementsMY";
import BothPartiesSignMY from "components/mobile-components/EBSAgreementPaper/BothPartiesSignMY";
import { toast } from "react-toastify";

export default function EBSAgreementPaperPage() {
	const kycDetails = useSelector((state) => state.businessSettings.data);
	const businessDetails = getUserBusiness();
	const lan = getLanguage();
	const createdBusinessDetails = getCreateUserBusiness();
	const [isChecked, setIsChecked] = useState(false);
	const [firstPartySignatureImage, setFirstPartySignatureImage] = useState("");
	const [secondPartySignatureImage, setSecondPartySignatureImage] =
		useState("");
	const [loader, setLoader] = useState(false);
	const [ip, setIP] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const deviceDetector = new DeviceDetector();
	const userAgent =
		"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
	const device = deviceDetector.parse(userAgent);
	device.ip = ip;
	const getData = async () => {
		const res = await axios.get("https://api.ipify.org/?format=json");
		setIP(res.data.ip);
	};

	const handleOnChange = (e) => {
		setIsChecked(!isChecked);
	};
	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	const nextHandler = async () => {
		if (firstPartySignatureImage) {
			setLoader(true);
			const image = DataURIToBlob(firstPartySignatureImage);
			const imageData = new FormData();
			imageData.append("key", image, "image.png");
			let uploadResponse = await User.toBucketImage(imageData);
			if (uploadResponse.status === 201 || uploadResponse.status === 200) {
				let data = {
					business_name:
						createdBusinessDetails?.business_name ??
						businessDetails?.business_name,
					business_address:
						createdBusinessDetails?.address_line ??
						businessDetails?.address_line,
					trade_no: kycDetails?.trade_no,
					nid_no: kycDetails?.nid_no,
					start_date: moment(new Date()).format("DD-MM-YYYY"),
					end_date: moment(new Date()).add("year", 1).format("DD-MM-YYYY"),
					user_info: device,
					business_signeture: uploadResponse?.results[0],
				};
				console.log(data);
				let response = await Business.createAgreement(data);
				if (response.status === 200 || response.status === 201) {
					setLoader(false);
					navigate("/ekkbaz-terms-conditons");
				}
			} else {
				return null;
			}
		} else {
			toast.warning("Signature not found");
		}
	};
	useEffect(() => {
		getData();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<div className='pt-14 px-4'>
						{/* headline part */}
						<HeadlinePart ekkLanguage={ekkLanguage} />
						{/* details of both parties */}
						{lan === "bn" ? (
							<BothPartiesDetailsBN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
							/>
						) : lan === "vn" ? (
							<BothPartiesDetailsVN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
							/>
						) : lan === "my" ? (
							<BothPartiesDetailsMY
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
							/>
						) : (
							<BothPartiesDetailsEN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
							/>
						)}
						{/* list of agreements */}
						{lan === "bn" ? (
							<AgreementsBN />
						) : lan === "vn" ? (
							<AgreementsVN />
						) : lan === "my" ? (
							<AgreementsMY />
						) : (
							<AgreementsEN />
						)}
						{/* signatures of both parties */}
						{lan === "bn" ? (
							<BothPartiesSignBN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
								firstPartySignatureImage={firstPartySignatureImage}
								setFirstPartySignatureImage={
									setFirstPartySignatureImage
								}
								secondPartySignatureImage={secondPartySignatureImage}
								setSecondPartySignatureImage={
									setSecondPartySignatureImage
								}
							/>
						) : lan === "vn" ? (
							<BothPartiesSignVN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
								firstPartySignatureImage={firstPartySignatureImage}
								setFirstPartySignatureImage={
									setFirstPartySignatureImage
								}
								secondPartySignatureImage={secondPartySignatureImage}
								setSecondPartySignatureImage={
									setSecondPartySignatureImage
								}
							/>
						) : lan === "my" ? (
							<BothPartiesSignMY
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
								firstPartySignatureImage={firstPartySignatureImage}
								setFirstPartySignatureImage={
									setFirstPartySignatureImage
								}
								secondPartySignatureImage={secondPartySignatureImage}
								setSecondPartySignatureImage={
									setSecondPartySignatureImage
								}
							/>
						) : (
							<BothPartiesSignEN
								businessDetails={businessDetails}
								kycDetails={kycDetails}
								createdBusinessDetails={createdBusinessDetails}
								firstPartySignatureImage={firstPartySignatureImage}
								setFirstPartySignatureImage={
									setFirstPartySignatureImage
								}
								secondPartySignatureImage={secondPartySignatureImage}
								setSecondPartySignatureImage={
									setSecondPartySignatureImage
								}
							/>
						)}
						{/* Check box for accept all */}
						<div className='trams-condition pt-10 pb-5 sm:pb-[20px]'>
							<label className='flex cursor-pointer my-2'>
								<IonCheckbox
									color='success'
									size='small'
									slot='start'
									value='terms'
									className='mr-2 checkbox-square'
									checked={isChecked}
									onIonChange={handleOnChange}></IonCheckbox>

								<p className='flex-1 text-14 text-[#262626] text-justify font-medium '>
									{ekkLanguage.kycVerification.acceptCheckbox}
								</p>
							</label>
						</div>

						<div className='pt-24 text-center pb-10'>
							{/* <IonButton
disabled={
firstPartySignatureImage &&
(isChecked ? false : true) | loader
}
color={
firstPartySignatureImage && isChecked
? "success"
: "dark"
}
className='font-bold h-[50px] w-full'
onClick={nextHandler}>
{loader ? (
<IonSpinner></IonSpinner>
) : (
<>
{ekkLanguage.businessCreateName.btnLabel}
<span className='pl-2'>
<ArrayRight />
</span>
</>
)}
</IonButton> */}
							<IonButton
								disabled={(isChecked ? false : true) | loader}
								color={isChecked ? "success" : "dark"}
								className='font-bold h-[50px] w-full'
								onClick={nextHandler}>
								{loader ? (
									<IonSpinner></IonSpinner>
								) : (
									<>
										{ekkLanguage.businessCreateName.btnLabel}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</>
								)}
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";

const Results = ({ speech, ekkLanguage }) => {
  return (
    <div
      className={`rounded-lg  border-2 border-[#2746F533] bg-[#2746F51A] ${
        speech ? "mt-4" : "lg:mt-20 mt-[35px]"
      }`}
    >
      {speech ? (
        <h6 className="px-4 py-2 text-12 font-bold inline-block rounded-[2px]">
          {speech}
        </h6>
      ) : (
        <div className="flex justify-center items-center flex-col py-3">
          <img
            className="w-[90px] h-[90px]"
            src="/assets/images/mouth.png"
            alt=""
          />
          <div className="flex justify-center w-full mt-3 px-5">
            {" "}
            <p className="text-14 font-medium text-[#2746F566] text-center">
              {ekkLanguage.voiceOrder.speechInfo}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;

import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Error() {
	const navigate = useNavigate();
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<div className='flex items-center justify-center h-screen'>
						<div className='text-center'>
							<h4 className='text-18 text-black-1000 font-bold pb-6'>
								No content found
							</h4>
							<IonButton
								color='primary'
								className='font-bold h-10 text-14'
								onClick={() => {
									navigate("/baz-ai");
								}}>
								Back To Baz AI
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleCoupon from "./SingleCoupon";
import Orders from "services/Orders";
import { getUserBusiness } from "config/user";
import { IonLoading } from "@ionic/react";

export default function CouponList() {
	// const [code, setCode] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [couponList, setCouponList] = useState(null);
	const [loader, setLoader] = useState(true);

	const addCouponHandler = async () => {
		let response = await Orders.getFsPromoCode(getUserBusiness().id);
		if (response.status === 200) {
			setLoader(false);
			setCouponList(response.results);
		} else {
			setLoader(false);
		}
	};
	useEffect(() => {
		addCouponHandler();
	}, []);
	return (
		<div className='pt-4'>
			{/* <div className='px-2'>
				<TextInput
					type='text'
					title={ekkLanguage.couponList.addCoupon}
					inputText={code}
					setInputText={setCode}
					placeholder={ekkLanguage.couponList.couponPlaceholder}
				/>
			</div>
			<div className='flex'>
				<button
					onClick={addCouponHandler}
					color='primary'
					className={`font-bold h-[50px] rounded-[5px] w-full ml-2 bg-success text-white flex justify-center items-center`}>
					{ekkLanguage.couponList.add}
				</button>
			</div> */}
			{loader ? (
				<IonLoading
					isOpen={loader}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<div className='px-2'>
					{couponList?.length ? (
						couponList.map((el, i) => {
							return <SingleCoupon item={el} key={i} />;
						})
					) : (
						<div
							className='text-center flex items-center justify-center mt-14'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/empty2.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

import React, { useEffect } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import Product from "services/Product";

const Record = ({ setLoading, setSpeech, setSearchedKeyWord }) => {
	const recorderControls = useVoiceVisualizer();
	const { stopRecording, recordedBlob, error, audioRef } = recorderControls;

	useEffect(() => {
		const sendAudioToAPI = async () => {
			stopRecording();
			if (recordedBlob) {
				setLoading(true);
				try {
					let formData = new FormData();
					formData.append("audio_file", recordedBlob);
					const response = await Product.audioProduct(formData);

					if (response.status === 200) {
						setLoading(false);
						setSpeech(response?.input_text);
						setSearchedKeyWord(response?.products);
						console.log("Audio sent successfully!");
					} else {
						setLoading(false);
						console.error("Failed to send audio.");
					}
				} catch (error) {
					console.error("Error sending audio:", error);
				}
			}
		};
		if (recordedBlob) {
			sendAudioToAPI();
		}
		if (error) {
			console.log("Error:", error);
		}
	}, [recordedBlob]);

	// useEffect(() => {
	//   let interval = setTimeout(() => {
	//     stopRecording();
	//   }, 30000);
	//   return () => clearTimeout(interval);
	// }, []);

	return (
		<div className='w-full relative'>
			<div className=''>
				<VoiceVisualizer
					// onlyRecording={true}
					// backgroundColor="white"
					mainBarColor='green'
					secondaryBarColor='red'
					controls={recorderControls}
					speed={5}
					ref={audioRef}
					// animateCurrentPick={true}
					defaultAudioWaveIconColor='green'
					defaultMicrophoneIconColor='red'
					isControlPanelShown={true}
					barWidth={2}
					// isDefaultUIShown={false}
					isProgressIndicatorShown={true}
					canvasContainerClassName='mb-5'
					progressIndicatorClassName='className'
					fullscreen={true}
					controlButtonsClassName=''
					progressIndicatorTimeClassName='hidden'
					recordingTime={30}
					duration={30}
					isProgressIndicatorTimeShown={false}
				/>
			</div>
		</div>
	);
};

export default Record;

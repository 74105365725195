import React from "react";

export default function Collection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.99984 9.16732V12.5006M14.9998 7.50065V10.834M14.1665 3.33398C16.2071 3.33398 17.3108 3.64628 17.8599 3.88852C17.9331 3.92078 17.9696 3.93691 18.0752 4.03763C18.1384 4.098 18.2539 4.27515 18.2836 4.35739C18.3332 4.4946 18.3332 4.5696 18.3332 4.7196V13.6766C18.3332 14.4339 18.3332 14.8126 18.2196 15.0072C18.1041 15.2052 17.9927 15.2972 17.7765 15.3733C17.5639 15.4481 17.1348 15.3657 16.2766 15.2008C15.676 15.0854 14.9636 15.0007 14.1665 15.0007C11.6665 15.0007 9.1665 16.6673 5.83317 16.6673C3.79258 16.6673 2.68889 16.355 2.13974 16.1128C2.06661 16.0805 2.03004 16.0644 1.9245 15.9637C1.86124 15.9033 1.74577 15.7262 1.71606 15.6439C1.6665 15.5067 1.6665 15.4317 1.6665 15.2817L1.6665 6.3247C1.6665 5.56739 1.6665 5.18874 1.78007 4.9941C1.89559 4.79612 2.007 4.70408 2.22322 4.62799C2.43578 4.55319 2.86487 4.63564 3.72303 4.80052C4.32369 4.91593 5.03607 5.00065 5.83317 5.00065C8.33317 5.00065 10.8332 3.33398 14.1665 3.33398ZM12.0832 10.0007C12.0832 11.1512 11.1504 12.084 9.99984 12.084C8.84924 12.084 7.9165 11.1512 7.9165 10.0007C7.9165 8.85006 8.84924 7.91732 9.99984 7.91732C11.1504 7.91732 12.0832 8.85006 12.0832 10.0007Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

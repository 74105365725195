import Pause from "assets/images/icon/svg/Pause";
import Player from "assets/images/icon/svg/Player";
import React from "react";

export default function PlayPauseBtn({
	audioRef,
	handlePlayPause,
	audioPlayer,
	isPlaying,
	setAudioPlayer,
}) {
	return (
		<span
			className='mr-2'
			onClick={() => {
				if (audioRef?.current) {
					audioRef?.current.pause();
				}
				handlePlayPause();
				setAudioPlayer(!audioPlayer);
			}}>
			{isPlaying ? <Pause /> : <Player />}
		</span>
	);
}

import { IonInput } from "@ionic/react";
import { Input } from "components/global-components/common/Input";
import React, { memo } from "react";

function OTPInput({ otp, ekkLanguage, setOtp }) {
  return (
    // <IonInput
    // 	type='number'
    // 	value={otp}
    // 	placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
    // 	onIonChange={(e) => setOtp(e.detail.value)}
    // 	clearInput></IonInput>

    <Input
      onChange={(e) => setOtp(e.target.value)}
      type="number"
      value={otp}
      label={ekkLanguage.otpsubmit.otpLabel}
      placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
      required
    ></Input>
  );
}
export default memo(OTPInput);

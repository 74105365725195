import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Calendar from "assets/images/icon/svg/Calendar";
import Add from "assets/images/icon/svg/Add";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CollectorAvatar from "assets/images/icon/svg/CollectorAvatar";
import DepositCard from "./DepositCard";
import DepositList from "./DepositList";
import { useNavigate } from "react-router-dom";
import Deposit from "services/Deposit";
import { bankListArray } from "data/bankList";
import {
	setCollectionDate,
	setCollectorData,
} from "redux/container/driverSlice";

export default function CollectionDeposit() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	// const [selectedDate, setSelectedDate] = useState(null);
	const selectedDate = useSelector((state) => state.driver.collectorDate);
	let viewDate = moment(selectedDate).format("YYYY-MM-DD");
	const collector = useSelector((state) => state.driver.collectorData);
	const navigate = useNavigate();
	const [depositList, setDepositList] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const dateChanger = (date) => {
		// setSelectedDate(date);
		getDepositList(collector?.id, moment(date).format("YYYY-MM-DD"));
		dispatch(setCollectionDate(date));
	};
	const CalendarCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-40 h-10 border p-2.5 rounded relative bg-white
								${
									selectedDate
										? "border-[#00B224] font-bold text-[#00B224]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex items-center'>
				<span>
					<Calendar
						color={selectedDate ? "#00B224" : "#FF564C"}></Calendar>
				</span>
				<span>
					<p
						className={`text-center pl-2.5 text-12  ${
							selectedDate ? "text-[#00B224]" : "text-[#FF564C]"
						} pt-0.5`}>
						{selectedDate ? viewDate : ekkLanguage.depositList.selectDate}
					</p>
				</span>
				<span className='absolute right-0 mr-2.5'>
					<Add color={selectedDate ? "#00B224" : "#FF564C"}></Add>
				</span>
			</div>
		</div>
	));

	const getDepositList = async (id, date) => {
		setLoading(true);
		let response = await Deposit.getDepositList(
			id,
			(date === "Invalid date") | (date === "") ? "" : date
		);
		if (response.status === 200) {
			setLoading(false);
			setDepositList(response.data);
		} else {
			setLoading(false);
		}
	};
	useEffect(() => {
		getDepositList(collector?.id, viewDate);
	}, []);
	console.log(selectedDate);
	return (
		<div>
			<div className='flex justify-between px-3'>
				<div
					className={`flex text-center text-12 w-40 relative rounded items-center 
      `}>
					<DatePicker
						onFocus={(e) => (e.target.readOnly = true)}
						placeholderText={ekkLanguage.depositList.selectDate}
						// selected={moment(selectedDate)._d ?? ""}
						onChange={(date) => dateChanger(date)}
						customInput={<CalendarCustomInput />}
						withPortal
					/>
				</div>

				<div
					className={`bg-white flex px-2 w-40 rounded border  ${
						collector?.name ? "border-[#00B224]" : "border-[#FF564C]"
					}`}>
					<div
						onClick={() => navigate("/collector-list")}
						className='text-center flex  text-12 font-semibold items-center justify-between w-full '>
						<span className='ml-2 '>
							<CollectorAvatar
								color={collector?.name ? "#00B224" : "#FF564C"}
							/>
						</span>{" "}
						<span
							className={`my-1 ${
								collector?.name ? "text-[#00B224]" : "text-[#FF564C]"
							} font-normal`}>
							{collector?.name ??
								ekkLanguage.depositList.selectCollector}
						</span>{" "}
						<Add color={collector?.name ? "#00B224" : "#FF564C"} />
					</div>
				</div>
			</div>

			<DepositList
				ekkLanguage={ekkLanguage}
				depositList={depositList}
				loading={loading}
				bankListArray={bankListArray}
			/>
		</div>
	);
}

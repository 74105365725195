import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import React from "react";
import { clearAuth } from "config/authFetch";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions, logout } from "redux/container/authSlice";

export default function AccessRestrictPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className=' bg-primary z-40 pl-4 py-3 left-0 top-0 w-full'>
						<h5 className='text-white text-18 font-bold text-center'>
							{ekkLanguage.restrict.pageTitle}
						</h5>
					</div>
					<div className='px-4'>
						<div>
							<img
								src='assets/images/padlock.png'
								className='m-auto py-10'
								alt='lock'
							/>
							<p className='text-center px-5 font-bold text-16 mb-[30px]'>
								{" "}
								{ekkLanguage.restrict.warning}
							</p>
							<p className='text-center px-5 font-semibold text-14 mb-[30px] text-primary'>
								{ekkLanguage.restrict.btnClick}
							</p>
							<button
								onClick={() => {
									dispatch(actions.logout());
									localStorage.removeItem("master_token");
									localStorage.removeItem("_u");
									localStorage.removeItem("_b");
									localStorage.removeItem("cb");
									localStorage.setItem("auth", "false");
									clearAuth();
									navigate("/");
								}}
								className='w-full pt-14'>
								<IonButton
									className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success'
									style={{
										boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
									}}>
									{ekkLanguage.businessCreateName.goBack}
								</IonButton>
							</button>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

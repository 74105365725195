import { agreements } from "data/agreements";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";

export default function AgreementsBN() {
  return (
    <div className="mt-2">
      <p className="text-justify text-222222 text-14 mb-2">
        <span>{digitChanger(1)}.</span> অত্র সাময়িক চুক্তিপত্রটি{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(moment(new Date()).format("DD MMM, YYYY"))
          )}
        </span>{" "}
        ইং তারিখ হইতে কার্যকারী হইবে এবং{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(
              moment(new Date()).add("year", 1).format("DD MMM, YYYY")
            )
          )}
        </span>{" "}
        ইং সাল পর্যন্ত পক্ষগণের মধ্যে বলবৎ থাকিবে। চুক্তির মেয়াদ ১ মাস পূর্ণ
        হওয়ার পর প্রথম পক্ষ/ডিস্ট্রিবিউটর স্বয়ংক্রিয়ভাবে{" "}
        <span className="font-bold">EBS (EkkBaz Seller Services)</span> এর জন্য
        যোগ্য হিসাবে গন্য হবেন।
      </p>
      {agreements.map((agreement) => {
        return (
          <p
            key={agreement.id}
            className="text-justify text-222222 text-14 mb-2"
          >
            <span>{digitChanger(agreement.id)}.</span>{" "}
            {agreement.bangla_description}
          </p>
        );
      })}
    </div>
  );
}

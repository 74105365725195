import Delete from "assets/images/icon/svg/Delete";
import React, { useRef } from "react";
import { useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

export default function Signature({
	signatureImage,
	setSignatureImage,
	placeholder,
	showDash,
	bgColor,
	hideDeleteButton,
}) {
	const signatureRef = useRef();
	const clear = () => {
		signatureRef.current.clear();
		setSignatureImage("");
	};
	const save = () => {
		setSignatureImage(
			signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
		);
	};

	// useEffect(() => {
	// 	if (hideDeleteButton) {
	// 		signatureRef.current.off();
	// 	}
	// }, [hideDeleteButton]);

	return (
		<div>
			<div className='flex justify-between items-center w-full pb-2'>
				{" "}
				<div className='my-3 flex justify-start w-full items-center'>
					<p className='text-justify text-222222 text-14 font-bold'>
						{placeholder}
					</p>
					{showDash && (
						<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
					)}
				</div>
				{!hideDeleteButton && (
					<span
						className={`h-10 w-10 flex items-center justify-center ${
							signatureImage ? "bg-primary" : "bg-white"
						} rounded-[5px]`}
						onClick={clear}>
						<Delete color={signatureImage ? "white" : "red"} />
					</span>
				)}
			</div>
			<div className='text-left rounded-[10px]'>
				<div
					// onMouseLeave={save}
					// onTouchEnd={save}
					className={`${`bg-${bgColor}`} rounded-[10px] overflow-hidden border-2 border-dashed border-[#00000054]`}
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
					<SignatureCanvas
						onEnd={save}
						ref={signatureRef}
						penColor='black'
						canvasProps={{
							width: window.innerWidth,
							height: 200,
							className: "sigCanvas",
						}}
					/>
				</div>
			</div>
		</div>
	);
}

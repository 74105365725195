import React, { Component } from "react";
import PropTypes from "prop-types";

export class InputWithImage extends Component {
  static defaultProps = {
    label: "",
    errorMessage: "",
    placeholder: "",
  };
  render() {
    const {
      label,
      source,
      placeholder,
      errorMessage,
      extraLabel,
      star,
      ...inputExtras
    } = this.props;
    return (
      <div className="mb-1">
        {typeof label === "object" && (
          <label className="flex tracking-wide text-grey-darker text-16 font-bold mb-2">
            {label.source && (
              <img className="mr-[10px] w-5 h-5" src={label.source} alt="" />
            )}{" "}
            {label.label} {star && <span className="text-primary ml-1">*</span>}
            {extraLabel && (
              <span className="ml-1 text-12 text-[#00000080]">
                ({extraLabel})
              </span>
            )}
          </label>
        )}
        <input
          placeholder={placeholder}
          className="appearance-none block w-full bg-grey-lighter text-grey-darker border text-14  rounded py-4 px-[15px] mb-4 leading-tight focus:outline-none focus:bg-white"
          {...inputExtras}
        />

        {errorMessage && <p className="text-red text-xs ">{errorMessage} </p>}
      </div>
    );
  }
}

InputWithImage.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

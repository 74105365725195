const vietnamese = {
	login: {
		contryLabel: "QUỐC GIA",
		contryPlaceHolder: "",
		phoneLable: "SỐ ĐIỆN THOẠI",
		phonePlaceHolder: "SỐ ĐIỆN THOẠI",
		validationLabel:
			"Số điện thoại không hợp lệ, Vui lòng viết số điện thoại bằng tiếng Anh",
		btnLabel: "Tiếp tục",
		tokenSuccessLabel:
			"Mã thông báo của bạn đã được gửi đến điện thoại của bạn",
		tokenSuccessFailed:
			"Mã thông báo của bạn chưa được tạo, vui lòng thử lại",
		langChange: "Thay đổi ngôn ngữ thành",
		termsAllow:
			"Vui lòng chấp nhận các điều khoản và điều kiện khi đăng nhập!",
	},
	password: {
		passwordLabel: "MẬT KHẨU",
		passwordPlaceHolder: "Nhập mật khẩu của bạn",
		forgetPass: "Quên mật khẩu",
		btnLabel: "Đăng nhập",
		otpSendLabel: "Mã đã được gửi đến điện thoại di động của bạn",
		backWord: "Quay lại",
		passError: "Mật khẩu không khớp với số điện thoại",
	},
	changePass: {
		passwordLabel: "MẬT KHẨU MỚI",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Mật khẩu không khớp",
		btnLabel: "Tiếp tục",
		successLabel: "Mật khẩu của bạn đã được thay đổi thành công",
		errorLabel: "Thay đổi mật khẩu thất bại",
		lengthErrorLabel: "Mật khẩu phải có ít nhất 4 ký tự",
		notMatchLabel: "Mật khẩu và xác nhận mật khẩu không khớp",
	},
	token: {
		tokenLabel: "MÃ TOKEN",
		tokenPlaceHolder: "Nhập mã token của bạn",
		tokenResend: "Gửi lại mã token",
		tokenSuccessLabel:
			"Mã đã được gửi đến điện thoại của bạn, nhập để tiếp tục",
		tokenErrorLabel: "Gửi mã token thất bại",
		timerLabel: "Vui lòng chờ",
		second: "giây",
		btnLabel: "Tiếp tục",
		tokenNotMatch: "Mã token không khớp",
		tokenVarified: "Mã token của bạn đã được xác minh",
		tokenNotWrite: "Vui lòng nhập mã token của bạn",
	},
	registration: {
		nameLabel: "HỌ VÀ TÊN ĐẦY ĐỦ",
		namePlaceholder: "Nhập họ và tên đầy đủ của bạn",
		emailLabel: "EMAIL",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập lại mật khẩu để xác nhận",
		validationLabel: "Trường nhập phải có ít nhất 4 ký tự",
		passNotMatch: "Mật khẩu và xác nhận mật khẩu không khớp nhau.",
		btnLabel: "Tiếp tục",
		successMessage: "Tạo Hồ Sơ Thành Công",
		errorMessage: "Vui lòng kiểm tra thông tin nhập hợp lệ",
		passLengthValidLabel: "Mật khẩu phải có ít nhất 4 ký tự",
		passwordLength: "Mật khẩu của bạn là",
		strong: "Mạnh mẽ'",
		medium: "Trung bình",
		weak: "yếu",
		gender: "Giới tính",
		male: "Nam",
		female: "Nữ",
		others: "Khác",
		dobLabel: "Ngày Sinh",
		day: "Ngày",
		month: "Tháng",
		year: "Năm",
	},
	letNav: {
		homeMenuLabel: "Trang Chủ",
		orderMenuLabel: "việc bán hàng",
		mySupplierMenuLabel: "Nhà Cung Cấp Của Tôi",
		businessProfileMenuLabel: "Cài Đặt Hồ Sơ Doanh Nghiệp",
		dashboardProfileMenuLabel: "Bảng Điều Khiển",
		phoneCall: "Gọi Để Đặt Hàng",
		myEmployeesMenuLabel: "Nhân Viên Của Tôi",
		product: "Sản Phẩm Của Tôi",
		supplier: "Mua",
		learning: "Học tập",
		collectionDeposit: "Thu nạp & Gửi tiền",
	},
	learning: {
		pageTitle: "Danh sách Học tập",
		learningDetailsTitle: "Chi tiết Học tập",
		comments: "Bình luận",
		commentAs: "Viết bình luận của bạn ở đây",
		likedWarning: "Bạn không thể thích nhiều hơn một lần",
	},
	home: {
		showMoreLabel: "Xem Thêm",
		brandLabel: "Thương Hiệu",
		offerLabel: "Ưu Đãi Mới",
		allSuppliersLabel: "Danh Sách Đặt Hàng",
		nearSuppliersLabel: "Nhà Cung Cấp",
		recommendentLabel: "Dành Riêng Cho Bạn",
		searchLabel: "Tìm kiếm sản phẩm",
		todayPurchaseLabel: "Mua Hàng Hôm Nay",
		totalOrderLabel: "Sản Phẩm Từ",
		totalOrder: "Đơn Đặt Hàng",
		skusLabel: "SKUs",
		tillNow: "Cho Đến Nay",
		orderDue: "Đơn Đặt Hàng Đến Hạn",
		brandProductPageTitle: "Sản Phẩm Thương Hiệu",
		offerProductPageTitle: "Sản Phẩm Ưu Đãi",
		offerProductNotFound: "Xin lỗi, không tìm thấy ưu đãi nào cho bạn.",
		mySupplierNotFound:
			"Bạn chưa có nhà cung cấp nào. Vui lòng mua sản phẩm để thêm nhà cung cấp của bạn vào đây.",
		account: "Tài Khoản",
		orderNumber: "Số Đơn Đặt Hàng",
		amount: "Số Tiền",
		warning: `Vui lòng lưu ý, các giao dịch sẽ bị ảnh hưởng vào ngày 1 tháng 3 năm 2024 do hệ thống đang được nâng cấp.`,
		warningHeading: "Cảnh Báo",
	},
	profile: {
		pageTitle: "Hồ Sơ & Cài Đặt",
		contactMenuLable: "Liên Hệ & Thông Tin Cơ Bản",
		passwordMenuLable: "Thay Đổi Mật Khẩu",
		logoutMenuLable: "Đăng Xuất",
		changeLanguageMenuLable: "Thay Đổi Ngôn Ngữ",
		kycMenuLable: "Thông tin KYC",
		subscriptionPlan: "gói đăng ký",
	},
	changeLanguages: {
		pageTitle: "Chọn Ngôn Ngữ",
		btnLabel: "Lưu",
	},
	contact: {
		pageTitle: "Thông tin người dùng",
		emailLabel: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		nidLabel: "NID",
		nidPlaceholder: "Nhập số NID",
		btnLabel: "Lưu",
		successLabel: "Cập nhật hồ sơ của bạn thành công.",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại.",
	},
	otpsend: {
		pageTitle: "Xác Minh Số Điện Thoại",
		btnLabel: "Gửi",
		message:
			"Nhấn để gửi mã xác thực đến điện thoại của bạn qua tin nhắn SMS.",
		successLabel: "Mã xác thực đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác thực chưa được tạo, vui lòng thử lại",
	},
	otpsubmit: {
		pageTitle: "Xác Minh Số Điện Thoại",
		btnLabel: "Gửi",
		verifiLabel: "Xác minh và tiếp tục",
		title: "Mã Xác Minh",
		paragraph: "Chúng tôi đã gửi mã xác thực đến Số Điện Thoại Của Bạn",
		otpLabel: "Mã OTP",
		otpPlaceholder: "Nhập mã OTP của bạn",
		receiveYetLabel: "Chưa nhận được mã?",
		againRequestLabel: "Yêu cầu lại",
		timerLabel: "Vui lòng đợi",
		second: "giây",
		successLabel: "Mã xác thực của bạn đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác thực chưa được tạo, vui lòng thử lại",
		tokenVarifiedMessage: "Mã xác thực của bạn đã được xác minh",
		validationLabel: "Vui lòng gửi mã OTP của bạn",
		optNotMatch: "OTP của bạn không hợp lệ",
	},
	changePassword: {
		pageTitle: "Thay Đổi Mật Khẩu",
		btnLabel: "Gửi",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập lại mật khẩu để xác nhận",
		successLabel: "Mật khẩu của bạn đã được cập nhật.",
		errorLabel: "Cập nhật mật khẩu của bạn thất bại.",
		passNotMatchLabel: "Mật khẩu và mật khẩu xác nhận không khớp.",
		changePassSuccessPara: "Mật khẩu của bạn đã được thay đổi thành công.",
		changePassContinueBtn: "Tiếp tục",
	},
	brandDetails: {
		skusLabel: "Số SKU",
		descriptionLabel: "Mô tả",
		productTitleLabel: "Sản Phẩm Thương Hiệu Này",
		pageTitle: "Chi Tiết Thương Hiệu",
		sku: "SKU",
		import: "Nhập khẩu",
		edit: "Chỉnh sửa",
		delete: "Xóa",
		deleteConformationAsk: "Bạn có chắc muốn xóa thương hiệu này không?",
		request: "Yêu cầu hiển thị giá",
	},
	mysuppliers: {
		pageTitle: "Danh sách vật tư",
		skusLabel: "Số SKU",
		productTitleLabel: "Sản Phẩm của Nhà Cung Cấp Này",
		supplierOrderAmount: "số lượng đặt hàng của nhà cung cấp",
	},
	createOrder: {
		pageTitle: "Tạo Đơn Hàng",
		buyerDetailsTitle: "Thông Tin Người Mua",
		sellerDetailsTitle: "Thông Tin Người Bán",
		deliveryDetailsTitle: "Giao Hàng",
		productItemTitle: "Sản Phẩm",
		costSummaryTitle: "Tóm Tắt Chi Phí",
		paymentDetailsTitle: "Chi Tiết Thanh Toán",
		paymentTermsTitle: "Điều Kiện Thanh Toán",
		paymentMethodTitle: "Phương Thức Thanh Toán",
		moqLabel: "Số Lượng Đặt Hàng Tối Thiểu",
		pcsLabel: "Cái",
		subtotal: "Tổng Cộng",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		immediatePayment: "Thanh Toán Ngay",
		pickAndPay: "Nhận Hàng và Thanh Toán",
		today: "Vào Đêm Ngày Giao Hàng",
		threeDays: "Thanh Toán Trong 3 Ngày",
		sevenDays: "Thanh Toán Trong 7 Ngày",
		immediatePaymentText: "Không có phí dịch vụ áp dụng cho đơn hàng này.",
		todayPaymentText1:
			"Đối với thanh toán trong ngày, sẽ có phí dịch vụ bổ sung ",
		todayPaymentText2: "sẽ được thêm vào đơn hàng này.",
		threePaymentText1:
			"Đối với thanh toán trong 3 ngày, sẽ có phí dịch vụ bổ sung ",
		threePaymentText2: "sẽ được thêm vào đơn hàng này.",
		sevenPaymentText1:
			"Đối với thanh toán trong 7 ngày, sẽ có phí dịch vụ bổ sung ",
		sevenPaymentText2: "sẽ được thêm vào đơn hàng này.",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		cashOnDeliveryText1: "Thanh toán bằng tiền mặt cho",
		cashOnDeliveryText2: "khi hàng hóa được giao.",
		cash: "Tiền Mặt",
		bkashText: "Thanh Toán Qua Bkash",
		nagadText: "Thanh Toán Qua Nagad",
		number: "Số Điện Thoại",
		numberWarning:
			"Nếu tiền được gửi đến số điện thoại khác ngoài số này, nó sẽ bị coi là bị hủy",
		cashText: "Thanh Toán Bằng Tiền Mặt Cho",
		bankTransfer: "Chuyển Khoản Ngân Hàng",
		preOrderCongress:
			"Đơn đặt hàng trước của bạn đã được hoàn thành thành công. Đại diện của chúng tôi sẽ liên hệ với bạn sớm.",
		bankTransferText1:
			"Chuyển khoản ngân hàng - Thanh toán vào tài khoản ngân hàng có 'Số Đơn Hàng' làm tham chiếu",
		bankTransferText2: "Tên Ngân Hàng",
		bankTransferText3: "Tên Tài Khoản",
		bankTransferText4: "Số Tài Khoản",
		bankTransferText5: "Số Tài Khoản Ngân Hàng",
		bankTransferText6: "Mã Ngân Hàng",
		bankTransferText7: "Chi Nhánh",
		btnLabel: "Xác Nhận",
		addMoreProductLabel: "Thêm Sản Phẩm Khác",
		selectProductLabel: "Gửi",
		congressTitle: "Chúc Mừng!",
		congressPara: "Đơn hàng của bạn đã được hoàn thành.",
		congressCancelText:
			"Nếu có bất kỳ vấn đề gì với đơn hàng, hãy hủy trước 12 giờ trưa. Sau 12 giờ trưa, bạn không thể hủy đơn hàng nữa.",
		congressProductLabel: "Tiếp Tục",
		orderNow: "Đặt Hàng Ngay",
		alert: "Cảnh Báo",
		importantMessage: "Thông báo quan trọng",
		deliveryDateMessage: "Vui lòng Chọn Ngày Giao Hàng",
		paymentTermsMessage: "Vui lòng Chọn Điều Kiện Thanh Toán",
		paymentMethodMessage: "Vui lòng Chọn Phương Thức Thanh Toán",
		backButtonMessage:
			"Giỏ hàng của bạn có một số sản phẩm, nếu bạn rời trang này, giỏ hàng của bạn sẽ bị trống.",
		okBtnLabel: "OK",
		loader: "Vui lòng đợi...",
		placeConfirmationMessage: "Bạn có chắc muốn đặt đơn hàng này không?",
		lessThanMoq:
			"Số lượng sản phẩm đã chọn ít hơn số lượng đặt hàng tối thiểu.",
		all: "Tất Cả",
		requestDeliveryDate: "Yêu Cầu Ngày Giao Hàng",
		fsMandatoryMessage: "Vui lòng chọn số tiền hỗ trợ tài chính.",
		fsCoupon: "FS phiếu mua hàng",
		downPayment: "Khoản trả trước ban đầu",
		fsServiceFee: "Phí dịch vụ hỗ trợ tài chính",
		fsPlaceholder: "Nhập số tiền hỗ trợ tài chính theo nhu cầu của bạn",
		fsWarning: "số tiền hỗ trợ tài chính không chính xác",
		dpWarning1:
			"Yêu cầu Hỗ trợ Tài chính của bạn đang được xem xét. Vui lòng chờ đợi một cách kiên nhẫn cho đến ngày làm việc tiếp theo.",
		dpWarning2:
			"Vui lòng lưu ý bên dưới Hình phạt nếu thanh toán không đúng hạn. Đây chỉ là gần đúng. Luôn tham khảo trang Chi tiết đơn hàng để biết số tiền thực tế.",
		orderId: "Id đơn hàng",
		dueAmount: "số tiền đến hạn",
		dueLoanTitle:
			"Bạn phải xóa các khoản phí trước đó trước khi có yêu cầu tài chính mới.",
	},
	selectProduct: {
		pageTitle: "Chọn Sản Phẩm",
		totalLabel: "Tổng Cộng",
		btnLabel: "Gửi",
	},
	orderList: {
		pageTitle: "Tạo Đơn Hàng",
		tabMenuRecent: "Tất Cả",
		tabMenuPlaced: "Đã Đặt Hàng",
		tabMenuAccepted: "Đã Chấp Nhận",
		tabMenuScDelivered: "Đã Đặt Giao Hàng",
		tabMenuPartialDelivered: "Giao Hàng Một Phần",
		tabMenuDelivered: "Đã Giao Hàng",
		tabMenuReturn: "Sản Phẩm Trả Lại",
		tabMenuPartialPaid: "Thanh Toán Một Phần",
		tabMenuPaid: "Đã Thanh Toán",
		tabMenuCancel: "Đã Hủy",
		tabMenuDeliveryFailed: "Giao Hàng Thất Bại",
		orderItem: "Sản Phẩm Đặt Hàng",
		seller: "Người Bán",
		buyer: "Người Mua",
		loader: "Vui lòng đợi...",
		createdBy: "Được Tạo Bởi",
		to: "Tới",
		for: "Cho",
		notFound: "Không Tìm Thấy",
	},
	orderDetails: {
		createdBy: "Được Tạo Bởi",
		placedLabel: "Đã Đặt Hàng",
		deliveredLabel: "Đã Giao Hàng",
		paidLabel: "Đã Thanh Toán",
		orderItemLabel: "Sản Phẩm Đặt Hàng",
		costItemLabel: "Chi Tiết Chi Phí",
		timelineLabel: "Dòng Thời Gian",
		subtotal: "Tổng Cộng",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		paymentSettlementLabel: "Quy Trình Thanh Toán",
		date: "Ngày",
		amount: "Số Tiền",
		dueDate: "Ngày Đáo Hạn",
		remark: "Ghi Chú",
		collectedBy: "Thu Tiền Bởi ",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		paymentMethod: "Phương Thức Thanh Toán",
		paymentTerms: "Điều Kiện Thanh Toán",
		paymentDueDate: "Ngày Đáo Hạn Thanh Toán",
		totalDue: "Tổng Tiền Đang Đợi Thanh Toán",
		totalPaid: "Tổng Tiền Đã Thanh Toán",
		remaining: "Còn Lại",
		deliveryDetailsLabel: "Chi Tiết Giao Hàng",
		selectTimeDate: "Chọn Ngày và Giờ Dự Kiến Giao Hàng",
		selectTime: "Chọn Giờ Giao Hàng",
		selectDate: "Chọn Ngày Giao Hàng",
		acceptOrder: "Chấp Nhận Đơn Hàng",
		cancelOrder: "Hủy Đơn Hàng",
		scheduleDelivery: "Lên Lịch Giao Hàng",
		BuyerName: "Tên Người Mua",
		address: "Địa Chỉ",
		phoneNumber: "Số Điện Thoại",
		email: "Email",
		deliveryDate: "Ngày Giao Hàng",
		downloadInvoices: "Tải Hóa Đơn",
		receiptDownloads: "In Biên Nhận",
		cancelConformationAsk: "Bạn có chắc muốn hủy đơn hàng này không?",
		cancelConformation: "Bạn không thể hủy đơn hàng này",
		cancelConformationParagraph:
			"Bạn không thể hủy đơn hàng này, bạn chỉ có thể hủy đơn hàng cùng ngày.",
		yesBtn: "Có",
		noBtn: "Không",
		withoutSettlement:
			"Thông tin thanh toán đã hoàn tất sẽ được hiển thị ở đây.",
		bankInfoLabel: "Thông Tin Tài Khoản Ngân Hàng để Chuyển Tiền Thanh Toán",
		assignDriver: "Phân Công Tài Xế",
		assignCollector: "Phân Công Người Thu Tiền",
		assignedDriver: "Tài Xế Được Phân Công",
		assignedCollector: "Người Thu Tiền Được Phân Công",
		notAssigned: "Chưa Được Phân Công",
		addDeliveryFee: "Thêm Phí Giao Hàng",
		addDiscount: "Thêm Giảm Giá",
		feePlaceHolder: "Nhập Số Tiền Phí Giao Hàng Của Bạn",
		discountPlaceHolder: "Nhập Số Tiền Giảm Giá Của Bạn",
		requestDeliveryDate: "Yêu Cầu Ngày Giao Hàng",
		scheduleDeliveryDate: "Lên Lịch Giao Hàng",
		scheduleDeliveryTime: "Lên Lịch Giờ Giao Hàng",
		deliveryFailMessage: "Thông Báo Giao Hàng Thất Bại",
		selectCancelOrder: "Chọn Lý Do Hủy Đơn Hàng",
		multipleTime: "Giao hàng thất bại nhiều lần",
		productNotAvailable: "Sản phẩm không có sẵn",
		cannotFindAddress: "Không tìm thấy địa chỉ",
		noShop: "Tìm thấy địa chỉ nhưng không có cửa hàng",
		buyerNotAccept: "Người mua không chấp nhận giá đơn hàng",
		supplierNotGive: "Nhà cung cấp không cung cấp sản phẩm",
		other: "Lý do khác",
		yes: "Có",
		no: "Không",
		deliveryTimeWarning:
			"Bạn phải chọn ngày giao hàng, nếu không chúng tôi không thể lên lịch giao hàng",
		deliveryDateWarning:
			"Bạn phải chọn giờ giao hàng, nếu không chúng tôi không thể lên lịch giao hàng",
		deliveryDateTimeWarning: "Bạn phải chọn cả ngày và giờ giao hàng",
		orderSearchLabel: "Tìm Kiếm Đơn Hàng Của Bạn",
		loanCollectionTitle: "Quyền Hỗ Trợ Tài Chính",
		transactionType: "Loại Giao Dịch",
		gateway: "Cổng Thanh Toán",
		accountName: "Tên Tài Khoản",
		accountNumber: "Số Tài Khoản",
		transactionNumber: "Số Giao Dịch",
		payEkkbaz: "chi trả Ekkbaz",
		close: "Đóng",
		confirmDelivery: "Giao hàng hoàn thành",
		send: "Gửi",
		receipt: "Biên lai",
		receiptNotProvided: "Không Cung cấp Biên lai",
		instantDelivery:
			"Bạn có chắc chắn muốn Giao hàng hoàn tất cho đơn hàng này không?",
		fss: "Hỗ trợ tài chính thanh toán",
		downPaymentLeft: "Số tiền còn lại cho đợt thanh toán trước",
		default: "Mặc định",
		first: "1st",
		second: "2nd",
		penaltyFee: "Phí phạt",
		due: "Hạn",
		third: "3rd",
		immediatePayment: "Thanh toán ngay lập tức",
		inSettlement: "Trong quá trình thanh toán",
		closeSettlement: "Đóng quá trình thanh toán",
		approve: "Phê duyệt",
		downPayment: "Thanh toán trước",
		sellerPayment: "Thanh toán của người bán",
		delivered: "Đã giao hàng",
		repaid: "Đã trả lại",
		amountWarning: "Số tiền thực tế có thể thay đổi.",
		penaltyHeader: "Lịch phạt mặc định",
	},
	businessCreateName: {
		pageTitle: "Tên Doanh Nghiệp",
		businessName: "Tên Doanh Nghiệp",
		businessNamePlaceholder: "Nhập Tên Doanh Nghiệp",
		paragraph:
			"Chúng ta cách nhau 3 bước để tạo doanh nghiệp của bạn. Hãy bắt đầu với tên doanh nghiệp của bạn.",
		btnLabel: "Tiếp Theo",
		submit: "Gửi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Nhập tên doanh nghiệp!",
		congratulation: "Chúc mừng",
		congratulationMessage: "Doanh nghiệp của bạn đã được tạo thành công",
		kycInformation:
			"Thông tin kyc của bạn chưa được gửi. Vui lòng gửi thông tin kyc của bạn",
		freeTrail: "Bắt đầu dùng thử miễn phí",
		kycSubmit: "Nộp Hồ Sơ KYC Của Bạn",
		pending:
			"Cảm ơn bạn đã gửi KYC. Quá trình xác minh KYC của bạn đang trong quá trình xử lý, vui lòng đợi.",
		goBack: "Quay Lại",
		trialStart: "Chúc mừng! Bạn đã bắt đầu dùng thử thành công.",
	},
	businessType: {
		pageTitle: "Loại Hình Kinh Doanh",
		paragraph: "Hãy chọn loại hình kinh doanh của bạn.",
		fmcg: "Hàng Tiêu Dùng Nhanh Chóng (FMCG)",
		miniMart: "Cửa Hàng Tiện Lợi Nhỏ",
		superMart: "Siêu Thị",
		pharmacy: "Nhà Thuốc",
		cart: "Cửa Hàng Xe Đẩy",
		bazaarStore: "Cửa Hàng Chợ Lớn",
		hawkerCenter: "Khu Chợ Bán Lẻ",
		restaurant: "Nhà Hàng",
		hotel: "Khách Sạn",
		cosmeticsStore: "Cửa Hàng Mỹ Phẩm",
		nonFmcg: "Không Phải Hàng Tiêu Dùng Nhanh Chóng (Non-FMCG)",
		office: "Văn Phòng",
		NGO: "Tổ Chức Phi Chính Phủ (NGO)",
		entertainmentPlace: "Địa Điểm Giải Trí",
		religiousOrganization: "Tổ Chức Tôn Giáo",
		spa: "Spa",
		salon: "Tiệm Làm Đẹp",
		gym: "Phòng Tập Thể Dục",
		electronicsStore: "Cửa Hàng Điện Tử",
		shoeStore: "Cửa Hàng Giày Dép",
		fashionStore: "Cửa Hàng Thời Trang",
		laundryStore: "Cửa Hàng Giặt Ủi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Tiếp Theo",
		validationLabel: "Vui lòng chọn loại hình kinh doanh",
		producer: "Nhà Sản Xuất",
		reseller: "Người Bán Lẻ",
	},
	businessLocation: {
		pageTitle: "Địa Điểm Kinh Doanh",
		paragraph: "Nhập thông tin liên hệ của doanh nghiệp của bạn.",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ của bạn",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceholder: "Nhập mã bưu điện",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		userName: "Tên người dùng",
		userNamePlaceholder: "Nhập tên người dùng của bạn",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "XONG",
		successLabel: "Chúc mừng, doanh nghiệp đã được tạo",
		errorLabel: "Không tạo được doanh nghiệp",
		validationLabel: "Vui lòng điền thông tin địa chỉ để tiếp tục.",
		loader: "Vui lòng đợi...",
		validationCityLabel: "Vui lòng điền thông tin thành phố.",
		validationPostCodeLabel: "Vui lòng điền thông tin mã bưu điện.",
		validationEmailLabel: "Vui lòng điền email của bạn",
	},
	locationPicker: {
		pageTitle: "Tìm Kiếm Vị Trí Cửa Hàng",
		btnLabel: "XÁC NHẬN",
		paragraph:
			"Thay đổi trạng thái của đánh dấu trên bản đồ để chọn vị trí kinh doanh của bạn",
	},
	location: {
		title: "Bật Vị Trí Của Bạn",
		paragraph:
			"Ứng dụng này yêu cầu dịch vụ vị trí phải được bật trên thiết bị của bạn và cho ứng dụng này. Bạn phải bật chúng trong Cài Đặt trước khi sử dụng ứng dụng này.",
		btnLabel: "Cho phép chỉ khi sử dụng ứng dụng",
	},
	businessSelect: {
		pageTitle: "Chọn Doanh Nghiệp",
	},
	pwaDownload: {
		header: "Tải Ứng Dụng EkkBaz",
		paragraph:
			"Tải ứng dụng và đặt hàng sỉ từ nhà cung cấp của bạn bất kỳ lúc nào từ bây giờ.",
		cancelBtn: "Hủy",
		downloadBtn: "Tải Xuống",
	},
	businessProfile: {
		pageTitle: "Cài Đặt Hồ Sơ Doanh Nghiệp",
		businessDetailsLabel: "Chi Tiết",
		registrationNo: "Số Đăng Ký",
		taxNo: "Số Thuế/VAT",
		mobileNo: "Số Điện Thoại Di Động",
		officeNo: "Số Điện Thoại Văn Phòng",
		email: "Email",
		website: "Trang Web",
		branchLabel: "Danh Sách Chi Nhánh",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		bankInfoLabel: "Thông Tin Ngân Hàng",
		bankName: "Tên Ngân Hàng",
		branchName: "Chi Nhánh Ngân Hàng",
		branchAddress: "Địa Chỉ Ngân Hàng",
		swiftCode: "Mã Ngân Hàng Swift",
		acName: "Tên Tài Khoản Ngân Hàng",
		acNumber: "Số Tài Khoản Ngân Hàng",
		bkashInfoLabel: "Thông Tin Bkash",
		bkashAcName: "Tên Tài Khoản Bkash",
		bkashAcNo: "Số Tài Khoản Bkash",
		nagadInfoLabel: "Thông Tin Nagad",
		nagadAcName: "Tên Tài Khoản Nagad",
		nagadAcNo: "Số Tài Khoản Nagad",
		upayInfoLabel: "Thông Tin Upay",
		upayAcName: "Tên Tài Khoản Upay",
		upayAcNo: "Số Tài Khoản Upay",
		verified: "Đã Xác Minh",
		unVerified: "Chưa Xác Minh",
		editBusinessMenu: "Chỉnh Sửa Doanh Nghiệp",
		paymentTermsSettings: "Thiết lập Điều kiện Thanh toán của Khách hàng",
		addPayMenu: "Thêm Phương Thức Thanh Toán",
		setLocation: "Cập nhật địa điểm kinh doanh",
		addMenu: "Thêm Chi Nhánh",
		verifyBusinessMenu: "Xác Minh Doanh Nghiệp",
		bankInfoNotFound: "Không tìm thấy thông tin ngân hàng.",
		deleteBusiness: "Xóa doanh nghiệp",
		deleteWarning:
			"Tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		otpSubmit: "OTP Gửi",
		confirmDeleteWarning:
			"Xin hãy suy nghĩ lại! tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		switchBusiness: "chuyển đổi kinh doanh",
	},
	paymentSettings: {
		pageTitle: "Thiết lập Điều kiện Thanh toán của Khách hàng",
		mainTitle: "Chọn Điều Khoản Thanh Toán phù hợp với bạn",
		subTitle:
			"Các điều khoản thanh toán bạn chọn ở đây, khách hàng chỉ có thể sử dụng các Điều Khoản Thanh Toán này khi đặt hàng.",
		btnLabel: "Lưu",
		payNow: "Thanh Toán Ngay",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		pickAndPay: "Tự Đến Nhận và Thanh Toán",
		today: "Vào Buổi Tối Cùng Ngày Giao Hàng",
		threeDays: "Thanh Toán Trong 3 Ngày",
		sevenDays: "Thanh Toán Trong 7 Ngày",
		fourteenDays: "Thanh Toán Trong 14 Ngày",
		thirtyDays: "Thanh Toán Trong 30 Ngày",
	},
	businessEdit: {
		pageTitle: "Chỉnh Sửa Doanh Nghiệp",
		uploadPhoto: "Tải Ảnh Lên",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ của bạn",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceholder: "Nhập mã bưu điện",
		resigter: "Số Đăng Ký",
		resigterPlaceholder: "Nhập số đăng ký của bạn",
		tex: "Số Thuế/VAT",
		texPlaceholder: "Nhập số thuế/VAT của bạn",
		mobile: "Số Điện Thoại Di Động",
		mobilePlaceholder: "Nhập số điện thoại di động của bạn",
		office: "Số Điện Thoại Văn Phòng",
		officePlaceholder: "Nhập số điện thoại văn phòng của bạn",
		website: "Trang Web",
		websitePlaceholder: "Nhập địa chỉ trang web của bạn",
		btnLabel: "Lưu",
		successLabel: "Hồ sơ của bạn đã được cập nhật thành công",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
	},
	bankDetails: {
		referenceNumber: "Số Tham Chiếu",
		bankName: "Tên Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNumber: "Số Tài Khoản",
		routeNumber: "Số Định Tuyến",
		branch: "Chi Nhánh",
	},
	invoice: {
		title: "HÓA ĐƠN",
		receipt: "Biên lai",
		buyerDetails: "Thông tin Người mua",
		sellerDetails: "Thông tin Người bán",
		description: "MÔ TẢ",
		qty: "SỐ LƯỢNG",
		price: "GIÁ",
		subtotal: "TỔNG CỘNG",
	},
	verifyBusiness: {
		numberOne: "1",
		numberTwo: "2",
		numberThree: "3",
		numberFour: "4",
		numberFive: "5",
		numberSix: "6",
	},
	receipt: {
		placed: "Đã Đặt Hàng",
		name: "Tên",
		qty: "Số Lượng",
		price: "Giá",
		subtotal: "Tổng Cộng",
		tax: "Thuế",
		discount: "Giảm Giá",
		creditFee: "Phí Dịch Vụ",
		deliveryFee: "Phí Giao Hàng",
		total: "Tổng Cộng",
		paid: "Đã Thanh Toán",
		totalDue: "Tổng Còn Nợ",
		paymentTerms: "Điều Khoản Thanh Toán",
		days: "Ngày",
		paymentMethod: "Phương Thức Thanh Toán",
		referenceNumber: "Số Tham Chiếu Ngân Hàng",
		deliveryDate: "Ngày Giao Hàng",
		dueDate: "Hạn Thanh Toán",
		printTime: "Thời Gian In",
		conditionsCreditFee: "Điều Kiện Thanh Toán & Phí Dịch Vụ",
		immediatePayment: "Thanh Toán Ngay",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		atNight: "Vào Buổi Tối Cùng Ngày Giao Hàng",
		payInthreeDay: "Thanh Toán Trong 3 Ngày",
		payInSevenDay: "Thanh Toán Trong 7 Ngày",
		afterSeven: "Sau 7 Ngày (thêm phí mỗi ngày)",
	},
	landing: {
		header1: "Transform your procurement team ",
		header2: "with Agentic Systems.",
		header3: "Trả Sau!",
		login: "Đăng Nhập",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malay: "Malay",
		retail: "Bán Lẻ",
		manufacture: "Nhà Cung Cấp",
		hotline: "Đường Dây Nóng",
		phoneNumber: "Số Điện Thoại",
		phoneNumberPlaceHolder: "Nhập Số Điện Thoại",
		brandHeader: "Các Thương Hiệu Hợp Tác Cùng Chúng Tôi!",
		fAbout: "Bán sản phẩm dễ dàng trên thị trường sỉ.",
		location: "Vị Trí",
		singapore: "Văn Phòng Singapore",
		bangladesh: "Văn Phòng Bangladesh",
		bOfficeAddress: "Tầng 13, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Thông Tin Khác",
		partners: "Đối Tác Của Chúng Tôi",
		news: "Tin Tức",
		support: "Hỗ Trợ",
		corporate: "Doanh Nghiệp",
		refundPolicy: "Chính Sách Hoàn Trả",
		termsCondition: "Điều Khoản",
		privacyPolicy: "Chính Sách Bảo Mật",
		contactUs: "Liên Hệ Chúng Tôi",
		messageUs: "Gửi Tin Nhắn",
		facebook: "Facebook",
		sales: "Bán Hàng",
		media: "Truyền Thông",
		downloadBtn: "Tải Ứng Dụng",
		backers: "Những Người Ủng Hộ Của Chúng Tôi",
		benefits: "Lợi Ích Của Bạn",
		discover: "Tin Tức Mới Nhất từ EkkBaz",
		benefitsOne: "Tăng doanh số bán hàng",
		benefitsDisOne: "",
		benefitsTwo: "Dễ dàng tiếp cận khách hàng",
		benefitsDisOneTwo: "",
		benefitsThree: "Tăng khả năng hiển thị sản phẩm",
		benefitsDisOneThree: "",
		benefitsFore: "Kỹ thuật số hóa quá trình bán hàng của bạn",
		benefitsDisOneFore: "",
		seeMore: "Xem Thêm",
		downloadApp: "Tải ứng dụng ngay bây giờ!",
		apiBtn: "Join Waitlist",
		logIn: "Login",
		ourTeam: "Our Team",
	},
	employees: {
		pageTitle: "Nhân viên",
		createNewEmployees: "Tạo nhân viên mới",
		searchPlaceHolder: "Tìm kiếm nhân viên của bạn",
		collectorList: "Danh sách thu thập",
		collectorSearch: "Trình thu thập tìm kiếm",
	},
	employeesDetails: {
		pageTitle: "Chi tiết nhân viên",
	},
	createEmployees: {
		pageTitle: "Tạo Nhân Viên Mới",
		customerNoHolder: "Nhập Số Điện Thoại Di Động",
		employeeNumber: "Mã Nhân Viên",
		name: "Tên",
		namePlaceholder: "Nhập tên nhân viên",
		confirmBtn: "Xác Nhận",
		selectEmployees: "Chọn chức vụ của nhân viên",
		admin: "Quản trị viên",
		stuff: "Nhân viên",
		orderManager: "Quản lý Đơn Hàng",
		bookManager: "Quản lý Sách",
		productManager: "Quản lý Sản Phẩm",
		employeeManager: "Quản lý Nhân Viên",
		nameError: "Vui lòng nhập tên nhân viên",
		roleError: "Vui lòng chọn chức vụ cho nhân viên",
		edit: "Chỉnh Sửa",
		delete: "Xóa",
		editEmployee: "Chỉnh Sửa Nhân Viên",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa nhân viên này không?",
		updateEmployee: "Cập Nhật Nhân Viên",
	},
	productList: {
		pageTitle: "Danh Sách Sản Phẩm",
		offerPageTitle: "Danh Sách Khuyến Mãi",
		searchYourProduct: "Tìm Kiếm Sản Phẩm",
		brandList: "Danh Sách Thương Hiệu",
		createProduct: "Tạo Sản Phẩm",
		createPack: "Tạo Gói",
		createOffer: "Tạo Khuyến Mãi",
		product: "Sản Phẩm",
		pack: "Gói",
		Offer: "Khuyến Mãi",
		discount: "Giảm Giá",
		all: "Tất Cả",
	},
	productDetails: {
		pageTitle: "Chi Tiết Sản Phẩm",
		offerStart: "Ngày Bắt Đầu Khuyến Mãi",
		offerEnd: "Ngày Kết Thúc Khuyến Mãi",
		moqLabel: "Số Lượng Tối Thiểu Đặt Hàng (MOQ)",
		consumerLabel: "Giá Tiêu Dùng (MRP)",
		profitLabel: "Lợi Nhuận",
		productDetailTitle: "Chi Tiết Sản Phẩm",
		expireData: "Ngày Hết Hạn",
		manufactureData: "Ngày Sản Xuất",
		productWeight: "Khối Lượng Sản Phẩm",
		packingType: "Loại Đóng Gói",
		importedBy: "Nhập Khẩu Bởi",
		manufacturedBy: "Sản Xuất Bởi",
		country: "Quốc Gia",
		descriptionLabel: "Mô Tả",
		btnLabel: "Đặt Hàng Ngay",
		inStock: "Còn Hàng",
		outStock: "Hết Hàng",
		description: "Mô Tả",
		inventory: "Tồn Kho",
		unpublished: "Chưa Xuất Bản",
		published: "Đã Xuất Bản",
		edit: "Chỉnh Sửa",
		delete: "Xóa",
		deleteText: "Bạn có chắc chắn muốn xóa sản phẩm này không?",
		myProfit: "Lợi Nhuận Của Tôi",
		buyerProfit: "Lợi Nhuận Của Người Mua",
		skuId: "SKU ID",
		preOrderBtn: "Yêu Cầu Đặt Hàng Trước",
		preOrderQuantity: "Nhập Số Lượng Đặt Hàng Trước",
		searchTram: "Tìm Kiếm Tram",
	},
	brandList: {
		pageTitle: "Danh Sách Nhà Cung Cấp/Thương Hiệu",
		searchBrand: "Tìm Kiếm Thương Hiệu Theo Tên",
		searchProduct: "Tìm kiếm sản phẩm của thương hiệu theo tên",
		myBrand: "Thương Hiệu Của Tôi",
		allBrand: "Tất Cả Thương Hiệu",
		createNewBrand: "Tạo Thương Hiệu Mới Của Tôi",
		importBrand: "Nhập Thương Hiệu Của Người Khác",
		brandList: "Danh Sách Thương Hiệu",
		products: "Sản phẩm",
		details: "Chi tiết",
		distributorCode: "Mã nhà phân phối",
	},
	brandDetail: {
		pageTitle: "Chi Tiết Thương Hiệu",
		sku: "Mã SKU",
		import: "Nhập",
		edit: "Chỉnh Sửa",
		delete: "Xóa",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa thương hiệu này không?",
	},
	createNewBrand: {
		pageTitle: "Tạo Thương Hiệu Mới",
		uploadNewLogo: "Tải lên logo mới",
		brandName: "Tên Thương Hiệu",
		brandNamePlaceHolder: "vui lòng nhập tên thương hiệu của bạn",
		selectCountry: "Chọn Quốc Gia",
		description: "Mô Tả",
		descriptionPlaceHolder: "vui lòng nhập mô tả thương hiệu của bạn",
		createdBy: "Được tạo bởi",
		complete: "Hoàn tất",
		congratulation: "Chúc mừng",
		congratulationParagraph:
			"Tạo Thương Hiệu Mới đã được hoàn thành thành công",
		continue: "Tiếp tục",
		conformModal: "Bạn có chắc chắn muốn tạo thương hiệu này không?",
		importBrandConformations:
			"Bạn có chắc chắn muốn nhập thương hiệu này không?",
		errorMessage: "Bạn không thể tải lên hình ảnh lớn hơn 1 MB",
		imageFormatError:
			"Định dạng hình ảnh không hợp lệ. Chỉ cho phép JPEG, PNG và JPG",
		ownerName: "Chủ sở hữu pháp lý của thương hiệu",
		ownerNamePlaceholder: "Nhập tên chủ sở hữu pháp lý",
		isLegalOwner: "Công ty của tôi sở hữu pháp lý thương hiệu này",
		ownerNumber: "Số điện thoại",
		ownerNumberPlaceholder: "vui lòng nhập số điện thoại của bạn",
	},
	updateBrand: {
		pageTitle: "Cập Nhật Thương Hiệu",
		congratulationParagraph: "Cập nhật thương hiệu thành công",
		conformModal: "Bạn có chắc chắn muốn cập nhật thương hiệu này không?",
	},
	createProduct: {
		pageTitle: "Tạo Sản Phẩm",
		productWarning: "Tiêu đề sản phẩm sẽ được tạo tự động ở đây.",
		productPhoto: "Ảnh Sản Phẩm",
		productPhotoUpload: "Tải Ảnh Sản Phẩm",
		uploadPhoto: "Tải Ảnh",
		uploadFromGallery: "Tải Từ Thư Viện",
		takePhoto: "Chụp Ảnh",
		productName: "Tên Sản Phẩm",
		productNamePlaceHolder: "Nhập tên sản phẩm của bạn",
		description: "Mô tả",
		descriptionPlaceHolder: "Nhập mô tả sản phẩm của bạn",
		selectBrand: "Chọn Thương Hiệu",
		selectDepartment: "Chọn bộ phận",
		selectCategory: "Chọn danh mục",
		selectSubCategory: "Chọn danh mục con",
		setPrice: "Đặt Giá",
		sizeDetermine: "Xác định Kích Thước",
		inventoryDetermine: "Xác định Tồn Kho",
		manufacturingDate: "Ngày Sản Xuất",
		expireData: "Ngày Hết Hạn",
		ManufacturerInformation: "Thông Tin Nhà Sản Xuất",
		save: "Lưu",
		publish: "Xuất Bản",
		purchasePrice: "Giá Mua",
		purchasePricePlaceHolder: "Nhập giá mua",
		sellingPrice: "Giá Bán",
		sellingPricePlaceHolder: "Nhập giá bán",
		priceWarning: "Giá của bạn đang trống",
		customerPrice: "Giá Khách Hàng (MRP)",
		customerPricePlaceHolder: "Nhập giá khách hàng",
		taxVat: "Thuế/VAT",
		moq: "Số Lượng Đặt Hàng Tối Thiểu (MOQ)",
		taxVatPlaceHolder: "Nhập Thuế/VAT",
		moqPlaceHolder: "Nhập giá MOQ",
		weight: "Khối Lượng",
		grossWeight: "Tổng Khối Lượng",
		grossWeightPlaceholder: "Nhập tổng khối lượng",
		weightPlaceholder: "Nhập khối lượng",
		weightWarning: "Khối lượng đang trống",
		measurement: "Đơn Vị Đo Lường",
		weightUnits: "Đơn Vị Khối Lượng",
		units: "Đơn Vị",
		packingType: "Loại Đóng Gói",
		ok: "Đồng Ý",
		manufacturedCountry: "Nước Sản Xuất",
		manufacturedBy: "Sản Xuất Bởi",
		manufacturedByPlaceHolder: "Nhập Tên Nhà Sản Xuất",
		importedBy: "Nhập Khẩu Bởi",
		importedByPlaceHolder: "Nhập tên của người nhập khẩu",
		distributedBy: "Phân Phối Bởi",
		distributedByPlaceHolder: "Nhập tên của người phân phối",
		initialInventory: "Tồn Kho Ban Đầu",
		initialInventoryPlaceHolder: "Nhập tồn kho ban đầu",
		skuPlaceholder: "Nhập số SKU",
		sku: "Số SKU",
		empty: "Rỗng",
		barCode: "Mã Vạch",
		scan: "Quét",
		width: "Chiều Rộng",
		widthPlaceholder: "Nhập chiều rộng",
		height: "Chiều Cao",
		heightPlaceholder: "Nhập chiều cao",
		length: "Chiều Dài",
		lengthPlaceholder: "Nhập chiều dài",
		searchDepartment: "Tìm Bộ Phận Theo Tên",
		searchCategory: "Tìm danh mục theo tên",
		searchSubCategory: "Tìm danh mục con theo tên",
		conformModal: "Bạn có chắc chắn muốn tạo sản phẩm này không?",
		updateConformModal: "Bạn có chắc chắn muốn cập nhật sản phẩm này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu sản phẩm này không?",
		updateBtn: "Cập Nhật",
		updateMessage: "Tên sản phẩm, giá và tồn kho phải có để có thể xuất bản.",
		publishMessage: "Bạn có chắc chắn muốn xuất bản sản phẩm này không?",
		unPublishMessage:
			"Bạn có chắc chắn muốn hủy xuất bản sản phẩm này không?",
		englishName: "Tên Tiếng Anh để Tìm Kiếm",
		englishNamePlaceholder: "Nhập tên tìm kiếm",
		productCreated: "Sản phẩm đã được tạo thành công!",
		finalGrossWeight: "Tổng Khối Lượng Giao Hàng Cuối",
		grossWeightUnit: "Đơn Vị Tổng Khối Lượng",
		productUpdated: "Sản phẩm đã được cập nhật thành công!",
	},
	updateProduct: {
		pageTitle: "Cập Nhật Sản Phẩm",
	},
	createPack: {
		pageTitle: "Tạo Gói",
		packWarning: "Tiêu đề Gói sẽ được tạo tự động ở đây.",
		packName: "Tên Gói",
		packNamePlaceHolder: "Vui lòng nhập tên gói của bạn",
		description: "Mô tả",
		descriptionPlaceHolder: "Vui lòng nhập mô tả",
		selectProduct: "Sản Phẩm Đã Chọn",
		qtyPack: "Số Lượng trong Gói",
		qtyPackPlaceholder: "Nhập số lượng trong gói",
		conformModal: "Bạn có chắc chắn muốn tạo gói này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu ưu đãi này không?",
		productCreated: "Gói đã được tạo thành công!",
		productUpdated: "Gói đã được cập nhật thành công!",
	},
	createOffer: {
		pageTitle: "Tạo Ưu đãi",
		offerWarning: "Tiêu đề Ưu đãi sẽ được tạo tự động ở đây.",
		offerName: "Tên Ưu đãi",
		offerNamePlaceHolder: "Vui lòng nhập tên Ưu đãi của bạn",
		description: "Mô tả",
		descriptionPlaceHolder: "Vui lòng viết mô tả",
		selectProduct: "Sản phẩm đã chọn",
		qtyOffer: "Số lượng trong Ưu đãi",
		qtyOfferPlaceholder: "Nhập số lượng trong Ưu đãi",
		conformModal: "Bạn có chắc chắn muốn tạo ưu đãi này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu ưu đãi này không?",
		offerStartDate: "Ngày Bắt đầu Ưu đãi",
		offerEndDate: "Ngày Kết thúc Ưu đãi",
		selectedItem: "Sản phẩm đã chọn",
		totalProductCount: "Tổng Số Sản phẩm",
		totalAmount: "Tổng Giá trị",
		spWarnings: "Bạn phải chọn một sản phẩm",
		closeButton: "Đóng",
		offerCreated: "Tạo Ưu đãi thành công!",
		offerUpdated: "Cập nhật Ưu đãi thành công!",
	},
	updateOffer: {
		pageTitle: "Cập nhật Ưu đãi",
		conformModal: "Bạn có chắc chắn muốn cập nhật ưu đãi này không?",
	},
	dashboard: {
		statusLable: "Trạng thái Đơn hàng",
		today: "Hôm nay",
		yesterday: "Hôm qua",
		thisWeek: "Tuần này",
		thisMonth: "Tháng này",
		thisYear: "Năm nay",
		lastWeek: "Tuần trước",
		lastMonth: "Tháng trước",
		lastYear: "Năm trước",
		custom: "Ngày tùy chỉnh",
		selectDay: "Chọn ngày",
		Order: "Đơn hàng",
		totalOrder: "Tổng số đơn hàng",
		totalOrderAmount: "tổng số tiền đặt hàng",
		fsDueAmount: "Hỗ trợ tài chính đến hạn",
		placed: "Đã đặt",
		accepted: "Đã chấp nhận",
		scDelivered: "Đã lên lịch giao hàng",
		partialDelivered: "Giao hàng một phần",
		delivered: "Đã giao hàng",
		return: "Trả sản phẩm",
		partialPaid: "Thanh toán một phần",
		paid: "Đã thanh toán",
		totalPaid: "Đã thanh toán",
		cancel: "Hủy",
		deliveryFailed: "Giao hàng thất bại",
		orderAmount: "Số tiền đơn hàng",
		totalAmount: "Số tiền đơn hàng",
		collectedAmount: "Số tiền thu",
		dueAmount: "Số tiền còn nợ",
		totalDue: "Số tiền còn nợ",
		directOrder: "Đơn hàng trực tiếp",
		directOrderAmount: "Số tiền đơn hàng trực tiếp",
		ekkHeroOrder: "Đơn hàng EkkHero",
		ekkHeroOrderAmount: "Số tiền đơn hàng EkkHero",
		cancelOrderAmount: "Số tiền đơn hàng bị hủy",
		seeDetails: "Xem chi tiết",
		period: "Giai đoạn",
		perviousWeek: "Tuần trước",
		nextWeek: "Tuần tới",
		supplierOrder: "Đơn Đặt Hàng Nhà cung cấp",
		customerOrder: "Đơn Đặt Hàng Khách hàng",
		preview: "xem trước",
		promoCode: "mã khuyến mại",
		monthlyOrder: "Đơn hàng hàng của tháng hiện tại",
		dueDate: "Ngày đáo hạn",
		orderNumber: "Số thứ tự",
	},

	kycVerification: {
		imageWarning: "Không thể tải lên hình ảnh lớn hơn 10 MB",
		imageUpload: "Tải lên hình ảnh của bạn ở đây hoặc duyệt",
		verifyUser: "Xác minh Người dùng",
		nidFront: "CMND - Mặt trước",
		nidBack: "CMND - Mặt sau",
		uploadNidFront: "Tải ảnh CMND mặt trước của bạn lên đây",
		uploadNidBack: "Tải ảnh CMND mặt sau của bạn lên đây",
		selfie: "Hãy chụp ảnh selfie của bạn",
		selfieLabel: "Chụp ảnh tự sướng",
		complete: "Tải lên hoàn tất",
		pleaseCheck: "Vui lòng kiểm tra",
		next: "TIẾP THEO",
		button: "nút",
		upload: "Vui lòng tải lên của bạn",
		nid: "CMND",
		copy: "sao chép",
		tradeLicense: "Giấy phép kinh doanh/Đăng ký kinh doanh",
		uploadTradeLicense:
			"Tải lên Giấy phép kinh doanh hoặc Đăng ký kinh doanh của bạn",
		proofAddressLabel1: "Chứng minh địa chỉ",
		proofAddressLabel2:
			"Hóa đơn tiện ích (như nước, điện, khí hoặc sao kê ngân hàng)",
		proofAddressPlaceholder: "Tải lên Giấy tờ hóa đơn tiện ích của bạn",
		taxLabel: "Mã số thuế cá nhân (TIN)",
		taxPlaceholder: "Tải lên Mã số thuế cá nhân (TIN) của bạn",
		brandAgreementLabel: "Hợp đồng hoặc Thỏa thuận thương hiệu",
		brandAgreementPlaceholder:
			"Tải lên Hợp đồng hoặc Thỏa thuận thương hiệu của bạn",
		ebsAgreementPageTitle: "Giấy thỏa thuận & hợp đồng",
		ebCompanyTitle: "EkkBaz Pvt. Ltd",
		giveLater: "Tôi sẽ cung cấp thông tin này sau",
		propertyType: "Loại tài sản",
		rented: "Thuê",
		own: "Sở hữu",
		rentalAgreement: "Hợp đồng thuê/Chứng từ với chủ nhà",
		ownAgreement: "Hợp đồng sở hữu/Chứng từ",
		rentalDocument: "Tài liệu hợp đồng thuê",
		ownDocument: "Giấy tờ sở hữu tài sản",
		startDate: "Ngày bắt đầu thuê",
		rentPerMonth: "Tiền thuê hàng tháng",
		rentPerMonthPlaceholder: "Nhập số tiền thuê cửa hàng hàng tháng của bạn",
		select: "Vui lòng chọn của bạn",
		fillup: "& điền thông tin của bạn",
		amount: "số tiền",
		click: "Vui lòng nhấp",
		submit: "GỬI",
		nidNo: "Số chứng minh nhân dân",
		nidNoPlaceholder: "vui lòng nhập Số chứng minh nhân dân của bạn ở đây",
		tradeLicenseNo: "Số Giấy phép Kinh doanh",
		tradeLicensePlaceholder: "vui lòng nhập số Giấy phép Kinh doanh",
		tinNumber: "Số TIN",
		tinNumberPlaceholder: "vui lòng nhập số TIN của bạn ở đây",
		brandSelectLabel: "Chọn thương hiệu",
		brandSelectPlaceholder: "Chọn thương hiệu",
		termsConditionPageTitle: "Điều Khoản & Điều Kiện của EkkBaz",
		termsLabel: "Điều Khoản & Điều Kiện",
		privacyLabel: "Chính Sách Bảo Mật",
		refundPolicy: "Chính Sách Hoàn Trả",
		acceptCheckbox:
			"Tôi xác nhận rằng tôi đã nhận, đọc và hiểu các tài liệu ở trên và tôi đồng ý với tất cả các điều khoản.",
	},
	restrict: {
		pageTitle: "Truy cập bị hạn chế",
		warning: `Tài khoản Nhân viên, Thu ngân và Lái xe không thể đăng nhập tại đây! Hãy thay đổi vai trò của bạn hoặc yêu cầu quản trị viên đăng nhập`,
		btnClick: "Nhấn vào nút màu xanh bên dưới để quay về Trang chủ",
	},
	notification: {
		pageTitle: "Thông báo",
		customer: "Khách hàng",
		supplier: "Nhà cung cấp",
		update: "Cập nhật",
		supllierPageTitle: "Thông báo từ Nhà cung cấp",
	},
	orderSummary: {
		orderProductPageTitle: "Tóm tắt Đơn hàng Sản phẩm",
		orderProductDetailsPageTitle: "Đặt hàng Chi tiết tóm tắt sản phẩm",
		driverProductPageTitle: "Đặt hàng trình điều khiển",
		orderDriverProductDrilldown:
			"Đặt hàng Trình điều khiển Chi tiết Tóm tắt Sản phẩm",
		driverDeliveryPageTitle: "Tóm tắt giao hàng của tài xế đặt hàng",
		deliveryAmountPageTitle: "Tóm tắt số tiền giao hàng",
		orderDeliveryAmountDrilldown: "Chi tiết số tiền giao hàng đặt hàng",
		buyerSummary: "Tóm tắt người mua",
		buyerSummaryDrillDown: "Tóm tắt người mua hàng đặt hàng",
		productTitle: "Tiêu đề sản phẩm",
		qty: "Số lượng",
		orderNo: "Số Đơn hàng",
		date: "Ngày",
		status: "Trạng thái",
		product: "Sản phẩm",
		driver: "Tài xế",
		searchPlaceholder: "Tìm kiếm trình điều khiển",
		totalAmount: "Tổng cộng",
		totalPaid: "Tổng số chi trả",
		totalDue: "Tổng số tiền đến hạn",
		numberOfOrders: "Tổng số đơn đặt hàng",
		deliveryFailed: "Giao hàng thất bại",
		gvmAmount: "GMV",
		numberOfFulfillment: "Thực hiện",
		orderCancel: "Hủy đơn hàng",
		revenue: "Doanh thu",
		delayInPayment: "Chậm trễ trong thanh toán",
		dueAmount: "Số tiền đến hạn",
		dayPassed: "ngày trôi qua",
		storeName: "Tên cửa hàng",
		delayPlaceholder: "Chọn ngày trì hoãn",
		selectPlaceholder: "Chọn ngày trì hoãn",
		amount: "Số lượng",
		orderAmount: "Số lượng đơn đặt hàng",
	},
	autoLogOut: {
		pageTitle: "Đăng xuất tự động",
		warningMsg:
			"Tự động đăng xuất vì số này đã được sử dụng trong lần đăng nhập khác",
		instrMsg: "Bấm vào nút xanh bên dưới để đăng nhập lại",
		btnLabel: "Đăng nhập",
	},
	loan: {
		cardTitle: "Yêu cầu hỗ trợ tài chính",
		amount: "Số lượng",
		serviceFee: "Phí Dịch vụ Hỗ trợ Tài chính",
		sfWarning:
			"Hỗ trợ tài chính lên đến 50% sẽ được cung cấp dựa trên hiệu suất của bạn trên nền tảng.",
	},
	loanDetails: {
		cardTitle: "Hỗ trợ Tài chính",
		requested: "Số tiền yêu cầu",
		approved: "Số tiền được duyệt",
		serviceFee: "Phí dịch vụ",
		dueDate: "Ngày đáo hạn",
		status: "Trạng thái",
		approvedDate: "Ngày duyệt",
		collectedDate: "Ngày thu tiền",
		repaidDate: "Ngày trả tiền",
		downPayment: "Tiền đặt cọc",
	},

	customerOrder: {
		pageTitle: "Đơn đặt hàng của khách hàng",
		customer: "khách hàng",
		customerPlaceHolder: "Tìm kiếm tên b khách hàng",
		customerOrderAmount: "Số tiền đặt hàng của khách hàng",
	},
	payment: {
		pageTitle: "Thanh toán Ekkbaz",
		bank: "Ngân hàng",
		acName: "Tên TK",
		acNumber: "Số TK",
		branch: "Chi nhánh",
		address: "Địa chỉ",
		city: "Thành phố",
		postal: "Mã bưu chính",
		country: "Quốc gia",
		selectTimeDate: "Chọn Thời gian & Ngày",
		transitionId: "ID Giao dịch",
		transitionIdPlaceHolder: "Nhập ID giao dịch của bạn",
		amount: "Số tiền",
		amountPlaceHolder: "Nhập số tiền bạn muốn gửi",
		uploadReceipt: "Tải lên Hóa đơn",
		senderInfo: "Thông tin Người gửi",
		receiverInfo: "Thông tin Người nhận",
		senderWarning: "Vui lòng thêm tài khoản ngân hàng của bạn trước",
		paymentConformationMessage:
			"Bạn có chắc chắn muốn thực hiện thanh toán này không?",
		inputWarning: "Vui lòng điền vào ô này",
		congressPayment: "Chúc mừng! Thanh toán của bạn đã được gửi thành công.",
		paymentRecieptImage: "Hình ảnh Biên lai Thanh toán",
		receiptOrBank: "Tải lên hình ảnh biên lai hoặc phiếu ngân hàng",
		warning: "Số tiền thanh toán không chính xác.",
	},
	depositList: {
		pageTitle: "Danh sách đặt cọc",
		collectionAndDeposit: "Đặt cọc & Thu nạp",
		depositDetails: "Chi tiết đặt cọc",
		collectionDetails: "Chi tiết thu nạp",
		selectDate: "Chọn ngày",
		selectCollector: "Chọn Người thu nạp",
		txnNo: "Số giao dịch",
		totalCollection: "Tổng thu",
		branch: "Chi nhánh",
		totalOrder: "Tổng đơn đặt hàng",
		verified: "Đã xác minh",
		unVerified: "Chưa xác minh",
		collection: "Tổng thu",
		totalDeposit: "Tổng tiền gửi",
		totalDue: "Tổng nợ",
		bankName: "Tên ngân hàng",
		acName: "Tên tài khoản",
		acNumber: "Số tài khoản",
		routeNumber: "Số đường đi",
		transectionNumber: "Số giao dịch",
		bankAddress: "Địa chỉ ngân hàng",
		collectorName: "Tên người thu",
		comment: "Bình luận",
		placeConformationMessage:
			"Bạn có chắc chắn muốn Xác minh khoản gửi này không?",
		placeVerifyMessage: "Bạn có chắc chắn muốn Xác minh khoản gửi này không?",
		placeUnverifyMessage:
			"Bạn có chắc chắn muốn Hủy xác minh khoản gửi này không?",
		confirmPageVerifyText:
			"Quá trình xác minh khoản gửi của bạn đã thành công.",
		confirmPageunVerifyText:
			"Quá trình hủy xác minh khoản gửi của bạn đã thành công.",
		depositAmount: "Số tiền gửi",
		collectionAmount: "Số tiền thu thập",
		numberOfCollection: "Số bộ sưu tập",
		depositType: "Loại tiền gửi",
	},
	voiceOrder: {
		pageTitle: "Đặt hàng bằng giọng nói",
		bannerHello: "Xin chào",
		bannerAI: "Chào mừng bạn đến với Voice Ordering AI",
		bannerUse: "Bạn có thể tìm thấy sản phẩm mong muốn bằng cách sử dụng tôi",
		speechTitle: "Giọng nói của bạn",
		speechHeader: "Tôi muốn đặt hàng - ",
		speechInfo:
			"Vui lòng nói cho tôi tên sản phẩm bằng cách nhấp vào nút trắng ở trên",
		keywordTitle: "Từ khóa phù hợp",
		keywordInfo:
			"Vui lòng chọn một từ khóa ở trên để lấy các sản phẩm liên quan",
		productsTitle: "Danh sách Sản phẩm phù hợp nhất",
	},
	couponList: {
		pageTitle: "Phiếu hỗ trợ tài chính",
		addCoupon: "Thêm Mã Giảm Giá",
		couponPlaceholder: "nhập mã giảm giá",
		add: "Thêm",
		applyCoupon: "Áp dụng Mã khuyến mãi FS",
		apply: "áp dụng",
		expired: "hết hạn",
		used: "đã sử dụng",
		remove: "xoá",
		applied: "đã áp dụng",
		endIn: "kết thúc trong",
	},
	addBankSettings: {
		bottomSheetTitle: "Thêm Tài Khoản Ngân Hàng",
		pageTitle: "Thêm Tài Khoản Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNamePlaceholder: "Nhập tên tài khoản của bạn",
		accountNumber: "Số Tài Khoản",
		accountNumberPlaceholder: "Nhập số tài khoản của bạn",
		selectBank: "Chọn Ngân Hàng",
		selectBankPlaceHolder: "Chọn tài khoản ngân hàng của bạn",
		branch: "Chi Nhánh",
		branchPlaceHolder: "Nhập chi nhánh ngân hàng của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ ngân hàng",
		selectCountry: "Chọn Quốc Gia",
		selectCountryPlaceHolder: "Chọn quốc gia ngân hàng của bạn",
		selectCity: "Chọn Thành Phố",
		selectCityPlaceHolder: "Chọn thành phố ngân hàng của bạn",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceHolder: "Nhập mã bưu điện ngân hàng của bạn",
		buttonTitle: "Lưu",
		bankNameWarning: "Vui lòng nhập tên tài khoản ngân hàng của bạn",
		bankNumberWarning: "Vui lòng nhập số tài khoản ngân hàng của bạn",
		selectBankWarning: "Vui lòng chọn ngân hàng",
		branchWarning: "Vui lòng nhập chi nhánh của bạn",
		addressWarning: "Vui lòng nhập địa chỉ của bạn",
		cityWarning: "Vui lòng nhập thành phố của bạn",
		searchBank: "Tìm kiếm ngân hàng của bạn",
	},
	addedBankList: {
		pageTitle: "Danh sách ngân hàng đã thêm",
		acName: "Tên tài khoản",
		acNumber: "Số tài khoản",
		branch: "Chi nhánh",
		address: "Địa chỉ",
		city: "Thành phố",
		country: "Quốc gia",
		addBank: "Thêm tài khoản ngân hàng của bạn",
	},
	blackListed: {
		pageTitle: "Danh sách đen",
		warningMsg: "Doanh nghiệp của bạn đã bị đưa vào Danh sách đen",
		instrMsg:
			"Vui lòng yêu cầu chủ doanh nghiệp của bạn liên hệ với EkkBaz để thực hiện các biện pháp cần thiết để kích hoạt lại doanh nghiệp của bạn.",
		btnLabel: "Đăng xuất",
	},
	ebsAgreement: {
		headline: "Thỏa thuận cấp phép nền tảng tạm thời",
	},
	kycInfo: {
		pageTitle: "Thông Tin KYC",
		nidFront: "NID - Mặt Trước",
		nidBack: "NID - Mặt Sau",
		tradeLicense: "Giấy Phép Kinh Doanh",
		selfie: "Ảnh Tự Sướng",
		proofAddress: "Chứng Minh Địa Chỉ",
		tin: "Mã Số Thuế (TIN)",
		contractPaper: "Hợp Đồng Thương Hiệu hoặc Hợp Đồng",
		agreementAndContact: "EBS Thương Hiệu hoặc Hợp Đồng",
		cheque: "kiểm tra hình ảnh",
		name: "Tên",
		signature: "Chữ Ký",
		notFound: "Không tìm thấy",
		viewPdf: "Xem PDF",
		hidePdf: "Ẩn PDF",
	},
	customerList: {
		pageTitle: "Danh Sách Nhà Phân Phối",
		searchPlaceHolder: "Tìm Kiếm Nhà Phân Phối Của Bạn",
		detailsPageTitle: "Chi Tiết Nhà Phân Phối",
		customerDetailsLabel: "Thông Tin Nhà Phân Phối",
		businessTypeLabel: "Loại Hình Kinh Doanh",
		customerNameLabel: "Tên",
		addressLabel: "Địa Chỉ",
		postalCodeLabel: "Mã Bưu Điện",
		cityLabel: "Thành Phố",
		acceptedCustomerLabel: "Nhà Phân Phối Đã Được Chấp Nhận",
		requestedCustomerLabel: "Yêu Cầu Giá Sản Phẩm",
		accept: "Chấp Nhận",
		acceptConfirmation: "Bạn có chắc chắn muốn chấp nhận Nhà Phân Phối này?",
		km: "km",
		createCustomer: "Tạo Nhà Phân Phối Mới",
		customerNoHolder: "Số Điện Thoại Nhà Phân Phối",
		nextBtn: "Tiếp Theo",
		deleteDistributor: "Xóa Nhà phân phối",
		addCustomPrice: "Thêm Giá Tùy Chỉnh Sản Phẩm",
		warningMsg: "Bạn có chắc chắn muốn xóa Nhà phân phối này không?",
		yes: "Có",
		no: "Không",
		successMsg: "Đã xóa nhà phân phối thành công!",
		customPriceMsg:
			"Bạn có chắc chắn muốn đặt giá tùy chỉnh cho nhà phân phối này không?",
		inputPlaceholder: "Nhập giá tùy chỉnh",
		priceEmptyError: "Giá tùy chỉnh không thể để trống!",
		alreadySetPriceError:
			"Giá sản phẩm này đã được đặt cho nhà phân phối này.",
		cancelBtn: "Hủy",
		submit: "Gửi",
		modalTitle: "Đặt Giá Tùy Chỉnh Cho Sản Phẩm",
		productsSectionTitle: "Sản Phẩm Giá Tùy Chỉnh",
		updatePriceTitle: "Cập Nhật Giá Tùy Chỉnh",
		deletePriceTitle: "Xóa Sản Phẩm Giá Này",
		productDetailTitle: "Chi Tiết Sản Phẩm",
		offerDetailsTitle: "Chi Tiết Khuyến Mãi",
		description:
			"Bạn có chắc chắn muốn xóa sản phẩm giá tùy chỉnh này không?",
		distributorCode: "Mã Nhà Phân Phối",
		notSet: "Chưa Đặt",
		distributorCodeAdd: "Thêm Mã Nhà Phân Phối",
		distributorCodeEdit: "Cập Nhật Mã Nhà Phân Phối",
		writeDistributorCode: "Nhập Mã Nhà Phân Phối",
		successLabel: "Thêm Mã Nhà Phân Phối Thành Công",
		editSuccessLabel: "Cập Nhật Mã Nhà Phân Phối Thành Công",
	},
	createSupplier: {
		pageTitle: "Tạo nhà cung cấp mới",
		invitedSupplierList: "Danh sách nhà cung cấp được mời",
		searchInvitedSupplierList: "Tìm kiếm danh sách nhà cung cấp được mời",
		customerExists: "AĐã tồn tại!",
		invited: "Được mời",
		newBusiness: "Doanh nghiệp mới được tạo thành công",
		addNameWarning: "Vui lòng thêm tên doanh nghiệp",
		addCustomerNameWarning: "Vui lòng thêm tên khách hàng",
	},
	createBusinessInfo: {
		pageTitle: "Thông Tin Quản Lý Hợp Đồng",
		title: "Thông Tin Doanh Nghiệp",
		brandsLabel: "Phân Phối Thương Hiệu",
		brandsPlaceholder: "Nhập Tên Thương Hiệu",
		skusLabel: "Tổng Số SKU",
		skusPlaceholder: "Nhập Số Lượng SKU",
		srsLabel: "Tổng Số SR",
		srsPlaceholder: "Nhập Số Lượng SR",
		driversLabel: "Tổng Số Tài Xế",
		driversPlaceholder: "Nhập Số Lượng Tài Xế",
		customersLabel: "Tổng Số Khách Hàng",
		customersPlaceholder: "Nhập Số Lượng Khách Hàng",
		salesLabel: "Doanh Số Bán Hàng Hàng Tháng",
		salesPlaceholder: "Nhập Tổng Doanh Số Bán Hàng",
		fsLabel: "Số Tiền Hỗ Trợ Tài Chính Hàng Tháng",
		fsPlaceholder: "Nhập Số Tiền Hỗ Trợ Tài Chính",
		pwFsLabel: "Tuần Dự kiến cho FS Cần",
		pwFsPlaceholder: "Chọn tuần",
		operationLabel: "Ngày Bắt đầu Hoạt động Doanh nghiệp",
		operationExtraLabel: "Năm",
		operationPlaceholder: "Chọn Năm",
		aeLabel: "Phản Hồi AE",
		aePlaceholder: "Nhập Phản Hồi AE",
		nextBtn: "Tiếp Theo",
		owner: "Chủ nhân",
		manager: "Quản lý",
		stuff: "Nhân viên",
		validationLabel: "Vui lòng điền địa chỉ để tiếp tục.",
		okBtnLabel: "Đồng ý",
		successMessage: "Thông tin doanh nghiệp được tạo thành công",
		brandError: "Nhập tên thương hiệu",
		skuError: "Nhập số lượng SKU",
		srError: "Nhập số lượng SR",
		driverError: "Nhập số lượng tài xế",
		customerError: "Nhập số lượng khách hàng",
		salesError: "Nhập số lượng bán hàng",
		fsError: "Nhập số lượng hỗ trợ tài chính",
		operationError: "Chọn năm bắt đầu hoạt động",
		feedbackError: "Nhập ý kiến của bạn",
		regionLabel: "Khu vực",
		areaLabel: "Khu vực",
		regionError: "Chọn một khu vực",
		areaError: "Chọn một khu vực",
		areaPlaceholderWarning: "Vui lòng chọn một khu vực trước",
		pwFsError: "Vui lòng chọn một tuần",
	},
	customerDetails: {
		AEUserLabel: "Nhân viên tài khoản",
		HSuserLabel: "Nhân viên Trung tâm",
		visitType: "Loại Hành trình",
		createdAt: "Được tạo lúc",
		createdBy: "Được tạo bởi",
		updatedAt: "Được cập nhật lúc",
		updatedBy: "Được cập nhật bởi",
		businessCardTitle: "Thông tin doanh nghiệp",
		contactCardTitle: "Thông tin liên hệ",
		contractCardTitle: "Thông tin hợp đồng",
		visitCardTitle: "Thông tin thăm",
		name: "Tên",
		number: "Số",
		role: "Vai trò",
		orderType: "Loại đơn hàng",
		serviceType: "Loại dịch vụ",
		revenueTier: "Mức doanh thu",
		expectedRevenue: "Doanh thu dự kiến hàng tháng",
		expectedCloseDate: "Ngày đóng dự kiến",
		status: "Trạng thái",
		checkIn: "Thời gian check-in",
		checkOut: "Thời gian check-out",
		remark: "Chú ý",
		location: "Địa điểm",
		image: "Hình ảnh",
		pageTitle: "Thông Tin Khách Hàng",
		businessType: "Loại Hình Doanh Nghiệp",
	},
	createContact: {
		pageHeadline: "Thông tin liên hệ",
		nameLabel: "Tên",
		namePlaceholder: "Nhập tên",
		numberLabel: "Số",
		numberPlaceholder: "Nhập số",
		roleError: "Chọn một vai trò",
	},
	createContract: {
		pageHeadline: "Thông tin hợp đồng",
		orderTypeLabel: "Loại đơn hàng",
		serviceTypeLabel: "Loại dịch vụ",
		revenueTierLabel: "Mức doanh thu",
		statusLabel: "Trạng thái",
		selectPlaceholder: "Chọn",
		monthlyRevenueLabel: "Doanh thu dự kiến hàng tháng",
		monthlyRevenuePlaceholder: "Nhập doanh thu dự kiến hàng tháng",
		expectedCloseDateLabel: "Ngày đóng dự kiến",
		expectedCloseDatePlaceholder: "Chọn ngày đóng",
		primaryOrder: "Chính",
		secondaryOrder: "Phụ",
		tertiaryOrder: "Thứ ba",
		successMessage: "Hợp đồng được tạo thành công",
		statusError: "Chọn một trạng thái",
		closeDateError: "Chọn một ngày đóng",
		monthlyRevenueError: "Nhập số lượng doanh thu hàng tháng",
		revenueTierError: "Chọn một mức doanh thu",
		serviceTypeError: "Chọn một loại dịch vụ",
		orderTypeError: "Chọn một loại đơn hàng",
	},
	newCustomer: {
		pageTitle: "Tạo Doanh Nghiệp Mới",
		mobileNo: "Số Điện Thoại",
		customerNoHolder: "Số điện thoại doanh nghiệp",
		customerName: "Tên Người Dùng",
		role: "Vai trò",
		selectRole: "Chọn Vai trò",
		customerNameHolder: "Nhập đầy đủ tên người dùng",
		businessPhoto: "Ảnh Doanh Nghiệp",
		uploadPhoto: "Tải ảnh Doanh Nghiệp/Cửa hàng lên",
		uploadPhotoTitle: "Tải ảnh lên",
		uploadFromGallery: "Tải lên từ Thư viện ảnh",
		takePhoto: "Chụp ảnh",
		businessName: "Tên Doanh Nghiệp",
		businessNameHolder: "Nhập tên doanh nghiệp/cửa hàng",
		address: "Địa chỉ",
		addressHolder: "Nhập địa chỉ doanh nghiệp/cửa hàng",
		city: "Thành phố",
		cityHolder: "Nhập tên thành phố",
		postalCode: "Mã Bưu chính",
		postalCodeHolder: "Nhập mã bưu chính",
		selectBusiness: "Chọn Loại Doanh Nghiệp",
		fmcg: "FMCG",
		nonFmcg: "NON-FMCG",
		nextBtn: "Tiếp Theo",
		completeBtn: "Hoàn Thành",
		customerFindText: `Doanh nghiệp đã được tìm thấy ở đây; bạn có thể chọn thêm vào danh sách khách hàng của mình nếu bạn muốn.`,
		addBusiness: "Thêm Doanh Nghiệp",
		addBusinessInfo:
			"Bạn có muốn thêm doanh nghiệp này làm khách hàng của bạn không?",
		noBtn: "Không",
		alreayExist: "Doanh nghiệp đã tồn tại!",
		reseller: "Người bán lại",
		businessNameWarning: "Vui lòng thêm Tên Doanh Nghiệp",
		distributorNameWarning: "Vui lòng thêm Tên Người Dùng",
		businessCreationSuccess: "Tạo Doanh Nghiệp Mới Thành Công",
		businessLocationWarning:
			"Vui lòng thêm Vị trí Doanh Nghiệp từ Google Map",
		locationValidation: "Sila pilih lokasi dari peta.",
	},
	industryType: {
		pageTitle: "Ngành công nghiệp'",
		title: "Xin vui lòng chọn ngành nghề làm việc của bạn",
	},
	addCard: {
		pageTitle: "Thêm Thẻ",
		addedList: "Danh Sách Thẻ Đã Thêm",
		addNewCard: "Thêm Thẻ Mới",
		continue: "Tiếp Tục",
	},
	paymentModule: {
		pageTitle: "Chọn Gói Nâng Cấp",
		upgradeNow: "Nâng Cấp Ngay",
		monthlyPlan: "Gói Hàng Tháng",
		annualPlan: "Gói Hàng Năm",
		card: "Thẻ",
		paymentMethod: "Phương Thức Thanh Toán",
		continue: "Tiếp Tục",
		selectedMethod: "Vui lòng chọn phương thức thanh toán..!",
		checkOut: "Thanh Toán",
		paymentCard: "Thẻ Thanh Toán",
		changeCard: "Thay Đổi Thẻ",
		selectPackage: "Chọn Gói",
		orderSummary: "Tóm Tắt Đơn Hàng",
		subtotal: "Tạm Tính",
		total: "Tổng Cộng",
		payNow: "Thanh Toán Ngay",
		successTitle: "Thanh Toán Thành Công",
		successText: "Cảm ơn bạn đã thanh toán",
		yourPlan: "Gói Đăng Ký Của Bạn",
		transactionHistory: "Lịch Sử Giao Dịch",
		cardNumber: "Số Thẻ",
		status: "Trạng Thái",
		invoice: "Hóa Đơn",
		newCard: "Thêm Thẻ Mới",
	},
};
export default vietnamese;

import { digitChanger } from "helpers/helper";
import React from "react";

export default function BothPartiesDetailsVN({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
}) {
	return (
		<div>
			{/* Phần thứ nhất */}
			<div>
				<p className='text-justify text-222222 text-14'>
					{/* new  */}
					<span className='font-bold'>
						{createdBusinessDetails?.business_name}
					</span>{" "}
					là một doanh nghiệp sở hữu duy nhất. là một công ty sở hữu duy
					nhất và đã đăng ký ở Bangladesh, có địa chỉ là:{" "}
					<span className='font-bold ml-1'>
						{createdBusinessDetails?.address_line
							? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
							: `${businessDetails?.address_line}, ${businessDetails?.city}`}
					</span>
					, Giấy phép Thương mại số{" "}
					<span className='font-bold'>
						{digitChanger(kycDetails?.trade_no)}
					</span>{" "}
					thay mặt cho Chủ sở hữu/Chứng minh thư nhân dân số{" "}
					<span className='font-bold'>
						{digitChanger(kycDetails?.nid_no)}
					</span>{" "}
					của công ty nói trên sau đây được gọi trong Thỏa thuận này với tư
					cách là Người bán /Nhà cung cấp, theo đó biểu hiện sẽ bao gồm
					những người thừa kế và cử tri/người được đề cử.
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						Bên thứ nhất
					</p>
				</div>
			</div>
			<h3 className='font-bold text-center my-5'>và</h3>
			{/* Phần thứ hai */}
			<div>
				<p className='text-justify text-222222 text-14'>
					{/* new  */}
					<span className='font-bold'>EkkBaz Private Limited</span> là một
					công ty. Được đăng ký và quản lý theo Đạo luật công ty của
					Bangladesh. có địa chỉ đăng ký là:{" "}
					<span className='font-bold'>
						Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore 409051
					</span>
					, thay mặt cho{" "}
					<span className='font-bold mx-1'>Zobaida Sultana</span>, Giám đốc
					điều hành của Công ty, sau đây gọi là{" "}
					<span className='font-bold'>EkkBaz, Buyer/Platform </span>trong
					Thỏa thuận này. Theo biểu thức nào sẽ bao gồm những người kế
					nhiệm, người đại diện theo pháp luật, giám đốc và người được đề
					cử.
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						Bên thứ hai
					</p>
				</div>
			</div>
		</div>
	);
}

import { IonItem } from "@ionic/react";

import React from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setSupplierList } from "redux/container/userSlice";

export default function SupplerListBottomSheet() {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.supplierList);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[200, 200, 100, 0]}
				onClose={() => dispatch(setSupplierList(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								navigate("/create-supplier");
								dispatch(setSupplierList(false));
							}}
							lines='none'>
							<div className='flex items-center'>
								<span className='py-[5px] pr-4'>
									<img
										className='h-10 w-10'
										src='assets/images/shop3.png'
										alt='user'
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.createSupplier.pageTitle}
								</p>
							</div>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setSupplierList(false));
								navigate("/invited-supplier-list");
							}}
							lines='none'>
							<div className='flex items-center'>
								<span className='py-[5px] pr-4'>
									<img
										className='h-10 w-10'
										src='assets/images/invited-supplier.png'
										alt='user'
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.createSupplier.invitedSupplierList}
								</p>
							</div>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setSupplierList(false))} />
			</Sheet>
		</div>
	);
}

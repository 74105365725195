import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import { getLanguage } from "config/language";
import { digitChanger, monthChanger } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { clearPaymentCard } from "redux/container/paymentMethodSlice";
import parse from "html-react-parser";
import Business from "services/Business";
import moment from "moment";

export default function CongratulationPage() {
	let { id } = useParams();
	let langInfo = getLanguage();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const loanInfo = useSelector((state) => state.loan.remainingInfo);
	const currency = useSelector((state) => state.payment.currency);
	let dispatch = useDispatch();
	const paymentMethod = useSelector((state) => state.payment);
	let [defaultPenalty, setDefaultPenalty] = useState([]);

	let warningCreator = () => {
		if (langInfo === "bn") {
			return `${monthChanger(
				digitChanger(loanInfo?.date)
			)} পর্যন্ত সমস্ত পেমেন্ট করতে না পারলে, ৳ ${digitChanger(
				Number(loanInfo.creditfee).toFixed(2)
			)} টাকার জরিমানা হবে। আমরা আপনার তাড়াতাড়ি পেমেন্টকে মূল্যায়ন করি।`;
		} else if (langInfo === "en") {
			return `If you do not settle all payments by ${loanInfo.date}, there will be a penalty fee of ৳ ${loanInfo.creditfee}. 
We appreciate your prompt payment.`;
		} else if (langInfo === "vn") {
			return `Nếu bạn không thanh toán tất cả các khoản thanh toán trước ngày ${loanInfo.date}, sẽ có một khoản phạt là ৳ ${loanInfo.creditfee}. Chúng tôi rất trân trọng việc thanh toán kịp thời của bạn.`;
		}
		else if (langInfo === "my") {
			return `Jika anda tidak menjelaskan semua pembayaran selewat-lewatnya ${loanInfo.date}, akan ada bayaran penalti sebanyak ৳ ${loanInfo.creditfee}.
			Kami menghargai pembayaran segera anda`;
		}
	};
	let detailsText = () => {
		if (paymentMethod?.hasLoan) {
			if (langInfo === "en") {
				return `Your <span style='color:#00B224;'>Down Payment</span> amount: ${digitChanger(
					Number(
						paymentMethod?.subTotalAmount - paymentMethod?.loanAmount
					).toFixed(2)
				)}.`;
			} else if (langInfo === "bn") {
				return `আপনার <span style='color:#00B224;'>ডাউন পেমেন্টের</span> পরিমাণ: ${digitChanger(
					Number(
						paymentMethod?.subTotalAmount - paymentMethod?.loanAmount
					).toFixed(2)
				)} টাকা।`;
			} else if (langInfo === "vn") {
				return `Số <span style='color:#00B224;'>tiền trả trước</span> của bạn: ${Number(
					paymentMethod?.subTotalAmount - paymentMethod?.loanAmount
				).toFixed(2)}`;
			}else if (langInfo === "my") {
				return `awak<span style='color:#00B224;'>Bayaran Pendahuluan</span> jumlah: ${Number(
					paymentMethod?.subTotalAmount - paymentMethod?.loanAmount
				).toFixed(2)}`;
			}
		}
	};
	let detailsText2 = () => {
		if (paymentMethod?.hasLoan) {
			if (langInfo === "en") {
				return `Use <span style='color:#00B224;'>Pay EkkBaz</span> to upload the bank receipt after transferring.`;
			} else if (langInfo === "bn") {
				return `ট্রান্সফারের পরে ব্যাঙ্কের রসিদ আপলোড করতে <span style='color:#00B224;'>Pay EkkBaz</span> ব্যবহার করুন৷`;
			} else if (langInfo === "vn") {
				return `Sử dụng <span style='color:#00B224;'>Pay EkkBaz</span> để tải lên biên lai ngân hàng sau khi chuyển.`;
			}else if (langInfo === "my") {
				return `guna <span style='color:#00B224;'>Pay EkkBaz</span> untuk memuat naik resit bank selepas pemindahan.`;
			}
		}
	};

	const getDefaultPenalty = async () => {
		let response = await Business.getDefaultPenalty(id);
		if (response?.status === 200) {
			setDefaultPenalty(response.result);
		}
	};
	const textChanger = (item) => {
		if (item === "1st Default") {
			return ekkLanguage.orderDetails.first;
		} else if (item === "2nd Default") {
			return ekkLanguage.orderDetails.second;
		} else if (item === "3rd Default") {
			return ekkLanguage.orderDetails.third;
		}
	};
	useEffect(() => {
		getDefaultPenalty();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.createOrder?.cosgressTitle}
									</h2>
									<p className='text-20 text-black-500 font-semibold mb-5'>
										{ekkLanguage.createOrder?.cosgressPara}
									</p>
									{!paymentMethod?.hasLoan && (
										<p
											className='text-14 text-black-500 font-semibold mb-5 px-6'
											style={{ color: "#A6A6A6" }}>
											{ekkLanguage.createOrder?.congressCancelText}
										</p>
									)}
									{paymentMethod?.hasLoan && (
										<div className='text-center px-2'>
											<p
												className='font-bold text-14 pb-3'
												style={{ color: "#F94B41" }}>
												{ekkLanguage.createOrder?.dpWarning1}
											</p>
											<p
												className='font-bold text-14 pb-4'
												style={{ color: "#F94B41" }}>
												{ekkLanguage.createOrder?.dpWarning2}
											</p>
										</div>
									)}
									{paymentMethod?.hasLoan && (
										<div className='px-4'>
											<table className='table-fixed w-full  '>
												<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
													<tr>
														<td className='text-primary text-14 font-bold py-[6px] text-center border border-AAABAD'>
															{
																ekkLanguage.orderDetails
																	.penaltyFee
															}
														</td>
														<td className='text-14 text-primary font-bold py-[6px] text-center border border-AAABAD'>
															{ekkLanguage.orderDetails.amount}
														</td>
													</tr>
													{defaultPenalty.length > 0
														? defaultPenalty?.map((el, i) => {
																return (
																	<tr key={i}>
																		<td className='text-14 font-medium py-[6px] text-center border border-AAABAD  w-7/12'>
																			{textChanger(
																				el?.title
																			)}
																			{" - "}
																			<span className='text-14'>
																				{digitChanger(
																					moment(
																						el?.due_date
																					).format(
																						"DD/MM/YYYY"
																					)
																				)}{" "}
																			</span>
																		</td>
																		<td className='text-14 font-medium py-[6px] text-center border border-AAABAD w-5/12'>
																			{
																				currency?.currency_symbol_native
																			}{" "}
																			{digitChanger(
																				Number(
																					el?.amount
																				).toFixed(2)
																			)}
																		</td>
																	</tr>
																);
														  })
														: undefined}
													{/* <tr>
														<td className='text-14 font-medium py-[6px] text-center border border-AAABAD'>
															{ekkLanguage.orderDetails.first}{" "}
															{ekkLanguage.orderDetails.default}
														</td>
														<td className='text-center text-14 font-medium py-[6px] border border-AAABAD'>
															{
																orderNumber?.country
																	?.currency_symbol_native
															}
															{digitChanger(
																Number(
																	orderNumber?.total +
																		orderNumber?.total *
																			(Number(
																				defaultPenalty?.[0]
																					?.percentage_amount
																			) /
																				100)
																).toFixed(2)
															)}
														</td>
													</tr>
													<tr>
														<td className='text-14 font-medium py-[6px] text-center border border-AAABAD'>
															{ekkLanguage.orderDetails.second}{" "}
															{ekkLanguage.orderDetails.default}
														</td>
														<td className='text-14 font-medium py-[6px] text-center border border-AAABAD'>
															{
																orderNumber?.country
																	?.currency_symbol_native
															}{" "}
															{digitChanger(
																Number(
																	orderNumber?.total +
																		orderNumber?.total *
																			(Number(
																				defaultPenalty?.[1]
																					?.percentage_amount
																			) /
																				100)
																).toFixed(2)
															)}
														</td>
													</tr>
													<tr>
														<td className='text-14 font-medium py-[6px] text-center border border-AAABAD'>
															{ekkLanguage.orderDetails.third}{" "}
															{ekkLanguage.orderDetails.default}
														</td>
														<td className='text-14 font-medium py-[6px] text-center border border-AAABAD'>
															{
																orderNumber?.country
																	?.currency_symbol_native
															}{" "}
															{digitChanger(
																Number(
																	orderNumber?.total +
																		orderNumber?.total *
																			(Number(
																				defaultPenalty?.[2]
																					?.percentage_amount
																			) /
																				100)
																).toFixed(2)
															)}
														</td>
													</tr> */}
												</tbody>
											</table>
											<p className='pt-[15px] pb-[30px] font-bold text-14 text-primary text-center'>
												{ekkLanguage.orderDetails.amountWarning}
											</p>
										</div>
									)}

									{/* <p className='font-bold text-222222 text-16 mb-5 px-6'>
										{detailsText() && parse(detailsText())}
									</p> */}

									{/* {paymentMethod?.paymentMethodDescription && (
										<div className='text-left px-4 pb-7 text-black-500 text-[13px] flex items-start flex-col justify-center'>
											{parse(
												paymentMethod?.paymentMethodDescription
											)}
										</div>
									)} */}
									<p className='font-bold text-222222 text-16 mb-12 px-6'>
										{detailsText2() && parse(detailsText2())}
									</p>
									{/* {loanInfo?.creditfee > 0 && (
										<p className='text-14 text-primary font-semibold mb-12 px-6'>
											{warningCreator()}
										</p>
									)} */}
									<Link
										to='/dashboard'
										className='w-full'
										onClick={() => {
											dispatch(clearPaymentCard());
										}}>
										<IonButton
											className='rounded-[10px] h-12 w-full text-white font-bold bg-success'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createOrder?.congressProductLabel}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { digitChanger, numberWithCommas } from "helpers/helper";
import { IonCol, IonLoading, IonSpinner } from "@ionic/react";
import Business from "services/Business";
import { getUserBusiness } from "config/user";
import { setCountryInfo } from "redux/container/userSlice";

export default function FSDueDetails() {
	const [dueLoan, setDueLoan] = useState({});
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { countryInfo } = useSelector((state) => state.user);
	let [loading, setLoading] = useState(true);
	let dispatch = useDispatch();

	const getBrandProducts = async () => {
		let response = await Business.getPreviousLoan(getUserBusiness()?.id);
		if (response.status === 402) {
			setLoading(false);
			console.log(response);
			setDueLoan(response);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCountryInfo();
		getBrandProducts();
	}, []);
	const getCountryInfo = async () => {
		let response = await Business.getCountry(getUserBusiness()?.country);
		if (response.status === 200) {
			dispatch(setCountryInfo(response.results));
		}
	};

	return (
		<div>
			<IonLoading
				isOpen={loading}
				message={ekkLanguage.createOrder.loader}
				spinner='circles'
			/>

			<div className='mt-5 pt-14 mb-5' style={{ background: "#F2F3F7" }}>
				<table
					className='border-collapse border w-full bg-white'
					style={{ boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)" }}>
					<thead>
						<tr className='h-[45px] border border-D9D9D9 px-4'>
							<th className='font-bold text-14 border border-D9D9D9'>
								S/N
							</th>
							<th className='font-bold text-14 border border-D9D9D9'>
								{ekkLanguage.dashboard.orderNumber}
							</th>
							<th className='font-bold text-14 border border-D9D9D9'>
								{ekkLanguage.dashboard.dueAmount}
							</th>
							<th className='font-bold text-14 border border-D9D9D9'>
								{ekkLanguage.dashboard.dueDate}
							</th>
						</tr>
					</thead>
					<tbody>
						{dueLoan?.results?.length
							? dueLoan?.results?.map((el, i) => {
									return (
										<tr className='text-center h-[34px] px-4' key={i}>
											<td className='font-semibold text-12 border border-D9D9D9'>
												{digitChanger(i + 1)}
											</td>
											<td className='font-semibold text-12 border border-D9D9D9'>
												<Link
													className='text-delivered'
													to={`/purchase-order-details/${el?.orderMId}/buyer`}>
													{digitChanger(el?.orderId)}
												</Link>
											</td>
											<td className='font-semibold text-12 border border-D9D9D9	'>
												{countryInfo?.currency_symbol_native}
												{""}
												{digitChanger(
													numberWithCommas(el?.dueAmount)
												)}
											</td>
											<td className='font-semibold text-12 border border-D9D9D9'>
												{digitChanger(
													moment(el?.dueDate).format("DD/MM/YYYY")
												)}
											</td>
										</tr>
									);
							  })
							: ""}
					</tbody>
				</table>
				{loading ? (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				) : (
					""
				)}
				{dueLoan?.results?.length === 0 && (
					<>
						<IonCol size='2'></IonCol>
						<IonCol size='8'>
							<div
								className='text-center flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</IonCol>
					</>
				)}
			</div>
		</div>
	);
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import AddBankAccount from "components/mobile-components/AddBankAccount";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";

export default function AddBankAccountPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let business = getUserBusiness();
  let [businessData, setBusinessData] = useState({});
  
  //get business data
  let getBusiness = async () => {
    let response = await Business.getBusiness(business?.id);
    if (response.status === 200) {
      setBusinessData(response.results);
      setUserBusiness({
        ...business,
        business_status: response.results.business_status,
      });
    }
  };
  useEffect(() => {
    getBusiness();
  }, []);
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.addBankSettings.pageTitle} />
          <AddBankAccount
            ekkLanguage={ekkLanguage}
            businessData={businessData}
          ></AddBankAccount>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummaryCard from "components/mobile-components/CreateOrder/CostSummaryCard";
import CustomerDetailsCard from "components/mobile-components/CreateOrder/CustomerDetailsCard";
import DeliveryCard from "components/mobile-components/CreateOrder/DelivertCard";
import OrderTotalCard from "components/mobile-components/CreateOrder/OrderTotalCard";
import PaymentCard from "components/mobile-components/CreateOrder/PaymentCard";
import ProductCard from "components/mobile-components/CreateOrder/ProductCard";
import SupplierDetailsCard from "components/mobile-components/CreateOrder/SupplierDetailsCard";
import React, { useEffect, useState } from "react";
import AddMoreProduct from "components/mobile-components/CreateOrder/AddMoreProduct";
import withLocation from "components/HOC/withLocation";
import { useSelector } from "react-redux";
import Business from "services/Business";
import { getUserBusiness } from "config/user";

function CreateOrderForAi() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [dueLoan, seTDueLoan] = useState();
	const getBrandProducts = async () => {
		let response = await Business.getPreviousLoan(getUserBusiness()?.id);
		if (response.status === 402) {
			seTDueLoan(response);
		}
	};
	useEffect(() => {
		getBrandProducts();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.createOrder.pageTitle} />
					<section className='px-2 pb-24 pt-14'>
						<CustomerDetailsCard />
						<SupplierDetailsCard />
						<DeliveryCard />
						<ProductCard />
						<AddMoreProduct />
						<PaymentCard />
						{/* <LoanCard /> */}
						<CostSummaryCard />
					</section>
					<OrderTotalCard dueLoan={dueLoan} />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default withLocation(CreateOrderForAi);

import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Banner from "components/mobile-components/ProductDetails/Banner";
import SupplierDetails from "components/mobile-components/SupplierDetails";
import SupplierDetailsBottomShit from "components/mobile-components/SupplierDetails/SupplierDetailsBottomShit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Brand from "services/Brand";

export default function SupplierDetailPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let { id, relId } = useParams();
	const [loading, setLoading] = useState(true);
	const [brandProducts, setBrandProducts] = useState([]);
	const [brandInfo, setBrandInfo] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [brand360, setBrand360] = useState([]);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getBrand360 = async () => {
		let response = await Brand.getBrand360(id);
		if (response.status === 200) {
			setBrand360(response.data);
		}
	};

	const getBrandInfo = async () => {
		let response = await Brand.getSupplierBrandDetails(relId);
		if (response.status === 200) {
			setBrandInfo({
				...response.results,
				...response.results.businessDetails,
			});
		}
	};
	const getBrandProducts = async () => {
		let response = await Brand.getSupplierBrandProducts(id);
		if (response.status === 200) {
			setBrandProducts(response.results);
			setLoading(false);
		}
	};
	const singleBrandProductSearch = async () => {
		setPageCount(2);
		let response = await Brand.getSupplierBrandProductSearch(id, searchQ);
		if (response.status === 200) {
			setBrandProducts(response.results);
			setPageCount((prev) => prev + 1);
		}
	};
	useEffect(() => {
		getBrandInfo();
		getBrand360();
	}, []);

	useEffect(() => {
		if (searchQ === "") {
			getBrandProducts();
		}
	}, [searchQ]);

	let settings = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-detail",
	};

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				let response = await Brand.getSupplierBrandProductSearch(
					id,
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					setBrandProducts(response.results);
					setLoading(false);
					setPageCount((prev) => prev + 1);
					setBrandProducts([...brandProducts, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				const response = await Brand.getSupplierBrandProducts(
					id,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandProducts([...brandProducts, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			}
		}
	};
	let slideData = brandInfo?.images;

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.brandDetails.pageTitle}
						from='supplierDetail'
					/>
					{loading && (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList?.loader}
						/>
					)}
					<div className='pt-14'>
						<Banner brandInfo={brandInfo} slideData={slideData} />
						<SupplierDetails
							brandInfo={brandInfo}
							product={brandProducts}
							settings={settings}
							ekkLanguage={ekkLanguage}
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							singleBrandProductSearch={singleBrandProductSearch}
							customer={brand360}
							id={id}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>
					</div>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								lodeMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
					<SupplierDetailsBottomShit
						brandInfo={brandInfo}
						getBrandInfo={getBrandInfo}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
// import { setCustomer } from "redux/container/customerSlice";

function SupplierFilter({
	setMyBrand,
	getAllBrandList,
	getMyBrandList,
	ekkLanguage,
	setReachedEnd,
	setHasMoreData,
}) {
	const [brandType, setBrandType] = useState("allBrand");
	return (
		<IonSegment
			scrollable
			className='orderBtnWrapper'
			value={brandType}
			onIonChange={(e) => {
				setMyBrand(e.target.value === "nearbySupplier" ? true : false);
			}}>
			<IonSegmentButton
				value='allBrand'
				className='paid relative'
				onClick={() => {
					setBrandType("allBrand");
					// setMyBrand(false);
					getAllBrandList();
					setReachedEnd(false);
					setHasMoreData(true);
				}}>
				<button className='text-[#00B224] flex items-center font-sans p-0 font-bold shadow-none text-14'>
					{ekkLanguage.home.allSuppliersLabel}
				</button>
			</IonSegmentButton>
			<IonSegmentButton
				value='nearbySupplier'
				className='cancel relative'
				onClick={() => {
					setBrandType("nearbySupplier");
					// setMyBrand(true);
					getMyBrandList();
					setReachedEnd(false);
					setHasMoreData(true);
				}}>
				<button className='text-primary flex items-center font-sans p-0 font-bold shadow-none text-14'>
					{ekkLanguage.home.nearSuppliersLabel}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(SupplierFilter);

import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import withLocation from "components/HOC/withLocation";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Map from "components/mobile-components/LocationPicker/Map";
import MapFunctional from "components/mobile-components/LocationPicker/MapFunctional";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function PickBusinessLocationPage() {
	const { to } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [error, setError] = useState();
	const location = useSelector((state) => state.user?.mapLocation);
	const myLocation = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.locationPicker.pageTitle} />
					<div className='pt-14'>
						{/* <Map
						// google={this.props.google}
						center={{ lat: 23.5204, lng: 90.399452 }}
						height='500px'
						zoom={15}
					/> */}

						<MapFunctional
							center={{
								lat: myLocation?.length ? myLocation[1] : 23.5204,
								lng: myLocation?.length ? myLocation[0] : 90.399452,
							}}
							height='400px'
							zoom={18}
							location={location}
							from='pickLocation'
						/>
						<div
							className='mx-4 mt-5 mb-10'
							style={{
								background: "#FFFFCC",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
							}}>
							<div className='rounded-[5px]'>
								<p className='text-808080 text-12 font-semibold px-[10px] py-[6px]'>
									{ekkLanguage.locationPicker.paragraph}
								</p>
							</div>
						</div>
						<div className='text-center'>
							<IonButton
								color='primary'
								className='font-bold h-10'
								onClick={() => {
									if (Object.keys(location).length) {
										navigate(`/create-supplier`);
									} else {
										setError(
											ekkLanguage.businessCreateName.errorLabel
										);
									}
								}}>
								{ekkLanguage.businessCreateName.btnLabel}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
export default withLocation(PickBusinessLocationPage);

import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import CommonCard from "./CommonCard";
import { bankListArray } from "data/bankList";

export default function DepositCard({ depositDetails }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let logo = bankListArray.find((el) => el.name === depositDetails.bankName);

	return (
		<div>
			<CommonCard
				title={depositDetails?.transactionNumber}
				image={logo?.image}
				isVerified={depositDetails?.status === "Verified" ? true : false}
				ekkLanguage={ekkLanguage}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-[#09C] text-14 font-bold py-[6px]'>
									{ekkLanguage.depositList.depositAmount}
								</td>
								<td className='text-[#09C] text-14 font-bold py-[6px] text-right'>
									{digitChanger(
										numberWithCommas(depositDetails?.payAmount)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-14 font-medium py-[6px]'>
									{ekkLanguage.depositList.collectionAmount}
								</td>
								<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
									{digitChanger(
										numberWithCommas(depositDetails?.payAmount)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-14 font-medium py-[6px]'>
									{ekkLanguage.depositList.numberOfCollection}
								</td>
								<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
									{digitChanger(depositDetails?.collectedList?.length)}
								</td>
							</tr>

							{depositDetails?.bankName === "Cash & Hub" && (
								<tr>
									<td className='text-000080 text-14 font-medium py-[6px]'>
										{ekkLanguage.depositList.depositType}
									</td>
									<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
										{depositDetails?.bankName}
									</td>
								</tr>
							)}
							{(depositDetails?.bankName === "Nagad") |
							(depositDetails?.bankName === "Bkash") ? (
								<>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.depositType}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.bankName}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.acNumber}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.accountNumber}
										</td>
									</tr>
								</>
							) : undefined}

							{depositDetails?.bankName !== "Cash & Hub" &&
							depositDetails?.bankName !== "Nagad" &&
							depositDetails?.bankName !== "Bkash" ? (
								<>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.bankName}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.bankName}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.acName}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.accountName}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.acNumber}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.accountNumber}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.routeNumber}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.routeNumber}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.transectionNumber}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.transactionNumber}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.branch}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.branchSubBranch}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-14 font-medium py-[6px]'>
											{ekkLanguage.depositList.bankAddress}
										</td>
										<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
											{depositDetails?.bankAddress}
										</td>
									</tr>
								</>
							) : undefined}
							<tr>
								<td className='text-000080 text-14 font-medium py-[6px]'>
									{ekkLanguage.depositList.collectorName}
								</td>
								<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
									{depositDetails?.userName}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-14 font-medium py-[6px]'>
									{ekkLanguage.depositList.comment}
								</td>
								<td className='text-1000 text-14 font-semibold py-[6px] text-right'>
									{depositDetails?.comment}
								</td>
							</tr>
						</tbody>
					</table>
					<div className='pt-[6px] pb-[10px]'>
						<img
							className='w-[146px] h-[146px] rounded-[5px] m-auto'
							src={depositDetails?.depositSlip}
							alt='signature'
						/>
						{/* <img
							className='w-[150px] h-[150px] rounded-[5px] '
							src='/assets/images/brand14.jpg'
							alt=''
						/> */}
					</div>
				</div>
			</CommonCard>
		</div>
	);
}

import React from "react";

export default function Phone() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 24 24'
			fill='none'>
			<path
				d='M23 11C22.7348 11 22.4804 10.8946 22.2929 10.7071C22.1053 10.5196 22 10.2652 22 9.99999C21.9979 7.87891 21.1543 5.84531 19.6545 4.34547C18.1547 2.84564 16.1211 2.00211 14 1.99999C13.7348 1.99999 13.4804 1.89463 13.2929 1.7071C13.1053 1.51956 13 1.26521 13 0.99999C13 0.734773 13.1053 0.480419 13.2929 0.292883C13.4804 0.105347 13.7348 -1.03539e-05 14 -1.03539e-05C16.6513 0.00290124 19.1931 1.0574 21.0678 2.93214C22.9426 4.80687 23.9971 7.34872 24 9.99999C24 10.2652 23.8946 10.5196 23.7071 10.7071C23.5196 10.8946 23.2652 11 23 11ZM20 9.99999C20 8.40869 19.3678 6.88257 18.2426 5.75735C17.1174 4.63213 15.5913 3.99999 14 3.99999C13.7348 3.99999 13.4804 4.10535 13.2929 4.29288C13.1053 4.48042 13 4.73477 13 4.99999C13 5.26521 13.1053 5.51956 13.2929 5.7071C13.4804 5.89463 13.7348 5.99999 14 5.99999C15.0609 5.99999 16.0783 6.42142 16.8284 7.17156C17.5786 7.92171 18 8.93912 18 9.99999C18 10.2652 18.1053 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 9.99999ZM22.183 22.164L23.093 21.115C23.6722 20.5339 23.9974 19.7469 23.9974 18.9265C23.9974 18.1061 23.6722 17.3191 23.093 16.738C23.062 16.707 20.656 14.856 20.656 14.856C20.0785 14.3063 19.3115 14.0003 18.5143 14.0014C17.7171 14.0025 16.9509 14.3107 16.375 14.862L14.469 16.468C12.9132 15.8241 11.4999 14.8792 10.3103 13.6875C9.12068 12.4958 8.17821 11.0809 7.53699 9.52399L9.13699 7.62399C9.68871 7.04816 9.99729 6.28187 9.99859 5.48439C9.9999 4.68692 9.69382 3.91962 9.14399 3.34199C9.14399 3.34199 7.29099 0.93899 7.25999 0.90799C6.68944 0.333738 5.91587 0.00701166 5.10642 -0.00159557C4.29696 -0.0102028 3.51662 0.3 2.93399 0.86199L1.78399 1.86199C-5.01001 9.74399 9.61999 24.261 17.762 24C18.5842 24.0048 19.399 23.8447 20.1583 23.5294C20.9176 23.2141 21.6061 22.7498 22.183 22.164Z'
				fill='#E50C00'
			/>
		</svg>
	);
}

import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import Envelope from "assets/images/icon/svg/Envelope";
import Message from "assets/images/icon/svg/Message";
import UserIcon from "assets/images/icon/svg/User";
import { getUser, setUser } from "config/user";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import User from "services/User";
import { DayPicker, MonthPicker, YearPicker } from "react-dropdown-date";

export default function Contact() {
  const user = getUser();
  const userData = useSelector((state) => state.user.data);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [birthDay, setBirthDay] = useState({
    year: userData?.dob ? moment(userData?.dob).format("YYYY") : "",
    month: userData?.dob ? Number(moment(userData?.dob).format("MM") - 1) : "",
    day: userData?.dob ? moment(userData?.dob).format("DD") : "",
  });
  const [changingMonth, setChangingMonth] = useState(false);
  const [gender, setGender] = useState(userData?.gender);
  const [name, setName] = useState(userData?.full_name);
  const [email, setEmail] = useState(userData.contact_and_basic_info?.email);
  const [nid, setNid] = useState(userData.contact_and_basic_info?.nid_number);
  const history = useNavigate();

  let contactUpdateHandler = async () => {
    if (email.includes("@") === false) {
      toast.warning(ekkLanguage.contact.currectEmail);
    }
    if ((email && email.includes("@")) || nid || gender || birthDay) {
      var reqbody = {
        id_number: nid,
        email: email,
        display_name: name,
        gender: gender,
        dob: moment(
          `${birthDay?.year}-${
            changingMonth ? Number(birthDay?.month) + 1 : birthDay?.month
          }-${birthDay?.day}`
        ).format("YYYY-MM-DD"),
      };
      try {
        let response = await User.basicInfoUpdate(user._id, reqbody);
        if (response.status === 200 || response.status === 201) {
          toast.success(ekkLanguage.contact.successLabel);
          setUser({ ...user, full_name: response.data.full_name });
          history("/profile");
        }
      } catch (e) {
        console.log(e);
      }
    } else {
    }
  };
  let permissionToChange = () => {
    if (email && nid && name) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="px-2 relative h-screen pt-24">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="flex mb-7">
              <span>
                <UserIcon />
              </span>

              <div className="ml-[10px] w-full">
                <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.businessLocation.userName}*
                </h5>
                <IonItem>
                  <IonInput
                    type="email"
                    value={name}
                    placeholder={
                      ekkLanguage.businessLocation.userNamePlaceholder
                    }
                    onIonInput={(e) => {
                      setName(e.detail.value);
                    }}
                    clearInput
                  ></IonInput>
                </IonItem>
              </div>
            </div>
          </IonCol>
          <IonCol size="12">
            <div className="flex mb-7">
              <span>
                <Message />
              </span>
              <div className="ml-[10px] w-full">
                <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.contact.emailLabel}*
                </h5>
                <IonItem>
                  <IonInput
                    type="email"
                    value={email}
                    placeholder={ekkLanguage.contact.emailPlaceholder}
                    onIonInput={(e) => {
                      setEmail(e.detail.value);
                    }}
                    clearInput
                  ></IonInput>
                </IonItem>
              </div>
            </div>
          </IonCol>
          <IonCol size="12">
            <div className="flex mb-7">
              <span>
                <img src="assets/images/nid.png" alt="icon" />
              </span>
              <div className="ml-[10px] w-full">
                <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.contact.nidLabel}*
                </h5>
                <IonItem>
                  <IonInput
                    type="text"
                    value={nid}
                    placeholder={ekkLanguage.contact.nidPlaceholder}
                    onIonInput={(e) => {
                      setNid(e.detail.value);
                    }}
                    clearInput
                  ></IonInput>
                </IonItem>
              </div>
            </div>
          </IonCol>
          <IonCol size="12">
            {" "}
            <div className="flex mb-2">
              <img
                className="mr-[10px] w-5 h-5 pl-1"
                src="/assets/images/gender.png"
                alt=""
              />
              <label className="font-bold text-16 pl-[1px] tracking-wide text-grey-darker">
                {ekkLanguage.registration.gender}
              </label>
            </div>
            <div className="pb-7 pt-1">
              <IonRadioGroup
                className="grid grid-cols-3 gap-2"
                value={gender}
                disabled={true}
                onIonChange={(e) => {
                  setGender(e.detail.value);
                }}
              >
                <IonRadio
                  className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
                  value="Male"
                  justify="start"
                  labelPlacement="end"
                  color="success"
                >
                  {ekkLanguage.registration.male}
                </IonRadio>
                <IonRadio
                  className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
                  value="Female"
                  justify="start"
                  labelPlacement="end"
                  color="success"
                >
                  {ekkLanguage.registration.female}
                </IonRadio>
                <IonRadio
                  className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
                  value="Others"
                  justify="start"
                  labelPlacement="end"
                  color="success"
                >
                  {ekkLanguage.registration.others}
                </IonRadio>
              </IonRadioGroup>
            </div>
          </IonCol>
          {/* Date of Birth  */}
          <IonCol size="12">
            <div className="flex mb-2">
              <img
                className="mr-[10px] w-5 h-5 pl-1"
                src="/assets/images/dob.png"
                alt=""
              />

              <h5 className="text-dark-1000 text-16 font-semibold">
                {ekkLanguage.registration.dobLabel}
              </h5>
            </div>
            <div className="grid grid-cols-3 gap-2 py-1">
              <div className="relative">
                <DayPicker
                  defaultValue={
                    userData?.dob ? birthDay?.day : ekkLanguage.registration.day
                  }
                  year={birthDay?.year}
                  month={birthDay?.month}
                  endYearGiven // mandatory if end={} is given in YearPicker
                  required={true}
                  value={birthDay?.day}
                  onChange={(day) => {
                    setBirthDay({ ...birthDay, day: day });
                  }}
                  id={"day"}
                  name={"day"}
                  classes={`py-3 ${
                    birthDay?.day ? "text-gray-darker" : "text-gray-400"
                  } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
                  optionClasses={"option classes"}
                />
                <img
                  className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
                  src="/assets/images/rightArrow.png"
                  alt=""
                />
              </div>
              <div className="relative">
                {" "}
                <MonthPicker
                  defaultValue={
                    userData?.dob
                      ? birthDay?.month
                      : ekkLanguage.registration.month
                  }
                  short
                  endYearGiven // mandatory if end={} is given in YearPicker
                  year={birthDay?.year}
                  required={true}
                  value={birthDay?.month}
                  onChange={(month) => {
                    setBirthDay({ ...birthDay, month: month });
                    setChangingMonth(true);
                  }}
                  id={"month"}
                  name={"month"}
                  classes={`py-3 ${
                    birthDay?.month ? "text-gray-darker" : "text-gray-400"
                  } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
                  optionClasses={"option classes"}
                />
                <img
                  className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
                  src="/assets/images/rightArrow.png"
                  alt=""
                />
              </div>
              <div className="relative">
                <YearPicker
                  defaultValue={
                    userData?.dob
                      ? birthDay?.year
                      : ekkLanguage.registration.year
                  }
                  start={1970}
                  end={2006}
                  reverse
                  required={true}
                  value={birthDay?.year}
                  onChange={(year) => {
                    setBirthDay({ ...birthDay, year: year });
                  }}
                  id={"year"}
                  name={"year"}
                  classes={`py-3 ${
                    birthDay?.year ? "text-gray-darker" : "text-gray-400"
                  } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
                  optionClasses={"option classes"}
                />
                <img
                  className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
                  src="/assets/images/rightArrow.png"
                  alt=""
                />
              </div>
            </div>
          </IonCol>
        </IonRow>
        <div className="absolute bottom-3 w-full pr-7">
          <IonRow>
            <IonCol size="12">
              <div className="text-center">
                <IonButton
                  disabled={!name}
                  className="bg-primary rounded-[7px] w-full font-extrabold text-12 h-10"
                  onClick={contactUpdateHandler}
                >
                  {ekkLanguage.contact.btnLabel}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonGrid>
    </div>
  );
}

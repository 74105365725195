import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Deposit = {
	getDepositList: (collector_id, date) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/collector-deposit-list?${
				collector_id ? `collector_id=${collector_id}` : ""
			}&select_date=${date}`
		);
	},
	getDepositDetails: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/collector-deposit-list/${id}`
		);
	},
	collectionStatus: (id, status) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/collector-deposit-update/${id}`,
			{
				method: "PUT",
				data: status,
			}
		);
	},
};
export default Deposit;

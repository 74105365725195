import { baseUrl } from "config/apiUrl";
import AuthFetch from "../config/authFetch";
import SuperFetch from "../config/SuperFetch";

const Auth = {
	countryAndUserFilter: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkbaz/users/phone`, {
			method: "POST",
			data: query,
		});
	},
	login: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkbaz/users/login`, {
			method: "POST",
			data: query,
		});
	},
	resendOptToken: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkbaz/resend-otp`, {
			method: "POST",
			data: query,
		});
	},
	resendOptTokenWithToken: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/resend-otp-auth`, {
			method: "POST",
			data: query,
		});
	},

	resetPassword: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/reset-password`, {
			method: "POST",
			data: query,
		});
	},

	userVerifyOtp: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkbaz/verify/otp`, {
			method: "POST",
			data: query,
		});
	},
	userCaptcha: (query) => {
		return AuthFetch(`${baseUrl.pass}/ekkbaz/users/verify-otp`, {
			method: "POST",
			data: query,
		});
	},
	// create new user
	createNewUser: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/set-profile`, {
			method: "POST",
			data: query,
		});
	},
	// Business select
	businessSelect: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/refresh/auth-token`, {
			method: "POST",
			data: query,
		});
	},
	businessSelect2: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/refresh/emp-auth-token`, {
			method: "POST",
			data: query,
		});
	},
	// Business select
	createUserBusiness: (query) => {
		return SuperFetch(`${baseUrl.business}/ekkbaz/businesses/user/business`, {
			method: "POST",
			data: query,
		});
	},
	resetPasswordWhenLogdIn: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/reset-password`, {
			method: "POST",
			data: query,
		});
	},
	refreshToken: (query) => {
		return SuperFetch(`${baseUrl.pass}/ekkbaz/users/refresh/auth-token`, {
			method: "POST",
			data: query,
		});
	},
	setTrialNewUser: (query) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/free_trial/`, {
			method: "POST",
			data: query,
		});
	},
};

export default Auth;

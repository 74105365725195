import { createSlice } from "@reduxjs/toolkit";

const buzAiSlice = createSlice({
	name: "buzAi",
	initialState: {
		data: [],
	},
	reducers: {
		setNewMessage: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = buzAiSlice;

export const setNewMessage = (values) => (dispatch, getState) => {
	if ((values[0].type === "cancel") | (values[0].type === "accept")) {
		let lastElement =
			getState().bazAi.data[getState().bazAi.data.length - 1].id;
		let updateElement = getState().bazAi.data?.map((el) => {
			return el?.id === lastElement
				? {
						...el,
						orderState: "hide",
				  }
				: el;
		});
		let data = [...updateElement, ...values];
		dispatch(actions.setNewMessage(data));
	} else {
		let data = [...getState().bazAi.data, ...values];
		dispatch(actions.setNewMessage(data));
	}
};
export const setUpdateMessage = (values) => (dispatch, getState) => {
	if (Array.isArray(values)) {
		let removeElement =
			getState().bazAi.data[getState().bazAi.data.length - 1].id;
		let remainingItems = getState().bazAi.data.filter(
			(el) => el.id !== removeElement
		);
		let updatedData = [...remainingItems, ...values];
		dispatch(actions.setNewMessage(updatedData));
	} else {
		let data = getState().bazAi.data.map((el) => {
			if (values?.updateImage) {
				return el.id ===
					getState().bazAi.data[getState().bazAi.data.length - 2].id
					? {
							...el,
							...values,
					  }
					: el;
			} else {
				return el.id ===
					getState().bazAi.data[getState().bazAi.data.length - 1].id
					? {
							...el,
							...values,
					  }
					: el;
			}
		});
		dispatch(actions.setNewMessage(data));
	}
};

export const setUpdateReceiverMessage = (values) => (dispatch, getState) => {
	let removeElement =
		getState().bazAi.data[getState().bazAi.data.length - 1].id;
	let remainingItems = getState().bazAi.data.filter(
		(el) => el.id !== removeElement
	);
	let updatedData = [...remainingItems, ...values];
	dispatch(actions.setNewMessage(updatedData));
};
// Export the reducer, either as a default or named export
export default reducer;

import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import { useDispatch } from "react-redux";
import { setUserOrderType } from "redux/container/orderDetailsSlice";

export default function OrderList() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [productLastResponse, setProductLastResponse] = useState([]);
	const [orderStatus, setOrderStatus] = useState([]);
	const [loading, setLoading] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let orderListPage = useSelector((state) => state.order.page);
	const dispatch = useDispatch();

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	let getInitOrderList = async () => {
		let response = await dispatch(fetchOrder("seller", ""));
		if (response.status === 200) {
			dispatch(setUserOrderType("seller"));
			setLoading(false);
			setProductLastResponse(response.data);
		} else {
			setLoading(false);
		}
	};

	let getOrderList = async (value) => {
		let response = await dispatch(fetchOrder("seller", value, orderListPage));
		if (response.status === 200) {
			dispatch(setUserOrderType("seller"));
			setProductLastResponse(response.data);
		}
	};
	useEffect(() => {
		setLoading(true);
		let timeDelay = setTimeout(() => {
			getInitOrderList();
		}, 500);
		return () => clearTimeout(timeDelay);
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.orderList.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<section>
						<OrderStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setLoading={setLoading}
						/>
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							getOrderList(orderStatus);
							setTimeout(() => ev.target.complete(), 500);
						}}
					>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner='bubbles'
						></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { IonGrid } from "@ionic/react";
import Products from "./Products";
import moment from "moment";
import React from "react";
import Search from "../Common/Search";
import SingleAMountBox from "../CustomerDetails/SingleAMountBox";
import { digitChanger } from "helpers/helper";

export default function SupplierDetails({
	brandInfo = {},
	product,
	settings,
	ekkLanguage,
	searchQ,
	setSearchQ,
	singleBrandProductSearch,
	customer,
	id,
	setReachedEnd,
	setHasMoreData,
}) {
	return (
		<div className='pt-5'>
			<div className='px-4 pb-4'>
				<h5 className='font-bold text-18 text-black-1000 pb-[10px]'>
					{brandInfo?.business_name}
				</h5>
				<h4 className='font-bold text-24 text-primary'>
					{digitChanger(brandInfo?.publish_products_count)}{" "}
					{ekkLanguage.brandList.products}
				</h4>
			</div>
			<div>
				<div className='flex items-center bg-white mb-[5px] py-[10px] px-4 cursor-pointer'>
					{brandInfo?.images?.length ? (
						<img
							className='h-10 w-10 rounded-full'
							src={brandInfo?.images[0]}
							alt='icon'></img>
					) : (
						<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
							<h3 className='font-bold text-24 text-white'>
								{brandInfo?.business_name &&
									brandInfo?.business_name[0].toUpperCase()}
							</h3>
						</div>
					)}

					<div className='ml-4'>
						<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
							{brandInfo?.business_name}
						</h4>
						<p className='text-10 text-black-500'>
							Joined on{" "}
							{brandInfo?.createdAt &&
								moment(brandInfo?.createdAt).format("DD-MM-YYYY")}
						</p>
					</div>
				</div>
			</div>
			<div className='bg-[#ffffff] py-5 mt-4'>
				<div
					className='border-b-2 pb-3 flex justify-between items-center px-4'
					style={{ bordreColor: "#f2f3f7" }}>
					<h3 className='text-222222 font-bold text-14 '>
						{" "}
						{ekkLanguage.brandList.details}
					</h3>
				</div>
				<div className='px-4 pt-3'>
					<p className='text-14 font-semibold text-black-1000'>
						{ekkLanguage.brandList.distributorCode}:
						<span className='font-normal text-14 text-black-500 ml-1'>
							{brandInfo?.code}
						</span>
					</p>
				</div>
			</div>
			<div className='pt-4 px-4 pb-1'>
				<div className='grid grid-cols-2 gap-4'>
					<SingleAMountBox
						name={ekkLanguage.dashboard.totalOrder}
						value={customer?.total_orders}
						withComma={false}
					/>
					<SingleAMountBox
						name={ekkLanguage.dashboard.totalAmount}
						value={customer?.total_amount}
						withComma={true}
					/>
					<SingleAMountBox
						name={ekkLanguage.dashboard.totalPaid}
						value={customer?.total_paid}
						withComma={true}
					/>
					<SingleAMountBox
						name={ekkLanguage.dashboard.totalDue}
						value={customer?.due}
						withComma={true}
					/>
				</div>
			</div>
			<div className='px-4 pt-4'>
				<Search
					placeholder={ekkLanguage.brandList.searchProduct}
					search={searchQ}
					setSearch={setSearchQ}
					singleSearch={singleBrandProductSearch}
					setReachedEnd={setReachedEnd}
					setHasMoreData={setHasMoreData}
				/>
			</div>
			<IonGrid>
				<Products
					brandId={id}
					recommendData={product}
					settings={settings}
				/>
			</IonGrid>
		</div>
	);
}

import { createSlice } from "@reduxjs/toolkit";

const driverSlice = createSlice({
	name: "driver",
	initialState: {
		driverData: {},
		collectorData: {},
		collectorDate: "",
	},
	reducers: {
		setDriverData: (state, action) => {
			state.driverData = action.payload;
		},
		setCollectorData: (state, action) => {
			state.collectorData = action.payload;
		},
		setCollectorDate: (state, action) => {
			state.collectorDate = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = driverSlice;

export const setDriverData = (data) => async (dispatch, getState) => {
	dispatch(actions.setDriverData(data));
};
export const setCollectorData = (data) => async (dispatch, getState) => {
	dispatch(actions.setCollectorData(data));
};
export const setCollectionDate = (data) => async (dispatch, getState) => {
	dispatch(actions.setCollectorDate(data));
};
export const setEmptyDriverData = () => async (dispatch, getState) => {
	dispatch(actions.setDriverData(""));
};

// Export the reducer, either as a default or named export
export default reducer;

import BazAi from "components/mobile-components/BazAI";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-pro-sidebar/dist/css/styles.css";
import { hasActive } from "helpers/helper";
import { Link, useLocation } from "react-router-dom";
import NotificationList from "components/mobile-components/Notifications";
import { getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import Notification from "services/Notification";
import {
	setCustomerUnreadCount,
	setNotification,
	setSupplierUnreadCount,
} from "redux/container/notificationSlice";

export default function BazAiPage() {
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	const [notificationType, setNotificationType] = useState("customer");
	const dispatch = useDispatch();
	const [pageCount, setPageCount] = useState(2);
	const id = getUserBusiness()?.id;
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const localLanguage = getLanguage();

	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	var windowSize = window.matchMedia("(max-width: 1100px)");

	const [showLeft, setShowLeft] = useState(!windowSize.matches ? true : false);
	const [showRight, setShowRight] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData.length]);

	const menuIconClick = () => {
		setShowLeft(!showLeft);
	};

	let textContentRef = useRef();
	useEffect(() => {
		textContentRef.current.scrollTop = textContentRef.current.scrollHeight;
	}, [messageData?.length]);

	let getNotificationList = async (value) => {
		if (hasMoreData) {
			if (notificationType === "customer") {
				let response = await Notification.getNewCustomerNotificationList(
					pageCount,
					id,
					localLanguage === "bn" ? "BD" : "EN"
				);
				if (response.success) {
					setPageCount((prev) => prev + 1);
					dispatch(
						setNotification([...notificationList, ...response?.results])
					);
					setNotificationList([...notificationList, ...response?.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				let response = await Notification.getNewSupplierNotificationList(
					pageCount,
					id,
					localLanguage === "bn" ? "BD" : "EN"
				);
				if (response.success) {
					setPageCount((prev) => prev + 1);
					dispatch(
						setNotification([...notificationList, ...response?.results])
					);
					setNotificationList([...notificationList, ...response?.results]);
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	let getInitCustomerNotificationList = async () => {
		setLoading(true);
		setPageCount(2);
		let response = await Notification.getNewCustomerNotificationList(
			1,
			id,
			localLanguage === "bn" ? "BD" : "EN"
		);
		if (response.success) {
			setLoading(false);
			dispatch(setNotification(response?.results));
			setNotificationList(response?.results);
		} else {
			setLoading(false);
		}
	};
	let getInitSupplierNotificationList = async () => {
		setLoading(true);
		setPageCount(2);
		let response = await Notification.getNewSupplierNotificationList(
			1,
			id,
			localLanguage === "bn" ? "BD" : "EN"
		);
		if (response.success) {
			setLoading(false);
			dispatch(setNotification(response?.results));
			setNotificationList(response?.results);
		} else {
			setLoading(false);
		}
	};

	let getUnreadCount = async () => {
		let response1 = await Notification.getNewCustomerUnreadCount(
			id,
			localLanguage === "bn" ? "BD" : "EN"
		);
		if (response1.success) {
			dispatch(setCustomerUnreadCount(response1?.unread_count));
		}
		let response = await Notification.getNewSupplierUnreadCount(
			id,
			localLanguage === "bn" ? "BD" : "EN"
		);
		if (response.sucess) {
			dispatch(setSupplierUnreadCount(response?.unread_count));
		}
	};

	useEffect(() => {
		getUnreadCount();
		if (notificationType === "customer") {
			getInitCustomerNotificationList();
		} else {
			getInitSupplierNotificationList();
		}
	}, [notificationType]);

	return (
		<div className='relative bg-white h-[100vh]'>
			<BazAiNav
				title='Baz AI'
				menuIconClick={menuIconClick}
				showLeft={showLeft}
				showRight={showRight}
				setShowRight={setShowRight}
			/>
			<div
				ref={textContentRef}
				className='container pt-12'
				style={{ overflowY: "scroll", height: "100vh" }}>
				<BazAi />
			</div>
			<div
				style={
					showLeft
						? {
								background: "#f9f9f9",
								left: "0",
								transition: ".3s all",
								top: 60,
						  }
						: {
								background: "#f9f9f9",
								left: "-100%",
								transition: ".3s all",
								top: 60,
						  }
				}
				className={`h-[calc(100%-60px)] w-[250px] top-0 absolute ${
					showLeft ? "show" : "hide"
				}`}>
				{/* <div className='pt-4 pb-4 border-b'>
					<img
						className='w-[150px] m-auto'
						src='/assets/images/ai.png'
						alt='img'
					/>
				</div> */}
				<div className='baz-ai-left-menu'>
					<ul>
						<li className={hasActive("/baz-ai", pathname)}>
							<div
								className='cursor-pointer'
								style={{ padding: "13px 30px 12px 12px" }}>
								<Link
									to='/baz-ai'
									className='flex items-center justify-between'>
									<div className='flex items-center '>
										<img
											className='w-6 h-6 mr-2 ml-1'
											src='assets/images/fav.png'
											alt='fev'
										/>
										{/* <span
											className=' flex justify-center items-center mr-4 rounded-[10px]'
											style={{
												height: 40,
												width: 40,
												background: "#00FF33",
											}}>
											<img
												className='w-[26px] h-[26px]'
												src='assets/images/bazAiIcon.png'
												alt='icon'
											/>
										</span> */}
										<h6 className='text-14 text-222222 font-bold'>
											Baz ai
										</h6>
									</div>
								</Link>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div
				style={
					showRight
						? {
								background: "#f9f9f9",
								right: "0",
								transition: ".3s all",
								top: 60,
						  }
						: {
								background: "#f9f9f9",
								right: "-100%",
								transition: ".3s all",
								top: 60,
						  }
				}
				className={`h-[calc(100%-60px)] w-[320px] top-0 absolute z-50 ${
					showRight ? "show" : "hide"
				}`}>
				<div className='pt-4 pb-4 border-b'>
					<h3 className='text-18 font-bold px-4'>Notification</h3>
				</div>
				<div>
					<NotificationList
						from={
							notificationType === "customer"
								? "customerNotification"
								: "supplierNotification"
						}
						localLanguage={localLanguage}
						notificationType={notificationType}
						setNotificationType={setNotificationType}
						loading={loading}
						setLoading={setLoading}
						setHasMoreData={setHasMoreData}
						setReachedEnd={setReachedEnd}
					/>
				</div>
			</div>
		</div>
	);
}

import {
	IonCol,
	IonGrid,
	IonIcon,
	IonLoading,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import NotificationDot from "assets/images/icon/svg/NotificationDot";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import { setUserOrderType } from "redux/container/orderDetailsSlice";

function NotificationTypeBtn({
	notificationType,
	setNotificationType,
	supplierUnreadCount,
	customerUnreadCount,
	setHasMoreData,
	setReachedEnd,
}) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useEffect(() => {
		if (notificationType === "customer") {
			dispatch(setUserOrderType("seller"));
		} else {
			dispatch(setUserOrderType("buyer"));
		}
	}, [notificationType, dispatch]);

	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<IonSegment
							value={notificationType}
							scrollable
							className='orderBtnWrapper no-ripple'
							onIonChange={(e) => {
								setNotificationType(e.detail.value);
								setHasMoreData(true);
								setReachedEnd(false);
							}}>
							<IonSegmentButton
								value='customer'
								className='cancel relative'>
								<button className='font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.notification.customer}
								</button>
								{customerUnreadCount ? (
									<div className='absolute top-4 right-5'>
										<NotificationDot width={8} height={8} />
									</div>
								) : (
									""
								)}
							</IonSegmentButton>
							<IonSegmentButton
								value='supplier'
								className='cancel relative'>
								<button className=' font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.notification.supplier}
								</button>
								{supplierUnreadCount ? (
									<div className='absolute top-4 right-5'>
										<NotificationDot width={8} height={8} />
									</div>
								) : (
									""
								)}
							</IonSegmentButton>
							{/* <IonSegmentButton value="Accepted" className="cancel relative">
                <button className={`  font-sans p-0 shadow-none text-14`}>
                  {ekkLanguage.notification.update}
                </button>
              </IonSegmentButton> */}
						</IonSegment>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
}
export default memo(NotificationTypeBtn);

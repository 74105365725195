import { IonCol, IonLoading } from "@ionic/react";
import React from "react";
import SingleCustomer from "./SingleCustomer";

export default function CustomerList({ ekkLanguage, customerList, loading }) {
	return (
		<div className='mt-[10px]'>
			{loading && (
				<IonLoading isOpen={true} message={ekkLanguage.orderList?.loader} />
			)}
			{customerList.length > 0 ? (
				<div className='grid grid-cols-2 gap-4'>
					{customerList.map((el, i) => {
						return <SingleCustomer item={el} key={i} />;
					})}
				</div>
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}

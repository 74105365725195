const bangla = {
	login: {
		contryLabel: "দেশ",
		contryPlaceHolder: "",
		phoneLable: "ফোন নম্বর",
		phonePlaceHolder: "মোবাইল নম্বর লিখুন",
		validationLabel: "আপনার ১১ ডিজিটের ফোন নম্বরটি লিখুন।",
		btnLabel: "পরবর্তী",
		tokenSuccessLabel: " সফলভাবে টোকেন পাঠানো হয়েছে",
		tokenSuccessFailed:
			"টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		langChange: "ভাষা পরিবর্তন করুন",
		termsAllow: "লগ-ইন করার সময় শর্তাবলী অনুমতি দিন!",
	},
	password: {
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceHolder: "পাসওয়ার্ড লিখুন",
		forgetPass: "পাসওয়ার্ড ভুলে গিয়েছেন?",
		btnLabel: "প্রবেশ করুন",
		otpSendLabel: "আপনার মোবাইলে কোড পাঠানো হয়েছে,",
		backWord: "পেছনে যান",
		passError: "ফোন নম্বরের সাথে পাসওয়ার্ড মিলছে না",
	},
	changePass: {
		passwordLabel: "নতুন পাসওয়ার্ড",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "পাসওয়ার্ড নিশ্চিত করুন",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "পাসওয়ার্ড মেলে না",
		btnLabel: "পরবর্তী",
		successLabel: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে",
		errorLabel: "আপনার পাসওয়ার্ড পরিবর্তন ব্যর্থ হয়েছে",
		lengthErrorLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		notMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
	},
	token: {
		tokenLabel: "টোকেন",
		tokenPlaceHolder: "টোকেন লিখুন",
		tokenResend: "পুনরায় টোকেন পাঠাও",
		tokenSuccessLabel:
			" আপনার মোবাইলে কোড পাঠানো হয়েছে, পরবর্তী ধাপে যেতে এটি লিখুন",
		tokenErrorLabel: "টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		btnLabel: "পরবর্তী",
		tokenNotMatch: "মেয়াদোত্তীর্ণ টোকেন",
		tokenVarified: "আপনার টোকেন যাচাই করা হয়েছে",
		tokenNotWrite: "আপনার টোকেন লিখুন",
	},
	registration: {
		nameLabel: "পূর্ণ নাম",
		namePlaceholder: "পূূর্ণ নাম লিখুন",
		emailLabel: "ইমেইল ",
		emailPlaceholder: "ইমেইল লিখুন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		validationLabel: "ইনপুট ক্ষেত্র ৪ অক্ষরের হতে হবে",
		passNotMatch: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		btnLabel: "পরবর্তী",
		successMessage: "প্রোফাইল সফলভাবে তৈরি করা হয়েছে",
		errorMessage: "বৈধ ইনপুট চেক করুন",
		passLengthValidLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		passwordLength: "আপনার পাসওয়ার্ডটি",
		strong: "শক্তিশালী",
		medium: "মাধ্যম",
		weak: "দুর্বল",
		gender: "লিঙ্গ",
		male: "পুরুষ",
		female: "নারী",
		others: "অন্যান্য",
		dobLabel: "জন্ম তারিখ",
		day: "দিন",
		month: "মাস",
		year: "বছর",
	},
	letNav: {
		homeMenuLabel: "হোম",
		orderMenuLabel: "বিক্রয়",
		mySupplierMenuLabel: "আমার সরবরাহকারী",
		businessProfileMenuLabel: "ব্যবসায়িক প্রোফাইল সেটিংস",
		phoneCall: "কল টু অর্ডার",
		myEmployeesMenuLabel: "কর্মী তালিকা",
		product: "আমার পণ্য",
		dashboardProfileMenuLabel: "ড্যাশবোর্ড",
		supplier: "ক্রয়",
		learning: "শিক্ষা",
		collectionDeposit: "কালেকশন & ডিপোজিট",
	},
	learning: {
		pageTitle: "শিক্ষণীয় বিষয়",
		learningDetailsTitle: "জ্ঞানের বিবরণ",
		comments: "মন্তব্য",
		commentAs: "এখানে আপনার মন্তব্য লিখুন",
		likedWarning: "একবারের বেশি লাইক দিতে পারবেন না",
	},
	home: {
		showMoreLabel: "আরো দেখুন",
		brandLabel: "ব্র্যান্ড",
		offerLabel: "অফার পণ্য",
		allSuppliersLabel: "অর্ডার তালিকা",
		nearSuppliersLabel: "সাপ্লায়ার",
		recommendentLabel: "শুধু আপনার জন্য",
		searchLabel: "পণ্য সার্চ করুন",
		todayPurchaseLabel: "আজকের কেনাকাটা",
		totalOrderLabel: "ধরণের পণ্য কিনেছেন",
		totalOrder: "টি অর্ডারে",
		skusLabel: "এসকেইউ",
		tillNow: "এখন পর্যন্ত",
		orderDue: "অর্ডার বাকি",
		brandProductPageTitle: "ব্র্যান্ডের সকল পণ্য",
		offerProductPageTitle: "মূল্যছাড় সকল পণ্য",
		offerProductNotFound: "দুঃখিত, আপনার জন্য কোন অফার পাওয়া যায়নি.",
		mySupplierNotFound:
			"আপনার কোনো সরবরাহকারী নেই। এখানে আপনার সরবরাহকারীদের যোগ করতে পণ্য কিনুন।",
		account: "অ্যাকাউন্ট",
		orderNumber: "অর্ডার নম্বর",
		amount: "এমাউন্ট",
		warning: `দয়া করে নোট করুন, লেনদেনগুলি ১লা মার্চ ২০২৪ প্রভাবিত হতে পারে, কারণ সিস্টেম আপগ্রেড করা হচ্ছে৷`,
		warningHeading: "সতর্কতা",
	},
	profile: {
		pageTitle: "প্রোফাইল এবং সেটিংস",
		contactMenuLable: "যোগাযোগ এবং মৌলিক তথ্য",
		passwordMenuLable: "পাসওয়ার্ড পরিবর্তন করুন",
		logoutMenuLable: "লগআউট",
		changeLanguageMenuLable: "ভাষা পরিবর্তন করুন",
		kycMenuLable: "KYC তথ্য",
		subscriptionPlan: "সাবস্ক্রিপশন প্ল্যান",
	},
	contact: {
		pageTitle: "ব্যবহারকারীর তথ্য",
		emailLabel: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		nidLabel: "এনআইডি",
		nidPlaceholder: "এনআইডি লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	otpsend: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "পাঠান",
		message:
			"খুদেবার্তা এর মাধ্যমে আপনার মোবাইলে প্রমাণীকরণ কোড পাঠাতে ট্যাপ করুন।",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
	},
	otpsubmit: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "জমা দিন",
		verifiLabel: "যাচাই করুন এবং চালিয়ে যান",
		title: "যাচাইকরণ কোড",
		paragraph: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
		otpLabel: "আপনার ওটিপি কোড লিখুন",
		otpPlaceholder: "আপনার ওটিপি কোড লিখুন",
		receiveYetLabel: "কোড পাননি?",
		againRequestLabel: "আবার অনুরোধ করুন",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
		tokenVarifiedMessage: "আপনার টোকেন যাচাই করা হয়েছে",
		validationLabel: "আপনার ওটিপি কোড জমা দিন",
		optNotMatch: "আপনার ওটিপি কোড সঠিক নয়",
	},
	changePassword: {
		pageTitle: "পাসওয়ার্ড পরিবর্তন",
		btnLabel: "জমা দিন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		successLabel: "আপনার পাসওয়ার্ড আপডেট করা হয়েছে.",
		errorLabel: "আপনার পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে",
		passNotMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		changePassSuccessPara: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে.",
		changePassContinueBtn: "পরবর্তী",
	},
	changeLanguages: {
		pageTitle: "ভাষা নির্বাচন করুন",
		btnLabel: "সংরক্ষণ করুন",
	},

	mysuppliers: {
		pageTitle: "সাপ্লায়ার তালিকা",
		skusLabel: "এসকেইউ",
		productTitleLabel: "এই সরবরাহকারী পণ্য",
		supplierOrderAmount: "সরবরাহকারীর অর্ডারের পরিমাণ",
	},

	createOrder: {
		pageTitle: "অর্ডার তৈরী করুন",
		buyerDetailsTitle: "ক্রেতার বিবরণ",
		sellerDetailsTitle: "বিক্রেতার বিবরণ",
		deliveryDetailsTitle: "পণ্য সরবরাহ",
		productItemTitle: "পণ্য আইটেম",
		costSummaryTitle: "খরচ সারসংক্ষেপ",
		paymentDetailsTitle: "অর্থ প্রদানের বিবরণ",
		paymentTermsTitle: "অর্থ প্রদানের শর্তাবলী",
		paymentMethodeTitle: "অর্থ প্রদানের পদ্ধতি",
		moqLabel: "সর্বনিম্ন অর্ডার",
		pcsLabel: "পিছ",
		subtotal: "সাব-টোটাল",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "মূল্যছাড়",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "আর্থিক সহায়তার সার্ভিস ফি",
		total: " মোট",
		immediatePayment: "তাৎক্ষণিক পেমেন্ট",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		immediatePaymentText: "এই অর্ডারের জন্য কোনও সার্ভিস চার্জ  প্রযোজ্য নয়",
		todayPaymentText1: "আজকের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		todayPaymentText2: "এই অর্ডারে যোগ করা হবে",
		threePaymentText1: "৩ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ ",
		threePaymentText2: "এই অর্ডারে যোগ করা হবে.",
		sevenPaymentText1: "৭ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		sevenPaymentText2: "এই অর্ডারে যোগ করা হবে",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		cashOnDeliveryText1: "নগদ অর্থ প্রদান করুন",
		cashOnDeliveryText2: "যখন পণ্য বিতরণ করা হয়",
		cash: "ক্যাশ",
		cashText: "নগদ অর্থ প্রদান করুন",
		bkashText: "বিকাশের মাধ্যমে অর্থ প্রদান করুন",
		nagadText: "নগদের মাধ্যমে অর্থ প্রদান করুন",
		number: "নম্বর",
		numberWarning:
			"এই নাম্বার ছাড়া অন্য কোন নাম্বারে টাকা পাঠালে সেটি বাতিল বলে গণ্য হবে",
		bankTransfer: "ব্যাংক ট্রান্সফার",
		bankTransferText1: "ব্যাংক ট্রান্সফার - পরিশোধ করুন",
		bankTransferText2: "রেফারেন্স হিসাবে 'অর্ডার নম্বর' সহ ব্যাংক অ্যাকাউন্ট",
		bankTransferText3: "ব্যাংকের নাম",
		bankTransferText4: "অ্যাকাউন্টের নাম",
		bankTransferText5: "অ্যাকাউন্টের নম্বর",
		bankTransferText6: "রাউট নম্বর",
		bankTransferText7: "শাখা",
		btnLabel: "নিশ্চিত",
		addMoreProductLabel: "আরও পণ্য যোগ করুন",
		selectProductLabel: "জমা দিন",
		cosgressTitle: "অভিনন্দন!",
		cosgressPara: "আপনার অর্ডার সম্পূর্ণ হয়েছে।",
		preOrderCongress:
			"আপনার প্রি-অর্ডার সম্পূর্ণ হয়েছে। শীগ্রই আপনার সাথে আমাদের প্রতিনিধি যোগাযোগ করবেন।",
		congressCancelText:
			"অর্ডার নিয়ে কোনো সমস্যা হলে রাত ১২ টার আগে বাতিল করুন। আপনি ১২ টার পরে অর্ডার বাতিল করতে পারবেন না।",
		congressProductLabel: "পরবর্তী",
		orderNow: "অর্ডার করুন",
		alert: "সতর্কতা",
		importentMessage: "গুরুত্বপূর্ণ বার্তা",
		deliveryDateMessage: "অনুগ্রহ করে ডেলিভারির তারিখ নির্বাচন করুন",
		paymentTermsMessage: "অনুগ্রহ করে অর্থপ্রদানের মেয়াদ নির্বাচন করুন",
		paymentMethodMessage: "অনুগ্রহ করে পেমেন্ট পদ্ধতি নির্বাচন করুন",
		backButtonMessage:
			"আপনার কার্টে কিছু আইটেম আছে, আপনি যদি এই পৃষ্ঠাটি ছেড়ে যান তবে আপনার কার্ট খালি হয়ে যাবে।",
		okBtnLabel: "ঠিক আছে",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		placeConformationMessage: "আপনি কি এই অর্ডার দেওয়ার বিষয়ে নিশ্চিত?",
		lessThenMoq: "ইনপুট করা আইটেম পরিমাণ সর্বনিম্ন অর্ডার পরিমাণের চেয়ে কম।",
		all: "সবগুলো",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		fsMandatoryMessage: "অনুগ্রহ করে আর্থিক সহায়তার পরিমাণ নির্বাচন করুন",
		fsCoupon: "আর্থিক সহায়তার ছাড়",
		downPayment: "প্রাথমিক ডাউন পেমেন্ট",
		fsServiceFee: "আর্থিক সহায়তার সার্ভিস ফি",
		fsPlaceholder: "আপনার প্রয়োজনীয় আর্থিক সহায়তার পরিমাণ লিখুন",
		fsWarning: "আর্থিক সহায়তার পরিমাণ সঠিক নয়",
		dpWarning1:
			"আপনার আর্থিক সহায়তার অনুরোধ পর্যালোচনা করা হচ্ছে। দয়া করে পরবর্তী কর্মদিবস পর্যন্ত অপেক্ষা করুন।",
		dpWarning2:
			"পেমেন্ট বিলম্বিত হলে নীচে একটি আনুমানিক পেনাল্টি ফি'র  চার্ট দেয়া আছে, চার্টটি অনুগ্রহ করে লক্ষ্য করুন। প্রকৃত পেনাল্টির পরিমাণের জন্য সর্বদা অর্ডারের বিবরণ পেজ  দেখুন।",
		orderId: "অর্ডার আইডি",
		dueAmount: "বকেয়ার পরিমাণ",
		dueLoanTitle:
			"একটি নতুন আর্থিক সহায়তার আগে আপনাকে অবশ্যই পূর্ববর্তী বকেয়া পরিশোধ করতে হবে।",
	},

	selectProduct: {
		pageTitle: "পণ্য নির্বাচন করুন",
		totalLabel: "মোট",
		btnLabel: "জমা দিন",
	},
	orderList: {
		pageTitle: "অর্ডার তালিকা",
		tabMenuRecent: "সবগুলো",
		tabMenuPlaced: "স্থাপিত",
		tabMenuAccepted: "গ্রহণ",
		tabMenuScDelivered: "ডেলিভারির জন্য নির্ধারিত",
		tabMenuPartialDelivered: "আংশিক ডেলিভারি",
		tabMenuDelivered: "ফুল ডেলিভারি",
		tabMenuReturn: "ফেরত পণ্য",
		tabMenuPartialPaid: "আংশিক পরিশোধ",
		tabMenuPaid: "ফুল পরিশোধ",
		tabMenuCancel: "বাতিল",
		tabMenuDeliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderItem: "অর্ডারকৃত পণ্য",
		seller: "বিক্রেতা",
		buyer: "ক্রেতা",
		createdBy: "তৈরি করেছেন",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		notFound: "কোন তালিকা পাওয়া যায়নি।",
	},
	orderDetails: {
		createdBy: "তৈরি করেছেন",
		placedLabel: "অর্ডার হয়েছে ",
		deliveredLabel: "ডেলিভারী সম্পন্ন হয়েছে",
		paidLabel: "পরিশোধিত",
		orderItemLabel: "অর্ডারকৃত পণ্য",
		castItemLabel: "খরচের বিবরণ",
		timelineLabel: "সময়রেখা",
		subtotal: "সাব-টোটাল",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "ডিসকাউন্ট",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: "মোট",
		paymentSettlementLabel: "পেমেন্ট সেটেলমেন্ট",
		date: "তারিখ",
		amount: "পরিমাণ",
		dueDate: "নির্দিষ্ট তারিখ",
		remark: "মন্তব্য",
		collectedBy: "দ্বারা সংগৃহীত",
		paymentDetailsLabel: "অর্থ প্রদানের বিবরণ",
		paymentMethod: "অর্থ প্রদানের পদ্ধতি",
		paymentTerms: "অর্থ প্রদানের শর্তাবলী",
		paymentDueDate: "অর্থ প্রদানের বাকি তারিখ",
		totalDue: "মোট বকেয়া",
		totalPaid: "মোট পরিশোধ",
		remaining: "অবশিষ্ট",
		deliveryDetailsLabel: "ডেলিভারির বিস্তারিত",
		selectTimeDate: "ডেলিভারির তারিখ ও সময় নির্ধারণ করুন",
		selectTime: "ডেলিভারির সময় নির্ধারণ করুন",
		selectDate: "ডেলিভারির তারিখ নির্ধারণ করুন",
		acceptOrder: "অর্ডার গ্রহণ করুন",
		cancelOrder: "অর্ডার বাতিল করুন",
		scheduleDelivery: "শিডিউল প্রস্তুত করুন",
		BuyerName: "ক্রেতার নাম",
		address: "ঠিকানা",
		phoneNumber: "মোবাইল নম্বর",
		email: "ইমেইল ",
		deliveryDate: "ডেলিভারি প্রদানের তারিখ",
		downloadInvoices: "চালান ডাউনলোড করুন",
		receiptDownloads: "রসিদ প্রিন্ট করুন",
		cancelConformationAsk: "আপনি কি নিশ্চিত আপনি এই অর্ডার বাতিল করতে চান?",
		cancelConformationParagraph:
			"আপনি এই অর্ডারটি বাতিল করতে পারবেন না, আপনি শুধুমাত্র একই দিনে আপনার অর্ডার বাতিল করতে পারবেন।",
		cancelConformation: "আপনি এই অর্ডারটি বাতিল করতে পারবেন না",
		yesBtn: "হ্যাঁ",
		noBtn: "না",
		withoutSettelment: "পেমেন্ট সম্পূর্ণ তথ্য এখানে দেখানো হবে।",
		bankInfoLabel: "পেমেন্ট ট্রান্সফারের জন্য ব্যাংক অ্যাকাউন্টের তথ্য",
		assignDriver: "ড্রাইভার নিয়োগ করুন",
		assignCollector: "কালেক্টর নিয়োগ করুন",
		assignedDriver: "ড্রাইভার নিযুক্ত হয়েছেন",
		assignedCollector: "কালেক্টর নিযুক্ত হয়েছেন ",
		notAssigned: "এখনো নিযুক্ত করা হয়নি",
		addDeliveryFee: "ডেলিভারি ফি যুক্ত করুন",
		addDiscount: "ডিসকাউন্ট যুক্ত করুন",
		feePlaceHolder: "ডেলিভারি ফি এমাউন্ট লিখুন",
		discountPlaceHolder: "ডিসকাউন্ট এমাউন্ট লিখুন",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		scheduleDeliveryDate: "সিডিউল ডেলিভারি তারিখ",
		scheduleDeliveryTime: "সিডিউল ডেলিভারি সময়",
		deliveryFailMessage: "ডেলিভারি ফেল মেসেজ",
		selectCancelOrder: "অর্ডার বাতিল করার কারণ নির্বাচন করুন",
		multipleTime: "একাধিকবার ডেলিভারি ব্যর্থ হয়েছে",
		productNotAvailable: "পণ্য স্টকে নেই",
		cannotFindAddress: "ঠিকানা খুঁজে পাওয়া যাচ্ছে না",
		noShop: "প্রতিষ্ঠাতার ঠিকানা কিন্তু সেখানে কোনো দোকান নেই",
		buyerNotAccept: "ক্রেতা অর্ডার মূল্য গ্রহণ করবে না",
		supplierNotGive: "সরবরাহকারী পণ্য দেয়নি",
		other: "অন্যান্য",
		yes: "হ্যাঁ",
		no: "না",
		deliveryTimeWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি তারিখ নির্বাচন করতে হবে, অন্যথায়, আমরা ডেলিভারির জন্য সময়সূচী প্রক্রিয়া করতে পারি না",
		deliveryDateWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি সময় নির্বাচন করতে হবে, অন্যথায়, আমরা ডেলিভারির জন্য সময়সূচী প্রক্রিয়া করতে পারি না",
		deliveryDateTimeWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি সময় নির্বাচন এবং একটি তারিখ করতে হবে",

		orderSearchLabel: "আপনার অর্ডারটি খুঁজুন",
		loanCollectionTitle: "আর্থিক সহায়তার সেটেলমেন্ট",
		transactionType: "ট্রানজেকশনের ধরণ",
		gateway: "পেমেন্ট গেটওয়ে",
		accountName: "অ্যাকাউন্টের নাম",
		accountNumber: "অ্যাকাউন্ট নম্বর",
		transactionNumber: "ট্রানজেকশন নম্বর",
		payEkkbaz: "পে একবাজ",
		close: "বন্ধ করুন",
		confirmDelivery: "ডেলিভারি সম্পুর্ন করুন",
		send: "সেন্ড করুন",
		receipt: "রশিদ",
		receiptNotProvided: "রশিদ প্রদান করা হয়নি",
		instantDelivery:
			"আপনি কি নিশ্চিত যে আপনি এই অর্ডারের জন্য ডেলিভারি সম্পূর্ণ করতে চান?",
		fss: "আর্থিক সহায়তা সেটেলমেন্ট",
		downPaymentLeft: "অগ্রিম পেমেন্ট অবশিষ্ট",
		default: "ডিফল্ট",
		first: "১ম",
		second: "২য়",
		penaltyFee: "জরিমানা ফি",
		due: "নির্ধারিত তারিখ",
		third: "৩য়",
		immediatePayment: "তাত্ক্ষণিক পেমেন্ট",
		inSettlement: "ইন সেটেলমেন্ট",
		closeSettlement: "ক্লোজ সেটেলমেন্ট",
		approve: "অনুমোদিত",
		downPayment: "অগ্রিম পরিশোধ",
		sellerPayment: "বিক্রেতার পেমেন্ট",
		delivered: "ডেলিভারি",
		repaid: "রিপেইড",
		amountWarning: "প্রকৃত পরিমাণ পরিবর্তিত হতে পারে।",
		penaltyHeader: "ডিফল্ট পেনাল্টি শিডিউল",
	},

	businessCreateName: {
		pageTitle: "ব্যবসার নাম",
		businessName: "ব্যবসার নাম",
		businessNamePlaceholder: "ব্যবসার নাম দিন",
		paragraph:
			"আপনার ব্যবসা তৈরি করতে ৩ ধাপ দূরে আছি আমরা। আপনার ব্যবসার নাম দিয়ে শুরু করা যাক।",
		btnLabel: "পরবর্তী",
		submit: "জমা দিন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		errorLabel: "ব্যবসায়ের নাম লিখুন!",
		congratulation: "অভিনন্দন!",
		congratulationMessage: "আপনার ব্যবসা সফলভাবে তৈরি হয়েছে",
		kycInformation:
			"আপনার কেওয়াইসি সাবমিট করা হয় নাই, দয়া করে আপনার কেওয়াইসি সাবমিট করুন",
		freeTrail: "ফ্রি ট্রায়াল শুরু করুন",
		kycSubmit: "কেওয়াইসি সাবমিট করুন",
		pending:
			"কেওয়াইসি সাবমিট করার জন্য ধন্যবাদ। আপনার কেওয়াইসি ভেরিফিকেশন চলছে, অনুগ্রহ করে অপেক্ষা করুন।",
		goBack: "ফিরে যাও",
		trialStart: "অভিনন্দন! ট্রায়াল একাউন্ট চালু হয়েছে।",
	},
	businessType: {
		pageTitle: "ব্যবসার ধরণ",
		paragraph: "আপনার ব্যবসার ধরণ নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		miniMart: "মিনি মার্ট",
		superMart: "সুপার মার্ট",
		pharmacy: "ফার্মেসি",
		cart: "কার্ট",
		bazaarStore: "বাজার দোকান",
		hawkerCenter: "হকার সেন্টার",
		restaurant: "রেস্টুরেন্ট",
		hotel: "হোটেল",
		cosmeticsStore: "প্রসাধনী দোকান",
		nonFmcg: "নন এফএমসিজি",
		office: "অফিস",
		NGO: "এনজিও",
		entertainmentPlace: "বিনোদনের স্থান",
		religiousOrganization: "ধর্মীয় সংগঠন",
		spa: "স্পা",
		salon: "সেলুন",
		gym: "জিম",
		electronicsStore: "ইলেক্ট্রনিক্স স্টোর",
		shoeStore: "জুতার দোকান",
		fashionStore: "ফ্যাশন স্টোর",
		laundryStore: "লন্ড্রি স্টোর",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "পরবর্তী",
		validationLabel: "একটি ব্যবসার ধরন নির্বাচন করুন",
		producer: "প্রোডিউসার",
		reseller: "রিসেলার",
	},
	businessLocation: {
		pageTitle: "ব্যবসার অবস্থান",
		paragraph: "আপনার ব্যবসার যোগাযোগের বিস্তারিত লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		email: "ইমেইল",
		emailPlaceholder: "আপনার ইমেল লিখুন",
		userName: "ব্যবহারকারীর নাম",
		userNamePlaceholder: "ব্যবহারকারীর নাম লিখুন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "সম্পন্ন করুন",
		successLabel: "ব্যবসা তৈরি করার জন্য অভিনন্দন",
		errorLabel: "ব্যবসা তৈরি ব্যর্থ হয়েছে",
		validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
		loader: "দয়া করে অপেক্ষা করুন...",
		validationCityLabel: "অনুগ্রহ করে শহর পূরণ করুন.",
		validationPostCodeLabel: "অনুগ্রহ করে পোস্টাল কোড পূরণ করুন.",
		locationValidation: "মানচিত্র থেকে অবস্থান চয়ন করুন",
		validationEmailLabel: "অনুগ্রহ করে ইমেইল পূরণ করুন.",
	},
	locationPicker: {
		pageTitle: "দোকান অবস্থান অনুসন্ধান",
		btnLabel: "নিশ্চিত করুন",
		paragraph: `মেপ এর মার্কারটির অবস্থা পরিবর্তন করে আপনার ব্যবসার অবস্থান নির্বাচন করুন।`,
	},
	location: {
		title: "আপনার অবস্থান সক্রিয় করুন",
		paragraph:
			"এই অ্যাপটির জন্য আপনার ডিভাইস এবং এই অ্যাপের জন্য লোকেশন পরিষেবা চালু থাকা প্রয়োজন। এই অ্যাপটি ব্যবহার করার আগে আপনাকে অবশ্যই সেগুলিকে সক্রিয় করতে হবে।",
		btnLabel: "শুধুমাত্র অ্যাপ ব্যবহার করার সময় অনুমতি দিন",
	},
	businessSelect: {
		pageTitle: "ব্যবসা নির্বাচন করুন",
	},
	pwaDownload: {
		header: "EkkBaz অ্যাপ ডাউনলোড করুন",
		paragraph:
			"অ্যাপটি ডাউনলোড করুন এবং এখন থেকে যেকোনো সময় আপনার সরবরাহকারীর কাছ থেকে বাল্ক অর্ডার করুন।",
		cancelBtn: "বাতিল করুন",
		downloadBtn: "ডাউনলোড করুন",
	},
	businessProfile: {
		pageTitle: "ব্যবসায়িক প্রোফাইল সেটিংস",
		businessDetailsLabel: "বিস্তারিত",
		registrationNo: "নিবন্ধনের নম্বর",
		taxNo: "টেক্স/ভ্যাট নম্বর",
		mobileNo: "মোবাইল নম্বর",
		officeNo: "অফিস নম্বর",
		email: "ইমেইল",
		website: "ওয়েবসাইট",
		branchLabel: "শাখা তালিকা",
		paymentDetailsLabel: "পেমেন্ট বিবরণ",
		bankInfoLabel: "ব্যাংক তথ্য",
		bankName: "ব্যাংকের নাম",
		branchName: "ব্যাংকের শাখা",
		branchAddress: "ব্যাংকের ঠিকানা",
		swiftCode: "ব্যাংক সুইফট কোড",
		acName: "ব্যাঙ্কের A/C নাম",
		acNumber: "ব্যাংক এ/সি নম্বর",
		bkashInfoLabel: "বিকাশ তথ্য",
		bkashAcName: "বিকাশ এ/সি নাম",
		bkashAcNo: "বিকাশ এ/সি নম্বর",
		nagadInfoLabel: "নগদ তথ্য",
		nagadAcName: "নাগদ এ/সি নাম",
		nagadAcNo: "নাগদ এ/সি নম্বর",
		upayInfoLabel: "উপাই তথ্য",
		upayAcName: "উপাই এ/সি নাম",
		upayAcNo: "উপাই এ/সি নম্বর",
		verified: "যাচাই",
		unVerified: "যাচাই করা হয়নি",
		editBusinessMenu: "ব্যবসা সম্পাদনা করুন",
		paymentTermsSettings: "কাষ্টমার পেমেন্ট শর্তাবলী সেটিং",
		addPayMenu: "পেমেন্ট অপশন যোগ করুন ",
		setLocation: "ব্যবসার লোকেশন আপডেট করুন",
		addMenu: "শাখা যোগ করুন",
		verifyBusinessMenu: "ব্যবসা যাচাই করুন",
		bankInfoNotFound: "ব্যাংক তথ্য পাওয়া যায়নি",
		deleteBusiness: "ব্যবসা মুছে ফেলুন",
		deleteWarning:
			"আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		otpSubmit: "ওটিপি প্রদান",
		confirmDeleteWarning:
			"আবার চিন্তা করুন! আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		switchBusiness: "ব্যবসা পরিবর্তন করুন",
	},
	paymentSettings: {
		pageTitle: "কাষ্টমার পেমেন্ট শর্তাবলী সেটিং",
		mainTitle: "আপনার উপযুক্ত অর্থপ্রদান শর্তাবলী নির্বাচন করুন",
		subTitle:
			"আপনি এখানে যে অর্থপ্রদানের শর্তাবলী নির্বাচন করেছেন, গ্রাহক অর্ডার করার সময় শুধুমাত্র এই অর্থপ্রদান শর্তাবলী ব্যবহার করতে পারবেন",
		btnLabel: "সেভ করুন",
		payNow: "এখনই অর্থ প্রদান করুন",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		fourteenDays: "১৪ দিনের মধ্যে অর্থ প্রদান করুন",
		thirtyDays: "৩০ দিনের মধ্যে অর্থ প্রদান করুন",
	},
	businessEdit: {
		pageTitle: "ব্যবসা সম্পাদনা করুন",
		uploadPhoto: "ছবি আপলোড",
		email: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		resigter: "নিবন্ধনের নম্বর",
		resigterPlaceholder: "নিবন্ধনের নম্বর লিখুন",
		tex: "টেক্স/ভ্যাট নম্বর",
		texPlaceholder: "টেক্স/ভ্যাট নম্বর লিখুন",
		mobile: "মোবাইল নম্বর",
		mobilePlaceholder: "মোবাইল নম্বর লিখুন",
		office: "অফিস নম্বর",
		officePlaceholder: "অফিস নম্বর লিখুন",
		website: "ওয়েবসাইট",
		websitePlaceholder: "ওয়েবসাইট লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	bankDetails: {
		referenceNumber: "পরিচিত সংখ্যা",
		bankName: "ব্যাংকের নাম",
		accountName: "A/C নাম",
		accountNumber: "A/C নম্বর",
		routeNumber: "রুট নম্বর",
		branch: "শাখা",
	},
	invoice: {
		title: "চালান",
		receipt: "রসিদ",
		buyerDetails: "ক্রেতার বিবরণ",
		sellerDetails: "বিক্রেতার বিবরণ",
		description: "বর্ণনা",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "সাব-টোটাল",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "স্থাপিত",
		name: "নাম",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "সাব-টোটাল",
		tax: "ট্যাক্স",
		discount: "ছাড়",
		creditFee: "সার্ভিস চার্জ ",
		deliveryFee: "ডেলিভারি করার টাকা",
		total: "মোট",
		paid: "পরিশোধিত",
		totalDue: "মোট বাকি",
		paymentTerms: "পরিশোধের শর্ত",
		days: "দিন",
		paymentMethod: "মূল্যপরিশোধ পদ্ধতি",
		referenceNumber: "ব্যাঙ্ক রেফারেন্স নম্বর",
		deliveryDate: "ডেলিভারির তারিখ ",
		dueDate: "পরিশোধযোগ্য তারিখ",
		printTime: "মুদ্রণের সময়",
		conditionsCreditFee: "অর্থপ্রদানের শর্তাবলী এবং সার্ভিস চার্জ ",
		immediatePayment: "জরুরী টাকা প্রদান",
		cashOnDelivery: "প্রদানোত্তর পরিশোধ",
		atNight: "ডেলিভারি দিবসের রাতে",
		payInthreeDay: "3 দিনের মধ্যে পেমেন্ট করুন",
		payInSevenDay: "7 দিনের মধ্যে পেমেন্ট করুন",
		afterSeven: "৭ দিন পর (প্রতিদিনের জন্য অতিরিক্ত)",
	},
	landing: {
		header1: "Transform your procurement team ",
		header2: "with Agentic Systems.",
		header3: "পরে পরিশোধ করুন!",
		login: "প্রবেশ করুন",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malay: "Malay",
		retail: "দোকানদার",
		manufacture: "সাপ্লাইয়ার",
		hotline: "হট লাইন",
		phoneNumber: "ফোন নম্বর",
		phoneNumberPlaceHolder: "ফোন নম্বর লিখুন",
		brandHeader: " আমাদের সাথে কাজ করা ব্র্যান্ড গুলো",
		fAbout: "সহজেই পণ্য বিক্রি করুন পাইকারি বাজারে।",
		location: "অবস্থান",
		singapore: "সিঙ্গাপুর অফিস",
		bangladesh: "বাংলাদেশ অফিস",
		bOfficeAddress: "১৩ তলা, রাজ্জাক প্লাজা, মগ বাজার মোড়, ঢাকা-১২০৫",
		otherInfo: "অন্যান্য তথ্য",
		news: "খবর",
		support: "সাপোর্ট",
		corporate: "কর্পোরেট",
		refundPolicy: "রিফান্ড পোলেসি",
		termsCondition: "ট্রার্ম & কন্ডিশন",
		privacyPolicy: "প্রাইভেসি পোলেসি",
		contactUs: "যোগাযোগ করুন",
		messageUs: "মেসেজ করুন",
		facebook: "ফেসবুক",
		sales: "বিক্রয়",
		media: "মিডিয়া",
		downloadBtn: "ডাউনলোড করুন",
		backers: "আমাদের ব্যাকার্স",
		benefits: "আপনার সুবিধা",
		discover: "সর্বশেষ একবাজ খবর",
		benefitsOne: "বৃদ্ধি বিক্রয়",
		partners: "আমাদের অংশীদার",
		benefitsDisOne: "",
		benefitsTwo: "সহজেই গ্রাহকের কাছে পৌঁছান",
		benefitsDisOneTwo: "",
		benefitsThree: "পণ্যের দৃশ্যমানতা বাড়ান",
		benefitsDisOneThree: "",
		benefitsFore: "আপনার বিক্রয় প্রক্রিয়া ডিজিটালাইজ করুন",
		benefitsDisOneFore: "",
		seeMore: "আরো দেখুন",
		downloadApp: "অ্যাপ ডাউনলোড করুন এখনই !",
		apiBtn: "Join Waitlist",
		logIn: "Login",
		ourTeam: "Our Team",
	},
	employees: {
		pageTitle: "কর্মী তালিকা",
		createNewEmployees: "নতুন কর্মী তৈরী করুন",
		searchPlaceHolder: "আপনার কর্মী খুঁজুন",
		collectorList: "কালেক্টরের তালিকা",
		collectorSearch: "কালেক্টর অনুসন্ধান করুন",
	},
	employeesDetails: {
		pageTitle: "কর্মীর বিবরণ",
	},
	createEmployees: {
		pageTitle: "নতুন কর্মী তৈরী করুন",
		customerNoHolder: "মোবাইল নম্বর লিখুন",
		employeeNumber: "কর্মীর নম্বর",
		name: "নাম",
		namePlaceholder: "কর্মীর নাম লিখুন",
		confirmBtn: "নিশ্চিত করুন",
		selectEmployees: "কর্মী নির্বাচন করুন",
		nameError: "অনুগ্রহ করে কর্মীর নাম লিখুন",
		roleError: "অনুগ্রহ করে কর্মীর রোল নির্বাচন করুন",
		edit: "ইডিট করুন",
		delete: "ডিলিট করুন",
		editEmployee: "ইডিট কর্মী",
		deleteConformationAsk: "আপনি কি এই কর্মীকে মুছে দেওয়ার বিষয়ে নিশ্চিত?",
		updateEmployee: "কর্মী আপডেট",
	},
	productList: {
		pageTitle: "পণ্য তালিকা",
		offerPageTitle: "অফার তালিকা",
		searchYourProduct: "পণ্য অনুসন্ধান করুন",
		brandList: "ব্র্যান্ড তালিকা",
		createProduct: "পণ্য তৈরি করুন",
		createPack: "প্যাক তৈরি করুন",
		createOffer: "অফার তৈরি করুন",
		product: "পণ্য",
		pack: "প্যাক",
		Offer: "অফার",
		discount: "ছাড়",
		all: "সবগুলো",
	},
	productDetails: {
		pageTitle: "পণ্যের বিবরণ",
		offerStart: "অফার শুরুর তারিখ",
		offerEnd: "অফার শেষ হওয়ার তারিখ",
		moqLabel: "সর্বনিম্ন অর্ডার",
		consumerLabel: "ভোক্তা মূল্য (এমআরপি)",
		profitLabel: "লাভ",
		productDetailTitle: "পণ্যের বিবরণ",
		expireData: "মেয়াদ উত্তীর্ণের তারিখ",
		manufactureData: "উত্পাদন তারিখ",
		productWeight: "পণ্যের ওজন",
		packingType: "প্যাকিং টাইপ",
		importedBy: "দ্বারা আমদানীকৃত",
		manufacturedBy: "দ্বারা নির্মিত",
		country: "দেশ",
		descriptionLabel: "বর্ণনা",
		btnLabel: "অর্ডার করুন",
		inStock: "ইন স্টক",
		outStock: "স্টক আউট",
		description: "বর্ণনা",
		inventory: "স্টক",
		unpublished: "আনপাবলিশ",
		published: "পাবলিশ",
		edit: "এডিট",
		delete: "মুছে ফেলুন",
		deleteText: "আপনি কি এই পণ্যটি  মুছে দেওয়ার বিষয়ে নিশ্চিত?",
		myProfit: "আমার লাভ",
		buyerProfit: "ক্রেতার লাভ",
		skuId: "এসকেউ আইডি",
		preOrderBtn: "প্রি-অর্ডার করুন",
		preOrderQuantity: "প্রি-অর্ডারের পরিমাণ লিখুন",
		searchTram: "অনুসন্ধানের ট্রাম",
	},
	brandList: {
		pageTitle: "ব্র্যান্ড তালিকা",
		searchBrand: "নাম অনুসারে ব্র্যান্ড খুঁজুন",
		searchProduct: "নাম অনুসারে ব্র্যান্ডের পণ্য অনুসন্ধান করুন",
		myBrand: "আমার ব্র্যান্ড",
		allBrand: "সব ব্র্যান্ড",
		createNewBrand: "নিজের ব্র্যান্ড তৈরি করুন",
		importBrand: "অন্যের ব্র্যান্ড ইমপোর্ট করুন",
		brandList: "ব্র্যান্ড তালিকা",
		products: "টি পণ্য",
		details: "বিস্তারিত",
		distributorCode: "ডিস্ট্রিবিউটর কোড",
	},
	brandDetails: {
		pageTitle: "ব্র্যান্ডের বিবরণ",
		sku: "এসকেইউ",
		import: "ইম্পর্ট",
		edit: "এডিট",
		delete: "ডিলিট",
		skusLabel: "এসকেইউ",
		descriptionLabel: "বর্ণনা",
		productTitleLabel: "এই ব্র্যান্ড পণ্য",
		deleteConformationAsk:
			"আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড মুছে ফেলতে চান?",
		request: "দাম দেখানোর অনুরোধ",
	},
	createNewBrand: {
		pageTitle: "নতুন ব্র্যান্ড তৈরি করুন",
		uploadNewLogo: "নতুন লোগো আপলোড করুন",
		brandName: "ব্র্যান্ড নাম",
		brandNamePlaceHolder: "আপনার ব্র্যান্ড নাম লিখুন",
		selectCountry: "দেশ নির্বাচন করুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার ব্র্যান্ড বিবরণ লিখুন দয়া করে",
		createdBy: "ক্রিয়েটেড বায়",
		complete: "সম্পূর্ণ",
		congratulation: "অভিনন্দন",
		congratulationParagraph: "নতুন ব্র্যান্ড তৈরি সফলভাবে হয়েছে",
		continue: "কন্টিনিউ",
		conformModal: "আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড তৈরি করতে চান?",
		importBrandConformations:
			"আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড আমদানি করতে চান?",
		errorMessage: "আপনি 1 MB এর বেশি ছবি আপলোড করতে পারবেন না",
		imageFormatError:
			"অবৈধ ছবি বিন্যাস। শুধুমাত্র JPEG, PNG এবং JPG অনুমোদিত।",
		ownerName: "ব্র্যান্ডের আইনি মালিক",
		ownerNamePlaceholder: "আইনি মালিকের নাম লিখুন",
		isLegalOwner: "আমার কোম্পানি আইনত এই ব্র্যান্ডের মালিক",
		ownerNumber: "ফোন নাম্বার",
		ownerNumberPlaceholder: "অনুগ্রহ করে আপনার ফোন নাম্বারটি প্রদান করুন",
	},
	updateBrand: {
		pageTitle: "ব্র্যান্ড আপডেট করুন",
		congratulationParagraph: "ব্র্যান্ড আপডেট সফলভাবে হয়েছে",
		conformModal: "আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড আপডেট করতে চান?",
	},
	createProduct: {
		pageTitle: "পণ্য তৈরি করুন",
		productWarning: "পণ্যের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		productPhoto: "পণ্যের ছবি",
		productPhotoUpload: "পণ্যের ছবি আপলোড করুন",
		uploadPhoto: "ছবি আপলোড",
		uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
		takePhoto: "ছবি তোল",
		productName: "পণ্যের নাম",
		productNamePlaceHolder: "আপনার পণ্যের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার পণ্য বিবরণ লিখুন দয়া করে",
		selectBrand: "ব্র্যান্ড নির্বাচন করুন",
		selectDepartment: "ডিপার্টমেন্ট নির্বাচন করুন",
		selectCategory: "ক্যাটাগরি নির্বাচন করুন",
		selectSubCategory: "সাব-ক্যাটাগরি নির্বাচন করুন",
		setPrice: "মূল্য নির্ধারণ করুন",
		sizeDetermine: "আকার নির্ধারণ করুন",
		inventoryDetermine: "স্টক নির্ধারণ",
		manufacturingDate: "প্রস্তুতকরণ তারিখ",
		expireData: "মেয়াদোত্তীর্ণ ডেটা",
		ManufacturerInformation: "প্রস্তুতকারকের তথ্য",
		save: "সেভ করুন",
		publish: "পাবলিশ করুন",
		purchasePrice: "ক্রয় মূল্য",
		purchasePricePlaceHolder: "ক্রয় মূল্য লিখুন",
		sellingPrice: "বিক্রয় মূল্য",
		sellingPricePlaceHolder: "বিক্রয় মূল্য লিখুন",
		priceWarning: "আপনার মূল্য খালি",
		customerPrice: "গ্রাহক মূল্য (MRP)",
		customerPricePlaceHolder: "গ্রাহক মূল্য লিখুন",
		taxVat: "ট্যাক্স/ভ্যাট",
		taxVatPlaceHolder: "ট্যাক্স/ভ্যাট লিখুন",
		moqPlaceHolder: "ন্যূনতম অর্ডারের পরিমাণ লিখুন",
		moq: "ন্যূনতম অর্ডারের পরিমাণ (MOQ)",
		weight: "ওজন",
		weightPlaceholder: "পণ্যের ওজন লিখুন",
		grossWeight: "মোট ওজন",
		grossWeightPlaceholder: "মোট ওজন লিখুন",
		weightWarning: "ওজন খালি",
		measurement: "মাপা",
		weightUnits: "ওজন ইউনিট",
		units: "ইউনিট",
		packingType: "প্যাকিং টাইপ",
		ok: "ঠিক আছে",
		manufacturedCountry: "উৎপাদিত দেশ",
		manufacturedBy: "উৎপাদকের নাম",
		manufacturedByPlaceHolder: "উৎপাদকের নাম লিখুন",
		importedBy: "আমদানিকারকের নাম",
		importedByPlaceHolder: "আমদানিকারকের নাম লিখুন",
		distributedBy: "বিতরণকারকের নাম",
		distributedByPlaceHolder: "বিতরণকারকের নাম লিখুন",
		initialInventory: "প্রাথমিক স্টক",
		initialInventoryPlaceHolder: "প্রাথমিক স্টক লিখুন",
		skuPlaceholder: "এসকেইউ লিখুন",
		sku: "এসকেইউ",
		empty: "খালি",
		barCode: "বার কোড",
		scan: "স্ক্যান",
		width: "প্রস্থ",
		widthPlaceholder: "প্রস্থ লিখুন",
		height: "উচ্চতা",
		heightPlaceholder: "উচ্চতা লিখুন",
		length: "দৈর্ঘ্য",
		lengthPlaceholder: "দৈর্ঘ্য লিখুন",
		searchDepartment: "নাম দিয়ে ডিপার্টমেন্ট সার্চ করুন",
		searchCategory: "নাম দিয়ে ক্যাটাগরি  সার্চ করুন",
		searchSubCategory: "নাম দিয়ে সাব-ক্যাটাগরি সার্চ করুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য তৈরি করতে চান?",
		updateConformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য আপডেট করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য সেভ করতে চান?",
		updateBtn: "আপডেট করুন",
		updateMessage: "পাবলিশ করার জন্য প্রোডাক্টের নাম, দাম, স্টক থাকতেই হবে।",
		publishMessage: "আপনি কি নিশ্চিত আপনি এই পণ্য পাবলিশ করতে চান?",
		unPublishMessage: "আপনি কি নিশ্চিত আপনি এই পণ্য আনপাবলিশ করতে চান?",
		englishName: "অনুসন্ধানের জন্য ইংরেজি নাম",
		englishNamePlaceholder: "অনুসন্ধানের নাম লিখুন",
		productCreated: "পণ্য সফলভাবে তৈরি হয়েছে !",
		finalGrossWeight: "চূড়ান্ত ডেলিভারি ওজন",
		grossWeightUnit: "মোট ওজন ইউনিট",
		productUpdated: "পণ্য সফলভাবে আপডেট হয়েছে !",
	},
	updateProduct: {
		pageTitle: "পণ্য আপডেট করুন",
	},
	createPack: {
		pageTitle: "প্যাক তৈরি করুন",
		packWarning: "প্যাকের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		packName: "প্যাকের নাম",
		packNamePlaceHolder: "অনুগ্রহ করে আপনার প্যাকের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার প্যাকের বিবরণ লিখুন",
		selectProduct: "পণ্যে নির্বাচন করুন",
		qtyPack: "প্যাকে পরিমাণ",
		qtyPackPlaceholder: "প্যাকে পরিমাণ লিখুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই প্যাক তৈরি করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই প্যাক সেভ করতে চান?",
		productCreated: "প্যাক সফলভাবে তৈরি হয়েছে !",
		productUpdated: "প্যাক সফলভাবে আপডেট হয়েছে !",
	},
	createOffer: {
		pageTitle: "অফার তৈরি করুন",
		offerWarning: "অফারের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		offerName: "অফারের নাম",
		offerNamePlaceHolder: "অনুগ্রহ করে আপনার অফারের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার অফারের বিবরণ লিখুন",
		selectProduct: "অফার নির্বাচন করুন",
		qtyOffer: "অফারের পরিমাণ",
		qtyOfferPlaceholder: "অফারের পরিমাণ লিখুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই অফার তৈরি করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই অফার সেভ করতে চান?",
		offerStartDate: "অফার শুরুর তারিখ",
		offerEndDate: "অফার শেষ হওয়ার তারিখ",
		selectedItem: "নির্বাচিত পণ্য",
		totalProductCount: "মোট পণ্য",
		totalAmount: "সর্ব মোট",
		spWarnings: "আপনাকে একটি পণ্য নির্বাচন করতে হবে",
		closeButton: "বন্ধ করুন",
		offerCreated: "অফার সফলভাবে তৈরি হয়েছে !",
		offerUpdated: "অফার সফলভাবে আপডেট হয়েছে !",
	},
	updateOffer: {
		pageTitle: "অফার আপডেট করুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই অফার আপডেট করতে চান?",
	},
	dashboard: {
		statusLable: "অর্ডারের অবস্থা",
		today: "আজ",
		yesterday: "গতকাল",
		thisWeek: "এই সপ্তাহ",
		thisMonth: "এই মাস",
		thisYear: "এই বছর",
		lastWeek: "গত সপ্তাহে",
		lastMonth: "গত মাসে",
		lastYear: "গত বছর",
		custom: "কাস্টম",
		selectDay: "নির্দিষ্ট দিন",
		Order: "অর্ডার",
		totalOrder: "মোট অর্ডার",
		totalOrderAmount: "মোট অর্ডারের পরিমাণ",
		fsDueAmount: "আর্থিক সহায়তা বকেয়া",
		placed: "স্থাপিত",
		accepted: "গ্রহণ",
		scDelivered: "ডেলিভারির জন্য নির্ধা..",
		partialDelivered: "আংশিক ডেলিভারি",
		delivered: "ফুল ডেলিভারি",
		return: "ফেরত পণ্য",
		partialPaid: "আংশিক পরিশোধ",
		paid: "ফুল পরিশোধ",
		totalPaid: "মোট পরিশোধ",
		cancel: "বাতিল",
		deliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderAmount: "অর্ডার এমাউন্ট",
		totalAmount: "মোট এমাউন্ট",
		collectedAmount: "কলেক্টেড এমাউন্ট",
		totalDue: "মোট বাকি",
		directOrder: "ডাইরেক্ট অর্ডার",
		directOrderAmount: "ডাইরেক্ট অর্ডার এমাউন্ট",
		ekkHeroOrder: "EkkHero অর্ডার",
		ekkHeroOrderAmount: "EkkHero অর্ডার এমা..",
		cancelOrderAmount: "বাতিল অর্ডার এমাউন্ট",
		seeDetails: "বিস্তারিত দেখুন",
		period: "সময়কাল",
		perviousWeek: "আগের সপ্তাহ",
		nextWeek: "পরের সপ্তাহে",
		supplierOrder: "সাপ্লাইয়ার অর্ডার",
		customerOrder: "কাষ্টমার অর্ডার",
		preview: "প্রিভিউ",
		promoCode: "প্রোমো কোড",
		monthlyOrder: "চলতি মাসের অর্ডার",
		dueAmount: "বাকি",
		dueDate: "বকেয়ার তারিখ",
		orderNumber: "অর্ডার নাম্বার",
	},
	kycVerification: {
		imageWarning: "আপনি 10 MB এর বেশি ছবি আপলোড করতে পারবেন না৷",
		imageUpload: "এখানে আপনার ছবি আপলোড করুন, অথবা ব্রাউজ করুন",
		verifyUser: "ব্যবহারকারী যাচাই",
		nidFront: "জাতীয় পরিচয়পত্র - সামনের দিক",
		nidBack: "জাতীয় পরিচয়পত্র - পিছনের দিক",
		uploadNidFront: "আপনার জাতীয় পরিচয়পত্র এখানে আপলোড করুন",
		uploadNidBack: "আপনার জাতীয় পরিচয়পত্র এখানে আপলোড করুন",
		selfie: "দয়া করে আপনার সেলফি তুলুন",
		selfieLabel: "সেলফি তুলুন",
		complete: "আপলোড সম্পূর্ণ",
		pleaseCheck: "চেক করুন",
		next: "পরবর্তী",
		button: "বাটন",
		upload: "আপলোড করুন",
		nid: "জাতীয় পরিচয়পত্র",
		copy: "কপি",
		tradeLicense: "ট্রেড লাইসেন্স/ব্যবসার নিবন্ধন",
		uploadTradeLicense:
			"আপনার ট্রেড লাইসেন্স বা ব্যবসার নিবন্ধন এখানে আপলোড করুন",
		proofAddressLabel1: "ঠিকানার প্রমাণ",
		proofAddressLabel2:
			"বিভিন্ন সেবা বিল (যেমন: পানি, বৈদ্যুতিক বিদ্যুত, গ্যাস বা ব্যাংক বিবৃতি)",
		proofAddressPlaceholder: "আপনার বিভিন্ন সেবা বিলের প্রমাণ আপলোড করুন",
		taxLabel: "করদাতা সনাক্তকরণ নম্বর (টিআইএন)",
		taxPlaceholder: "আপনার করদাতা সনাক্তকরণ নম্বর (টিআইএন) আপলোড করুন",
		brandAgreementLabel: "ব্র্যান্ড চুক্তির পত্র",
		brandAgreementPlaceholder: "আপনার ব্র্যান্ড চুক্তির পত্র আপলোড করুন",
		ebsAgreementPageTitle: "Agreement & Contract Paper",
		ebCompanyTitle: "EkkBaz Bangladesh Pvt. Ltd",
		giveLater: "আমি এই তথ্য পরে দেব",
		propertyType: "সম্পত্তির প্রকার",
		rented: "ভাড়া",
		own: "নিজের",
		rentalAgreement: "মালিকের সাথে ভাড়া চুক্তি/ দলিল",
		ownAgreement: "	মালিকানা চুক্তি/ দলিল",
		rentalDocument: "ভাড়া চুক্তির নথি",
		ownDocument: "নিজস্ব সম্পত্তির কাগজ",
		startDate: "ভাড়া শুরুর তারিখ",
		rentPerMonth: "প্রতি মাসে ভাড়া",
		rentPerMonthPlaceholder: "প্রতি মাসে আপনার দোকান ভাড়া লিখুন",
		select: "অনুগ্রহ করে, আপনার নির্বাচন করুন",
		fillup: "এবং আপনার পূরণ করুন",
		amount: "পরিমাণ",
		click: "ক্লিক করুন",
		submit: "জমা দিন",
		nidNo: "জাতীয় পরিচয়পত্র নম্বর",
		nidNoPlaceholder: "আপনার জাতীয় পরিচয়পত্র নম্বর লিখুন",
		tradeLicenseNo: "ট্রেড বা ব্যবসা লাইসেন্স নম্বর",
		tradeLicensePlaceholder: "দয়া করে ট্রেড/ব্যবসা লাইসেন্স নম্বর লিখুন",
		tinNumber: "টিআইএন নম্বর",
		tinNumberPlaceholder: "আপনার টিআইএন নম্বর লিখুন দয়া করে",
		brandSelectLabel: "ব্র্যান্ড নির্বাচন করুন",
		brandSelectPlaceholder: "ব্র্যান্ড নির্বাচন করুন",
		termsConditionPageTitle: "EkkBaz টার্মস & কন্ডিশনস",
		termsLabel: "টার্মস & কন্ডিশনস",
		privacyLabel: "প্রাইভেসী পলিসি",
		refundPolicy: "রিফান্ড পলিসি",
		acceptCheckbox:
			"আমি স্বীকার করছি যে, আমি উপরোক্ত নথিগুলি পেয়েছি, পড়েছি এবং বুঝেছি এবং আমি সমস্ত শর্তগুলি মেনে চলতে সম্মত আছি।",
	},
	restrict: {
		pageTitle: "সংরক্ষিত এলাকা",
		warning: `স্টাফ, কালেক্টর, এবং ড্রাইভার অ্যাকাউন্ট এখানে লগইন করতে পারবেন না ! আপনার রোল পরিবর্তন করুন বা অ্যাডমিনকে লগইন করতে বলুন।`,
		btnClick: "হোম যেতে নীচের সবুজ বোতামে ক্লিক করুন",
	},
	notification: {
		pageTitle: "বিজ্ঞপ্তি",
		customer: "কাস্টমার",
		supplier: "সাপ্লায়ার",
		update: "আপডেট",
		supllierPageTitle: "সাপ্লায়ার থেকে বিজ্ঞপ্তি",
	},
	orderSummary: {
		orderProductPageTitle: "অর্ডার পণ্য সারাংশ",
		orderProductDetailsPageTitle: "অর্ডার পণ্য সারাংশ বিবরণ",
		driverProductPageTitle: "অর্ডার ড্রাইভার পণ্য সারাংশ",
		orderDriverProductDrilldown: "অর্ডার ড্রাইভার পণ্য সারাংশ বিবরণ",
		driverDeliveryPageTitle: "অর্ডার ড্রাইভার ডেলিভারি সারাংশ",
		deliveryAmountPageTitle: "অর্ডার ডেলিভারি পরিমাণ সারাংশ",
		orderDeliveryAmountDrilldown: "অর্ডার ডেলিভারি পরিমাণ বিবরণ",
		buyerSummary: "ক্রেতার সারাংশ",
		buyerSummaryDrillDown: "ক্রেতার সারাংশ বিবরণ",
		delayInPayment: "পেমেন্টে বিলম্ব",
		delayInPaymentDetails: "পেমেন্টে বিলম্ব বিবরণ",
		productTitle: "পণ্যের শিরোনাম",
		qty: "পরিমাণ",
		orderNo: "অর্ডার নম্বর",
		date: "তারিখ",
		status: "স্ট্যাটাস",
		product: "পণ্য",
		driver: "ড্রাইভার",
		searchPlaceholder: "ড্রাইভার অনুসন্ধান করুন",
		totalAmount: "সর্বমোট পরিমাণ",
		totalPaid: "সর্বমোট পরিশোধ",
		totalDue: "সর্বমোট বাকি",
		numberOfOrders: "মোট অর্ডার",
		deliveryFailed: "ডেলিভারি ব্যর্থ",
		gvmAmount: "GMV",
		numberOfFulfillment: "পূর্ণতা",
		orderCancel: "অর্ডার বাতিল",
		revenue: "আয়",
		delayInPayment: "পেমেন্টে বিলম্ব",
		dueAmount: "বাকি টাকা",
		dayPassed: "দিন অতিবাহিত",
		storeName: "দোকানের নাম",
		selectPlaceholder: "দিন নির্বাচন করুন",
		amount: "পরিমাণ",
		orderAmount: "অর্ডার পরিমাণ",
	},
	autoLogOut: {
		pageTitle: "স্বয়ংক্রিয় লগআউট",
		warningMsg:
			"স্বয়ংক্রিয় লগআউট যেহেতু এই নম্বরটি অন্য লগইনে ব্যবহৃত হয়েছিল৷",
		instrMsg: "আবার লগইন করতে নিচের সবুজ বোতামে ক্লিক করুন",
		btnLabel: "লগইন",
	},
	loan: {
		cardTitle: "আর্থিক সহায়তার জন্য অনুরোধ",
		amount: "পরিমাণ",
		serviceFee: "আর্থিক সহায়তার সার্ভিস ফি",
		sfWarning:
			"ব্যবহারের উপর ভিত্তিকরে, ৫০% পর্যন্ত আর্থিক সহায়তা পাওয়া যায়",
	},
	loanDetails: {
		cardTitle: "আর্থিক সহায়তা",
		requested: "অনুরোধ পরিমাণ",
		approved: "অনুমোদিত পরিমাণ",
		serviceFee: "আর্থিক সহায়তা ফি",
		dueDate: "পরিশোধের শেষ তারিখ",
		status: "স্ট্যাটাস",
		approvedDate: "অনুমোদনের তারিখ",
		collectedDate: "কালেকশন তারিখ",
		repaidDate: "পরিশোধ তারিখ",
		downPayment: "ডাউন পেমেন্ট",
	},

	customerOrder: {
		pageTitle: "কাস্টমার অর্ডার",
		customerOrderAmount: "কাস্টমার অর্ডারের পরমাণ",
		customer: "কাস্টমার",
		customerPlaceHolder: "নাম অনুসারে কাস্টমার খুঁজুন",
	},
	payment: {
		pageTitle: "পে একবাজ",
		bank: "ব্যাংক",
		acName: "A/C নাম",
		acNumber: "A/C নম্বর",
		branch: "শাখা",
		address: "ঠিকানা",
		city: "শহর",
		postal: "পোস্টাল কোড",
		country: "দেশ",
		selectTimeDate: "সময় এবং তারিখ নির্বাচন করুন",
		transitionId: "ট্রানজেকশন আইডি",
		transitionIdPlaceHolder: "আপনার ট্রানজেকশন আইডি লিখুন",
		amount: "পরিমাণ",
		amountPlaceHolder: "আপনার পাঠানো পরিমাণ লিখুন",
		uploadReceipt: "রসিদ আপলোড করুন",
		senderInfo: "প্রেরকের তথ্য",
		receiverInfo: "প্রাপকের তথ্য",
		senderWarning: "দয়া করে আপনার ব্যাংক অ্যাকাউন্ট যোগ করুন",
		paymentConformationMessage:
			"আপনি কি নিশ্চিত যে আপনি এই পেমেন্টটি জমা দিতে চান?",
		inputWarning: "দয়া করে এই ফিল্ডটি পূর্ণ করুন",
		congressPayment: "অভিনন্দন! আপনার পেমেন্টটি সফলভাবে জমা হয়েছে।",
		paymentRecieptImage: "পেমেন্ট রশিদের ছবি",
		receiptOrBank: "রসিদ বা ব্যাংক স্লিপ এর ছবি আপলোড করুন",
		warning: "অর্থের পরিমাণ সঠিক নয়",
	},
	depositList: {
		pageTitle: "ডিপোজিটের তালিকা",
		collectionAndDeposit: "ডিপোজিট এবং সংগ্রহ",
		depositDetails: "ডিপোজিট বিবরণ",
		collectionDetails: "সংগ্রহ বিবরণ",
		selectDate: "তারিখ নির্বাচন",
		selectCollector: "কালেক্টর নির্বাচন",
		txnNo: "লেনদেন নং",
		totalCollection: "মোট সংগ্রহ",
		branch: "শাখা",
		totalOrder: "মোট অর্ডার",
		verified: "ভেরিফাইড",
		unVerified: "আন ভেরিফাইড",
		collection: "মোট সংগ্রহ",
		totalDeposit: "মোট জমা",
		totalDue: "মোট বাকি",
		bankName: "ব্যাংকের নাম",
		acName: "একাউন্ট ধারকারীর নাম",
		acNumber: "একাউন্ট নাম্বার",
		routeNumber: "রুট নাম্বার",
		transectionNumber: "লেনদেন নাম্বার",
		bankAddress: "ব্যাংকের ঠিকানা",
		collectorName: "কালেক্টরের নাম",
		comment: "মন্তব্য",
		placeVerifyMessage: "আপনি কি নিশ্চিত যে আপনি এই জমা ভেরিফাইড করতে চান?",
		placeUnverifyMessage:
			"আপনি কি নিশ্চিত যে আপনি এই জমা আন ভেরিফাইড করতে চান?",
		confirmPageVerifyText: "আপনার ডিপোজিট ভেরিফাই সফল হয়েছে।",
		confirmPageunVerifyText: "আপনার ডিপোজিট আন ভেরিফাইড সফল হয়েছে।",
		depositAmount: "ডিপোজিট এমাউন্ট",
		collectionAmount: "কালেকশান এমাউন্ট",
		numberOfCollection: "কালেকশানের সংখ্যা",
		depositType: "লেনদেনের ধরন",
	},
	voiceOrder: {
		pageTitle: "ভয়েস অর্ডারিং",
		bannerHello: "হ্যালো",
		bannerAI: "ভয়েস অর্ডারিং এআই-তে স্বাগতম",
		bannerUse: "আমাকে ব্যবহার করে আপনি প্রত্যাশিত পণ্য খুজে পেতে পারেন",
		speechTitle: "আপনার উক্তি",
		speechHeader: "আমি অর্ডার করতে চাই - ",
		speechInfo: "পন্য খুজে পেতে উপরের সাদা বাটনে ক্লিক করে রেকর্ড করুন",
		keywordTitle: "মেলানো কীওয়ার্ড",
		keywordInfo: "রিলেটেড পন্য পেতে উপরের একটি কীওয়ার্ড সিলেক্ট করুন",
		productsTitle: "মেলানো পণ্যের তালিকা",
	},
	couponList: {
		pageTitle: "আর্থিক সহয়তা প্রোমো কোড",
		addCoupon: "আর্থিক সহয়তা প্রোমো কোড যোগ করুন",
		couponPlaceholder: "একটি আর্থিক সহয়তা প্রোমো কোড লিখুন",
		add: "যোগ করুন",
		applyCoupon: "আর্থিক সহয়তা প্রোমো কোড প্রয়োগ করুন",
		apply: "প্রয়োগ",
		expired: "মেয়াদোত্তীর্ণ",
		used: "ব্যবহৃত",
		remove: "মুছেন",
		applied: "এপ্লাইড",
		endIn: "পর্যন্ত",
	},
	addBankSettings: {
		bottomSheetTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		pageTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		accountName: "অ্যাকাউন্ট নাম",
		accountNamePlaceholder: "আপনার অ্যাকাউন্ট নাম লিখুন",
		accountNumber: "অ্যাকাউন্ট নম্বর",
		accountNumberPlaceholder: "আপনার অ্যাকাউন্ট নম্বর লিখুন",
		selectBank: "ব্যাংক নির্বাচন করুন",
		selectBankPlaceHolder: "আপনার ব্যাংক অ্যাকাউন্ট নির্বাচন করুন",
		branch: "শাখা",
		branchPlaceHolder: "আপনার ব্যাংক শাখা লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "ব্যাংক ঠিকানা লিখুন",
		selectCountry: "দেশ নির্বাচন করুন",
		selectCountryPlaceHolder: "আপনার ব্যাংক দেশ নির্বাচন করুন",
		selectCity: "শহর নির্বাচন করুন",
		selectCityPlaceHolder: "শহর নির্বাচন করুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceHolder: "আপনার ব্যাংক পোস্টাল কোড লিখুন",
		buttonTitle: "সংরক্ষণ করুন",
		bankNameWarning: "আপনার ব্যাংক অ্যাকাউন্ট নাম লিখুন",
		bankNumberWarning: "আপনার ব্যাংক অ্যাকাউন্ট নম্বর লিখুন",
		selectBankWarning: "একটি ব্যাংক নির্বাচন করুন",
		branchWarning: "দয়া করে আপনার ব্যাংক শাখা লিখুন",
		addressWarning: "দয়া করে আপনার ঠিকানা লিখুন",
		cityWarning: "দয়া করে আপনার শহর লিখুন",
		searchBank: "আপনার ব্যাংক খুজুন",
	},
	addedBankList: {
		pageTitle: "ব্যাংক তালিকা",
		acName: "একাউন্ট নাম",
		acNumber: "একাউন্ট নাম্বার",
		branch: "শাখা",
		address: "ঠিকানা",
		city: "শহর",
		country: "দেশ",
		addBank: "আপনার ব্যাংক অ্যাকাউন্ট যোগ করুন",
	},
	blackListed: {
		pageTitle: "ব্ল্যাকলিস্টেড",
		warningMsg: "আপনার ব্যবসা ব্ল্যাকলিস্টে রয়েছে",
		instrMsg:
			"আপনার ব্যবসার মালিককে অনুরোধ করুন EkkBaz এ যোগাযোগ করে প্রয়োজনীয় ব্যবস্থা নিয়ে পুনরায় আপনার ব্যবসা সক্রিয় করুন।",
		btnLabel: "লগ আউট",
	},
	ebsAgreement: {
		headline: "প্লাটফর্ম লাইসেন্সিং বিষয়ক সাময়িক চুক্তি পত্র",
	},
	kycInfo: {
		pageTitle: "KYC তথ্য",
		nidFront: "জাতীয় পরিচয়পত্র - সামনের দিক",
		nidBack: "জাতীয় পরিচয়পত্র - পিছনের দিক",
		tradeLicense: "ট্রেড লাইসেন্স",
		selfie: "সেলফি",
		proofAddress: "ঠিকানার প্রমাণ",
		tin: "ট্যাক্সপেয়ার আইডেন্টিফিকেশন নম্বর (টিআইএন)",
		contractPaper: "ব্র্যান্ডদের সাথে চুক্তি পত্র",
		agreementAndContact: "চুক্তি বা চুক্তি পত্র",
		cheque: "চেকের ছবি",
		name: "নাম",
		signature: "স্বাক্ষর",
		notFound: "পাওয়া যায়নি",
		viewPdf: "PDF দেখুন",
		hidePdf: "PDF বন্ধ করুন",
	},
	customerList: {
		pageTitle: "ডিস্ট্রিবিউটর তালিকা",
		searchPlaceHolder: "আপনার ডিস্ট্রিবিউটরের নাম লিখুন",
		detailsPageTitle: "ডিস্ট্রিবিউটরের বিবরণ",
		customerDetailsLabel: "ডিস্ট্রিবিউটর তথ্য",
		businessTypeLabel: "ব্যবসার ধরণ",
		customerNameLabel: "নাম",
		addressLabel: "ঠিকানা",
		postalCodeLabel: "পোস্টাল কোড",
		cityLabel: "শহর",
		acceptedCustomerLabel: "গৃহীত ডিস্ট্রিবিউটর",
		requestedCustomerLabel: "মূল্যের জন্য অনুরোধ",
		accept: "গ্রহণ করুন",
		acceptConfirmation:
			"আপনি কি নিশ্চিত আপনি এই ডিস্ট্রিবিউটরকে গ্রহণ করতে চান?",
		km: "কি.মি.",
		createCustomer: "নতুন ডিস্ট্রিবিউটর তৈরি করুন",
		customerNoHolder: "ডিস্ট্রিবিউটরের মোবাইল নম্বর",
		nextBtn: "পরবর্তী",
		deleteDistributor: "ডিস্ট্রিবিউটর মুছুন",
		addCustomPrice: "স্পেশাল মূল্যের জন্য পণ্য যোগ করুন",
		warningMsg: "আপনি কি নিশ্চিত যে আপনি এই ডিস্ট্রিবিউটরকে মুছতে চান?",
		yes: "হ্যাঁ",
		no: "না",
		successMsg: "ডিস্ট্রিবিউটরটি সফলভাবে মোছা হয়েছে!",
		customPriceMsg:
			"এই ডিস্ট্রিবিউটরের জন্য কাস্টম মূল্য সেট করতে কি আপনি নিশ্চিত?",
		inputPlaceholder: "স্পেশাল মূল্য লিখুন",
		priceEmptyError: "স্পেশাল মূল্য খালি রাখা যাবে না!",
		alreadySetPriceError:
			"এই পণ্যের মূল্যটি ইতিমধ্যে এই ডিস্ট্রিবিউটরের জন্য নির্ধারণ করা হয়েছে।",
		cancelBtn: "বাতিল",
		submit: "জমা দিন",
		modalTitle: "স্পেশাল মূল্য নির্ধারণ করুন",
		productsSectionTitle: "স্পেশাল মূল্যর পণ্য তালিকা",
		updatePriceTitle: "স্পেশাল মূল্য পরিবর্তন করুন",
		deletePriceTitle: "স্পেশাল মূল্য থেকে পণ্য মুছুন",
		productDetailTitle: "পণ্যের বিবরণ",
		offerDetailsTitle: "অফার বিবরণ",
		description:
			"আপনি কি নিশ্চিত যে আপনি এই স্পেশাল মূল্যর পণ্যটি মুছতে চান?",
		distributorCode: "ডিস্ট্রিবিউটর কোড",
		notSet: "এখনো সেট করা হয়নি",
		distributorCodeAdd: "ডিস্ট্রিবিউটর কোড এড করুন",
		distributorCodeEdit: "ডিস্ট্রিবিউটর কোড আপডেইট করুন",
		writeDistributorCode: "ডিস্ট্রিবিউটর কোড লিখুন",
		successLabel: "ডিস্ট্রিবিউটর কোড এড সফল হয়েছে",
		editSuccessLabel: "ডিস্ট্রিবিউটর কোড আপডেইট সফল হয়েছে",
	},
	createSupplier: {
		pageTitle: "নতুন সাপ্লাইয়ার তৈরি করুন",
		invitedSupplierList: "ইনভাইটেড সাপ্লাইয়ার তালিকা",
		searchInvitedSupplierList: "ইনভাইটেড সাপ্লাইয়ার তালিকা খুঁজুন",
		customerExists: "ইতিমধ্যেই বিদ্যমান!",
		invited: "ইনভাইটেড",
		newBusiness: "নতুন ব্যবসা সফলভাবে তৈরি হয়েছে",
		addNameWarning: "অনুগ্রহ করে ব্যবসার নাম যোগ করুন",
		addCustomerNameWarning: "অনুগ্রহ করে গ্রাহকের নাম যোগ করুন",
	},
	createBusinessInfo: {
		pageTitle: "কনট্রাক্ট পরিচালনা তথ্য",
		title: "ব্যবসার তথ্য",
		brandsLabel: "ব্র্যান্ডের নাম",
		brandsPlaceholder: "যার যার সাথে ডিস্ট্রিবিউটর ব্যবসা করেন",
		skusLabel: "মোট এসকিইউ",
		skusPlaceholder: "এসকিইউ পরিমাণ",
		srsLabel: "মোট এসআর",
		srsPlaceholder: "এসআর পরিমাণ",
		driversLabel: "মোট ডিএসআর",
		driversPlaceholder: "ডিএসআর পরিমাণ",
		customersLabel: "মোট কাস্টমার",
		customersPlaceholder: "কাস্টমার পরিমাণ",
		salesLabel: "মাসিক বিক্রয় পরিমাণ",
		salesPlaceholder: "বিক্রয় পরিমাণ লিখুন",
		fsLabel: "মাসিক আর্থিক সহায়তা প্রয়োজন",
		fsPlaceholder: "আর্থিক সহায়তার পরিমাণ লিখুন",
		pwFsLabel: "আর্থিক সহায়তা প্রয়োজনের সম্ভাব্য সপ্তাহ",
		pwFsPlaceholder: "সপ্তাহ নির্বাচন করুন",
		operationLabel: "ব্যবসার অপারেশন শুরুর তারিখ/বছর",
		operationExtraLabel: "বছর",
		operationPlaceholder: "বছর নির্বাচন করুন",
		aeLabel: "এ ই মতামত",
		aePlaceholder: "এ ই মতামত লিখুন",
		nextBtn: "পরবর্তী",
		owner: "মালিক",
		manager: "ম্যানেজার",
		stuff: "স্টাফ",
		validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
		okBtnLabel: "ঠিক আছে",
		successMessage: "ব্যবসা তথ্য সফলভাবে তৈরি হয়েছে",
		brandError: "ব্র্যান্ডের নাম লিখুন",
		skuError: "এসকিউ পরিমাণ লিখুন",
		srError: "এসআর পরিমাণ লিখুন",
		driverError: "ডিএসআর পরিমাণ লিখুন",
		customerError: "গ্রাহক পরিমাণ লিখুন",
		salesError: "বিক্রয় পরিমাণ লিখুন",
		fsError: "আর্থিক সাপোর্ট পরিমাণ লিখুন",
		operationError: "অপারেশন শুরুর বছর নির্বাচন করুন",
		feedbackError: "আপনার মতামত লিখুন",
		regionLabel: "জেলা",
		areaLabel: "উপজেলা/থানা",
		regionError: "একটি অঞ্চল নির্বাচন করুন",
		areaError: "একটি এলাকা নির্বাচন করুন",
		areaPlaceholderWarning: "অনুগ্রহ করে প্রথমে একটি অঞ্চল নির্বাচন করুন",
		pwFsError: "একটি সপ্তাহ নির্বাচন করুন",
	},
	customerDetails: {
		AEUserLabel: "অ্যাকাউন্ট এক্সিকিউটিভ",
		HSuserLabel: "হাব স্টাফ",
		visitType: "ভিজিটের ধরন",
		createdAt: "তৈরির সময়",
		createdBy: "তৈরি করেছে",
		updatedAt: "আপডেটের সময়",
		updatedBy: "আপডেট করেছে",
		businessCardTitle: "ব্যবসার তথ্য",
		contactCardTitle: "যোগাযোগের তথ্য",
		contractCardTitle: "চুক্তির তথ্য",
		visitCardTitle: "ভিজিটের তথ্য",
		name: "নাম",
		number: "নম্বর",
		role: "রোল",
		orderType: "অর্ডার টাইপ",
		serviceType: "সার্ভিস টাইপ",
		revenueTier: "রেভিনিউ টায়ার",
		expectedRevenue: "মাসিক প্রত্যাশিত রেভিনিউ",
		expectedCloseDate: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ",
		status: "স্ট্যাটাস",
		checkIn: "চেক ইন সময়",
		checkOut: "চেক আউট সময়",
		remark: "মন্তব্য",
		location: "অবস্থান",
		image: "ছবি",
		pageTitle: "কাষ্টমারের বিবরণ",
		businessType: "ব্যবসার ধরন",
	},
	createContact: {
		pageHeadline: "যোগাযোগ তথ্য",
		nameLabel: "নাম",
		namePlaceholder: "নাম লিখুন",
		numberLabel: "নম্বর",
		numberPlaceholder: "নম্বর লিখুন",
		roleError: "একটি রোল নির্বাচন করুন",
	},
	createContract: {
		pageHeadline: "কনট্রাক্ট তথ্য",
		orderTypeLabel: "অর্ডার টাইপ",
		serviceTypeLabel: "সেবা টাইপ",
		revenueTierLabel: "রেভিনিউ টিয়ার",
		statusLabel: "স্ট্যাটাস",
		selectPlaceholder: "বাছাই করুন",
		monthlyRevenueLabel: "প্রত্যাশিত মাসিক রেভিনিউ",
		monthlyRevenuePlaceholder: "প্রত্যাশিত মাসিক রেভিনিউ লিখুন",
		expectedCloseDateLabel: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ",
		expectedCloseDatePlaceholder: "তারিখ নির্বাচন করুন",
		primaryOrder: "প্রাইমারি",
		secondaryOrder: "সেকেন্ডারি",
		tertiaryOrder: "টারশিয়ারি",
		successMessage: "কনট্রাক্ট সফলভাবে তৈরি হয়েছে",
		statusError: "স্ট্যাটাস নির্বাচন করুন",
		closeDateError: "আনুমানিক চুক্তি স্বাক্ষরের তারিখ করুন",
		monthlyRevenueError: "মাসিক রেভিনিউ পরিমাণ লিখুন",
		revenueTierError: "রেভিনিউ টিয়ার নির্বাচন করুন",
		serviceTypeError: "সেবা টাইপ নির্বাচন করুন",
		orderTypeError: "অর্ডার টাইপ নির্বাচন করুন",
	},
	newCustomer: {
		pageTitle: "নতুন ব্যবসা তৈরি করুন",
		mobileNo: "মোবাইল নম্বর",
		customerNoHolder: "ব্যবসায়িক মোবাইল নম্বর",
		customerName: "ব্যবহারকারীর নাম",
		role: "রোল",
		selectRole: "রোল নির্বাচন করুন",
		customerNameHolder: "ব্যবহারকারীর পূর্ণ নাম লিখুন",
		businessPhoto: "ব্যবসায়িক ছবি",
		uploadPhoto: "ব্যবসা/স্টোরের ছবি আপলোড করুন",
		uploadPhotoTitle: "ছবি আপলোড",
		uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
		takePhoto: "ছবি তোল",
		businessName: "ব্যবসার নাম",
		businessNameHolder: "ব্যবসা/স্টোরের নাম লিখুন",
		address: "ঠিকানা",
		addressHolder: "ব্যবসা/স্টোরের ঠিকানা লিখুন",
		city: "শহর",
		cityHolder: "শহরের নাম লিখুন",
		postalCode: "পোস্ট কোড",
		postalCodeHolder: "পোস্ট কোড লিখুন",
		selectBusiness: "ব্যবসার ধরন নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		nonFmcg: "নন-এফএমসিজি",
		nextBtn: "পরবর্তী",
		completeBtn: "সম্পূর্ণ",
		customerFindText: `ব্যবসা ইতিমধ্যেই এখানে পাওয়া গেছে, আপনি চাইলে এটিকে আপনার কাস্টমার তালিকাতে যোগ করতে পারেন।`,
		addBusiness: "ব্যবসা যোগ করুন",
		addBusinessInfo: "আপনি কি আপনার কাস্টমার হিসাবে এই ব্যবসা যোগ করতে চান?",
		noBtn: "না",
		alreayExist: "ব্যবসা ইতিমধ্যে বিদ্যমান!",
		reseller: "রিসেলার",
		businessNameWarning: "অনুগ্রহ করে ব্যবসার নাম যোগ করুন",
		distributorNameWarning: "অনুগ্রহ করে ব্যবসা/স্টোরের নাম যোগ করুন",
		businessCreationSuccess: "নতুন ব্যবসা সফলভাবে তৈরি করা হয়েছে",
		locationValidationWarning: "গুগল ম্যাপ থেকে ব্যবসার অবস্থান যোগ করুন",
	},
	industryType: {
		pageTitle: "ওয়ার্কিং ইন্ডাস্ট্রি",
		title: "অনুগ্রহ করে আপনার ইন্ডাস্ট্রি নির্বাচন করুন",
	},
	addCard: {
		pageTitle: "এড পেমেন্ট কার্ড",
		addedList: "যোগ করা কার্ড তালিকা",
		addNewCard: "নতুন কার্ড যোগ করুন",
		continue: "চালিয়ে যান",
	},
	paymentModule: {
		pageTitle: "আপগ্রেড প্ল্যান নির্বাচন করুন",
		upgradeNow: "এখনই আপগ্রেড করুন",
		monthlyPlan: "মাসিক প্ল্যান",
		annualPlan: "বার্ষিক প্ল্যান",
		card: "কার্ড",
		paymentMethod: "পেমেন্ট পদ্ধতি",
		continue: "চালিয়ে যান",
		selectedMethod: "অনুগ্রহ করে একটি পেমেন্ট পদ্ধতি নির্বাচন করুন..!",
		checkOut: "চেক আউট",
		paymentCard: "পেমেন্ট কার্ড",
		changeCard: "কার্ড পরিবর্তন করুন",
		selectPackage: "প্যাকেজ নির্বাচন করুন",
		orderSummary: "অর্ডার সারাংশ",
		subtotal: "সাবটোটাল",
		total: "মোট",
		payNow: "এখন পরিশোধ করুন",
		successTitle: "পেমেন্ট সফল",
		successText: "আপনার পেমেন্টের জন্য ধন্যবাদ",
		yourPlan: "আপনার সাবস্ক্রিপশন প্ল্যান",
		transactionHistory: "লেনদেনের ইতিহাস",
		cardNumber: "কার্ড নম্বর",
		status: "স্ট্যাটাস",
		invoice: "ইনভয়েস",
		newCard: "নতুন কার্ড যোগ করুন",
	},
};
export default bangla;

import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BrandList from "components/mobile-components/BrandList";
import AllBrands from "components/mobile-components/BrandList/AllBrands";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";

export default function AllBrandListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [brandList, setBrandList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [brandListLoader, setBrandLoader] = useState(true);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getAllBrandList = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Brand.getAllBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandLoader(false);
				setBrandList(response.results);
			} else {
				setBrandLoader(false);
			}
		} else {
			let response = await Brand.getAllBrandList();
			if (response.status === 200) {
				setBrandList(response.results);
				setBrandLoader(false);
			} else {
				setBrandLoader(false);
			}
		}
	};

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				const response = await Brand.getAllBrandListSearch(
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				const response = await Brand.getAllBrandList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	let singleBrandSearch = async () => {
		let response = await Brand.getAllBrandListSearch(searchQ);
		if (response.status === 200) {
			setBrandList(response.results);
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getAllBrandList();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.brandList.brandList} />
					<section className='pt-14'>
						{brandListLoader && <IonLoading isOpen={true} />}
						<AllBrands
							ekkLanguage={ekkLanguage}
							brandList={brandList}
							searchQ={searchQ}
							setSearchQ={setSearchQ}
							singleBrandSearch={singleBrandSearch}
							setHasMoreData={setHasMoreData}
							setReachedEnd={setReachedEnd}
						/>
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								lodeMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

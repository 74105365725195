import { IonCol } from "@ionic/react";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export default function SingleDeposit({ item, logo, ekkLanguage }) {
	return (
		<IonCol size='12'>
			<Link to={`/deposit-collection/${item?._id}`}>
				<div
					className='mb-2 rounded-[10px] overflow-hidden bg-white pt-[5px]'
					style={{
						boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
					}}>
					<div className={`py-[7px]  px-4 relative`}>
						<ul className='flex items-center justify-between'>
							<li className=' h-[24px]'>
								{item?.status === "Verified" ? (
									<span className='text-10 text-white rounded-[25px] bg-success py-[3px]  px-[10px] inline-flex items-center justify-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='12'
											viewBox='0 0 12 12'
											fill='none'
											className='mr-[3px]'>
											<path
												d='M3.87459 10.3306C3.53396 10.3307 3.20728 10.1953 2.96662 9.95428L0.22153 7.2102C-0.0738435 6.91473 -0.0738435 6.43578 0.22153 6.14031C0.516999 5.84494 0.995953 5.84494 1.29142 6.14031L3.87459 8.72348L10.7086 1.8895C11.004 1.59413 11.483 1.59413 11.7785 1.8895C12.0738 2.18497 12.0738 2.66392 11.7785 2.95939L4.78256 9.95428C4.5419 10.1953 4.21523 10.3307 3.87459 10.3306Z'
												fill='white'
											/>
										</svg>
										{ekkLanguage.depositList.verified}
									</span>
								) : (
									<span className='text-12 text-white rounded-[25px] bg-primary px-[10px] py-[3px] inline-flex items-center justify-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='12'
											viewBox='0 0 12 12'
											fill='none'
											className='mr-[3px]'>
											<path
												d='M10.5001 1.50084C10.3595 1.36024 10.1687 1.28125 9.96986 1.28125C9.77099 1.28125 9.58025 1.36024 9.43961 1.50084L6.00011 4.94034L2.56061 1.50084C2.41996 1.36024 2.22923 1.28125 2.03036 1.28125C1.83148 1.28125 1.64075 1.36024 1.50011 1.50084C1.3595 1.64149 1.28052 1.83222 1.28052 2.03109C1.28052 2.22996 1.3595 2.42069 1.50011 2.56134L4.93961 6.00084L1.50011 9.44034C1.3595 9.58099 1.28052 9.77172 1.28052 9.97059C1.28052 10.1695 1.3595 10.3602 1.50011 10.5008C1.64075 10.6414 1.83148 10.7204 2.03036 10.7204C2.22923 10.7204 2.41996 10.6414 2.56061 10.5008L6.00011 7.06134L9.43961 10.5008C9.58025 10.6414 9.77099 10.7204 9.96986 10.7204C10.1687 10.7204 10.3595 10.6414 10.5001 10.5008C10.6407 10.3602 10.7197 10.1695 10.7197 9.97059C10.7197 9.77172 10.6407 9.58099 10.5001 9.44034L7.06061 6.00084L10.5001 2.56134C10.6407 2.42069 10.7197 2.22996 10.7197 2.03109C10.7197 1.83222 10.6407 1.64149 10.5001 1.50084Z'
												fill='white'
											/>
										</svg>
										{ekkLanguage.depositList.unVerified}
									</span>
								)}
							</li>
							<li>
								<img
									className='h-full max-w-[35px]'
									src={logo?.image}
									alt='bank'
								/>
							</li>
							<li>
								<h6 className='font-bold text-16 text-delivered'>
									৳{digitChanger(numberWithCommas(item?.payAmount))}
								</h6>
							</li>
						</ul>
					</div>
					<div className='px-4 py-[7px]'>
						<ul className='flex'>
							<li className='w-1/2'>
								{/* <p className='text-black-500 text-12  font-normal py-[5px] flex'>
									{ekkLanguage.depositList.txnNo}
									{" - "}
									<span className='text-black-1000 text-12 font-bold'>
										{item?.transactionNumber}
									</span>
								</p> */}

								<p className='text-black-500 text-12  font-normal py-[5px] flex'>
									{ekkLanguage.depositList.totalCollection}
									{" - "}
									<span className='text-black-1000 text-12 font-bold'>
										৳
										{digitChanger(
											numberWithCommas(item?.collectedAmount)
										)}
									</span>
								</p>
							</li>
							<li>
								{/* <p className='text-black-500 text-12  font-normal py-[5px] flex'>
									{ekkLanguage.depositList.branch}
									{" - "}
									<span className='text-black-1000 text-12 font-bold'>
										{item?.branchSubBranch}
									</span>
								</p> */}
								<p className='text-black-500 text-12  font-normal py-[5px] flex'>
									{ekkLanguage.depositList.totalOrder}
									{" - "}
									<span className='text-black-1000 text-12 font-bold'>
										{digitChanger(Number(item?.totalOrders))}
									</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</Link>
		</IonCol>
	);
}

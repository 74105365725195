import React, { useEffect, useState } from "react";
import BlogData from "services/Blog";
import moment from "moment";
import Footer from "pages/Landing/Footer";
import Navigation from "pages/Landing/Navigation";
import "../Landing/style.scss";
import parse from "html-react-parser";
import { getLanguage } from "config/language";
import Blog from "services/Blog";

export default function RefundPolicy() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const [singleBlog, setSingleBlog] = useState({});
	const [loading, setLoading] = useState(false);

	const lan = getLanguage();

	useEffect(() => {
		const getTermCodition = async () => {
			let response = await Blog.getRefundPolicyWithoutAuth(
				lan.toUpperCase()
			);
			if (response.status === 200) {
				setSingleBlog(response?.results?.[0]);
				setLoading(false);
			}
		};
		getTermCodition();
	}, [lan]);

	useEffect(() => {
		function onScroll() {
			// let currentPosition = window.pageYOffset;
			// document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
			let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
			if (currentPosition > 150) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	const getSingleBlog = async () => {
		setLoading(true);
		let response = await BlogData.singleBlog("5c84db949ac43470f0effcf3");

		if (response.status === 200) {
			setLoading(false);
			setSingleBlog(response.data);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getSingleBlog();
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, []);
	// 	console.log(colsData);
	// 	let cols = colsData.map((col, colKey) => {
	// 		// let tpl = eval("`" + col.content + "`");
	// 		// let content = col.content;
	// 		// content = content.replace(/<\/p>|<p>|&nbsp;|&amp;|<\/strong>|<strong>/g, "");
	// 		// content = content.replace(/<br>/g, "\n\n");
	// 		// let minContent = content.length > 300 ? content.slice(0, 300) + "..." : content;
	// 		let createdAt = moment(col.createdAt, "YYYY-MM-DD").format(
	// 			"DD MMM, YYYY"
	// 		);
	// 		// let span = $("<span />")
	// 		// 	.attr("className", "")
	// 		// 	.html(col.article);
	// 		// let span = $("<span></span>").text(col.content);
	// 		// console.log(span[0].innerText);

	// 		let span = document.createElement("span");
	// 		span.innerHTML = col.content;
	// 		let content =
	// 			(span.textContent || span.innerText).substring(0, 100) + "...";
	// 		return (
	// 			<Link
	// 				key={colKey}
	// 				className='column news-card'
	// 				to={{
	// 					pathname:
	// 						type === "blog"
	// 							? `/${type}/${col._id}`
	// 							: type === "bounty"
	// 							? `/token-offer/${type}/${col._id}`
	// 							: type === "dashboardBlog"
	// 							? `/dashboard/home/${col._id}`
	// 							: `/${type}/en/${col._id}`,

	// 					// slug: col.slug
	// 				}}>
	// 				<div className='news-card-img-wrapper'>
	// 					<img
	// 						// src={
	// 						// 	col.thumbnail_image &&
	// 						// 	this.checkMedia(col.thumbnail_image)
	// 						// }
	// 						alt='card infographic'
	// 						className='news-thumbnail'
	// 					/>
	// 					<div className='news-card-img-overlay' />
	// 				</div>
	// 				<figcaption className='news-card-info-wrapper'>
	// 					<span>{createdAt}</span>
	// 					<hgroup>
	// 						<h3>{col.title}</h3>
	// 					</hgroup>
	// 					<p>{content}</p>
	// 				</figcaption>
	// 			</Link>
	// 		);
	// 	});
	// 	return cols;
	// };

	// const _handleNewsRows = (data, rowsCount, columnsCount) => {
	// 	if (data && data.length > 0) {
	// 		let rows = [];
	// 		const rowCount = rowsCount ? rowsCount : data.length;
	// 		const colCount = columnsCount ? columnsCount : 3;
	// 		const limit =
	// 			rowsCount && columnsCount ? rowCount * colCount : data.length;

	// 		for (let rowKey = 0; rowKey < limit; rowKey = rowKey + colCount) {
	// 			let colsData = data.slice(rowKey, rowKey + colCount);

	// 			rows.push(
	// 				<section key={rowKey} className='row news-cards-wrapper'>
	// 					{_handleNewsCols(colsData, "blog")}
	// 				</section>
	// 			);
	// 		}
	// 		return rows;
	// 	} else {
	// 		return [
	// 			<p key={0} style={{ width: "100%", textAlign: "center" }}>
	// 				No articles available.
	// 			</p>,
	// 		];
	// 	}
	// };

	return (
		<section>
			<Navigation scrolling={scrolling ? "isScrolled" : ""} bg={true} />
			{loading ? (
				<div className='pt-[100px] sm:pt-[150px] pb-24'>
					<div className='m-auto w-full lg:w-8/12'>
						<div className='flex h-full justify-center items-center'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='pt-[100px] sm:pt-[120px] pb-24'>
					<div className='container'>
						<div className='m-auto w-full lg:w-8/12'>
							<div>
								<h2 className='lg:text-40 md:text-32 text-22 text-black-1000 font-bold pb-3'>
									{singleBlog?.title}
								</h2>
								<p className='text-12 sm:text-16 font-normal text-808080 pb-5'>
									{moment(singleBlog.created_at, "YYYY-MM-DD").format(
										"DD MMM, YYYY"
									)}
								</p>

								{singleBlog.content && parse(singleBlog?.content)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Footer />
		</section>
	);
}

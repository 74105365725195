import React, { useState } from "react";
import {
	IonApp,
	IonButton,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "components/mobile-components/Common/UploadImage";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import User from "services/User";
import { toast } from "react-toastify";
import { getUserBusiness, setUserBusiness } from "config/user";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { useNavigate } from "react-router-dom";

export default function SelfieVerificationPage() {
	const bs = useSelector((state) => state.businessSettings);
	const [cameraImage, setCameraImage] = useState(bs.data.selfie ?? "");
	const [loader, setLoader] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [cameraImageURL, setCameraImageURL] = useState(null);

	let dispatch = useDispatch();
	const business = getUserBusiness();
	const navigate = useNavigate();

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	const nextHandler = async () => {
		// if (cameraImage.includes(".jpg")) {
		// 	localStorage.setItem("auth", true);
		// 	return navigate("/trade-license-verification");
		// }

		setLoader(true);
		const cameraFile = DataURIToBlob(cameraImage);
		const cameraData = new FormData();
		cameraData.append("key", cameraFile, "image.jpg");
		let cameraResponse = await User.toBucketImage(cameraData);
		if (cameraResponse.status === 201) {
			setCameraImageURL(cameraResponse?.results[0]);
			dispatch(
				setKYC({
					...bs.data,
					selfie: cameraResponse?.results[0],
				})
			);
			let query = {
				selfie: cameraResponse?.results[0],
			};
			const res = bs.data?.kycUpdate
				? await Business.updateKYC(bs.data?.kycId, {
						selfie: cameraResponse?.results[0],
				  })
				: await Business.createKYC(query);
			if (res.status === 200) {
				dispatch(
					setKYC({
						kycUpdate: true,
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				navigate("/trade-license-verification");
			} else if (res.status === 201) {
				dispatch(
					setKYC({
						kycId: res.results?.id,
						kycUpdate: true,
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				navigate("/trade-license-verification");
			}
			localStorage.setItem("auth", true);
		} else {
			toast.error("Image upload failed");
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.kycVerification.verifyUser}
					/>
					<div className='pt-14 px-4'>
						<UploadImage
							cameraImage={cameraImage}
							setCameraImage={setCameraImage}
							label={ekkLanguage.kycVerification.selfieLabel}
							placeholder={ekkLanguage.kycVerification.selfie}
							imgSource='/assets/images/take_selfie.png'
							bHeight={438}
							ekkLanguage={ekkLanguage}></UploadImage>
						<div className='pt-24 text-center pb-10'>
							<IonButton
								disabled={(cameraImage ? false : true) | loader}
								color={cameraImage ? "success" : "dark"}
								className='font-bold h-[50px] w-full'
								onClick={nextHandler}>
								{loader ? (
									<IonSpinner></IonSpinner>
								) : (
									<>
										{ekkLanguage.businessCreateName.btnLabel}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</>
								)}
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { digitChanger, monthChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

export default function FinanceSupportCard({
	order,
	loanDetails,
	collection,
	defaultPenalty,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const textChanger = (item) => {
		if (item === "1st Default") {
			return ekkLanguage.orderDetails.first;
		} else if (item === "2nd Default") {
			return ekkLanguage.orderDetails.second;
		} else if (item === "3rd Default") {
			return ekkLanguage.orderDetails.third;
		}
	};

	return (
		<div className='px-2 pb-5'>
			{loanDetails?.default?.loanStatus ? (
				<div>
					<div
						className='bg-primary px-[10px] relative mb-5'
						style={{
							borderRadius: 10,
						}}>
						<img
							src='/assets/images/danger.png'
							alt=''
							className='absolute right-[15px] top-[10px]'
						/>
						<div>
							<div className='pt-3 flex items-center mb-5'>
								<img
									src='/assets/images/error3.png'
									alt='error'
									className='mr-[5px] w-3 h-3 '
								/>
								<h4 className='font-bold text-12 text-white'>
									{loanDetails?.default?.loanStatus}
								</h4>
							</div>

							<table className='table-fixed w-full'>
								<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
									<tr>
										<td className='text-white text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.loanStatus}
										</td>
										<td className='text-white text-12 font-medium py-[6px] text-right'>
											{digitChanger(
												moment(
													loanDetails?.default?.next_due_date
												).format("DD-MM-YYYY")
											)}
										</td>
									</tr>
									<tr>
										<td className='text-white text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.penaltyFee}
										</td>
										<td className='text-white text-12 font-medium py-[6px] text-right'>
											{order?.country?.currency_symbol_native}
											{digitChanger(
												Number(
													loanDetails?.default?.amount
												).toFixed(2)
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : undefined}

			{loanDetails?.loanStatus !== "Seller Payment Fully Paid" &&
			loanDetails?.loanStatus !== "Seller Payment Partially Paid" &&
			loanDetails?.loanStatus !== "Fully Repaid" &&
			loanDetails?.loanStatus !== "Partially Repaid" &&
			loanDetails?.loanStatus !== "Fully Delivered" &&
			loanDetails?.loanStatus !== "Partially Delivered" &&
			loanDetails?.loanStatus !== "1st Default" &&
			loanDetails?.loanStatus !== "2nd Default" &&
			loanDetails?.loanStatus !== "3rd Default" &&
			loanDetails?.loanStatus !== "In Settlement" &&
			loanDetails?.loanStatus !== "Closed Settlement" ? (
				<div className='mb-4'>
					<h4 className='text-primary text-14 font-bold py-[6px] mb-[10px]'>
						{ekkLanguage.orderDetails.penaltyHeader}
					</h4>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-primary text-14 font-bold py-[6px] text-center border border-AAABAD w-7/12'>
									{ekkLanguage.orderDetails.penaltyFee}
								</td>
								<td className='text-14 text-primary font-bold py-[6px] text-center border border-AAABAD w-5/12'>
									{ekkLanguage.orderDetails.amount}
								</td>
							</tr>
							{defaultPenalty.length > 0
								? defaultPenalty?.map((el, i) => {
										return (
											<tr key={i}>
												<td className='text-14 font-medium py-[6px] text-center border border-AAABAD text-black-500 w-7/12'>
													{textChanger(el?.title)}
													{" - "}
													<span className='text-14'>
														{digitChanger(
															moment(el?.due_date).format(
																"DD/MM/YYYY"
															)
														)}{" "}
													</span>
												</td>
												<td className='text-14 font-medium py-[6px] text-center border border-AAABAD text-black-500 w-5/12'>
													{order?.country?.currency_symbol_native}{" "}
													{digitChanger(
														Number(el?.amount).toFixed(2)
													)}
												</td>
											</tr>
										);
								  })
								: undefined}
						</tbody>
					</table>
				</div>
			) : undefined}

			{/* {(loanDetails?.loanStatus !== "Seller Payment Fully Paid") |
			(loanDetails?.loanStatus !== "Seller Payment Partially Paid") |
			(loanDetails?.loanStatus !== "Fully Repaid") |
			(loanDetails?.loanStatus !== "Partially Repaid") |
			(loanDetails?.loanStatus !== "Fully Delivered") |
			(loanDetails?.loanStatus !== "SPartially Delivered") |
			(loanDetails?.loanStatus !== "1st Default") |
			(loanDetails?.loanStatus !== "2nd Default") |
			(loanDetails?.loanStatus !== "3rd Default") |
			(loanDetails?.loanStatus !== "In Settlement") |
			(loanDetails?.loanStatus !== "Closed Settlement") ? (
				<div className='mb-4'>
					<h4 className='text-primary text-14 font-bold py-[6px] mb-[10px]'>
						{ekkLanguage.orderDetails.penaltyHeader}
					</h4>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-primary text-14 font-bold py-[6px] text-center border border-AAABAD w-7/12'>
									{ekkLanguage.orderDetails.penaltyFee}
								</td>
								<td className='text-14 text-primary font-bold py-[6px] text-center border border-AAABAD w-5/12'>
									{ekkLanguage.orderDetails.amount}
								</td>
							</tr>
							{defaultPenalty.length > 0
								? defaultPenalty?.map((el, i) => {
										return (
											<tr key={i}>
												<td className='text-14 font-medium py-[6px] text-center border border-AAABAD text-black-500 w-7/12'>
													{textChanger(el?.title)}
													{" - "}
													<span className='text-14'>
														{digitChanger(
															moment(el?.due_date).format(
																"DD/MM/YYYY"
															)
														)}{" "}
													</span>
												</td>
												<td className='text-14 font-medium py-[6px] text-center border border-AAABAD text-black-500 w-5/12'>
													{order?.country?.currency_symbol_native}{" "}
													{digitChanger(
														Number(el?.amount).toFixed(2)
													)}
												</td>
											</tr>
										);
								  })
								: undefined}
						</tbody>
					</table>
				</div>
			) : undefined} */}

			<div
				className='bg-[#ffffff] rounded-[10px] pt-5'
				style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)" }}>
				<div
					className='border-b-2 pb-3 flex justify-between items-center px-4'
					style={{ bordreColor: "#f2f3f7" }}>
					<h3
						className='text-222222 font-bold text-14 '
						// onClick={() => console.log("asof")}
					>
						{ekkLanguage.loanDetails.cardTitle}
					</h3>
				</div>
				<div>
					<div className='px-[10px]'>
						<>
							{
								<table
									className='table-fixed w-full'
									style={{ borderBottom: "2px solid #F2F3F7" }}>
									<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.paymentMethod}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{order?.payment_method}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.paymentTerms}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												<span>{order?.payment_term?.name}</span>
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.requested}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{/* {translator(order.currency)}{" "} */}
												{order?.country?.currency_symbol_native}
												{/* {digitChanger(numberWithCommas(loanDetails?.loanRequestAmount))} */}
												{digitChanger(
													numberWithCommas(
														loanDetails?.requestAmount ?? 0.0
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.approved}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{/* {translator(order.currency)}{" "} */}
												{order?.country?.currency_symbol_native}
												{/* {digitChanger(numberWithCommas(loanDetails?.loanApprovedAmount))} */}
												{digitChanger(
													numberWithCommas(
														loanDetails?.approvedAmount ?? 0.0
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.serviceFee}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{/* {translator(order?.currency)}{" "} */}
												{order?.country?.currency_symbol_native}
												{/* {loanDetails?.interestAmount ? "-" : null} */}
												{digitChanger(
													numberWithCommas(
														loanDetails?.interestAmount ?? 0.0
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.createOrder.fsCoupon}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{/* {translator(order.currency)}{" "} */}
												{"-"}
												{order?.country?.currency_symbol_native}
												{digitChanger(
													numberWithCommas(
														order?.fs_coupon_amount ?? 0
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.dueDate}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{/* {translator(order.currency)}{" "} */}
												{/* {order?.country?.currency_symbol_native}
                      {digitChanger(
                        numberWithCommas(order?.deliveryFee ?? 0.0)
                      )} */}{" "}
												{digitChanger(
													moment(
														loanDetails?.paymentDueDate
													).format("DD-MM-YYYY")
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.status}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right capitalize'>
												{/* {translator(order.currency)}{" "} */}

												{loanDetails?.loanStatus}
												{/* {order?.country?.currency_symbol_native} */}
												{/* {digitChanger(numberWithCommas(order?.payment_term?.creditFee))} */}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.loanDetails.downPayment}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right capitalize'>
												{order?.country?.currency_symbol_native}
												{digitChanger(
													numberWithCommas(
														loanDetails?.total_buyer_down_payment ??
															0
													)
												)}
												{/* {order?.country?.currency_symbol_native} */}
												{/* {digitChanger(numberWithCommas(order?.payment_term?.creditFee))} */}
											</td>
										</tr>
										{loanDetails?.approvedTime && (
											<tr>
												<td className='text-000080 text-12 font-medium py-[6px]'>
													{ekkLanguage.loanDetails.approvedDate}
												</td>
												<td className='text-000080 text-12 font-medium py-[6px] text-right'>
													{/* {translator(order.currency)}{" "} */}
													{digitChanger(
														moment(
															loanDetails?.approvedTime
														).format("DD-MM-YYYY")
													)}
												</td>
											</tr>
										)}
										{loanDetails?.collectedDateTime && (
											<tr>
												<td className='text-000080 text-12 font-medium py-[6px]'>
													{ekkLanguage.loanDetails.collectedDate}
												</td>
												<td className='text-000080 text-12 font-medium py-[6px] text-right'>
													{/* {translator(order.currency)}{" "} */}
													{digitChanger(
														moment(
															loanDetails?.collectedDateTime
														).format("DD-MM-YYYY")
													)}
												</td>
											</tr>
										)}
										{loanDetails?.repaidDate && (
											<tr>
												<td className='text-000080 text-12 font-medium py-[6px]'>
													{ekkLanguage.loanDetails.repaidDate}
												</td>
												<td className='text-000080 text-12 font-medium py-[6px] text-right'>
													{digitChanger(
														moment(
															loanDetails?.repaidDate
														).format("DD-MM-YYYY")
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							}
						</>
					</div>
					{collection.length ? (
						<>
							<div className='px-[10px] pt-3'>
								<h4 className='font-bold text-12'>
									{ekkLanguage.orderDetails.fss}
								</h4>
							</div>
							<div className='px-[10px]'>
								{collection.map((el, i) => {
									return (
										<table
											className='table-fixed w-full'
											style={{ borderBottom: "2px solid #F2F3F7" }}>
											<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
												<tr>
													<td className='text-black-1000 text-12 font-bold py-[6px]'>
														{
															ekkLanguage.orderDetails
																.transactionType
														}
													</td>
													<td className='text-black-1000 text-12 font-bold py-[6px] text-right'>
														{el?.trns_type}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.gateway}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														<span>
															{el?.buyer_gateway?.bank?.name}
														</span>
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.accountName}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_gateway?.account_name}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{
															ekkLanguage.orderDetails
																.accountNumber
														}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_gateway?.account_no}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{
															ekkLanguage.orderDetails
																.transactionNumber
														}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_transaction_no}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.date}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{digitChanger(
															monthChanger(
																moment(el?.payment_date).format(
																	"DD MMM, YYYY"
																)
															)
														)}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.amount}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{
															order?.country
																?.currency_symbol_native
														}
														{digitChanger(
															Number(el?.amount).toFixed(2)
														)}
													</td>
												</tr>

												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.receipt}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.image ? (
															<img
																// onClick={() => {
																// 	let details = {
																// 		...el,
																// 	};
																// 	dispatch(setPaymentDetails(details));
																// 	navigate("/fs-payment-reciept");
																// }}
																src={el?.image}
																alt='reciept'
																className='rounded-[10px] block h-[100px] w-[100px] m-auto lg:ml-[167px] ml-[50px]'
																style={{
																	background: "#E6E6E6",
																}}
															/>
														) : (
															ekkLanguage.orderDetails
																.receiptNotProvided
														)}
													</td>
												</tr>
											</tbody>
										</table>
									);
								})}
							</div>
						</>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
}

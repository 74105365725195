import React, { useState } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import PlayPauseBtn from "./PlayPauseBtn";

export default function AudioPlayer({ data, setAudioPlayer, audioPlayer }) {
	const [audioRef] = useState(() => React.createRef());
	const [isPlaying, setIsPlaying] = useState(false);
	const handlePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	return (
		<>
			<PlayPauseBtn
				audioRef={audioRef}
				handlePlayPause={handlePlayPause}
				audioPlayer={audioPlayer}
				isPlaying={isPlaying}
				setAudioPlayer={setAudioPlayer}
			/>
			<AudioVisualizer
				blob={data?.audioBlob}
				width={200}
				height={52}
				barWidth={3}
				gap={1.5}
				barColor={"white"}
			/>

			<audio
				src={data?.audioUrl}
				ref={audioRef}
				onEnded={() => setIsPlaying(false)}
			/>
		</>
	);
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUserBusiness } from "config/user";
import { Navigate } from "react-router-dom";
import CheckBusiness from "components/CheckBusiness";
import { selectors } from "redux/container/authSlice";
import { setLanguage } from "config/language";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Partners from "./Partners";
import OurTeam from "./OurTeam";
import BannerRegistration from "./BannerRegistration";

export default function RegistrationPage() {
	const [scrolling, setScrolling] = useState(false);
	const { loginStatus } = useSelector(selectors.getAuth);
	if (loginStatus) {
		let business = getUserBusiness();
		if (business) {
			return <Navigate to='/dashboard' />;
		} else {
			return <CheckBusiness />;
		}
	}

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}
	return (
		<>
			<section style={{ background: "#f2f3f7" }}>
				<Navigation scrolling={scrolling ? "isScrolled" : ""} />
				<BannerRegistration />
				<OurTeam />
				<Partners />
				<Footer />
			</section>
		</>
	);
}

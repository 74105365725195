import SuperFetch from "config/SuperFetch";
import { baseUrl } from "config/apiUrl";

const Customer = {
	getCustomerInfo: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/total-analytics-buyer?buyerId=${id}`
		);
	},
	getCustomer: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/customer/${id}`);
	},
};
export default Customer;

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Bug from "assets/images/icon/svg/Bug";
import Delivery from "assets/images/icon/svg/Delivery";
import Walet from "assets/images/icon/svg/Walet";
import {
	addStatusClass,
	digitChanger,
	numberWithCommas,
	translator,
} from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function OrderStatus({ order, fsInfo }) {
	let status = addStatusClass(order?.order_status);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const orderUserType = useSelector(
		(state) => state.orderDetails.userOrderType
	);

	return (
		<div className='mt-9 mb-4'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='text-center'>
							<h2 className='text-black-1000 text-20 font-bold mb-3'>
								{orderUserType === "seller"
									? order?.created_by_business?.business_name
									: order?.seller?.business_name}
							</h2>
							<h3 className='text-primary text-20 font-bold mb-3'>
								{order?.country?.currency_symbol_native}
								{digitChanger(
									numberWithCommas(
										order?.total -
											Number(order?.fs_coupon_amount ?? 0)
									)
								)}{" "}
								{/* | {ekkLanguage.orderDetails.remaining} {":"}{" "}
								{order?.country?.currency_symbol_native}
								{digitChanger(
									numberWithCommas(
										order?.total -
											order?.total_paid -
											Number(order?.fs_coupon_amount ?? 0)
									)
								)} */}
							</h3>

							<p className='text-black-500 text-16 font-bold flex items-center justify-center'>
								{order?.order_origin === "EkkBaz" &&
									orderUserType === "seller" &&
									"Origin: EkkBaz |"}
								{orderUserType === "seller" &&
									ekkLanguage.orderDetails.createdBy}{" "}
								{orderUserType === "seller" &&
									order?.created_by_user?.full_name}
								{order?.order_origin === "EkkHero Sales" &&
									orderUserType === "seller" && (
										<img
											src='/assets/images/hero-icon.png'
											className='h-[14px] w-[14px] ml-2'
											alt='hero'
										/>
									)}
							</p>
						</div>
					</IonCol>
				</IonRow>

				<IonRow>
					<IonCol size='12'>
						<div className='order-status'>
							<ol className='steps'>
								<li
									className={`step ${
										(fsInfo?.loanStatus === "Approved") |
										(fsInfo?.loanStatus ===
											"Down Payment Fully Paid") |
										(fsInfo?.loanStatus ===
											"Down Payment Partially Paid") |
										(fsInfo?.loanStatus ===
											"Seller Payment Fully Paid") |
										(fsInfo?.loanStatus ===
											"Seller Payment Partially Paid") |
										(fsInfo?.loanStatus === "Fully Repaid") |
										(fsInfo?.loanStatus === "Partially Repaid") |
										(fsInfo?.loanStatus === "Fully Delivered") |
										(fsInfo?.loanStatus === "Partially Delivered") |
										(fsInfo?.loanStatus === "1st Default") |
										(fsInfo?.loanStatus === "2nd Default") |
										(fsInfo?.loanStatus === "3rd Default") |
										(fsInfo?.loanStatus === "In Settlement") |
										(fsInfo?.loanStatus === "Closed Settlement")
											? "is-complete"
											: ""
									} `}
									data-step='1'>
									<span className='absolute  bottom-[-40px] text-12'>
										{ekkLanguage.orderDetails.approve}
									</span>
								</li>
								<li
									className={`step ${
										(fsInfo?.loanStatus ===
											"Down Payment Fully Paid") |
										(fsInfo?.loanStatus ===
											"Seller Payment Fully Paid") |
										(fsInfo?.loanStatus ===
											"Down Payment Partially Paid") |
										(fsInfo?.loanStatus ===
											"Seller Payment Partially Paid") |
										(fsInfo?.loanStatus === "Fully Repaid") |
										(fsInfo?.loanStatus === "Partially Repaid") |
										(fsInfo?.loanStatus === "Fully Delivered") |
										(fsInfo?.loanStatus === "Partially Delivered") |
										(fsInfo?.loanStatus === "1st Default") |
										(fsInfo?.loanStatus === "2nd Default") |
										(fsInfo?.loanStatus === "3rd Default") |
										(fsInfo?.loanStatus === "In Settlement") |
										(fsInfo?.loanStatus === "Closed Settlement")
											? "is-complete"
											: ""
									} `}
									data-step='2'>
									<span className='absolute bottom-[-40px] text-12'>
										{ekkLanguage.orderDetails.downPayment}
									</span>
								</li>

								<li
									className={`step ${
										(fsInfo?.loanStatus ===
											"Seller Payment Fully Paid") |
										(fsInfo?.loanStatus ===
											"Seller Payment Partially Paid") |
										(fsInfo?.loanStatus === "Fully Repaid") |
										(fsInfo?.loanStatus === "Partially Repaid") |
										(fsInfo?.loanStatus === "Fully Delivered") |
										(fsInfo?.loanStatus === "Partially Delivered") |
										(fsInfo?.loanStatus === "1st Default") |
										(fsInfo?.loanStatus === "2nd Default") |
										(fsInfo?.loanStatus === "3rd Default") |
										(fsInfo?.loanStatus === "In Settlement") |
										(fsInfo?.loanStatus === "Closed Settlement")
											? "is-complete"
											: ""
									} `}
									data-step='3'>
									<span className='absolute bottom-[-40px] text-12'>
										{ekkLanguage.orderDetails.sellerPayment}
									</span>
								</li>
								<li
									className={`step ${
										(fsInfo?.loanStatus === "Fully Repaid") |
										(fsInfo?.loanStatus === "Partially Repaid") |
										(fsInfo?.loanStatus === "Fully Delivered") |
										(fsInfo?.loanStatus === "Partially Delivered") |
										(fsInfo?.loanStatus === "1st Default") |
										(fsInfo?.loanStatus === "2nd Default") |
										(fsInfo?.loanStatus === "3rd Default") |
										(fsInfo?.loanStatus === "In Settlement") |
										(fsInfo?.loanStatus === "Closed Settlement")
											? "is-complete"
											: ""
									} `}
									data-step='4'>
									<span className='absolute bottom-[-40px] text-12'>
										{ekkLanguage.orderDetails.delivered}
									</span>
								</li>
								<li
									className={`step ${
										(fsInfo?.loanStatus === "Fully Repaid") |
										(fsInfo?.loanStatus === "Partially Repaid") |
										(fsInfo?.loanStatus === "1st Default") |
										(fsInfo?.loanStatus === "2nd Default") |
										(fsInfo?.loanStatus === "3rd Default") |
										(fsInfo?.loanStatus === "In Settlement") |
										(fsInfo?.loanStatus === "Closed Settlement")
											? "is-complete"
											: ""
									} `}
									data-step='5'>
									<span className='absolute  bottom-[-40px] text-12'>
										{ekkLanguage.orderDetails.repaid}
									</span>
								</li>
							</ol>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}

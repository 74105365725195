import React, { useState } from "react";
import {
	IonApp,
	IonButton,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { useNavigate } from "react-router-dom";
import ImageUploader from "components/mobile-components/VerifyBusiness/ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import { getUserBusiness, setUserBusiness } from "config/user";
import { setKYC } from "redux/container/businessSettingsSlice";
import User from "services/User";
import Business from "services/Business";
import Cross from "assets/images/icon/svg/Cross";

export default function TaxVerificationPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState(bs.data?.tin_img ?? "");
	const [loader, setLoader] = useState(false);
	const business = getUserBusiness();

	let dispatch = useDispatch();

	const nextHandler = async () => {
		if (!files1[0].preview && bs.data?.tin_no) {
			return navigate("/brand-agreement-verification");
		}
		setLoader(true);
		let frontPart = new FormData();
		frontPart.append("key", files1[0]);

		if (files1.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			if (response.status === 201) {
				setLoader(false);
				dispatch(
					setKYC({
						tin_no: bs.data?.tin_no,
						tin_img: response.results[0],
					})
				);

				let query = {
					tin_no: bs.data?.tin_no,
					tin_img: response.results[0],
					business: business?.id,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							tin_no: bs.data?.tin_no,
							tin_img: response.results[0],
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/brand-agreement-verification");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/brand-agreement-verification");
				}
			} else {
				setLoader(false);
			}
		}
	};
	const deleteQueryHandler = () => {
		dispatch(
			setKYC({
				tin_no: "",
			})
		);
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<div className='pt-14 px-4'>
						<div className='py-5'>
							<p className='text-16 font-semibold text-black-1000 pb-3 '>
								{ekkLanguage.kycVerification.tinNumber}
							</p>

							<div className='relative w-full'>
								<input
									className={`bg-white w-full h-[50px] rounded-[7px] pl-5 search-input border common-search $`}
									type='string'
									placeholder={
										ekkLanguage.kycVerification.tinNumberPlaceholder
									}
									style={{
										boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.05) ",
									}}
									value={bs.data?.tin_no}
									onChange={(e) => {
										dispatch(
											setKYC({
												tin_no: e.target.value,
											})
										);
									}}></input>
								{bs.data?.tin_no && (
									<span
										className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
										onClick={deleteQueryHandler}>
										<Cross />
									</span>
								)}
							</div>
						</div>
						<div className='pb-5'>
							<div className='pb-[5px] flex items-center justify-between'>
								<h4 className='font-bold text-black text-16'>
									{ekkLanguage.kycVerification.taxLabel}
								</h4>
								<span
									className={`h-10 w-10 flex items-center justify-center ${
										files1 ? "bg-primary" : "bg-white"
									} rounded-[5px]`}
									onClick={() => setFiles1("")}>
									<Delete color={files1 ? "white" : "red"} />
								</span>
							</div>

							<div className='mt-2'>
								<ImageUploader
									files={files1}
									setFiles={setFiles1}
									bHeight={438}
									placeholder={
										ekkLanguage.kycVerification.taxPlaceholder
									}
									imgSource={"/assets/images/tax_new.png"}
								/>
							</div>
						</div>
						<div className='pt-24 text-center pb-10'>
							<IonButton
								disabled={
									(files1 && bs.data?.tin_no ? false : true) | loader
								}
								color={files1 && bs.data?.tin_no ? "success" : "dark"}
								className='font-bold h-[50px] w-full'
								onClick={nextHandler}>
								{loader ? (
									<IonSpinner></IonSpinner>
								) : (
									<>
										{ekkLanguage.businessCreateName.btnLabel}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</>
								)}
							</IonButton>
							{/* {!loader && (
								<IonButton
									color='white'
									className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
									onClick={() =>
										navigate("/brand-agreement-verification")
									}>
									{ekkLanguage.kycVerification.giveLater}
									<span className='pl-2'>
										<ArrayRight color='red' />
									</span>
								</IonButton>
							)} */}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

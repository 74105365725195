import LeftArrow from "assets/images/icon/svg/LeftArrow";
import Menu from "assets/images/icon/svg/Menu";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DotMenu from "assets/images/icon/svg/DotMenu";
import { clearAuth } from "config/authFetch";
import {
	setDashboardBottomSheet,
	eraseMapLocation,
	setBottomSheet,
	setBrandDetails,
	setBrandList,
	setBusinessBottomSheet,
	setEditEmployees,
	setMyEmployees,
	setMyProduct,
	setProductDetails,
	setAddBankBottomSheet,
	setSupplierDetails,
	setSupplierList,
	// // eraseMapLocation,
	// setBottomSheet,
	// setBrandDetails,
	// setBrandList,
	// setBusinessBottomSheet,
	// setEditEmployees,
	// setMyEmployees,
	// setMyProduct,
	// setProductDetails,
} from "redux/container/userSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { setCreateUserBusiness } from "config/user";
import { actions, logout } from "redux/container/authSlice";
import SupplierBell from "assets/images/icon/svg/SupplierBell";
import { selectTotalUnreadCount } from "redux/container/notificationSlice";
import { setDriverData } from "redux/container/driverSlice";
import { digitChanger } from "helpers/helper";
import { clearPaymentCard } from "redux/container/paymentMethodSlice";
import { clearCart } from "redux/container/shoppingCartSlice";
import { removeFsCoupon, setPaymentInfo } from "redux/container/loanSlice";
import Mic from "assets/images/icon/svg/Mic";

// import SupplierBell from "assets/images/icon/svg/SupplierBell";

export default function BackButtonTitle({
	title,
	burgerMenu,
	menuIconClick,
	from,
	type,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const unreadCount = useSelector(selectTotalUnreadCount);
	const onCloseModal = () => {
		setIsOpen(false);
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<>
			<div className=' bg-primary z-40 flex pl-4 items-center justify-between py-2 fixed left-0 top-0 w-full'>
				<div className='flex items-center'>
					{burgerMenu ? (
						<div
							className='flex items-center justify-center h-10 w-10 mr-4 rounded-[10px] bg-FB766E'
							onClick={menuIconClick}>
							<Menu color='white' />
						</div>
					) : (
						<div
							className='flex items-center justify-center h-10 w-10 mr-4 rounded-[10px] bg-FB766E'
							onClick={() => {
								if (
									location?.pathname.split("/")[1] === "brand-details"
								) {
									navigate("/brand-list");
								} else if (
									location?.pathname.split("/")[1] === "brand-list"
									// location?.pathname.split("/")[1] === "categories" ||
									// location?.pathname.split("/")[1] === "sub-categories"
								) {
									navigate("/product-list");
								} else if (
									location?.pathname.split("/")[1] ===
									"supplier-detail"
								) {
									dispatch(eraseMapLocation());
									navigate("/supplier-list");
								} else if (
									location?.pathname.split("/")[1] ===
									"search-location"
								) {
									dispatch(eraseMapLocation());
									navigate(-1);
								} else if (
									location?.pathname.split("/")[1] === "pick-location"
								) {
									setCreateUserBusiness(null);
									dispatch(eraseMapLocation());
									navigate(-1);
								} else if (
									location?.pathname.split("/")[1] ===
										"product-details" &&
									!type
								) {
									navigate(-1);
								} else if (
									location?.pathname.split("/")[1] ===
										"product-details" ||
									location?.pathname.split("/")[1] === "create-pack" ||
									location?.pathname.split("/")[1] ===
										"create-offer" ||
									location?.pathname.split("/")[1] === "create-product"
								) {
									navigate("/product-list");
								} else if (
									location?.pathname.split("/")[1] ===
									"select-business"
								) {
									dispatch(actions.logout());
									localStorage.removeItem("master_token");
									localStorage.removeItem("_u");
									localStorage.removeItem("_b");
									localStorage.removeItem("cb");
									localStorage.setItem("auth", "false");
									clearAuth();
									navigate("/");
								} else if (
									location?.pathname.split("/")[1] === "create-order"
								) {
									setIsOpen(true);
								} else if (
									location?.pathname.split("/")[1] === "my-brand"
								) {
									navigate("/create-product");
								} else {
									navigate(-1);
								}
							}}>
							<LeftArrow color='#ffffff' />
						</div>
					)}

					<h5 className='text-white text-18 font-bold'>{title}</h5>
				</div>
				{from === "dashboard" && (
					<div className='mr-4 flex items-center'>
						<span onClick={() => navigate("/baz-ai")} className='mr-2'>
							{/* <Mic /> */}
							<img
								className='w-[26px] h-[26px]'
								src='assets/images/bazAiIcon.png'
								alt='icon'
							/>
						</span>
						<div
							onClick={() => {
								navigate(`/notifications`);
							}}
							className='h-11 w-11 rounded-[10px] mr-2  flex justify-center items-center relative'>
							<SupplierBell />
							{unreadCount ? (
								<div className='text-8 text-primary rounded-full h-[15px] w-[15px] flex items-center justify-center font-bold absolute top-1.5 right-2.5 bg-white'>
									<p className='mx-[1px] mt-[0.75px] mb-[0px] font-bold'>
										{digitChanger(unreadCount)}
									</p>
								</div>
							) : (
								""
							)}
						</div>
						<span onClick={() => dispatch(setDashboardBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "orderDetailsPage" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "businessProfile" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBusinessBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "myEmployees" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setMyEmployees(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "employeesDetails" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setEditEmployees(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "productList" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setMyProduct(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "brandList" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBrandList(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "brandDetails" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBrandDetails(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{/* {from === "supplierDetail" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBrandDetails(true))}>
							<DotMenu />
						</span>
					</div>
				)} */}
				{from === "supplierList" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setSupplierList(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "supplierDetail" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setSupplierDetails(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "productDetails" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setProductDetails(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "addedBankList" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setAddBankBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
			</div>
			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				id='pwa-modal'
				style={customStyles}>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOrder.alert}
					</h6>
					<p className='text-12 font-medium pb-[10px] text-black-500'>
						{ekkLanguage.createOrder.backButtonMessage}
					</p>
					<div className='flex justify-end pt-3'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white text-14'
							onClick={() => {
								localStorage.removeItem("cart");
								localStorage.removeItem("ps");
								dispatch(clearCart());
								dispatch(clearPaymentCard());
								dispatch(setPaymentInfo({}));
								navigate(`/supplier-list`);
								dispatch(removeFsCoupon());
							}}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

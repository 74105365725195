import React, { useRef, useState } from "react";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { updateToken } from "config/SuperFetch";
import {
	setCreateUserBusiness,
	setLocalUser,
	setUser,
	setUserBusiness,
} from "config/user";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import { IonSpinner } from "@ionic/react";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";
import { initBusiness } from "config/user";
export default function Password({
	password,
	setPassword,
	loading,
	startLoading,
	setIsForgotPassword,
	stopLoading,
	phoneNo,
	country,
	countryCode,
	sSize,
}) {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [loginClick, setLoginClick] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [errorMessage, setErrorMessage] = useState("");
	const [eyeIcon, setEyeIcon] = useState(false);

	const forgotPass = () => {
		return (
			<span
				onClick={() => onForgotPassword(phoneNo, country, countryCode)}
				className='text-xs underline text-red cursor-pointer'>
				{ekkLanguage?.password.forgetPass}
			</span>
		);
	};

	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.current.click();
		}
	};

	//forgotpassword handler
	const onForgotPassword = async (phoneNumber, country, countryCode) => {
		// navigate("/login");
		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
		};
		try {
			startLoading();

			let response = await Auth.resendOptToken(req_body);
			if (response.success) {
				await setIsForgotPassword(true);
				// setBlockIndex(authBox_Block.TOKEN);
				toast.success(ekkLanguage?.password.otpSendLabel);
				navigate("/submit-token");
			}
			stopLoading();
		} catch (e) {
			stopLoading();
			console.log(e);
		}
	};

	//this is login handler for calling redux login dispatch.
	const loginHandler = async (countryCode, country, phoneNo, password) => {
		setErrorMessage("");
		setLoginClick(true);
		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
			password,
		};
		try {
			const response = await Auth.login(req_body);
			if (response?.status === 200) {
				dispatch(loginFromSignUp(response));
				setLoginClick(false);
				updateToken(response);
				setLocalUser(response?.data);
				if (response?.data?.user.Country === "Bangladesh") {
					// navigate("/home");
					setLanguage("bn");
					dispatch(selectLan());
					checkBusiness(response?.data);
				} else if (response?.data?.user.Country === "Viet Nam") {
					// navigate("/home");
					setLanguage("vn");
					dispatch(selectLan());
					checkBusiness(response?.data);
				} else if (response?.data?.user.Country === "Malaysia") {
					// navigate("/home");
					setLanguage("my");
					dispatch(selectLan());
					checkBusiness(response?.data);
				} else {
					setLanguage("en");
					dispatch(selectLan());
					checkBusiness(response?.data);
				}
			}
		} catch (e) {
			stopLoading();
			console.log(e);
			setLoginClick(false);
			setErrorMessage(ekkLanguage?.password.passError);
			// toast.error(e.errorData.message);
		}
	};

	const checkBusiness = async (business) => {
		if (business?.associated_businesses.length === 0) {
			setCreateUserBusiness(initBusiness);
			navigate("/industry-type");
		} else if (business?.associated_businesses.length > 1) {
			navigate("/select-business");
		} else if (business?.associated_businesses.length === 1) {
			try {
				if (
					business?.associated_businesses[0]?.business_status !==
					"Black Listed"
				) {
					const res = await Auth.businessSelect(
						business?.associated_businesses[0]
					);
					if (res.status === 200) {
						setUserBusiness(res?.business_info);
						setUser(res?.user);
						localStorage.removeItem("setUser");
						updateToken(res);
						window.location.href = "/baz-ai";
					}
				} else {
					navigate("/black-listed");
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<div className=' bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md w-full'>
			<>
				<label
					className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
					{ekkLanguage?.password.passwordLabel}
					<span
						onClick={() => {
							localStorage.clear();
							window.location.href = "/";
						}}
						className='text-xs text-red cursor-pointer flex items-center'>
						<span className='mr-1'>
							<LeftArrow2 />
						</span>
						{ekkLanguage?.password.backWord}
					</span>
				</label>
				<div className='relative'>
					<input
						type={!eyeIcon ? "password" : "text"}
						onKeyPress={_handleKeyPress}
						onChange={(event) => setPassword(event.target.value)}
						placeholder={ekkLanguage?.password.passwordPlaceHolder}
						id={"ekk-input"}
						style={{
							paddingLeft: sSize.matches ? 10 : 20,
							height: sSize.matches ? 50 : 60,
							flex: "flex: 1 0 auto",
							borderRadius: 15,
							borderWidth: 1,
							borderLeft: "1px solid [#00000026]",
						}}
						className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
					/>
					<span
						className='absolute right-5 top-[50%]'
						onClick={() => setEyeIcon(!eyeIcon)}
						style={{ transform: "translateY(-50%)" }}>
						{eyeIcon ? <EyeOn /> : <EyeOff />}
					</span>
				</div>

				<div className='py-4 flex justify-between items-center w-full'>
					<p className='text-red text-xs text-primary'>{errorMessage}</p>
					<div>{forgotPass()}</div>
				</div>
				{/* <div className="flex w-full items-center justify-end"></div> */}
			</>

			<div className=''>
				<button
					onClick={() =>
						loginHandler(countryCode, country, phoneNo, password)
					}
					ref={clickBtnRef}
					style={
						loginClick
							? {
									color: "rgba(0, 0, 0, 0.26)",
									backgroundColor: "rgba(0, 0, 0, 0.12)",
									userSelect: "none",
									cursor: "no-drop",
							  }
							: {
									background:
										"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
							  }
					}
					className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
					{loginClick ? (
						<IonSpinner name='crescent'></IonSpinner>
					) : (
						ekkLanguage?.password.btnLabel
					)}
				</button>
			</div>
		</div>
	);
}

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import HeartOutline from "assets/images/icon/svg/HeartOutline";
import Share from "assets/images/icon/svg/Share";
import Star from "assets/images/icon/svg/Star";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import RecommendProduct from "../Home/RecommendProduct";
import ProductFilter from "../SelectProduct/ProductFilter";
import ProductSearch from "../SelectProduct/ProductSearch";

export default function SuppliersDetails({
	supplierInfo,
	product,
	settinges,
	productCount,
	productBrands,
	getProductsFromBrands,
	getProductBrands,
	setSearchQ,
	searchQ,
	singleProductSearch,
	setSelectedBrand,
	setHasMoreData,
	setReachedEnd,
	from,
	brandId,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='porduct-details'>
			<IonGrid>
				<IonRow className='px-2'>
					<IonCol size='12'>
						<div>
							<h5 className='font-semibold text-20 text-dark pt-2 pb-1'>
								{supplierInfo?.business_name}
							</h5>
							<p className='text-16 text-black-500 font-semibold'>
								{digitChanger(supplierInfo?.address_line)}
							</p>
							<p className='text-18 text-primary font-semibold'>
								{digitChanger(productCount?.count ?? digitChanger(0))}{" "}
								{ekkLanguage.mysuppliers.skusLabel}
							</p>
						</div>
					</IonCol>
					<IonCol size='12' className='pb-6'>
						<ul className='flex justify-between'>
							{/* <li>
								<div className='flex items-center'>
									<Star width={15} height={15} color='#F94B41' />
									<Star width={15} height={15} color='#F94B41' />
									<Star width={15} height={15} color='#F94B41' />
									<Star width={15} height={15} color='#F94B41' />
									<Star width={15} height={15} color='#00000030' />
									<div className='pl-2'>
										<p
											className='text-16'
											style={{ color: "#00000080" }}>
											4.0
										</p>
									</div>
								</div>
							</li>
							<li>
								<div>
									<p className='text-16 text-000054'>3.2k Sold</p>
								</div>
							</li>
							<li>
								<div>
									<HeartOutline />
								</div>
							</li> */}
							<li>
								<div>
									<Share color='black' />
								</div>
							</li>
						</ul>
					</IonCol>
				</IonRow>
				<ProductSearch
					setSearchQ={setSearchQ}
					searchQ={searchQ}
					fetchProduct={getProductBrands}
					singleProductSearch={singleProductSearch}
					setSelectedBrand={setSelectedBrand}
				/>
				<ProductFilter
					productBrands={productBrands}
					getProductsFromBrands={getProductsFromBrands}
					fetchProduct={getProductBrands}
					setSearchQ={setSearchQ}
					setSelectedBrand={setSelectedBrand}
				/>
				<RecommendProduct
					from={from}
					recommendData={product}
					title={ekkLanguage.mysuppliers.productTitleLabel}
					settinges={settinges}
				/>
			</IonGrid>
		</div>
	);
}

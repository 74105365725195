import React from "react";

export default function CollectorAvatar({ color = "#FF564C" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98837 10.5488C4.04161 10.5488 1.52515 10.9944 1.52515 12.7787C1.52515 14.563 4.02565 15.0245 6.98837 15.0245C9.93512 15.0245 12.4509 14.5782 12.4509 12.7946C12.4509 11.0111 9.95109 10.5488 6.98837 10.5488Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98845 8.00495C8.92224 8.00495 10.4896 6.43687 10.4896 4.50309C10.4896 2.5693 8.92224 1.00195 6.98845 1.00195C5.05467 1.00195 3.48659 2.5693 3.48659 4.50309C3.48006 6.43034 5.03725 7.99842 6.96378 8.00495H6.98845Z"
        stroke={color}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { digitChanger } from "helpers/helper";
import React from "react";

export default function BothPartiesDetailsMY({
  businessDetails,
  kycDetails,
  createdBusinessDetails,
}) {
  return (
    <div>
      {/* first part  */}
      <div>
        <p className="text-justify text-222222 text-14">
          <span className="font-bold">
            {createdBusinessDetails?.business_name}
          </span>{" "}
          adalah perniagaan milikan tunggal. yang berdaftar dan tunggal syarikat
          milikan di Bangladesh, yang beralamat:{" "}
          <span className="font-bold">
            {createdBusinessDetails?.address_line
              ? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
              : `${businessDetails?.address_line}, ${businessDetails?.city}`}
          </span>
          ,No. Lesen Perdagangan {digitChanger(kycDetails?.trade_no)} pada bagi
          pihak Pemilik/Kad Pengenalan Kebangsaan syarikat tersebut Tidak.{" "}
          {digitChanger(kycDetails?.nid_no)} selepas ini dirujuk dalam
          Perjanjian ini sebagai Penjual/Pembekal, yang mana ungkapan hendaklah
          termasuk waris dan pemilih/penama.
        </p>
        <div className="my-5 flex justify-end w-full items-center">
          <div className="w-[50px] h-[1px] bg-222222 mr-2"></div>
          <p className="text-justify text-222222 text-14 font-bold">
            Pihak Pertama
          </p>
        </div>
      </div>
      <h3 className="font-bold text-center my-5">And</h3>
      {/* second part  */}
      <div>
        <p className="text-justify text-222222 text-14">
          <span className="font-bold">EkkBaz Private Limited</span> ialah
          syarikat. Yang berdaftar dan dikawal selia di bawah Syarikat Perbuatan
          Bangladesh. yang alamat berdaftarnya ialah:{" "}
          <span className="font-bold">
            Dataran Paya Lebar #07-54, 60 Paya Lebar Road Singapura 409051
          </span>
          , bagi pihak <span className="font-bold">Zobayeda Sultana</span>
          ,Mengurus Pengarah Syarikat, selepas ini dirujuk sebagai{" "}
          <span className="font-bold">EkkBaz, Pembeli/Platform</span> di dalam
          ini Perjanjian. Dengan ungkapan yang mana akan disertakan
          penggantinya, wakil undang-undang, pengarah dan calon.
        </p>
        <div className="my-5 flex justify-end w-full items-center">
          <div className="w-[50px] h-[1px] bg-222222 mr-2"></div>
          <p className="text-justify text-222222 text-14 font-bold">
            Pihak Kedua
          </p>
        </div>
      </div>
    </div>
  );
}

import { digitChanger } from "helpers/helper";
import React from "react";

export default function BothPartiesDetailsBN({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
}) {
	return (
		<div>
			{/* first part  */}
			<div>
				<p className='text-justify text-222222 text-14'>
					<span className='font-bold'>
						{createdBusinessDetails?.business_name}
					</span>{" "}
					একটি এক মালিকানা ব্যবসা প্রতিষ্ঠান যা বাংলাদেশের নিবন্ধিত এবং
					একমালিকানা ব্যবসা প্রতিষ্ঠান, যাহার ঠিকানাঃ{" "}
					<span className='font-bold'>
						{createdBusinessDetails?.address_line
							? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
							: `${businessDetails?.address_line}, ${businessDetails?.city}`}
					</span>
					, ট্রেড লাইসেন্স নং-{" "}
					<span className='font-bold'>
						{digitChanger(kycDetails?.trade_no)}
					</span>{" "}
					এর পক্ষে উক্ত প্রতিষ্ঠানের সত্ত্বাধিকারী/ জাতীয় পরিচয়পত্র নম্বর-{" "}
					<span className='font-bold'>
						{digitChanger(kycDetails?.nid_no)}
					</span>{" "}
					(অতঃপর এই চুক্তিপত্রে{" "}
					<span className='font-bold mr-1'>বিক্রেতা/সরবরাহকারি</span>
					হিসেবে উল্লেখ করা হবে, যে অভিব্যক্তি দ্বারা এর উত্তরাধিকারী এবং
					নির্বাচিত/ মনোনীত ব্যক্তিবর্গ অন্তর্ভুক্ত থাকবে)।
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						প্রথম পক্ষ
					</p>
				</div>
			</div>
			<h3 className='font-bold text-center my-5'>এবং</h3>
			{/* second part  */}
			<div>
				<p className='text-justify text-222222 text-14'>
					<span className='font-bold'>
						একবাজ বাংলাদেশ প্রাইভেট লিমিটেড
					</span>{" "}
					একটি কোম্পানি যা বাংলাদেশের কোম্পানি আইনে নিবন্ধিত ও নিয়ন্ত্রিত,
					যার নিবন্ধিত ঠিকানাঃ{" "}
					<span className='font-bold'>
						রাজ্জাক প্লাজা- ১৩তম ফ্লোর (লিফট-১২), মগবাজার, ঢাকা-১২১৭
					</span>
					, এর পক্ষে কোম্পানির ব্যবস্থাপনা পরিচালক
					<span className='font-bold mx-1'>জোবায়েদা সুলতানা</span>
					(অতঃপর এই চুক্তিপত্রে{" "}
					<span className='font-bold'>একবাজ, ক্রেতা/প্লাটফর্ম</span> হিসেবে
					উল্লেখ করা হবে, যে অভিব্যক্তি দ্বারা এর উত্তরাধিকারী, আইনী
					প্রতিনিধি, পরিচালক এবং নির্বাচিত/ মনোনীত ব্যক্তিবর্গ অন্তর্ভুক্ত
					থাকবে)।
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						দ্বিতীয় পক্ষ
					</p>
				</div>
			</div>
		</div>
	);
}

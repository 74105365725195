import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
	IonLoading,
	useIonAlert,
} from "@ionic/react";
import { Input } from "components/global-components/common/Input";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import LocationPin from "assets/images/icon/svg/LocationPin";
import {
	getCreateUserBusiness,
	getUser,
	setCreateUserBusiness,
	setLocalUser,
	setUserBusiness,
} from "config/user";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Auth from "services/Auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateToken } from "config/SuperFetch";
import { useDispatch, useSelector } from "react-redux";
import { postalCodeInfo } from "data/postalCode";
import { eraseMapLocation } from "redux/container/userSlice";
import Business from "services/Business";
import { setKYC } from "redux/container/businessSettingsSlice";

export default function BusinessLocation() {
	const business = getCreateUserBusiness();
	const location = useSelector((state) => state.user.mapLocation);
	const [address, setAddress] = useState(business?.address_line);
	const [city, setCity] = useState(business?.city);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let postalCode = postalCodeInfo.find((el) =>
		String(location.area).includes(el.thana)
	);

	const [postCode, setPostCode] = useState(business?.postal_code);
	const [email, setEmail] = useState(getCreateUserBusiness().email);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cityError, setCityError] = useState("");
	const [postCodeError, setPostCodeError] = useState("");
	const [emailError, setEmailError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const data = getCreateUserBusiness();

	async function businessDataSave() {
		if (address) {
			setError("");
			if (city) {
				setCityError("");
				if (postCode) {
					setPostCodeError("");
					if (email) {
						setEmailError("");
						try {
							// console.log(city, postCode, address, email);
							setLoading(true);
							const response = await Business.createUserBusiness({
								...data,
								business_group: "Seller",
							});
							if (response.status === 201) {
								let userData = {
									associated_businesses: [{ ...response.results }],
									user: response?.user,
									business_group: "Seller",
								};
								setUserBusiness({ ...response.results });
								dispatch(
									setKYC({
										business: response.results.id,
										kycUpdate: false,
									})
								);

								setLoading(false);
								toast.success(
									ekkLanguage.businessLocation.successLabel
								);
								localStorage.setItem(
									"setUser",
									JSON.stringify(userData)
								);
								updateToken(response);
								dispatch(eraseMapLocation());
								const res = await Auth.businessSelect2({
									...response.results,
								});
								if (res.status === 200) {
									setUserBusiness(res?.business_info);
									updateToken(res);
								}
								navigate("/business-success");
							} else {
								setLoading(false);
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						setEmailError(
							ekkLanguage.businessLocation.validationEmailLabel
						);
					}
				} else {
					setPostCodeError(
						ekkLanguage.businessLocation.validationPostCodeLabel
					);
				}
			} else {
				setCityError(ekkLanguage.businessLocation.validationCityLabel);
			}
		} else {
			setError(ekkLanguage.businessLocation.validationLabel);
		}
	}

	useEffect(() => {
		if (address) {
			setError("");
		}
	}, [address]);

	return (
		<div className='business-location pt-14'>
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={ekkLanguage.businessLocation.loader}
				/>
			) : (
				""
			)}
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='pt-5 text-center'>
							<h5 className='text-black-1000 text-16 font-medium pb-6'>
								{ekkLanguage.businessLocation.paragraph}
							</h5>
							<div className='text-center'>
								<span className='business-loation-pin '>
									<img
										className='m-auto'
										src='assets/images/location.png'
										alt='location'
									/>
								</span>
							</div>
							<div className='text-left'>
								<IonRow className='pb-7'>
									<IonCol size='12'>
										<div>
											<Input
												onChange={(event) => {
													setAddress(event.target.value);
													const business = getCreateUserBusiness();
													business.address_line =
														event.target.value;
													setCreateUserBusiness(business);
												}}
												type='text'
												label={ekkLanguage.businessLocation.address}
												placeholder={
													ekkLanguage.businessLocation
														.addressPlaceholder
												}
												required
											/>
											{/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.address}
                      </h5> */}
											{/* <IonItem>
                        <IonInput
                          value={address}
                          placeholder={
                            ekkLanguage.businessLocation.addressPlaceholder
                          }
                          onIonChange={(e) => {
                            setAddress(e.detail.value);
                            const business = getCreateUserBusiness();
                            business.address_line = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem> */}
											{error && (
												<div className='text-primary mt-2 text-right'>
													{error}
												</div>
											)}
										</div>
									</IonCol>
								</IonRow>
								<IonRow className='pb-7'>
									<IonCol size='6'>
										<div>
											{/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.city}
                      </h5>
                      <IonItem>
                        <IonInput
                          value={city}
                          placeholder={
                            ekkLanguage.businessLocation.cityPlaceholder
                          }
                          onIonChange={(e) => {
                            setCity(e.detail.value);
                            const business = getCreateUserBusiness();
                            business.city = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem> */}
											<Input
												onChange={(event) => {
													setCity(event.target.value);
													const business = getCreateUserBusiness();
													business.city = event.target.value;
													setCreateUserBusiness(business);
												}}
												type='text'
												label={ekkLanguage.businessLocation.city}
												placeholder={
													ekkLanguage.businessLocation
														.cityPlaceholder
												}
												required
											/>
											{cityError && (
												<div className='text-primary mt-2 text-right'>
													{cityError}
												</div>
											)}
										</div>
									</IonCol>
									<IonCol size='6'>
										<div>
											{/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.postalCode}
                      </h5>
                      <IonItem>
                        <IonInput
                          value={postCode}
                          placeholder={
                            ekkLanguage.businessLocation.postalCodePlaceholder
                          }
                          onIonChange={(e) => {
                            console.log(e.detail.value);
                            setPostCode(e.detail.value);

                            const business = getCreateUserBusiness();
                            business.postal_code = e.detail.value;
                            setCreateUserBusiness(business);
                            console.log(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem>
                      {postCodeError && (
                        <div className="text-primary mt-2 text-right">
                          {postCodeError}
                        </div>
                      )} */}
											<Input
												onChange={(event) => {
													setPostCode(event.target.value);
													const business = getCreateUserBusiness();
													business.postal_code =
														event.target.value;
													setCreateUserBusiness(business);
												}}
												type='text'
												label={
													ekkLanguage.businessLocation.postalCode
												}
												placeholder={
													ekkLanguage.businessLocation
														.postalCodePlaceholder
												}
												required
												errorMessage={postCodeError}
											/>
										</div>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size='12'>
										<div>
											{/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.email}
                      </h5>
                      <IonItem>
                        <IonInput
                          type="email"
                          value={email}
                          placeholder={
                            ekkLanguage.businessLocation.emailPlaceholder
                          }
                          onIonChange={(e) => {
                            setEmail(e.detail.value);
                            const business = getCreateUserBusiness();
                            business.email = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem> */}
											<Input
												onChange={(event) => {
													setEmail(event.target.value);
													const business = getCreateUserBusiness();
													business.email = event.target.value;
													setCreateUserBusiness(business);
												}}
												type='email'
												required
												label={ekkLanguage.businessLocation.email}
												placeholder={
													ekkLanguage.businessLocation
														.emailPlaceholder
												}
												errorMessage={emailError}
											/>
										</div>
									</IonCol>
								</IonRow>
							</div>
							<div className='pt-12'>
								<IonButton
									color='success'
									className='font-bold h-[50px] w-full'
									onClick={() => {
										businessDataSave();
									}}>
									<span className='text-white'>
										{ekkLanguage.businessLocation.btnLabel}
									</span>
								</IonButton>
							</div>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CouponList from "components/mobile-components/CoupnList";
import React from "react";
import { useSelector } from "react-redux";

function PromoCodeListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.couponList.pageTitle} />
					<section className='px-2 pt-14'>
						<CouponList />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default PromoCodeListPage;

// import Trash from "assets/images/icon/svg/Trash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { useDropzone } from "react-dropzone";
import Pen from "assets/images/icon/svg/Pen";
import Cross from "assets/images/icon/svg/Cross";
import Modal from "react-modal";
import { IonCol, IonRow } from "@ionic/react";
import Webcam from "react-webcam";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export default function ImageUploadWithCamera({
	uploadImage,
	setUploadImage,
	isCameraMood,
	setIsCameraMood,
	placeholder,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [isCameraOpen, setIsCameraOpen] = useState(false);
	const [isOpenUpload, setIsOpenUpload] = useState(false);

	const onOpenUploadModal = () => {
		setIsOpenUpload(true);
		setIsCameraOpen(false);
		setIsCameraMood(false);
	};
	const onCloseUploadModal = () => setIsOpenUpload(false);

	const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

	const webcamRef = React.useRef(null);

	const cameraOpenHandler = () => {
		setIsCameraMood(true);
		setIsCameraOpen(true);
		onCloseUploadModal();
	};
	let videoConstraints = {
		facingMode: facingMode,
		width: "100%",
		height: "100%",
		minWidth: "320px",
	};
	const trashImageHandler = () => {
		setUploadImage(null);
		setIsCameraOpen(false);
		setIsCameraMood(true);
	};

	const handleTakePhoto = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setUploadImage(imageSrc);
		setIsCameraOpen(false);
	}, [webcamRef, setUploadImage]);

	const handleClick = React.useCallback(() => {
		setFacingMode((prevState) =>
			prevState === FACING_MODE_USER
				? FACING_MODE_ENVIRONMENT
				: FACING_MODE_USER
		);
	}, []);

	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",

		onDrop: (acceptedFiles) => {
			onCloseUploadModal();
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setUploadImage(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});

	return (
		<div className='pt-4'>
			<div className='text-left  overflow-hidden'>
				<div className=' flex items-center justify-center bg-E6E6E6 h-[320px] relative'>
					{isCameraMood && (
						<div className='text-left rounded-[10px] overflow-hidden'>
							<div
								className='rounded-[10px] flex items-center justify-center'
								style={{
									boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
									background: "#E6E6E6",
									height: !isCameraOpen && "320px",
									width: "100%",
								}}>
								{isCameraOpen ? (
									<div className='relative photo-camera h-[320px]'>
										<Webcam
											className='webcam'
											audio={false}
											ref={webcamRef}
											screenshotFormat='image/jpeg'
											videoConstraints={videoConstraints}
											screenshotQuality={1}
										/>
										<div className='bg-black-500 absolute bottom-0 w-full flex items-center justify-between'>
											<span
												onClick={handleClick}
												className=' rounded-[7px] text-center flex items-center justify-center'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.1)",
												}}>
												<img
													src='/assets/images/camera-switch.png'
													alt='Camera'
													className='z-1'
												/>
											</span>
											<span
												onClick={handleTakePhoto}
												className='cursor-pointer rounded-[7px] text-center flex items-center justify-center'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.1)",
													left: "50%",
													transform: "translateX(-50%)",
												}}>
												<img
													src='/assets/images/camera-icon.png'
													alt='Camera'
													className='z-1'
												/>
											</span>
											<span></span>
										</div>
									</div>
								) : (
									<>
										{uploadImage ? (
											<>
												<img src={uploadImage} alt='avatar' />
												<div onClick={onOpenUploadModal}>
													<span
														className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
														style={{
															background: "#5A7EFF",
															lineHeight: "28px",
														}}>
														<Pen />
													</span>
												</div>
											</>
										) : (
											<div
												onClick={cameraOpenHandler}
												className='text-center rounded-[5px] inline-block bg-white px-9 py-5 cursor-pointer'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.07)",
												}}>
												<img
													className='m-auto'
													src='/assets/images/camera.jpg'
													alt='camera'
												/>
												<p className='text-14 pt-1 text-black-1000'>
													{/* {ekkLanguage.payment.takePhoto} */}
													take photo
												</p>
											</div>
										)}
									</>
								)}{" "}
							</div>
						</div>
					)}
					<>
						{!isCameraMood && (
							<>
								{uploadImage?.length ? (
									<>
										{uploadImage[0]?.preview ? (
											<>
												<img
													src={uploadImage[0]?.preview}
													alt='avatar'
												/>
												<div onClick={onOpenUploadModal}>
													<span
														className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
														style={{
															background: "#5A7EFF",
															lineHeight: "28px",
														}}>
														<Pen />
													</span>
												</div>
											</>
										) : (
											<>
												{Array.isArray(uploadImage) ? (
													<>
														<img
															src={uploadImage[0]}
															alt='avatar'
														/>
														<div onClick={onOpenUploadModal}>
															<span
																className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
																style={{
																	background: "#5A7EFF",
																	lineHeight: "28px",
																}}>
																<Pen />
															</span>
														</div>
													</>
												) : (
													<>
														<img src={uploadImage} alt='avatar' />
														<div onClick={onOpenUploadModal}>
															<span
																className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
																style={{
																	background: "#5A7EFF",
																	lineHeight: "28px",
																}}>
																<Pen />
															</span>
														</div>
													</>
												)}
											</>
										)}
									</>
								) : (
									<div
										className='text-center rounded-[5px] inline-block bg-white px-9 py-5 cursor-pointer'
										style={{
											boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
										}}
										onClick={onOpenUploadModal}>
										<img
											className='m-auto pb-[10px]'
											src='/assets/images/add-image.png'
											alt='camera'
										/>
										<p className='text-14 pt-1 text-black-1000'>
											{placeholder ??
												ekkLanguage.createProduct
													.productPhotoUpload}
										</p>
									</div>
								)}
							</>
						)}
					</>
				</div>
			</div>

			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenUpload}
				ariaHideApp={false}
				onRequestClose={onCloseUploadModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.createProduct.uploadPhoto}
						</h4>
						<span onClick={onCloseUploadModal} className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<IonRow>
							<IonCol size='6' style={{ padding: 0, paddingRight: 5 }}>
								<div {...getRootProps({ className: "dropzone" })}>
									<input {...getInputProps()} />
									<div
										className='text-center rounded-[5px] inline-block bg-white py-5 cursor-pointer w-full'
										style={{
											boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
										}}
										// onClick={onOpenUploadModal}
									>
										<img
											className='m-auto pb-[10px]'
											src='/assets/images/gallery.png'
											alt='camera'
										/>
										<p className='text-10 pt-1 text-delivered'>
											{ekkLanguage.createProduct.uploadFromGallery}
										</p>
									</div>
								</div>
							</IonCol>
							<IonCol size='6' style={{ padding: 0, paddingLeft: 5 }}>
								<div
									className='text-center rounded-[5px] inline-block bg-white py-5 cursor-pointer w-full'
									style={{
										boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
									}}
									onClick={cameraOpenHandler}>
									<img
										className='m-auto pb-[10px]'
										src='/assets/images/camera.png'
										alt='camera'
									/>
									<p className='text-10 pt-1 text-delivered'>
										{ekkLanguage.createProduct.takePhoto}
									</p>
								</div>
							</IonCol>
						</IonRow>
					</div>
				</div>
			</Modal>
		</div>
	);
}

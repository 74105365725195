import React, { useState } from "react";
import {
	IonApp,
	IonButton,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { useNavigate } from "react-router-dom";
import ImageUploader from "components/mobile-components/VerifyBusiness/ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import User from "services/User";
import { setKYC } from "redux/container/businessSettingsSlice";
import { getUserBusiness, setUserBusiness } from "config/user";
import Business from "services/Business";

export default function UtilityVerificationPage() {
	const bs = useSelector((state) => state.businessSettings);
	const [files1, setFiles1] = useState(bs.data?.address_proof_img ?? "");
	const [loader, setLoader] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	let dispatch = useDispatch();
	const business = getUserBusiness();

	const nextHandler = async () => {
		if (!files1[0].preview) {
			return navigate("/tax-verification");
		}
		setLoader(true);
		let frontPart = new FormData();
		frontPart.append("key", files1[0]);

		if (files1.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			if (response.status === 201) {
				setLoader(false);
				dispatch(
					setKYC({
						address_proof_img: response.results[0],
					})
				);

				let query = {
					address_proof_img: response.results[0],
					business: business?.id,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							address_proof_img: response.results[0],
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/tax-verification");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/tax-verification");
				}
			} else {
				setLoader(false);
			}
		}
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<div className='pt-14 px-4'>
						<div className='pb-5'>
							<div className='pt-4 pb-[5px] flex items-center justify-between'>
								<h4 className='font-bold text-black text-16'>
									{ekkLanguage.kycVerification.proofAddressLabel1}
								</h4>
								<span
									className={`h-10 w-10 flex items-center justify-center ${
										files1 ? "bg-primary" : "bg-white"
									} rounded-[5px]`}
									onClick={() => setFiles1("")}>
									<Delete color={files1 ? "white" : "red"} />
								</span>
							</div>
							<h6 className='font-medium text-[#00000080] text-14 pb-[10px]'>
								{ekkLanguage.kycVerification.proofAddressLabel2}
							</h6>

							<ImageUploader
								files={files1}
								setFiles={setFiles1}
								bHeight={438}
								placeholder={
									ekkLanguage.kycVerification.proofAddressPlaceholder
								}
								imgSource={"/assets/images/electric_bill.png"}
							/>
						</div>
						<div className='pt-24 text-center pb-10'>
							<IonButton
								disabled={(files1 ? false : true) | loader}
								color={files1 ? "success" : "dark"}
								className='font-bold h-[50px] w-full'
								onClick={nextHandler}>
								{loader ? (
									<IonSpinner></IonSpinner>
								) : (
									<>
										{ekkLanguage.businessCreateName.btnLabel}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</>
								)}
							</IonButton>
							{/* {!loader && (
								<IonButton
									color='white'
									className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
									onClick={() => navigate("/tax-verification")}>
									{ekkLanguage.kycVerification.giveLater}
									<span className='pl-2'>
										<ArrayRight color='red' />
									</span>
								</IonButton>
							)} */}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

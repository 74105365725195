import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Notification = {
  // * New inApp RTC APIs V2.0
  getNewCustomerNotificationList: (page, id, language = "EN", limit = 20) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-list/?user_type=seller&user_id=${id}&page=${page}&limit=${limit}&country=${language}`
    );
  },
  getNewSupplierNotificationList: (page, id, language = "EN", limit = 20) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-list/?user_type=buyer&user_id=${id}&page=${page}&limit=${limit}&country=${language}`
    );
  },
  setNewReadNotification: (notificationId) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/read/${notificationId}/?user_type=buyer`,
      {
        method: "PUT",
      }
    );
  },
  setNewSellerReadNotification: (notificationId) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/read/${notificationId}/?user_type=seller`,
      {
        method: "PUT",
      }
    );
  },
  getNewSupplierUnreadCount: (id, language = "EN") => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-count/?user_type=buyer&user_id=${id}&country=${language}`
    );
  },
  getNewCustomerUnreadCount: (id, language = "EN") => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-count/?user_type=seller&user_id=${id}&country=${language}`
    );
  },
};

export default Notification;

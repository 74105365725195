import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import SupplierList from "components/mobile-components/SupplierList";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";
import Search from "components/mobile-components/Common/Search";

export default function InvitedSupplierListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [brandList, setBrandList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [loading, setLoading] = useState(true);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getMyBrandList = async () => {
		setPageCount(2);
		setBrandList([]);
		setLoading(true);
		if (searchQ) {
			let response = await Brand.getInvitedDistributorListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else {
			let response = await Brand.getInvitedDistributorList();
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	let singleBrandSearch = async () => {
		let response = await Brand.getInvitedDistributorListSearch(searchQ);

		if (response.status === 200) {
			setBrandList(response.results);
		}
	};

	// let loadMoreData = async () => {
	// 	if (hasMoreData) {
	// 		let response = await Brand.getInvitedDistributorList(pageCount);
	// 		if (response.status === 200) {
	// 			setPageCount((prev) => prev + 1);
	// 			setBrandList(response.data, ...brandList);
	// 			if (!response.data.length) {
	// 				setHasMoreData(false);
	// 			}
	// 		} else {
	// 			setHasMoreData(false);
	// 		}
	// 	}
	// };

	useEffect(() => {
		if (searchQ === "") {
			getMyBrandList();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.createSupplier.invitedSupplierList}
					/>

					<section className='pt-14 px-2'>
						<div className='pt-2'>
							<Search
								placeholder={
									ekkLanguage.createSupplier.searchInvitedSupplierList
								}
								search={searchQ}
								setSearch={setSearchQ}
								singleSearch={singleBrandSearch}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						</div>

						<SupplierList
							ekkLanguage={ekkLanguage}
							loading={loading}
							brandList={brandList}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>
					</section>
					{/* <div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 500);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

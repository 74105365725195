import React, { useState } from "react";
import SectionHeader from "./SectionHeader";
import { useSelector } from "react-redux";
import "./style.scss";

export default function OurTeam() {
	const [teams] = useState([
		{
			image: "assets/images/team1.png",
			name: "Enam Chowdhury",
			linkDin: "https://www.linkedin.com/in/enamanc/",
			x: "https://twitter.com/enamanc",
			fb: "https://www.facebook.com/enamanc",
			logo: "assets/images/team1_logo.png",
		},
		{
			image: "assets/images/team2.png",
			name: "Zobaida Sultana",
			linkDin: "https://www.linkedin.com/in/zobaida-s-59374a13/",
			x: "https://twitter.com/Zobaidachowdhur",
			fb: "https://www.facebook.com/zschowdhury?mibextid=kFxxJD",
			logo: "assets/images/team2_logo.png",
		},
		{
			image: "assets/images/team3.png",
			name: "Jimmy Guo",
			linkDin: "https://www.linkedin.com/in/jimmyjcguo/",
			x: "",
			fb: "",
			logo: "assets/images/team3_logo.png",
		},
		{
			image: "assets/images/team4.png",
			name: "Towfiq Ahmed",
			linkDin: "https://www.linkedin.com/in/towfiq-ahmed-90a2687b/",
			x: "",
			fb: "https://www.facebook.com/towfiq.a.chowdhury?mibextid=eQY6cl ",
			logo: "assets/images/team4_logo.png",
		},
	]);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='container m-auto  pb-[20px] md:pb-[20px] lg:pb-[50px] bg-F2F3F7 '>
			<SectionHeader title={ekkLanguage.landing.ourTeam} />
			<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4'>
				{teams.map((el, i) => {
					return (
						<div className='mb-10 text-center' key={i}>
							<img
								src={el?.image}
								alt='avatar'
								className='rounded-full mb-[15px] m-auto'
							/>
							<h5
								className='text-26 font-semibold mb-[15px]'
								style={{ color: "#222" }}>
								{el?.name}
							</h5>
							<img src={el?.logo} alt='logo' className='mb-5 m-auto' />
							<div className='flex justify-center align-center'>
								{el?.linkDin && (
									<a
										href={el?.linkDin}
										className='mx-[10px]'
										target='_blank'
										rel='noreferrer'>
										<img
											className='m-auto w-5'
											src='assets/images/linkdin.png'
											alt='icon'
										/>
									</a>
								)}
								{el?.x && (
									<a
										href={el?.x}
										className='mx-[10px]'
										target='_blank'
										rel='noreferrer'>
										<img
											className='m-auto w-5'
											src='assets/images/x.png'
											alt='icon'
										/>
									</a>
								)}
								{el?.fb && (
									<a
										href={el?.fb}
										className='mx-[10px]'
										target='_blank'
										rel='noreferrer'>
										<img
											className='m-auto w-5'
											src='assets/images/fb.png'
											alt='icon'
										/>
									</a>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React from "react";
import SingleDeposit from "./SingleDeposit";

export default function DepositList({
	loading,
	ekkLanguage,
	depositList,
	bankListArray,
}) {
	return (
		<>
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={ekkLanguage.orderList.loader}
				/>
			) : (
				<IonGrid>
					{Array.isArray(depositList) && depositList.length > 0 ? (
						<IonRow className='pt-[20px]'>
							{depositList &&
								depositList.map((list, index) => {
									let logo = bankListArray.find(
										(el) => el.name === list.bankName
									);

									console.log(list);
									return (
										<SingleDeposit
											item={list}
											key={index}
											ekkLanguage={ekkLanguage}
											logo={logo}
										/>
									);
								})}
						</IonRow>
					) : (
						<IonRow>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</IonRow>
					)}
				</IonGrid>
			)}
		</>
	);
}

import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Calendar from "assets/images/icon/svg/Calendar";
import Add from "assets/images/icon/svg/Add";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CollectorAvatar from "assets/images/icon/svg/CollectorAvatar";
import DepositCard from "./DepositCard";
import CollectionDetailsCard from "./CollectionDetailsCard";
import { IonButton, IonGrid, IonRow } from "@ionic/react";
import { ClipLoader } from "react-spinners";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import DepositApi from "services/Deposit";
import { useNavigate, useParams } from "react-router-dom";
import { digitChanger } from "helpers/helper";
import {
	setCollectionDate,
	setCollectorData,
} from "redux/container/driverSlice";

export default function Deposit() {
	const { id } = useParams();
	const [selectedDate, setSelectedDate] = useState(null);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let viewDate = moment(selectedDate).format("DD-MM-yyyy");
	const [collector, setCollector] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [depositDetails, setDepositDetails] = useState({});
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);
	//   const driverInfo = useSelector((state) => state.driver.driverData);

	const dateChanger = (date) => {
		setSelectedDate(date);
		// getOrderStatusResult(status, date);
		// let dateStatus = {
		// 	status: status,
		// 	date: date,
		// };
		// dispatch(getSelectedDate(dateStatus));
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const getDeposit = async () => {
		setLoading(true);
		let response = await DepositApi.getDepositDetails(id);
		if (response.status === 200) {
			setLoading(false);
			setDepositDetails(response.data);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getDeposit();
	}, []);

	const statusChange = async () => {
		setLoader(true);
		let response = await DepositApi.collectionStatus(
			id,
			depositDetails?.status === "Verified"
				? {
						status: "unVerified",
				  }
				: {
						status: "Verified",
				  }
		);
		if (response.status === 201) {
			setLoader(false);
			dispatch(setCollectorData({}));
			dispatch(setCollectionDate(""));
			navigate(`/confirm-page/${response.data?.status}`);
		} else {
			setLoader(false);
		}
	};

	return (
		<div className='px-4'>
			<div className='flex justify-between items-center mb-4'>
				<h4 className='font-bold text-black-1000 font-16'>
					{depositDetails?.userName}
				</h4>
				<h4
					style={{ border: "1px solid rgba(0, 0, 0, 0.10)" }}
					className='font-bold text-black-1000 font-16 bg-white py-2 px-[17px] rounded-[5px]'>
					{digitChanger(
						moment(depositDetails?.createdAt).format("DD/MM/YYYY")
					)}
				</h4>
			</div>
			<h4 className='font-bold text-20 text-black-1000 pb-[10px]'>
				{ekkLanguage.depositList.depositDetails}
			</h4>
			<DepositCard depositDetails={depositDetails} />
			<h4 className='font-bold text-20 text-black-1000 pb-[10px] pt-7'>
				{ekkLanguage.depositList.collectionDetails}
			</h4>
			<CollectionDetailsCard
				collections={depositDetails?.collectedList}
				ekkLanguage={ekkLanguage}
			/>

			<div className='pt-12 pb-10 '>
				<IonButton
					color={
						depositDetails?.status === "Verified" ? "primary" : "success"
					}
					className='font-bold h-[50px] w-full'
					onClick={() => {
						onOpenModal();
					}}>
					{depositDetails?.status === "Verified"
						? ekkLanguage.depositList.unVerified
						: ekkLanguage.depositList.verified}
				</IonButton>
			</div>
			<Modal
				ariaHideApp={false}
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<img
							src={
								depositDetails?.status === "Verified"
									? "/assets/images/remove.png"
									: "/assets/images/verified.png"
							}
							alt='icon'
						/>
					</span>

					<h6 className='text-16 font-bold pb-[10px]'>
						{depositDetails?.status === "Verified"
							? ekkLanguage.depositList.placeUnverifyMessage
							: ekkLanguage.depositList.placeVerifyMessage}
					</h6>
					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 ${
								depositDetails?.status === "Verified"
									? "bg-primary"
									: "bg-success"
							}  text-white flex justify-center items-center`}
							onClick={!loader && statusChange}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

import { getLanguage } from "config/language";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { removeFsCoupon, setFsCoupon } from "redux/container/loanSlice";
import { setFsCouponAmount } from "redux/container/shoppingCartSlice";

export default function SingleCoupon({ item, added }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const ekkLanguage = useSelector((state) => state.lan.lan);
	const getType = (item) => {
		if (item?.is_expired) {
			return (
				<span
					className={`absolute right-0 bottom-0 bg-CCCCCC
					}  text-white font-bold text-16 py-1 px-4 block`}
					style={{ borderRadius: "11px 0px" }}>
					{ekkLanguage.couponList.expired}
				</span>
			);
		} else if (item?.is_used) {
			return (
				<span
					className={`absolute right-0 bottom-0 bg-CCCCCC
					}  text-white font-bold text-16 py-1 px-4 block`}
					style={{ borderRadius: "11px 0px" }}>
					{ekkLanguage.couponList.used}
				</span>
			);
		} else if (added) {
			return (
				<span
					onClick={() => {
						dispatch(setFsCouponAmount(0));
						dispatch(removeFsCoupon());
					}}
					className={`absolute right-0 bottom-0 bg-primary
					}  text-white font-bold text-16 py-1 px-4 block`}
					style={{ borderRadius: "11px 0px" }}>
					{ekkLanguage.couponList.remove}
				</span>
			);
		} else {
			return (
				<span
					onClick={() => {
						if (pathname === "/coupon-list") {
							dispatch(setFsCoupon(item));
							navigate("/create-order");
						}
					}}
					className={`absolute right-0 bottom-0 bg-success
					}  text-white font-bold text-16 py-1 px-4 block`}
					style={{ borderRadius: "11px 0px" }}>
					{ekkLanguage.couponList.apply}
				</span>
			);
		}
	};
	const upToPercent = (item) => {
		if (getLanguage() === "en") {
			return `Up to ${item?.percentage}%`;
		} else if (getLanguage() === "bn") {
			return `${digitChanger(item?.percentage)}% ছাড়`;
		} else if (getLanguage() === "vn") {
			return `Giảm giá tới ${item?.percentage}%`;
		}else if (getLanguage() === "my") {
		return `diskaun ${item?.percentage}%`;
	}
	};
	return (
		<div className='mb-5'>
			<div
				className='flex bg-white rounded-[10px] border relative'
				style={{ borderColor: "rgba(0,0,0,.1)" }}>
				<div
					className='flex justify-center items-center w-[120px] py-4 relative'
					style={{
						borderRight: "1.5px dashed rgba(0,0,0,.2)",
					}}>
					{item?.is_expired || item?.is_used ? (
						<img src='assets/images/discount3.png' alt='icon' />
					) : (
						<img src='assets/images/discount2.png' alt='icon' />
					)}

					{/* <span
						className='px-[5px] py-[2px] absolute text-10 font-bold top-0 left-0'
						style={{
							borderRadius: "9px 0px 7px 0px",
							background: "rgba(255, 86, 76, 0.20)",
							color: "#FF564C",
						}}>
						Only for you
					</span> */}
					<span
						style={{
							background: added ? "white" : "var(--background)",
							height: 10,
							width: 20,
							position: "absolute",
							top: "-11px",
							right: "-8px",
							zIndex: 10,
						}}></span>
					<span
						style={{
							background: added ? "white" : "var(--background)",
							height: 16,
							width: 16,
							position: "absolute",
							top: "-7px",
							right: "-8px",
							borderRadius: " 50%",
							border: "1px solid rgba(0,0,0,.1)",
						}}></span>
					<span
						style={{
							background: added ? "white" : "var(--background)",
							height: 16,
							width: 16,
							position: "absolute",
							bottom: "-7px",
							right: "-8px",
							borderRadius: " 50%",
							border: "1px solid rgba(0,0,0,.1)",
						}}></span>
					<span
						style={{
							background: added ? "white" : "var(--background)",
							height: 10,
							width: 20,
							position: "absolute",
							bottom: "-11px",
							right: "-8px",
							zIndex: 10,
						}}></span>
				</div>
				<div className='w-full ml-4'>
					<div className='flex justify-between'>
						<h4 className='font-semibold text-16 mt-[6px] text-black-500'>
							{item?.promocode}
						</h4>
						<span
							className='border text-black-500 py-[3px] font-bold text-8 px-2 mt-[10px] mr-[10px] color-white rounded-[23px] '
							style={{ background: "#F2F3F7" }}>
							{getLanguage() !== "bn" && ekkLanguage.couponList.endIn}{" "}
							{digitChanger(
								monthChanger(
									moment(item?.end_date).format("DD MMM YYYY")
								)
							)}{" "}
							{getLanguage() === "bn" && ekkLanguage.couponList.endIn}
						</span>
					</div>
					<div className='flex justify-between'>
						<h2
							className='font-bold text-20'
							style={{
								color:
									item?.is_expired || item?.is_used
										? "rgba(0,0,0,.5)"
										: "#FF0D00",
							}}>
							{upToPercent(item)}
						</h2>
						{added && (
							<div className='mt-2 flex mr-[10px]'>
								<img
									className='w-3 h-3 mr-1'
									src='assets/images/checkmark.png'
									alt='check'
								/>
								<span className='text-10 font-bold text-success'>
									{ekkLanguage.couponList.applied}
								</span>
							</div>
						)}
					</div>
					{/* <div className='mb-[10px]'>
						<span
							className='border text-black-500 py-[3px] font-normal text-10 px-2 mt-[10px] mr-[10px] color-white rounded-[23px]'
							style={{ background: "#F2F3F7" }}>
							Min. Order ৳12000
						</span>
					</div> */}
					{getType(item)}
				</div>
			</div>
		</div>
	);
}

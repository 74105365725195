import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import SingleSupplierProduct from "./SingleProduct";

export default function Products({
	recommendData,
	title,
	settings,
	brandId,
	lodeMoreData,
	searchNext,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='related-product-card pb-7 pt-2'>
			<IonGrid>
				<IonRow>
					{recommendData?.length ? (
						recommendData.map((el, i) => {
							return (
								<IonCol size='4' sizeSm='4' className='mb-6' key={i}>
									<SingleSupplierProduct
										id={el.id}
										name={el.brand_name}
										title={el.title}
										supplierName={el.business_name}
										qty={el.product_count}
										img={el.images}
										tag={el.tag}
										offer={el.offer}
										offerAmount={el.offerAmount}
										nearby={el.nearby}
										price={el.procure_price}
										oldPrice={el.oldPrice}
										newPrice={el.newPrice}
										sellPrice={el.sellPrice}
										offerPrice={el.offer_price}
										profit={el.distributor_profit}
										nameShow={settings.nameShow}
										supplierNameShow={settings.supplierNameShow}
										skuShow={settings.skuShow}
										priceShow={settings.priceShow}
										oldPriceShow={settings.oldPriceShow}
										distShow={settings.distShow}
										profitShow={
											el.profit || el.consumer_price
												? settings.profitShow
												: false
										}
										sellPriceShow={settings.sellPriceShow}
										titleShow={settings.titleShow}
										link={settings.link}
										type={el.type}
										currency={el.currency}
										brandId={brandId}
										isSpacialPrice={el?.is_special_price}
									/>
								</IonCol>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</IonRow>
			</IonGrid>
		</div>
	);
}

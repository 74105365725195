import React from "react";

export default function StockOut() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='36'
			viewBox='0 0 30 36'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.73227 16.5523L14.6941 10.8007L25.2677 16.5523L15 22.481L4.73227 16.5523Z'
				fill='#D2D3D7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.2677 16.5522V28.409L15 34.3371L4.73227 28.409V16.5522L15 22.481L25.2677 16.5522Z'
				fill='#E8E7E5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 22.481L25.2677 16.5522L28.3594 21.4052L18.0916 27.3326L15 22.481Z'
				fill='#726A72'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 22.481V34.3371L4.73227 28.409V16.5522L15 22.481Z'
				fill='#919FAB'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 22.481L4.73227 16.5522L1.64062 21.4052L11.9084 27.3326L15 22.481Z'
				fill='#BEBEC2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.0296 17.903C19.6203 17.903 23.3651 14.1954 23.3651 9.65038C23.3651 5.10538 19.6203 1.39709 15.0296 1.39709C10.4388 1.39709 6.6933 5.10467 6.6933 9.65038C6.6933 14.1961 10.4381 17.903 15.0296 17.903Z'
				fill='#E8E7E5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.6391 6.82877C11.5048 6.69447 11.5048 6.47721 11.6391 6.34291C11.7734 6.20861 11.9913 6.20861 12.1256 6.34291L14.9466 9.16385L17.7682 6.34291C17.9025 6.20861 18.1198 6.20861 18.2541 6.34291C18.3884 6.47721 18.3884 6.69447 18.2541 6.82877L15.4331 9.65041L18.2541 12.4713C18.3186 12.5359 18.3548 12.6234 18.3548 12.7146C18.3548 12.8059 18.3186 12.8934 18.2541 12.9579C18.1895 13.0221 18.1022 13.0581 18.0112 13.0581C17.9201 13.0581 17.8328 13.0221 17.7682 12.9579L14.9466 10.1363L12.1256 12.9579C12.0609 13.0221 11.9735 13.0581 11.8824 13.0581C11.7912 13.0581 11.7038 13.0221 11.6391 12.9579C11.5746 12.8934 11.5384 12.8059 11.5384 12.7146C11.5384 12.6234 11.5746 12.5359 11.6391 12.4713L14.4607 9.65041L11.6391 6.82877Z'
				fill='#FCE17D'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.8424 12.87L14.2048 9.50766L10.8424 6.14531C10.3186 5.62148 11.1152 4.82695 11.6377 5.34938L15 8.71172L18.3624 5.34938C18.8869 4.82695 19.6807 5.62219 19.1576 6.14531L15.7952 9.50766L19.1576 12.87C19.6877 13.3988 18.8799 14.1827 18.3624 13.6652L15 10.3029L11.6377 13.6652C11.1174 14.1863 10.3186 13.3924 10.8424 12.87ZM18.3251 27.0042L15.7903 23.0259L25.2445 17.5676L27.7793 21.5459L18.3251 27.0042ZM24.867 28.5293V24.5264L18.4214 28.248C18.1613 28.3964 17.8287 28.3184 17.6656 28.0631L15 23.8788L12.3345 28.0631C12.2563 28.1851 12.1342 28.2724 11.9935 28.3068C11.8528 28.3412 11.7042 28.3202 11.5786 28.248L5.13305 24.5264V28.5293L15 34.2253L24.867 28.5293ZM4.75548 17.5676L14.2097 23.0259L11.6749 27.0042L2.22071 21.5459L4.75548 17.5676ZM15 18.4521C12.2979 18.4521 9.87141 17.2477 8.23032 15.3478L5.69555 16.811L15 22.1829L24.3045 16.811L21.7697 15.3478C20.1286 17.2477 17.7021 18.4521 15 18.4521ZM15 1.6875C10.6877 1.6875 7.17985 5.19539 7.17985 9.50766C7.17985 13.8199 10.6877 17.3271 15 17.3271C19.3123 17.3271 22.8202 13.8192 22.8202 9.50766C22.8202 5.19609 19.3123 1.6875 15 1.6875ZM29.0435 21.4376L25.9041 16.5087C25.8547 16.431 25.7868 16.3667 25.7065 16.3216L22.4552 14.4443C23.396 13.0282 23.9452 11.3309 23.9452 9.50766C23.9452 4.57523 19.9324 0.5625 15 0.5625C10.0676 0.5625 6.05485 4.57523 6.05485 9.50766C6.05485 11.3309 6.60399 13.0282 7.54477 14.4443L4.29352 16.3216C4.21323 16.3667 4.14535 16.431 4.09594 16.5087L0.956491 21.4376C0.783523 21.7069 0.872116 22.0669 1.14915 22.2265L4.00805 23.8774V28.8541C4.00805 29.0545 4.11563 29.2402 4.2893 29.3407L14.7188 35.3623C14.8044 35.4113 14.9013 35.4371 15 35.4371C15.0987 35.4371 15.1956 35.4113 15.2813 35.3623L25.7107 29.3407C25.8844 29.2402 25.992 29.0545 25.992 28.8541V23.8774L28.8509 22.2265C29.1265 22.0676 29.2172 21.709 29.0435 21.4376Z'
				fill='#282828'
			/>
		</svg>
	);
}

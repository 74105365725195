import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import { getLanguage } from "config/language";
import { digitChanger, monthChanger } from "helpers/helper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setPaymentInfo } from "redux/container/loanSlice";
import { setPaymentMethodText } from "redux/container/paymentMethodSlice";

export default function ConfirmPage() {
	const { status } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let dispatch = useDispatch();

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.createOrder.cosgressTitle}
									</h2>

									<p className='text14 text-black-500 font-semibold mb-5'>
										{status === "Verified"
											? ekkLanguage.depositList
													.confirmPageunVerifyText
											: ekkLanguage.depositList
													.confirmPageVerifyText}
									</p>
									<Link
										to='/deposit-collection-list'
										className='w-full'
										onClick={() => {
											dispatch(setPaymentMethodText(""));
											dispatch(setPaymentInfo({}));
										}}>
										<IonButton
											className='rounded-[10px] h-12 w-full text-white font-bold bg-primary'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createOrder.congressProductLabel}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

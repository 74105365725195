import { digitChanger } from "helpers/helper";
import React from "react";

export default function BothPartiesDetailsEN({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
}) {
	return (
		<div>
			{/* first part  */}
			<div>
				<p className='text-justify text-222222 text-14'>
					<span className='font-bold'>
						{createdBusinessDetails?.business_name}
					</span>{" "}
					is a sole proprietorship business. which is a registered and sole
					proprietorship company in Bangladesh, whose address is:{" "}
					<span className='font-bold'>
						{createdBusinessDetails?.address_line
							? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
							: `${businessDetails?.address_line}, ${businessDetails?.city}`}
					</span>
					, Trade License No. {digitChanger(kycDetails?.trade_no)} on
					behalf of the said company's Proprietor/National Identity Card
					No. {digitChanger(kycDetails?.nid_no)} hereinafter referred to in
					this Agreement as Seller/Supplier, by which expression shall
					include heirs and electors/nominees.
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						First Party
					</p>
				</div>
			</div>
			<h3 className='font-bold text-center my-5'>And</h3>
			{/* second part  */}
			<div>
				<p className='text-justify text-222222 text-14'>
					<span className='font-bold'>EkkBaz Private Limited</span> is a
					company. Which is registered and regulated under the Companies
					Act of Bangladesh. whose registered address is:{" "}
					<span className='font-bold'>
						Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore 409051
					</span>
					, on behalf of{" "}
					<span className='font-bold'>Zobayeda Sultana</span>, Managing
					Director of the Company, hereinafter referred to as{" "}
					<span className='font-bold'>EkkBaz, Buyer/Platform</span> in this
					Agreement. By which expression shall be included its successors,
					legal representatives, directors and nominees.
				</p>
				<div className='my-5 flex justify-end w-full items-center'>
					<div className='w-[50px] h-[1px] bg-222222 mr-2'></div>
					<p className='text-justify text-222222 text-14 font-bold'>
						Second Party
					</p>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import Signature from "../Common/Signature";
import { digitChanger } from "helpers/helper";

export default function BothPartiesSignBN({
	businessDetails,
	kycDetails,
	createdBusinessDetails,
	firstPartySignatureImage,
	setFirstPartySignatureImage,
	secondPartySignatureImage,
	setSecondPartySignatureImage,
}) {
	return (
		<div className='border-2 border-[#808080] rounded-md p-2 mt-[10px]'>
			{/* 1st party's signature */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					প্রথম পক্ষ
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14'>
				<span className='font-bold'>
					{createdBusinessDetails?.business_name}
				</span>{" "}
				একটি এক মালিকানা ব্যবসা প্রতিষ্ঠান যা বাংলাদেশের নিবন্ধিত এবং
				একমালিকানা ব্যবসা প্রতিষ্ঠান, যাহার ঠিকানাঃ
				<span className='font-bold ml-1'>
					{createdBusinessDetails?.address_line
						? `${createdBusinessDetails?.address_line}, ${createdBusinessDetails?.city}`
						: `${businessDetails?.address_line}, ${businessDetails?.city}`}
				</span>
				, ট্রেড লাইসেন্স নং-
				<span className='font-bold ml-1'>
					{digitChanger(kycDetails?.trade_no)}
				</span>{" "}
				এর পক্ষে উক্ত প্রতিষ্ঠানের সত্ত্বাধিকারী/ জাতীয় পরিচয়পত্র নম্বর-
				<span className='font-bold mx-1'>
					{digitChanger(kycDetails?.nid_no)}
				</span>
			</p>
			{/* Signature  */}
			<Signature
				signatureImage={firstPartySignatureImage}
				setSignatureImage={setFirstPartySignatureImage}
				placeholder={"স্বাক্ষর"}
				showDash={true}
				bgColor={"white"}
			/>
			<div className='w-full bg-[#808080] h-[2px] my-[20px]'></div>
			{/* 2nd party's signature */}
			<div className='my-3 flex justify-start w-full items-center'>
				<p className='text-justify text-222222 text-14 font-bold'>
					দ্বিতীয় পক্ষ
				</p>
				<div className='w-[50px] h-[1px] bg-222222 ml-2'></div>
			</div>
			<p className='text-justify text-222222 text-14 pb-2'>
				<span className='font-bold'>একবাজ বাংলাদেশ প্রাইভেট লিমিটেড</span>{" "}
				একটি কোম্পানি যা বাংলাদেশের কোম্পানি আইনে নিবন্ধিত ও নিয়ন্ত্রিত, যার
				নিবন্ধিত ঠিকানাঃ{" "}
				<span className='font-bold'>
					রাজ্জাক প্লাজা- ১৩তম ফ্লোর (লিফট-১২), মগবাজার, ঢাকা-১২১৭
				</span>
				।এর পক্ষে কোম্পানির ব্যবস্থাপনা পরিচালক,{" "}
				<span className='font-bold'>জোবায়েদা সুলতানা</span>।
			</p>
			{/* Signature  */}

			{/* <div
				className={`my-2 p-2 h-[200px] bg-transparent rounded-[10px] border-2 border-dashed border-[#00000054]`}
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
				<img
					className='w-full h-full rounded-[10px]'
					src={
						"https://ekkfilesdbt.blob.core.windows.net/userssblobs/f6a298bf-b319-410f-be1b-7c6aa89e5c47image.jpg"
					}
					alt=''
				/>
			</div> */}
			{/* <Signature
				signatureImage={secondPartySignatureImage}
				setSignatureImage={setSecondPartySignatureImage}
				placeholder={"স্বাক্ষর"}
				showDash={true}
				bgColor={""}
				hideDeleteButton={true}
			/> */}
		</div>
	);
}

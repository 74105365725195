import { IonItem } from "@ionic/react";

import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setCardBottomSheet } from "redux/container/userSlice";
import Payment from "services/Payment";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";

export default function CardBottomSheet({ selectedCard, getCardList }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.cardBottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [defaultLoader, setDefaultLoader] = useState(false);
	const [defaultCard, setDefaultCard] = useState(false);
	const isOpenDefaultCard = () => setDefaultCard(true);
	const isCloseDefaultCard = () => setDefaultCard(false);

	const [removeCard, setRemoveCard] = useState(false);
	const [removeLoader, setRemoveLoader] = useState(false);
	const isOpenRemoveCard = () => setRemoveCard(true);
	const isCloseRemoveCard = () => setRemoveCard(false);

	const defaultCardHandler = async () => {
		setDefaultLoader(true);
		let payload = {
			customer_id: selectedCard.customer,
			payment_method_id: selectedCard.id,
		};
		let response = await Payment.setDefaultCard(payload);
		if (response.status === 201) {
			setDefaultLoader(false);
			getCardList();
			isCloseDefaultCard();
			dispatch(setCardBottomSheet(false));
		} else {
			setDefaultLoader(false);
		}
	};

	const removeCardHandler = async () => {
		isCloseRemoveCard();
		setRemoveLoader(true);
		let payload = {
			customer_id: selectedCard.customer,
			payment_method_id: selectedCard.id,
		};
		let response = await Payment.setRemoveCard(payload);
		if (response.status === 200) {
			setRemoveLoader(false);
			getCardList();
			isCloseRemoveCard();
			dispatch(setCardBottomSheet(false));
		} else {
			setRemoveLoader(false);
		}
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[200, 200, 100, 0]}
				onClose={() => dispatch(setCardBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								isOpenDefaultCard(true);
								dispatch(setCardBottomSheet(false));
							}}
							lines='none'>
							<div className='flex items-center'>
								<span className='py-[5px] pr-4'>
									<img
										className='h-10 w-10'
										src='assets/images/edit.png'
										alt='user'
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									Set Default Card
								</p>
							</div>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								isOpenRemoveCard();
								dispatch(setCardBottomSheet(false));
							}}
							lines='none'>
							<div className='flex items-center'>
								<span className='py-[5px] pr-4'>
									<img
										className='h-10 w-10'
										src='assets/images/remove.png'
										alt='user'
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									Remove Card
								</p>
							</div>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setCardBottomSheet(false))}
				/>
			</Sheet>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={defaultCard}
				onRequestClose={isCloseDefaultCard}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						Do you want to set default this card?
					</h6>

					<div className='flex justify-end'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
							onClick={isCloseDefaultCard}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`ml-2 ${
								defaultLoader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={!defaultLoader && defaultCardHandler}>
							{defaultLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={removeCard}
				onRequestClose={isCloseRemoveCard}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						Do you want to set remove this card?
					</h6>

					<div className='flex justify-end'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
							onClick={isCloseRemoveCard}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`ml-2 ${
								removeLoader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={!removeLoader && removeCardHandler}>
							{removeLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

import { agreements } from "data/agreements";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";

export default function AgreementsEN() {
  return (
    <div className="mt-2">
      <p className="text-justify text-222222 text-14 mb-2">
        <span>{digitChanger(1)}.</span> This temporary agreement shall be
        effective from{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(moment(new Date()).format("DD MMM, YYYY"))
          )}
        </span>{" "}
        and shall remain in force between the parties until{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(
              moment(new Date()).add(1, "year").format("DD MMM, YYYY")
            )
          )}
        </span>
        . The First Party/Distributor will become automatically eligible for{" "}
        <span className="font-bold">EBS (EkkBaz Seller Services)</span> upon completion of the
        one-month contract period.
      </p>
      {agreements.map((agreement) => {
        return (
          <p
            key={agreement.id}
            className="text-justify text-222222 text-14 mb-2"
          >
            <span>{digitChanger(agreement.id)}.</span>{" "}
            {agreement.english_description}
          </p>
        );
      })}
    </div>
  );
}

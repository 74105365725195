import { agreements } from "data/agreements";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";

export default function AgreementsMY() {
  return (
    <div className="mt-2">
      <p className="text-justify text-222222 text-14 mb-2">
        <span>{digitChanger(1)}.</span>Perjanjian sementara ini hendaklah
        berkesan daripada{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(moment(new Date()).format("DD MMM, YYYY"))
          )}
        </span>{" "}
        dan hendaklah terus berkuat kuasa antara pihak-pihak sehingga{" "}
        <span className="font-bold">
          {digitChanger(
            monthChanger(
              moment(new Date()).add(1, "year").format("DD MMM, YYYY")
            )
          )}
        </span>
        . Pihak Pertama/Pengedar akan layak secara automatik untuk{" "}
        <span className="font-bold">EBS (EkkBaz Seller Services)</span> setelah
        selesai tempoh kontrak sebulan.
      </p>
      {agreements.map((agreement) => {
        return (
          <p
            key={agreement.id}
            className="text-justify text-222222 text-14 mb-2"
          >
            <span>{digitChanger(agreement.id)}.</span>{" "}
            {agreement.malay_description}
          </p>
        );
      })}
    </div>
  );
}

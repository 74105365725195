import { createSlice } from "@reduxjs/toolkit";

export const loanSlice = createSlice({
	name: "loan",
	initialState: {
		remainingInfo: {},
		paymentDetails: {},
		fsCoupon: {},
	},
	reducers: {
		setPaymentInfo: (state, action) => {
			state.remainingInfo = action.payload;
		},
		setPaymentDetails: (state, action) => {
			state.paymentDetails = action.payload;
		},
		setFsCoupon: (state, action) => {
			state.fsCoupon = action.payload;
		},
	},
});

export const { actions, reducer } = loanSlice;

//payment information
export const setPaymentInfo = (values) => async (dispatch, getState) => {
	dispatch(actions.setPaymentInfo(values));
};
export const setPaymentDetails = (values) => async (dispatch, getState) => {
	dispatch(actions.setPaymentDetails(values));
};
export const setFsCoupon = (values) => async (dispatch, getState) => {
	dispatch(actions.setFsCoupon(values));
};
export const removeFsCoupon = () => async (dispatch, getState) => {
	dispatch(actions.setFsCoupon({}));
};
export default reducer;

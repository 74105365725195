import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonRow,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import ProductList from "components/mobile-components/ProductList";
import ProductBottomSheet from "components/mobile-components/ProductList/ProductBottomSheet";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Product from "services/Product";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import Card from "components/mobile-components/Common/Card";
import SingleBrand from "components/mobile-components/MyBrandList/SingleBrand";
import Brand from "services/Brand";

export default function ProductListPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [productList, setProductList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [selectedItem, setSelectedItem] = useState({});
	const [filteredSelections, setFilteredSelections] = useState("all");
	const [tempSearchParams, setTempSearchParams] = useState("");

	const [brandList, setBrandList] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState("");

	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const [isOpenUnPublish, setIsOpenUnPublish] = useState(false);
	const onOpenUnPublishModal = () => setIsOpenUnPublish(true);
	const onCloseUnPublishModal = () => setIsOpenUnPublish(false);

	const [isOpenBrandModal, setIsOpenBrandModal] = useState(false);
	const onOpenModalBrandModal = () => setIsOpenBrandModal(true);
	const onCloseModalBrandModal = () => setIsOpenBrandModal(false);

	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getProductList = async () => {
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Product.singleProductSearch(
				searchQ,
				filteredSelections,
				selectedBrand
			);

			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			} else {
				setLoader(false);
				setProductList([]);
			}
		} else {
			let response = await Product.productList(
				filteredSelections,
				selectedBrand
			);
			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			} else {
				setLoader(false);
				setProductList([]);
			}
		}
	};
	const getProductListWithBrand = async (bId) => {
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Product.singleProductSearch(
				searchQ,
				filteredSelections,
				bId
			);

			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			} else {
				setLoader(false);
				setProductList([]);
			}
		} else {
			let response = await Product.productList(filteredSelections, bId);
			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			} else {
				setLoader(false);
				setProductList([]);
			}
		}
	};
	const getOfferList = async () => {
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await await Product.singleOfferSearch(searchQ);
			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			}
		} else {
			let response = await Product.getOfferList();
			if (response.status === 200) {
				setLoader(false);
				setProductList(response.results);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (filteredSelections === "offer") {
				getOfferList();
			} else {
				getProductList();
			}
		}
	}, [searchQ]);

	useEffect(() => {
		if (filteredSelections === "offer") {
			getOfferList();
		} else {
			getProductList();
		}
	}, [filteredSelections]);

	let singleProductSearch = async () => {
		setTempSearchParams(searchQ);
		setPageCount(2);
		setLoader(true);
		let response =
			filteredSelections !== "offer"
				? await Product.singleProductSearch(
						searchQ,
						filteredSelections,
						selectedBrand
				  )
				: await Product.singleOfferSearch(searchQ);
		if (response.status === 200) {
			setLoader(false);
			setProductList(response.results);
		} else {
			setLoader(false);
			setProductList([]);
		}
	};

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				const response =
					filteredSelections !== "offer"
						? await Product.singleProductSearch(
								searchQ,
								filteredSelections,
								selectedBrand,
								pageCount
						  )
						: await Product.singleOfferSearch(searchQ, pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				const response =
					filteredSelections !== "offer"
						? await Product.productList(
								filteredSelections,
								selectedBrand,
								pageCount
						  )
						: await Product.getOfferList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const statusChange = async () => {
		let productInfo = {
			product: {
				product_id: selectedItem.product_id,
			},
			catalog: {
				cat_id: selectedItem.cat_id,
				catalog_status:
					selectedItem.status === "Publish" ? "Unpublish" : "Publish",
			},
		};
		let offerInfo = {
			offers: {
				id: selectedItem.id,
				publish_status:
					selectedItem.publish_status === "Publish"
						? "Unpublish"
						: "Publish",
			},
		};
		let res =
			selectedItem.type === "offer"
				? await Product.updateOffer(offerInfo)
				: await Product.updateProduct(productInfo);
		if (res.status === 200) {
			let updateListProduct = productList.map((el) =>
				el.id === selectedItem.id
					? {
							...el,
							status:
								selectedItem.status === "Publish"
									? "Unpublish"
									: "Publish",
					  }
					: el
			);
			let updateListOffer = productList.map((el) =>
				el.id === selectedItem.id
					? {
							...el,
							publish_status:
								selectedItem.publish_status === "Publish"
									? "Unpublish"
									: "Publish",
					  }
					: el
			);
			if (selectedItem.type === "offer") {
				setProductList(updateListOffer);
				toast.success(
					selectedItem.publish_status === "Publish"
						? "Unpublish successfully"
						: "Publish successfully"
				);
			} else {
				setProductList(updateListProduct);
				toast.success(
					selectedItem.status === "Publish"
						? "Unpublish successfully"
						: "Publish successfully"
				);
			}
			onCloseModal();
			onCloseUnPublishModal();
		} else {
		}
	};

	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const customModalStyles2 = {
		content: {
			...customModalStyles.content,
			width: "calc(100% - 20px)",
			maxHeight: "calc(100% - 30px)",
			maxWidth: "500px",
		},
		overlay: {
			...customModalStyles.overlay,
		},
	};

	//brand list
	const getMyBrandList = async () => {
		let response = await Brand.getMyBrandList(1, 100);
		if (response.status === 200) {
			setBrandList(response.results);
		}
	};
	useEffect(() => {
		getMyBrandList();
	}, []);

	let filterHandler = () => {
		if (!selectedBrand) {
			onOpenModalBrandModal();
		} else {
			setSelectedBrand("");
			getProductListWithBrand("");
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.productList.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from='productList'
					/>

					<section className='pt-14 px-4'>
						<ProductList
							searchQ={searchQ}
							setSearchQ={setSearchQ}
							ekkLanguage={ekkLanguage}
							productList={productList}
							setSelectedItem={setSelectedItem}
							onOpenModal={onOpenModal}
							onOpenUnPublishModal={onOpenUnPublishModal}
							loader={loader}
							singleProductSearch={singleProductSearch}
							setFilteredSelections={setFilteredSelections}
							filterHandler={filterHandler}
							selectedFilter={selectedBrand}
							tempSearchParams={tempSearchParams}
							setTempSearchParams={setTempSearchParams}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>

						<Modal
							style={customModalStyles}
							closeTimeoutMS={200}
							isOpen={isOpen}
							onRequestClose={onCloseModal}
							classNames='bottom-sheet-modal'>
							<div className='p-5'>
								<span className='mb-2 inline-block mx-2'>
									<CancelPopUp />
								</span>
								<h6 className='text-16 font-bold pb-[10px]'>
									{ekkLanguage.createProduct.publishMessage}
								</h6>

								<div className='flex'>
									<button
										className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
										onClick={onCloseModal}>
										{ekkLanguage.orderDetails.noBtn}
									</button>
									<button
										onClick={() => statusChange()}
										color='primary'
										className={`${
											loader && "select-none cursor-no-drop"
										} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}>
										{loader ? (
											<ClipLoader
												sizeUnit={"px"}
												size={20}
												color={"white"}
												loading={true}
											/>
										) : (
											ekkLanguage.orderDetails.yesBtn
										)}
									</button>
								</div>
							</div>
						</Modal>
						<Modal
							style={customModalStyles}
							closeTimeoutMS={200}
							isOpen={isOpenUnPublish}
							onRequestClose={onCloseUnPublishModal}
							classNames='bottom-sheet-modal'>
							<div className='p-5'>
								<span className='mb-2 inline-block mx-2'>
									<CancelPopUp />
								</span>
								<h6 className='text-16 font-bold pb-[10px]'>
									{ekkLanguage.createProduct.unPublishMessage}
								</h6>

								<div className='flex'>
									<button
										className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
										onClick={onCloseUnPublishModal}>
										{ekkLanguage.orderDetails.noBtn}
									</button>
									<button
										onClick={() => statusChange()}
										color='primary'
										className={`${
											loader && "select-none cursor-no-drop"
										} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}>
										{loader ? (
											<ClipLoader
												sizeUnit={"px"}
												size={20}
												color={"white"}
												loading={true}
											/>
										) : (
											ekkLanguage.orderDetails.yesBtn
										)}
									</button>
								</div>
							</div>
						</Modal>

						<Modal
							style={customModalStyles2}
							closeTimeoutMS={200}
							isOpen={isOpenBrandModal}
							onRequestClose={onCloseModalBrandModal}
							classNames='bottom-sheet-modal'>
							<div>
								<Card
									title={ekkLanguage.brandList.pageTitle}
									onHide
									closeModal={onCloseModalBrandModal}>
									<div>
										<IonRow>
											{brandList.length > 0 ? (
												brandList.map((item, i) => {
													return (
														<IonCol size='6' key={i}>
															<div className='flex items-center justify-between'>
																<div
																	className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer'
																	onClick={() => {
																		getProductListWithBrand(
																			item.id
																		);
																		setSelectedBrand(item.id);
																		onCloseModalBrandModal();
																	}}>
																	{item?.images?.length ? (
																		<div className='h-8 w-8 rounded-full overflow-hidden'>
																			<img
																				src={
																					item?.images[0]
																				}
																				alt='logo'></img>
																		</div>
																	) : (
																		<div className='h-8 w-8 flex justify-center items-center bg-black-333 rounded-full'>
																			<h3 className='font-bold text-16 text-white'>
																				{item?.brand_name[0].toUpperCase()}
																			</h3>
																		</div>
																	)}

																	<div className='ml-4'>
																		<h4 className='text-12 text-black-1000 font-bold pb-[2px]'>
																			{item?.brand_name}
																		</h4>

																		<p className='text-10 text-black-500'>
																			{item?.role ===
																				"Imported" &&
																				"Imported"}
																		</p>
																	</div>
																</div>
															</div>
														</IonCol>
													);
												})
											) : (
												<>
													<IonCol size='2'></IonCol>
													<IonCol size='8'>
														<div
															className='text-center flex items-center justify-center'
															style={{
																height: "calc(100vh - 420px)",
															}}>
															<div>
																<img
																	className='m-auto mb-[10px] mt-5'
																	src='/assets/images/not-found-file.png'
																	alt='emptyCard'
																/>
																<p className='text-16 font-normal text-222222 mb-[35px]'>
																	{
																		ekkLanguage.orderList
																			.notFound
																	}
																</p>
															</div>
														</div>
													</IonCol>
												</>
											)}
										</IonRow>
									</div>
								</Card>

								{/* <div className='flex'>
									<button
										className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
										onClick={onCloseModal}>
										{ekkLanguage.orderDetails.noBtn}
									</button>
									<button
										onClick={() => statusChange()}
										color='primary'
										className={`${
											loader && "select-none cursor-no-drop"
										} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}>
										{loader ? (
											<ClipLoader
												sizeUnit={"px"}
												size={20}
												color={"white"}
												loading={true}
											/>
										) : (
											ekkLanguage.orderDetails.yesBtn
										)}
									</button>
								</div> */}
							</div>
						</Modal>
					</section>
					<ProductBottomSheet />

					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";
import BrandCard from "./BrandCard";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

export default function SelectBrands({
	ekkLanguage,
	brandList,
	selectedBrandList,
}) {
	// brandList.length > 0;
	return (
		<div className='w-full flex items-center justify-center px-2 mt-4'>
			<IonGrid classNae='w-full'>
				<IonRow className='block'>
					{brandList.length > 0 ? (
						brandList.map((brand) => {
							return (
								<BrandCard
									key={brand?.id}
									brandId={brand?.id}
									brandName={brand?.brand_name}
									imgSource={brand?.images?.[0]}
									brand={brand}
									selectedBrandList={selectedBrandList}></BrandCard>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</IonRow>
			</IonGrid>
		</div>
	);
}

import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function PaymentDetailsCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const placedOrderItems = useSelector((state) => state.order?.deliveredItems);
	let paymentTermTextFn = () => {
		if (order?.payment_term?.duration == 0) {
			return "Immediate Payment";
		} else if (order?.payment_term?.duration == 1) {
			return `At Night of Delivery Day ${moment()
				.format(order?.delivery_date)
				.format("DD-MM-YYYY")}`;
		} else {
			return `Pay in ${order?.payment_term?.duration} days`;
		}
	};

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.paymentDetailsLabel}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 ">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.assignedCollector}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.collectorName ??
										ekkLanguage.orderDetails.notAssigned}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentMethod}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.payment_method}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentTerms}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									<span>{order?.payment_term?.name}</span>
								</td>
							</tr>

							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentDueDate}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									<span>
										{digitChanger(
											moment(
												order?.payment_term?.paymentDueDate
											).format("DD-MM-YYYY")
										)}
									</span>
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.totalDue}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(
										order?.order_status !== "Placed"
											? numberWithCommas(
													order?.total -
														Number(order?.fs_coupon_amount ?? 0)
											  )
											: numberWithCommas(
													placedOrderItems?.total -
														Number(order?.fs_coupon_amount ?? 0)
											  )
									)}
									{/* {digitChanger(numberWithCommas(order?.total))} */}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.totalPaid}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(numberWithCommas(order?.total_paid))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.remaining}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(
										order?.order_status !== "Placed"
											? numberWithCommas(
													order?.total -
														order?.total_paid -
														Number(order?.fs_coupon_amount ?? 0)
											  )
											: numberWithCommas(
													placedOrderItems?.total -
														order?.total_paid -
														Number(order?.fs_coupon_amount ?? 0)
											  )
									)}
								</td>
							</tr>
						</tbody>
					</table>
					{order?.payment_method === "Bank Transfer" && (
						<div className='border-t-2'>
							<p className='text-12 text-black-300 font-bold pt-2'>
								{ekkLanguage.orderDetails.bankInfoLabel}
							</p>
							<table className='table-fixed w-full'>
								<tbody className="before:content-[' '] before:block before:h-2 ">
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.referenceNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											<span className='text-12 text-black-1000 font-bold'>
												{digitChanger(order?.order_id)}
											</span>
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.bankName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											Bank Asia
										</td>
									</tr>

									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.accountName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											EkkBaz Bangladesh Pvt. Ltd
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.accountNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											044 3300 1328
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.routeNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											070 274 187
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.branch}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											Mogbazar Branch
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</div>
			</Card>
		</div>
	);
}

import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const DashBoard = {
	getAnalytics: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/total-analytics-seller?date_start=${start}&date_end=${end}`
		);
	},
	getStatusDetails: (start, end, status, limit = 500, page = 1) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-list-by-date?date_start=${start}&date_end=${end}&status=${status}&limit=${limit}`
		);
	},
	getAnalyticsDetails: (start, end, status, limit = 500, page = 1) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-list-by-date-others?date_start=${start}&date_end=${end}&status=${status}&limit=${limit}`
		);
	},
	getOrderProductSummary: (status, date) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/product-order-summery?seller_date=${date}&status=${status}`
		);
	},
	getProductSummaryDrilldown: (status, date, catId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/product-order-summery-details?seller_date=${date}&status=${status}&catalog_id=${catId}`
		);
	},
	getOrderDriverProductSummary: (date, driverId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/deliver-order-product-summery?driverId=${driverId}&seller_date=${date}`
		);
	},
	getDriverProductSummaryDrilldown: (driverId, date, catId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/deliver-order-product-summery-details?driverId=${driverId}&seller_date=${date}&catalog_id=${catId}`
		);
	},

	getDriverDeliverySummary: (date, driverId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/deliver-order-summery-status?seller_date=${date}&driverId=${driverId}`
		);
	},
	getDeliveryAmountSummary: (date, driverId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/delivery-order-amount-summery?driverId=${driverId}&seller_date=${date}`
		);
	},
	getDeliveryAmountSummaryDrilldown: (date, driverId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/delivery-order-amount-summery/details?driverId=${driverId}&seller_date=${date}`
		);
	},

	getBuyerSummary: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller-order/monthly-order?fromDate=${start}&toDate=${end}`
		);
	},

	getAllDelayInPay: () => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-age-summery-get-all`
		);
	},
	getDelayInPay: (day) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-age-summery-get-all?day=${day}`
		);
	},
	getDelayInPaymentDrilldown: (buyerId) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-age-summery-details-all?buyerId=${buyerId}`
		);
	},
	getDelayInPaymentDayDrilldown: (buyerId, day) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/order-age-summery-details-all?day=${day}&buyerId=${buyerId}`
		);
	},
	getBuyerSummaryDrillDown: (buyerId, start, end, page = 1, limit = 21) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller-order/buyer-summer-details?fromDate=${start}&toDate=${end}&buyerId=${buyerId}&limit=${limit}&page=${page}`
		);
	},

	getSupplierOrder: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/chat-report-user?date_start=${start}&date_end=${end}`
		);
	},
	getCustomerOrder: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/seller/chat-report?date_start=${start}&date_end=${end}`
		);
	},
};
export default DashBoard;

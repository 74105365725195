import {
	IonApp,
	IonContent,
	IonLoading,
	IonPage,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import TimeLineTextBox from "components/mobile-components/OrderDetails/TimeLineTextBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOderCollectionAmount,
	fetchOrderDetailsByOrderId,
	fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";
import SelectTimeDate from "components/mobile-components/OrderDetails/SelectTimeDate";
import FinanceSupportCard from "components/mobile-components/OrderDetails/LoanCard";
import { getUser, getUserBusiness } from "config/user";
import LoanCollectionCard from "components/mobile-components/OrderDetails/LoanCollectionCard";
import { setPaymentInfo } from "redux/container/payEkkbazSlice";

export default function OrderDetailsPage() {
	let paymentInfo = useSelector((state) => state.payEkkbaz.paymentInfo);
	const from = useSelector((state) => state.order.from);
	const { orderUserType } = useParams();
	const appBusiness = getUserBusiness();

	let { id } = useParams();

	const dispatch = useDispatch();
	const response = useSelector((state) => state.order);
	const [order, setOrder] = useState({});
	const [loading, setLoading] = useState(true);
	let [collection, setCollection] = useState([]);
	let [loanDetails, setLoanDetails] = useState({});
	let [loanPaymentList, setLoanPaymentList] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useIonViewDidEnter(() => {
		if (from === "orderList") {
		  dispatch(fetchOrderSingle(id));
		} else {
		  dispatch(fetchOrderDetailsByOrderId(id));
		}
	  });
	  useIonViewWillEnter(() => {
		if (from === "orderList") {
		  dispatch(fetchOrderSingle(id));
		} else {
		  dispatch(fetchOrderDetailsByOrderId(id));
		}
	  });

	  const fetchOderCollectionAmount = async () => {
		if (from === "orderList") {
		  let response = await Orders.orderAmountCollection(id);
		  if (response?.status === 200) {
			setCollection(response.data);
		  }
		} else {
		  let res = await Orders.orderAmountCollection(response?.singleOrder?._id);
		  if (res?.status === 200) {
			setCollection(res.data);
		  }
		}
	  };

	useEffect(() => {
		if (from === "orderList") {
		  dispatch(fetchOrderSingle(id));
		  fetchOderCollectionAmount(id);
		} else {
		  dispatch(fetchOrderDetailsByOrderId(id));
		  if (response?.singleOrder?.order_id === id) {
			fetchOderCollectionAmount(response?.singleOrder?._id);
		  }
		}
	  }, [response?.singleOrder?.order_id, id]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={
							orderUserType === "seller"
								? response?.singleOrder?.created_by_business
										?.business_name
								: response?.singleOrder?.seller?.business_name
						}
						from={
							response.singleOrder?.order_status === "Placed"
								? `${orderUserType === "buyer" && "orderDetailsPage"}`
								: `orderDetailsPage`
						}
					/>
					{response.status === "loading" ? (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList.loader}
						/>
					) : (
						<>
							<OrderMetaBar order={response.singleOrder} />
							<section className='px-2 pt-24'>
								<OrderOnwerState order={response.singleOrder} />
								<DeliveryDetailsCard order={response.singleOrder} />
								<OrderItemCard order={response.singleOrder} />
								<CostSummeryCard order={response.singleOrder} />
								{!response.singleOrder?.hasLoan && (
									<PaymentDetailsCard order={response.singleOrder} />
								)}

								{orderUserType === "buyer" && loanDetails?.orderId && (
									<FinanceSupportCard
										loanDetails={loanDetails}
										order={response.singleOrder}
										collection={loanPaymentList}></FinanceSupportCard>
								)}
								{/* {orderUserType === "buyer" && loanPaymentList && (
									<LoanCollectionCard
										collection={loanPaymentList}
										order={response.singleOrder}></LoanCollectionCard>
								)} */}
								{!response.singleOrder?.hasLoan && (
									<PaymentSettlementCard collection={collection} />
								)}

								{(response.singleOrder?.order_status === "Placed") |
									(response.singleOrder?.order_status ===
										"Accepted") && orderUserType === "seller" ? (
									<SelectTimeDate
										order={response.singleOrder}
										accepted={
											response.singleOrder?.order_status ===
											"Accepted"
										}
									/>
								) : (
									""
								)}

								<TimeLine order={response.singleOrder} />
								<BottomSheet
									orderUserType={orderUserType}
									order={response.singleOrder}
									loanDetails={loanDetails}
								/>
							</section>
						</>
					)}

					{/* <TimeLineTextBox order={response.singleOrder} /> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import BrandProductDetails from "components/mobile-components/BrandProductDetails/BrandProductDetails";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Banner from "components/mobile-components/ProductDetails/Banner";
import ProductDetails from "components/mobile-components/ProductDetails/ProductDetails";
import ProductDetailsSheet from "components/mobile-components/ProductDetails/ProductDetailsSheet";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Product from "services/Product";

export default function BrandProductDetailsPage() {
	let { id } = useParams();
	let { type } = useParams();
	let [loading, setLoading] = useState(false);
	const sellerId = useSelector((state) => state.product.brandId);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [product, setProduct] = useState([]);
	//get suppliers data
	let productData = async () => {
		setLoading(true);
		let response =
			type === "offer"
				? await Product.singleOfferDetails(id)
				: await Product.singleBrandProductDetails(id, sellerId);
		if (response.status === 200) {
			setProduct(response.results);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	useEffect(() => {
		productData();
	}, []);
	let defaultImageMokeUp = () => {
		if (product?.type === "variant") {
			return "/assets/images/varient_big_default.png";
		} else if (product?.type === "pack") {
			return "/assets/images/pack_big_default.png";
		} else if (product?.type === "offer") {
			return "/assets/images/offer_big_default.png";
		} else {
			return "/assets/images/varient_big_default.png";
		}
	};

	let slideData = product?.images?.length
		? [...product?.images]
		: [`${defaultImageMokeUp()}`];

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.productDetails.pageTitle}
						from='brandProductDetail'
					/>
					{loading ? (
						<IonLoading
							isOpen={loading}
							message={ekkLanguage.orderList.loader}
						/>
					) : (
						<div className='pt-14'>
							{" "}
							<Banner
								slideData={slideData}
								serverImsge={product.images?.length}
							/>
							<BrandProductDetails product={product} />
						</div>
					)}
					{/* <ProductDetailsSheet
						product={product}
						productData={productData}
						setProduct={setProduct}
					/> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";
import AddCard from "../AddCard";
import { IonLoading } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCardBottomSheet } from "redux/container/userSlice";

export default function CardDetails({
	cardList,
	cardLoading,
	getCardList,
	setSelectedCard,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	return (
		<div className=''>
			<AddCard getCardList={getCardList} />
			{cardLoading && (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			)}
			<div className='mt-5'>
				{cardList?.length ? (
					cardList?.map((el, i) => {
						return (
							<div
								className={`border-2 py-2 px-3 rounded-[10px] mb-4 ${
									el?.is_default && "border-success"
								}`}
								onClick={() => {
									setSelectedCard(el);
									dispatch(setCardBottomSheet(true));
								}}>
								<div className='flex justify-between items-center'>
									<h4 className='font-bold uppercase text-success'>
										{el?.brand}
									</h4>
									<h4 className='font-bold'>
										********{el?.last4}
										<span className='block text-right mt-1 font-normal'>
											{el?.exp_month}/{el?.exp_year}
										</span>
									</h4>
								</div>
							</div>
						);
					})
				) : (
					<div>No Card Available</div>
				)}
			</div>
		</div>
	);
}

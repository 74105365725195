import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import EditPen from "assets/images/icon/svg/EditPen";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

export default function ImageUploader({
  files,
  setFiles,
  bHeight,
  placeholder,
  imgSource,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <>
      {files?.length ? (
        <>
          <div
            style={{ width: "100%", height: bHeight }}
            className="flex justify-center items-center relative overflow-hidden "
          >
            <div
              style={{
                height: bHeight,
              }}
              className="text-center"
            >
              <img
                style={{ height: bHeight }}
                src={files[0].preview ?? files}
                alt="addImage"
                className="m-auto mb-2 object-contain"
              />

              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {/* <div className='absolute z-10 bottom-4 right-4 cursor-pointer rounded bg-white'>
									<EditPen />
								</div> */}
              </div>
            </div>
          </div>
          {/* {files[0]?.size > 1000000 && (
						<div className='text-primary mt-4 text-right'>
							{ekkLanguage.kycVerification.imageWarning}
						</div>
					)} */}
        </>
      ) : (
        <div
          className="flex justify-center items-center rounded-[10px]"
          style={{
            cursor: "pointer",
            borderRadius: 10,
            width: "100%",
            height: bHeight,
            background: "#E9EFFF",
            border: "1px dashed rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <div className="text-center">
            <input {...getInputProps()} />
            <img
              src={imgSource}
              alt="addImage"
              className="m-auto mb-[10px]"
              // style={{
              //   filter: "drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.2))",
              // }}
            />
            <p
              className="text-18 font-bold py-[5px] text-center px-4"
              style={{ color: "#4285F4" }}
            >
              {placeholder}
            </p>
            {/* {files[0]?.size > 1000000 && ( */}
            <div className="text-[#00000054] mt-4 text-12">
              {ekkLanguage.kycVerification.imageWarning}
            </div>
            {/* )} */}
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import {
	setCustomerDetails,
	setSupplierDetails,
} from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import Customer from "services/Customer";
import { toast } from "react-toastify";
import Card from "../Common/Card";
import Product from "services/Product";
import { getUserBusiness } from "config/user";

export default function SupplierDetailsBottomShit({ brandInfo, getBrandInfo }) {
	const [loader, setLoader] = useState(false);
	const [codeLoader, setCodeLoader] = useState(false);
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.supplierDetails);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [disCode, setDisCode] = useState("");
	const business = getUserBusiness();

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const [isOpenCodeModel, setIsOpenCodeModel] = useState(false);
	const onCloseCodeModal = () => setIsOpenCodeModel(false);

	const handleDistributorCode = async function () {
		setLoader(true);
		let response = await Product.setDistributorCode({
			initiator_id: brandInfo.id,
			related_id: business.id,
			code: disCode,
		});
		if (response.status === 200) {
			setLoader(false);
			getBrandInfo();
			toast.success(
				brandInfo?.code
					? ekkLanguage.customerList.editSuccessLabel
					: ekkLanguage.customerList.successLabel
			);
			onCloseCodeModal();
		} else {
			setLoader(false);
		}
	};

	useEffect(() => setDisCode(brandInfo?.code), [brandInfo?.code]);

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[200, 200, 160, 0]}
				onClose={() => dispatch(setSupplierDetails(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setSupplierDetails(false));
								setIsOpenCodeModel(true);
							}}
							lines='none'>
							<div className='flex items-center justify-center py-[5px]'>
								<span className='mr-[10px]'>
									<img
										src={
											brandInfo?.code
												? "/assets/images/edit2.png"
												: "/assets/images/addDis.png"
										}
										className='h-10 w-10'
										alt=''
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{brandInfo?.code
										? ekkLanguage.customerList.distributorCodeEdit
										: ekkLanguage.customerList.distributorCodeAdd}
								</p>
							</div>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setSupplierDetails(false))}
				/>
			</Sheet>

			{/* add distributor code */}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpenCodeModel}
				onRequestClose={onCloseCodeModal}
				classNames='bottom-sheet-modal'
				showBackdrop={true}>
				<div>
					<Card
						title={ekkLanguage.customerList.distributorCode}
						onHide={true}
						closeModal={onCloseCodeModal}>
						<div>
							<div className='pt-5 pb-5'>
								<IonLabel>
									{ekkLanguage.customerList.writeDistributorCode}
								</IonLabel>
								<IonItem style={{ "--background": "white" }}>
									<input
										type='string'
										value={disCode}
										className='focus-visible:none outline-none'
										onChange={(e) => setDisCode(e.target.value)}
										placeholder={
											ekkLanguage.customerList.distributorCode
										}></input>
								</IonItem>
							</div>

							<div className='text-center pt-0'>
								<IonButton
									color='primary'
									onClick={handleDistributorCode}>
									{codeLoader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.createOrder.btnLabel
									)}
								</IonButton>
							</div>
						</div>
					</Card>
				</div>
			</Modal>
		</div>
	);
}

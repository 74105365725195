import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import Deposit from "components/mobile-components/CollectionDeposit/Deposit";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DepositCollectionPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	useEffect(() => {
		const handleBackButton = (event) => {
			event.preventDefault();
			navigate(-1);
		};

		// Add an event listener for the hardware back button
		document.addEventListener("backbutton", handleBackButton);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("backbutton", handleBackButton);
		};
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.depositList.collectionAndDeposit}
					/>
					<section className='pt-20'>
						<Deposit />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

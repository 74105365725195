import { IonSpinner } from "@ionic/react";
import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import EditPen from "assets/images/icon/svg/EditPen";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBrands } from "redux/container/userSlice";
import User from "services/User";

export default function BrandImageUploader({
  item,
  bHeight,
  placeholder,
  imgSource,
  setLoader,
  loader,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const selectedList = useSelector((state) => state.user.selectedBrands);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      let selectedItem = selectedList.find((el) => el.id === item.id);
      let updateItem = {
        ...selectedItem,
        loading: true,
      };
      let updateList = selectedList.map((el) =>
        el.id === item.id ? updateItem : el
      );
      dispatch(setSelectedBrands(updateList));

      let file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      let formData = new FormData();
      formData.append("key", file[0]);

      if (file.length !== 0) {
        User.toBucketImage(formData).then((res) => {
          let updateItem = {
            ...selectedItem,
            files: file,
            agreeUrl: res?.results?.[0],
            loading: false,
          };
          let updateList = selectedList.map((el) =>
            el.id === item.id ? updateItem : el
          );
          dispatch(setSelectedBrands(updateList));
        });
      }
    },
  });

  return (
    <>
      {item?.files?.length ? (
        <>
          <div
            style={{
              width: "100%",
              height: bHeight,
              border: "1px dashed rgba(0, 0, 0, 0.5)",
              borderRadius: 10,
            }}
            className="flex justify-center items-center relative overflow-hidden "
          >
            <div
              style={{
                height: bHeight,

                padding: 20,
              }}
              className="text-center"
            >
              <img
                style={{ height: bHeight }}
                src={item?.files[0].preview ?? item?.files}
                alt="addImage"
                className="m-auto mb-2 object-contain"
              />
              {/* 
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<div className='h-10 w-10 flex items-center justify-center bg-primary absolute z-10 top-2 right-14 cursor-pointer rounded'>
									<EditPen />
								</div>
							</div> */}
            </div>
          </div>
        </>
      ) : (
        <div
          className="flex justify-center items-center rounded-[10px]"
          style={{
            cursor: "pointer",
            borderRadius: 10,
            width: "100%",
            height: bHeight,
            background: "#E9EFFF",
            border: "1px dashed rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          {item.loading ? (
            <div className="flex items-center justify-center h-[156px] m-auto">
              <IonSpinner name="crescent"></IonSpinner>
            </div>
          ) : (
            <div className="text-center">
              <input {...getInputProps()} />
              <img
                src={imgSource}
                alt="addImage"
                className="m-auto mb-[10px]"
                // style={{
                // 	filter:
                // 		"drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.2))",
                // }}
              />
              <p
                className="text-18 font-bold py-[5px] text-center px-4"
                style={{ color: "#4285F4" }}
              >
                {placeholder}
              </p>
              {/* {files[0]?.size > 1000000 && ( */}
              <div className="text-[#00000054] mt-4 text-12">
                {ekkLanguage.kycVerification.imageWarning}
              </div>
              {/* )} */}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export const agreements = [
  {
    id: 2,
    bangla_description:
      " প্রথম পক্ষ/ডিস্ট্রিবিউটর তার ব্যাবসা পরিচালনার জন্য EkkBaz/দ্বিতীয় পক্ষ এর তৈরিকৃত অনলাইন প্লাটফর্ম ব্যাবহার করবেন এবং এর মাধ্যমে তাহার প্রতিদিন এর ব্যাবসা এর বিস্তারিত অনলাইন প্লাটফর্ম এ প্রবেশ করবেন। এই ব্যাবসা বিস্তারিত এর ক্ষেত্রে প্রথম পক্ষ/ডিস্ট্রিবিউটর তাহার প্রতিদিন এর সেকেন্ডারি বিক্রয় এবং প্রাইমারি বিক্রয় এর প্রতিটি তথ্য প্রদান করবেন।",
    english_description:
      "The First Party/Distributor shall utilize the online platform developed by EkkBaz/Second Party to conduct their business and enter their daily business details into the online platform. With regards to these business details, the first party/distributor shall provide all information pertaining to their secondary sales and primary sales on a daily basis.",
    vietnamese_description:
      "Bên thứ nhất/Nhà phân phối sẽ sử dụng nền tảng trực tuyến do EkkBaz/Bên thứ hai phát triển để tiến hành hoạt động kinh doanh và nhập thông tin chi tiết kinh doanh hàng ngày của họ vào nền tảng trực tuyến. Liên quan đến các chi tiết kinh doanh này, bên/nhà phân phối thứ nhất phải cung cấp tất cả thông tin liên quan đến doanh số bán hàng thứ cấp và doanh số bán hàng chính của họ hàng ngày.",
    malay_description:
      "Pihak Pertama/Pengedar hendaklah menggunakan platform dalam talian yang dibangunkan oleh EkkBaz/Pihak Kedua untuk menjalankan perniagaan mereka dan memasukkan butiran perniagaan harian mereka ke dalam platform dalam talian. Berkenaan dengan butiran perniagaan ini, pihak/pengedar pertama hendaklah memberikan semua maklumat yang berkaitan dengan jualan sekunder dan jualan utama mereka setiap hari",
  },
  {
    id: 3,
    bangla_description:
      "প্রথম পক্ষ/ডিস্ট্রিবিউটর তাহার প্রতিদিন এর বিক্রয় এর বিস্তারিত অনলাইন প্লাটফর্মএ প্রবেশ করার জন্য উনার বিক্রয় প্রতিনিধি বা টেরিটরি সেলস অফিসার এর নাম, মোবাইল নাম্বার। তাহার সকল পন্য এর ডিস্ট্রিবিউটর দাম (DP), দোকানদার এর দাম (TP), তাহার বিক্রয় এর জন্য নির্ধারিত সকল দোকান এর লিস্ট EkkBaz/দ্বিতীয় পক্ষ এর সাথে শেয়ার করবেন।",
    english_description:
      "The First Party/Distributor is responsible for entering their daily sales details into the online platform, including the name of their Sales Representative or Territory Sales Officer and their Mobile Number. Additionally, they are required to share with EkkBaz/Second party the distributor price (DP), shopkeeper price (TP), and the list of all shops scheduled for its sale.",
    vietnamese_description:
      "Bên/Nhà phân phối thứ nhất chịu trách nhiệm nhập thông tin chi tiết về doanh số bán hàng hàng ngày của họ vào nền tảng trực tuyến, bao gồm tên của Đại diện bán hàng hoặc Nhân viên bán hàng khu vực và Số điện thoại di động của họ. Ngoài ra, họ phải chia sẻ với EkkBaz/Bên thứ hai về giá nhà phân phối (DP), giá chủ cửa hàng (TP) và danh sách tất cả các cửa hàng dự kiến bán.",
    malay_description:
      "Pihak Pertama/Pengedar bertanggungjawab untuk memasukkan butiran jualan harian mereka ke dalam platform dalam talian, termasuk nama Wakil Jualan atau Pegawai Jualan Wilayah mereka dan Nombor Mudah Alih mereka. Selain itu, mereka dikehendaki berkongsi dengan EkkBaz/pihak kedua harga pengedar (DP), harga pekedai (TP) dan senarai semua kedai yang dijadualkan untuk jualannya",
  },
  {
    id: 4,
    bangla_description:
      "প্রথম পক্ষ/ডিস্ট্রিবিউটর কে অনলাইন প্লাটফর্মএ অভ্যস্ত করার জন্য EkkBaz/দ্বিতীয় পক্ষ একজন হাব এক্সিকুটিভ (অনলাইন বা অফলাইন) নিয়োগ করবেন  এবং এই হাব এক্সিকুটিভ সর্বোচ্চ ২ সপ্তাহ প্রথম পক্ষ/ডিস্ট্রিবিউটরকে সাপোর্ট দিবেন EkkBaz/দ্বিতীয় পক্ষ এর তৈরিকৃত অনলাইন প্লাটফর্ম এ অভ্যস্ত করার জন্য।",
    english_description:
      "EkkBaz/Second Party shall appoint a Hub Executive, either online or offline, to acquaint the First Party/Distributor with the online platform. This Hub Executive will provide support to the First Party/Distributor for a maximum of 2 weeks to ensure their familiarity with the online platform developed by EkkBaz/Second Party.",
    vietnamese_description:
      "EkkBaz/Bên thứ hai sẽ chỉ định Giám đốc điều hành Hub, trực tuyến hoặc ngoại tuyến, để giúp Bên thứ nhất/Nhà phân phối làm quen với nền tảng trực tuyến. Giám đốc điều hành Hub này sẽ cung cấp hỗ trợ cho Bên thứ nhất/Nhà phân phối trong tối đa 2 tuần để đảm bảo họ làm quen với nền tảng trực tuyến do EkkBaz/Bên thứ hai phát triển.",
    malay_description:
      "EkkBaz/Pihak Kedua hendaklah melantik Eksekutif Hab, sama ada dalam talian atau luar talian, untuk membiasakan Pihak Pertama/Pengedar dengan platform dalam talian. Eksekutif Hab ini akan memberikan sokongan kepada Pihak Pertama/Pengedar selama maksimum 2 minggu untuk memastikan kebiasaan mereka dengan platform dalam talian yang dibangunkan oleh EkkBaz/Pihak Kedua.",
  },
  {
    id: 5,
    bangla_description:
      "অত্র চুক্তির মেয়াদকালীন সময়ে পক্ষ/ডিস্ট্রিবিউটর অন্য কোন তৃতীয় পক্ষের কাছে একবাজের সাথে ব্যবসায়ের কোন তথ্য প্রদানে সর্বদা বিরত থাকবে এবং সকল প্রকার গোপনীয় তথ্য প্রকাশ রোধ করতে পর্যাপ্ত সুরক্ষা পদ্ধতি প্রয়োগ করবে। গোপনীয় তথ্যে এমন তথ্য অন্তর্ভুক্ত হবে না যা জনসাধারণের কাছে জানা ছিল বা আইন দ্বারা এই জাতীয় তথ্য প্রকাশের অনুমতি রয়েছে।",
    english_description:
      "During the term of this Agreement, the Party/Distributor shall refrain from disclosing any business information to any third party other than EkkBaz, and shall implement adequate safeguards to prevent the disclosure of all confidential information. Confidential information shall not include information that is already known to the public or whose disclosure is permitted by law.",
    vietnamese_description:
      "Trong thời hạn của Thỏa thuận này, Bên/Nhà phân phối sẽ không tiết lộ bất kỳ thông tin kinh doanh nào cho bất kỳ bên thứ ba nào ngoài EkkBaz và sẽ thực hiện các biện pháp bảo vệ thích hợp để ngăn chặn việc tiết lộ tất cả thông tin bí mật. Thông tin bí mật sẽ không bao gồm thông tin đã được công chúng biết đến hoặc được pháp luật cho phép tiết lộ.",
    malay_description:
      "Sepanjang tempoh Perjanjian ini, Pihak/Pengedar hendaklah mengelak daripada mendedahkan sebarang maklumat perniagaan kepada mana-mana pihak ketiga selain EkkBaz, dan hendaklah melaksanakan perlindungan yang mencukupi untuk menghalang pendedahan semua maklumat sulit. Maklumat sulit tidak boleh termasuk maklumat yang telah diketahui umum atau pendedahan yang dibenarkan oleh undang-undang.",
  },
];

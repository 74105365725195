import { IonCol } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

export default function PaymentRecieptImage() {
  const paymentDetails = useSelector(state=>state.loan.paymentDetails);

  return (
    <div className="mt-14 px-4">
      {/* <IonCol size="2"></IonCol> */}
      <IonCol size="8">
        <div
          className="text-center flex items-center justify-center"
        //   style={{ height: "calc(100vh - 420px)" }}
        >
          <div>
            <img
              className="rounded-lg p-1"
              src={paymentDetails?.image}
              alt="reciept"
              style={{
                border: "0.5px solid rgba(0, 0, 0, 0.3)",
                background: "#E6E6E6",
              }}
            />
            {/* <p className="text-16 font-normal text-222222 mb-[35px]">
              {ekkLanguage.orderList.notFound}
            </p> */}
          </div>
        </div>
      </IonCol>
    </div>
  );
}

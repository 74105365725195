import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CustomerDetails from "components/mobile-components/CustomerDetails";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Customer from "services/Customer";

export default function CustomerDetailsPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { id, relationId } = useParams();
	const [customer, setCustomer] = useState({});
	const [customerInfo, setCustomerInfo] = useState({});
	const [loader, setLoader] = useState(true);

	const getDetails = async (state) => {
		let amountInfo = await Customer.getCustomerInfo(relationId);
		let customerInfo = await Customer.getCustomer(id);
		if (amountInfo.status === 200 && customerInfo.status === 200) {
			setLoader(false);
			setCustomer(amountInfo.data);
			setCustomerInfo(customerInfo.results);
		} else {
			setLoader(false);
		}
	};
	useEffect(() => {
		getDetails();
	}, []);

	// console.log(customerInfo);
	return (
		<IonApp>
			<IonPage className=''>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.customerDetails.pageTitle} />
					{loader ? (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList?.loader}></IonLoading>
					) : (
						<CustomerDetails
							customer={customer}
							customerInfo={customerInfo}
						/>
					)}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

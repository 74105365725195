export const bankListArray = [
	{
		id: 1,
		type: "cash",
		logo: "/assets/images/money2.png",
		image: "/assets/images/money2.png",
		name: "Cash & Hub",
		acName: "Cash & Hub",
		acNumber: "044330013280",
		routeNumber: "70274187",
		branch: "Mogbazar Branch",
		countryName: "Bangladesh",
		countryShortName: "BD",
		localLnName: "ব্যাংক এশিয়া",
		value: "Cash & Hub",
	},
	{
		id: 2,
		type: "mfs",
		logo: "/assets/images/bkash.png",
		image: "/assets/images/bkash.png",
		name: "Bkash",
		acName: "Bkash",
		acNumber: "01701898144",
		routeNumber: "",
		branch: "",
		countryName: "Bangladesh",
		countryShortName: "BD",
		localLnName: "বিকাশ",
		value: "Bkash",
	},
	{
		id: 3,
		type: "mfs",
		logo: "/assets/images/nogod.png",
		image: "/assets/images/nogod.png",
		name: "Nagad",
		acName: "Nagad",
		acNumber: "01701898144",
		routeNumber: "",
		branch: "",
		countryName: "Bangladesh",
		countryShortName: "BD",
		localLnName: "নগদ",
		value: "Nagad",
	},
	{
		id: 4,
		type: "bank",
		logo: "/assets/images/islami-bank.png",
		image: "/assets/images/islami-bank.png",
		name: "Islami Bank limited",
		acName: "EkkBaz Bangladesh Pvt. ltd",
		acNumber: "20503110100185612",
		routeNumber: "125274245",
		branch: "Islami Bank Motijheel Branch, Dhaka",
		countryName: "Bangladesh",
		countryShortName: "BD",
		localLnName: "ইসলামী ব্যাংক",
		value: "Islami Bank",
	},
	{
		id: 5,
		type: "bank",
		logo: "/assets/images/bank-asia.png",
		image: "/assets/images/bank-asia.png",
		name: "Bank Asia",
		acName: "EkkBaz Bangladesh Pvt Ltd.",
		acNumber: "044330013280",
		routeNumber: "70274187",
		branch: "Mogbazar Branch",
		countryName: "Bangladesh",
		countryShortName: "BD",
		localLnName: "ব্যাংক এশিয়া",
		value: "Bank Asia",
	},
];

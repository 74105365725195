import { IonLabel } from "@ionic/react";
import React from "react";

export default function CustomSelect({
  options,
  setOption,
  mainLabel,
  extraLabel,
  labelImage,
  star,
  option,
  selectPlaceholder,
  type,
  disable,
  selectPlaceholderWarning,
  localLanguage,
  handleOption,
}) {
  return (
    <div className="my-5">
      <IonLabel className="flex items-center mb-[11px] ">
        <img className="mr-[10px] w-6 h-6" src={labelImage} alt=""></img>
        <span className="text-16 font-bold">{mainLabel}</span>
        {star && <span className="text-primary mx-1">*</span>}
        {extraLabel && (
          <p className="ml-1 text-12 text-[#00000080]">({extraLabel})</p>
        )}
      </IonLabel>
      <select
        disabled={disable}
        onChange={(e) => {
            setOption(e.target.value);
        }}
        className="w-full h-[50px] pl-[15px] bg-white rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:w-full"
        style={{
          WebkitAppearance: "none",
          backgroundImage: 'url("/assets/images/dropdown.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 14px center",
        }}
        value={option}
      >
        <option className="text-14" value="">
          {!disable ? selectPlaceholder : selectPlaceholderWarning}
        </option>
        {options?.map(
          (item) =>
            type === "country-selection" && (
              <option
                className="text-14"
                key={item.value}
                value={item.value}
                data-image={item.flag}
              >
                {localLanguage === "bn" ? item.country : item.country}
              </option>
            )
        )}

        {options?.map(
          (item) =>
            type === "country-selection" && (
              <option className="text-14" key={item.value} value={item.value}>
                <img className="w-[46px] h-[30px]" src={item.flag} alt="" />{" "}
                {localLanguage === "bn" ? item.country : item.country}
              </option>
            )
          //   <option className="text-14" key={item.id} value={item.name}>
          //     {localLanguage === "bn" ? item.bn_name : item.name}
          //   </option>
        )}
      </select>
    </div>
  );
}

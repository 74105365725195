import { IonCol, IonLoading } from "@ionic/react";
import React from "react";
import Search from "../Common/Search";
import SingleSupplier from "./SingleSupplier";
import SupplierFilter from "./SupplierFilter";

export default function SupplierList({
	ekkLanguage,
	brandList,
	loading,
}) {
	return (
		<div className='mt-[10px]'>
			{loading && (
				<IonLoading isOpen={true} message={ekkLanguage.orderList?.loader} />
			)}
			{brandList.length > 0 ? (
				<div className='grid grid-cols-3 gap-3'>
					{brandList.map((el, i) => {
						return <SingleSupplier item={el} key={i} />;
					})}
				</div>
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}
						>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}

import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import Payment from "services/Payment";
import SingleTransaction from "./SingleTransaction";
import { IonSpinner } from "@ionic/react";
import { useSelector } from "react-redux";

export default function TransactionHistory() {
	const [loading, setLoading] = useState(false);
	const [transactionHistory, setTransactionHistory] = useState(null);
	const business = getUserBusiness();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const getTransactionHistory = async () => {
		setLoading(true);
		let response = await Payment.getTransactionHistory(business.id);
		if (response.success) {
			setLoading(false);
			setTransactionHistory(response?.results);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getTransactionHistory();
	}, []);
	return (
		<div className='px-4'>
			<h4 className='font-bold text-18 mb-3 text-white'>
				{ekkLanguage.paymentModule.transactionHistory}
			</h4>

			<div>
				{transactionHistory?.length ? (
					transactionHistory?.map((el, i) => {
						return <SingleTransaction key={i} item={el} />;
					})
				) : (
					<>
						{loading ? (
							<div className='flex justify-center items-center py-5'>
								<IonSpinner
									name='crescent'
									className='mr-2'></IonSpinner>
							</div>
						) : (
							<div className='flex justify-center items-center py-5'>
								<p className='text-white'>Not found!</p>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

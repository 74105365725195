import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "redux/container/authSlice";
import BannerArea from "./BannerArea";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Partners from "./Partners";
import OurTeam from "./OurTeam";

export default function BazAiLanding() {
	const [scrolling, setScrolling] = useState(false);
	const { loginStatus } = useSelector(selectors.getAuth);

	useEffect(() => {}, [loginStatus]);

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}

	return (
		<section style={{ background: "#f2f3f7" }}>
			<Navigation scrolling={scrolling ? "isScrolled" : ""} />
			<BannerArea />
			{/* <OurTeam /> */}
			<Partners />
			<Footer />
		</section>
	);
}

import React from "react";

export default function Delete({ color = "#F94B41" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_2323_384)">
        <path
          d="M19.2499 3.66667H16.4083C16.1955 2.63213 15.6326 1.70257 14.8144 1.03466C13.9962 0.366749 12.9728 0.00133331 11.9166 0L10.0833 0C9.02707 0.00133331 8.00363 0.366749 7.18544 1.03466C6.36725 1.70257 5.80434 2.63213 5.59159 3.66667H2.74992C2.5068 3.66667 2.27365 3.76324 2.10174 3.93515C1.92983 4.10706 1.83325 4.34022 1.83325 4.58333C1.83325 4.82645 1.92983 5.05961 2.10174 5.23151C2.27365 5.40342 2.5068 5.5 2.74992 5.5H3.66659V17.4167C3.66804 18.6318 4.15139 19.7967 5.01062 20.656C5.86985 21.5152 7.03479 21.9985 8.24992 22H13.7499C14.965 21.9985 16.13 21.5152 16.9892 20.656C17.8484 19.7967 18.3318 18.6318 18.3333 17.4167V5.5H19.2499C19.493 5.5 19.7262 5.40342 19.8981 5.23151C20.07 5.05961 20.1666 4.82645 20.1666 4.58333C20.1666 4.34022 20.07 4.10706 19.8981 3.93515C19.7262 3.76324 19.493 3.66667 19.2499 3.66667ZM10.0833 1.83333H11.9166C12.4852 1.83403 13.0396 2.01059 13.5039 2.33882C13.9682 2.66705 14.3196 3.13087 14.5098 3.66667H7.49C7.68029 3.13087 8.03166 2.66705 8.49594 2.33882C8.96022 2.01059 9.51467 1.83403 10.0833 1.83333ZM16.4999 17.4167C16.4999 18.146 16.2102 18.8455 15.6945 19.3612C15.1787 19.8769 14.4793 20.1667 13.7499 20.1667H8.24992C7.52057 20.1667 6.8211 19.8769 6.30538 19.3612C5.78965 18.8455 5.49992 18.146 5.49992 17.4167V5.5H16.4999V17.4167Z"
          fill={color}
        />
        <path
          d="M9.16667 16.5C9.40978 16.5 9.64294 16.4034 9.81485 16.2315C9.98676 16.0596 10.0833 15.8264 10.0833 15.5833V10.0833C10.0833 9.84021 9.98676 9.60705 9.81485 9.43514C9.64294 9.26323 9.40978 9.16666 9.16667 9.16666C8.92355 9.16666 8.69039 9.26323 8.51849 9.43514C8.34658 9.60705 8.25 9.84021 8.25 10.0833V15.5833C8.25 15.8264 8.34658 16.0596 8.51849 16.2315C8.69039 16.4034 8.92355 16.5 9.16667 16.5Z"
          fill={color}
        />
        <path
          d="M12.8334 16.5C13.0765 16.5 13.3097 16.4034 13.4816 16.2315C13.6535 16.0596 13.7501 15.8264 13.7501 15.5833V10.0833C13.7501 9.84021 13.6535 9.60705 13.4816 9.43514C13.3097 9.26323 13.0765 9.16666 12.8334 9.16666C12.5903 9.16666 12.3571 9.26323 12.1852 9.43514C12.0133 9.60705 11.9167 9.84021 11.9167 10.0833V15.5833C11.9167 15.8264 12.0133 16.0596 12.1852 16.2315C12.3571 16.4034 12.5903 16.5 12.8334 16.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2323_384">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

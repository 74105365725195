import React, { useEffect, useRef, useState } from "react";
import SenderBankInfoCard from "./SenderBankInfoCard";
import ReceiverBankInfoCard from "./ReceiverBankInfo";
import DataTimeCard from "./DataTimeCard";
import { useDispatch, useSelector } from "react-redux";
import Delete from "assets/images/icon/svg/Delete";
import ImageUploader from "../VerifyBusiness/ImageUploader";
import ImageUploadWithCamera from "../Common/ImageUploadWithCamera";
import {
	setEmptyPaymentInfo,
	setPaymentInfo,
} from "redux/container/payEkkbazSlice";
import Payment from "services/Payment";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import User from "services/User";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function PayEkkbaz() {
	let paymentInfo = useSelector((state) => state.payEkkbaz.paymentInfo);
	let orderDetails = useSelector((state) => state.order.singleOrder);
	let daliveryDate = useSelector((state) => state.payEkkbaz?.paymentDate);
	const { id } = useParams();

	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [senderBank, setSenderBank] = useState({});
	const [receiverBank, setReceiverBank] = useState({});
	const [transactionId, setTransactionId] = useState(
		paymentInfo?.transactionId
	);
	const [amount, setAmount] = useState(paymentInfo?.payableAmount);
	const [isCameraMood, setIsCameraMood] = useState(false);
	const [uploadImage, setUploadImage] = useState({});
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [amountWarning, setAmountWarning] = useState(false);
	const [transationWarning, setTransationWarning] = useState(false);
	const [imageURL, setImageURL] = useState("");
	const navigate = useNavigate();

	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const getReceiverBankInfo = async () => {
		const response = await Payment.getReceiverBankInfo(
			orderDetails?.payment_method
		);
		if (response.status === 200) {
			setReceiverBank(response.results);
		} else {
			setReceiverBank({});
		}
	};
	const getSenderBankInfo = async () => {
		const response = await Payment.getSenderBankInfo();
		if (response.status === 200) {
			setSenderBank(response.results[0]);
		} else {
			setSenderBank({});
		}
	};
	useEffect(() => {
		getSenderBankInfo();
		getReceiverBankInfo();
	}, []);
	// product?.customerPrice
	useEffect(() => {
		dispatch(
			setPaymentInfo({
				...paymentInfo,
				transactionId,
				images: uploadImage,
				amount,
			})
		);
	}, [transactionId, amount, uploadImage]);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const createNewPayment = async () => {
		setLoader(true);
		let data = {
			loan_track_id: Number(paymentInfo?.loanTrackingId),
			payment_date: moment(daliveryDate).format("D-M-YYYY h:mm:ss A"),
			ekkbaz_gateway: Number(receiverBank?.id),
			buyer_gateway: Number(senderBank?.id),
			buyer_transaction_no: transactionId,
			amount: Number(amount),
			trns_type: "Down Payment",
			order_id: Number(orderDetails?.order_id),
		};
		let formData = new FormData();
		if (uploadImage?.length) {
			setLoader(true);
			if (Array.isArray(uploadImage)) {
				formData.append("key", uploadImage[0]);
				let response = await User.toBucketImage(formData);
				if (response.status === 201) {
					setImageURL(response?.results);
					let res = await await Payment.sellerPaySubmit({
						...data,
						image: response?.results[0],
					});
					if (res.status === 201) {
						setLoader(false);
						toast.success(ekkLanguage.createProduct.productCreated);
						onCloseModal();
						navigate(`/purchase-order-details/${id}/buyer`);
						dispatch(setEmptyPaymentInfo());
					} else {
						setLoader(false);
					}
				} else {
					setLoader(false);
				}
			} else {
				function DataURIToBlob(dataURI) {
					const splitDataURI = dataURI.split(",");
					const byteString =
						splitDataURI[0].indexOf("base64") >= 0
							? atob(splitDataURI[1])
							: decodeURI(splitDataURI[1]);
					const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
					const ia = new Uint8Array(byteString.length);
					for (let i = 0; i < byteString.length; i++)
						ia[i] = byteString.charCodeAt(i);
					return new Blob([ia], { type: mimeString });
				}
				const cameraFile = DataURIToBlob(uploadImage);
				const cameraData = new FormData();
				cameraData.append("key", cameraFile, "image.jpg");
				let cameraResponse = await User.toBucketImage(cameraData);
				if (cameraResponse.status === 201) {
					setImageURL(cameraResponse?.results);
					let res = await await Payment.sellerPaySubmit({
						...data,
						image: cameraResponse?.results[0],
					});
					if (res.status === 201) {
						setLoader(false);
						toast.success(ekkLanguage.createProduct.productCreated);
						onCloseModal();
						navigate(`/purchase-order-details/${id}/buyer`);
						dispatch(setEmptyPaymentInfo());
					} else {
						setLoader(false);
					}
				} else {
					setLoader(false);
				}
			}
		} else {
			let res = await await Payment.sellerPaySubmit(data);
			if (res.status === 201) {
				setLoader(false);
				toast.success(ekkLanguage.createProduct.productCreated);
				onCloseModal();
				navigate(`/purchase-order-details/${id}/buyer`);
				dispatch(setEmptyPaymentInfo());
			} else {
				setLoader(false);
			}
		}
	};

	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<div className='pt-14'>
			<SenderBankInfoCard senderBank={senderBank} />
			<ReceiverBankInfoCard receiverBank={receiverBank} />
			<DataTimeCard />

			<div className='px-4 mb-5'>
				<div className='mb-4'>
					<div className='flex '>
						<img
							src='/assets/images/money-transfer.png'
							alt='icon'
							className='mr-[10px] w-5 h-5'
						/>

						<h4 className='pb-3 font-bold text-16 text-black-1000'>
							{ekkLanguage.payment.transitionId}
						</h4>
					</div>

					<input
						className='pl-4 h-[50px] rounded-[5px] bg-white placeholder-black-333 w-full border'
						style={{ boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.05)" }}
						type='string'
						placeholder={ekkLanguage.payment.transitionIdPlaceHolder}
						value={transactionId}
						onChange={(e) => setTransactionId(e.target.value)}
					/>
					{transationWarning && (
						<div className='text-primary mt-2 text-right'>
							{ekkLanguage.payment.inputWarning}
						</div>
					)}
				</div>
				<div className='mb-4'>
					<div className='flex'>
						<img
							src='/assets/images/money2.png'
							alt='icon'
							className='mr-[10px] w-5 h-5'
						/>

						<h4 className='pb-3 font-bold text-16 text-black-1000'>
							{ekkLanguage.payment.amount}
						</h4>
					</div>

					<input
						ref={numberInputRef}
						className={`pl-4 h-[50px] rounded-[5px] ${
							amount > paymentInfo?.payableAmount
								? "bg-F94B41-200"
								: "bg-white"
						}  placeholder-black-333 w-full border`}
						style={{ boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.05)" }}
						type='number'
						placeholder={ekkLanguage.payment.amountPlaceHolder}
						value={amount}
						onChange={(e) => setAmount(e.target.value)}
					/>
					{amount > paymentInfo?.payableAmount && (
						<div className='text-primary mt-2 text-right'>
							{ekkLanguage.payment.warning}
						</div>
					)}
				</div>
			</div>
			<div className='px-4'>
				<div>
					<div className='pt-4 flex items-center justify-between'>
						<div className='flex'>
							<img
								src='/assets/images/upload.png'
								alt='icon'
								className='mr-[10px] w-5 h-5'
							/>

							<h4 className='pb-3 font-bold text-16 text-black-1000'>
								{ekkLanguage.payment.uploadReceipt}
							</h4>
						</div>

						<span
							className='h-10 w-10 flex items-center justify-center bg-white rounded-[5px]'
							onClick={() => {
								setIsCameraMood(false);
								setUploadImage("");
							}}>
							<Delete />
						</span>
					</div>

					<ImageUploadWithCamera
						isCameraMood={isCameraMood}
						setIsCameraMood={setIsCameraMood}
						uploadImage={uploadImage}
						setUploadImage={setUploadImage}
						placeholder={ekkLanguage.payment.receiptOrBank}
					/>
				</div>
			</div>
			<div className='mt-[70px] mb-7 px-4'>
				<button
					onClick={() => {
						if (
							amount &&
							amount <= paymentInfo?.payableAmount &&
							transactionId
						) {
							if (amount) setAmountWarning(false);
							if (transactionId) setTransationWarning(false);

							onOpenModal();
						} else {
							if (!amount) setAmountWarning(true);
							if (!transactionId) setTransationWarning(true);
						}
					}}
					className='h-50 py-3 w-full rounded-[5px] text-white font-bold text-20 flex justify-center items-center bg-success'
					style={{ boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)" }}>
					{ekkLanguage.orderDetails.send}
					<img
						className='ml-[10px] w-5'
						src='/assets/images/send2.png'
						alt='icon'
					/>
				</button>
			</div>
			{/* order conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.payment.paymentConformationMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>

						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && createNewPayment}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import OrderInvoiceSearch from "./OrderInvoiceSearch";
import OrderStatusBtn from "./OrderStatusBtn";
import OrderStatusCard from "./OrderStatusCard";

export default function OrderStatus({ setOrderStatus, loading, setLoading }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='px-2 pt-14'>
			<OrderInvoiceSearch
				setLoading={setLoading}
				ekkLanguage={ekkLanguage}
			/>
			<OrderStatusBtn setOrderStatus={setOrderStatus} />
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<OrderStatusCard />
			)}
		</div>
	);
}

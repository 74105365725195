export const BUSINESS_TYPE_INFO = [
	{
		key: "MART",
		title: "Mini Mart",
		type: "Mini Mart",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/mini-mart.png",
		banglaText: "মিনি মার্ট",
	},
	{
		key: "SUPERMART",
		title: "Super Mart",
		type: "Super Mart",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/shopping.png",
		banglaText: "সুপার মার্ট",
	},
	{
		key: "PHARMACY",
		title: "Pharmacy",
		type: "Pharmacy",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/pills.png",
		banglaText: "ফার্মেসি",
	},
	{
		key: "Cart",
		title: "Cart",
		type: "Cart",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/cart.png",
		banglaText: "কার্ট",
	},
	{
		key: "Bazaar Store",
		title: "Bazaar Store",
		type: "Bazaar Store",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/bazzar-store.png",
		banglaText: "বাজার দোকান",
	},

	{
		key: "HAWKER",
		title: "Hawker Center",
		type: "Hawker Center",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/food-stand.png",
		banglaText: "হকার সেন্টার",
	},
	{
		key: "RESTAURANT",
		title: "Restaurant",
		type: "Restaurant",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/restaurant.png",
		banglaText: "রেস্টুরেন্ট",
	},
	{
		key: "HOTEL",
		title: "Hotel",
		type: "Hotel",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/hotel.png",
		banglaText: "হোটেল",
	},

	{
		key: "COSMETICS",
		title: "Cosmetics Store",
		type: "Cosmetics Store",
		catagore: "buyer",
		known_as: "FMCG",
		image: "assets/images/business/makeup.png",
		banglaText: "প্রসাধনী দোকান",
	},

	//non-fmcg

	{
		key: "OFFICE",
		title: "Office",
		type: "Office",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/office-building.png",
		banglaText: "অফিস",
	},
	{
		key: "NGO",
		title: "NGO",
		type: "NGO",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/ngo.png",
		banglaText: "এনজিও",
	},
	{
		key: "ENTERTAINMENT",
		title: "Entertainment Place",
		type: "Entertainment Place",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/movies.png",
		banglaText: "বিনোদনের স্থান",
	},
	{
		key: "Religious Organization",
		title: "Religious Organization",
		type: "Religious Organization",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/religious-organization.png",
		banglaText: "ধর্মীয় সংগঠন",
	},
	{
		key: "SPA",
		title: "Spa",
		type: "Spa",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/spa.png",
		banglaText: "স্পা",
	},
	{
		key: "SALON",
		title: "Salon",
		type: "Salon",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/salon.png",
		banglaText: "সেলুন",
	},
	{
		key: "GYM",
		title: "Gym",
		type: "Gym",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/gym.png",
		banglaText: "জিম",
	},

	{
		key: "ELECTRONIC",
		title: "Electronics Store",
		type: "Electronics Store",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/electronic-store.png",
		banglaText: "ইলেক্ট্রনিক্স স্টোর",
	},
	{
		key: "SHOE",
		title: "Shoe Store",
		type: "Shoe Store",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/shoe-store.png",
		banglaText: "জুতার দোকান",
	},
	{
		key: "FASHION",
		title: "Fashion Store",
		type: "Fashion Store",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/fashion-store.png",
		banglaText: "ফ্যাশন স্টোর",
	},

	{
		key: "LAUNDRY",
		title: "Laundry Store",
		type: "Laundry Store",
		catagore: "buyer",
		known_as: "NON-FMCG",
		image: "assets/images/business/laundry-store.png",
		banglaText: "লন্ড্রি স্টোর",
	},
	{
		key: "MANUFACTURER",
		title: "Manufacturer",
		type: "Manufacturer",
		catagore: "seller",
		known_as: "FMCG",
		image: "assets/images/business/manufacturer.png",
	},
	{
		key: "DISTRIBUTOR",
		title: "Distributor",
		type: "Distributor",
		catagore: "seller",
		known_as: "FMCG",
		image: "assets/images/business/moving-truck.png",
	},
	{
		key: "WHOLESALER",
		title: "Wholesaler",
		type: "Wholesaler",
		catagore: "seller",
		known_as: "FMCG",
		image: "assets/images/business/wholesale.png",
	},
	{
		key: "BRAND",
		title: "Brand",
		type: "Brand",
		image: "assets/images/business/brand.png",
	},

	//retails
];

import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import Business from "services/Business";

export default function KYCInfo({ kycInfo, ekkLanguage }) {
	let businessDetails = getUserBusiness();
	let [agreementDetails, setAgreementDetails] = useState();
	let [agreementView, setAgreementView] = useState(true);

	let getAgreementDetails = async () => {
		let response = await Business.agreementDetails(businessDetails.id);
		if (response.status === 200) {
			setAgreementDetails(response.results);
		} else {
			setAgreementDetails(null);
		}
	};
	useEffect(() => {
		getAgreementDetails();
	}, []);

	let singleKycInfo = (title, img, height) => {
		return (
			<div className='mb-5'>
				<h4 className='font-bold text-black-1000 mb-[10px]'>{title}</h4>
				<div>
					<>
						{img ? (
							<img src={img} alt='img' />
						) : (
							<div className='text-center'>
								<img
									src='assets/images/notFound2.png'
									alt='img'
									className='m-auto'
								/>
								<h4
									className='font-bold text-18'
									style={{ color: "#347BFA" }}>
									{ekkLanguage.kycInfo.notFound}
								</h4>
							</div>
						)}
					</>
				</div>
			</div>
		);
	};
	const onButtonClick = () => {
		const pdfUrl = agreementDetails?.agreement_paper;
		const link = document.createElement("a");
		link.href = pdfUrl;
		link.download = "document.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<div className='px-4 py-5'>
			{singleKycInfo(ekkLanguage.kycInfo.nidFront, kycInfo?.nid_front_img)}
			{singleKycInfo(ekkLanguage.kycInfo.nidBack, kycInfo?.nid_back_img)}
			{singleKycInfo(ekkLanguage.kycInfo.tradeLicense, kycInfo?.trade_img)}
			{singleKycInfo(ekkLanguage.kycInfo.selfie, kycInfo?.selfie)}
			{singleKycInfo(
				ekkLanguage.kycInfo.proofAddress,
				kycInfo?.address_proof_img
			)}
			{singleKycInfo(ekkLanguage.kycInfo.tin, kycInfo?.tin_img)}
			{singleKycInfo(ekkLanguage.kycInfo.cheque, kycInfo?.cheque_img)}
			<div className='mb-5'>
				<h4 className='font-bold text-black-1000 mb-[10px]'>
					{ekkLanguage.kycInfo.contractPaper}
				</h4>
				<div>
					<>
						{kycInfo?.brands_arg?.length
							? kycInfo?.brands_arg?.map((el, i) => {
									return (
										<div className='mb-5' key={i}>
											<div className='flex items-center mb-4'>
												{el?.brand_image?.length ? (
													<div
														className='h-10 w-10 rounded-full overflow-hidden'
														style={{
															boxShadow:
																"0px 2px 2px rgba(0,0,0,.25)",
														}}>
														<img
															src={el?.brand_image[0]}
															alt='logo'></img>
													</div>
												) : (
													<div
														className='h-10 w-10 bg-black-333 rounded-full'
														style={{
															boxShadow:
																"0px 2px 2px rgba(0,0,0,.25)",
														}}>
														<h3 className='h-10 w-10 flex justify-center items-center font-bold text-24 text-white'>
															{el?.brand_name?.[0].toUpperCase()}
														</h3>
													</div>
												)}
												<h4 className='ml-2 font-bold text-16'>
													{el?.brand_name}
												</h4>
											</div>

											<div>
												<img src={el?.agreeUrl} alt='img' />
											</div>
										</div>
									);
							  })
							: undefined}
					</>
				</div>
			</div>
			<div>
				<div>
					<div className='flex justify-center items-center mb-5'>
						<h4 className='font-bold  text-success mb-[10px] text-center'>
							{ekkLanguage.kycInfo.agreementAndContact}
						</h4>
						{/* <p
								className='text-delivered font-bold text-16'
								onClick={() => setAgreementView(!agreementView)}>
								{!agreementView
									? ekkLanguage.kycInfo.viewPdf
									: ekkLanguage.kycInfo.hidePdf}
							</p> */}
					</div>

					<div className='mb-5'>
						{agreementDetails?.agreement_paper && agreementView && (
							<iframe
								src={agreementDetails?.agreement_paper}
								width='100%'
								height='700px'
								title='PDF Viewer'
							/>
						)}
						{agreementDetails?.updated_paper && (
							<img
								className='m-auto'
								src={agreementDetails?.updated_paper}
								alt='img'
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

import React from "react";

export default function Chart() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<g clipPath='url(#clip0_1426_456)'>
				<path
					d='M19.1667 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V0.833333C1.66667 0.61232 1.57887 0.400358 1.42259 0.244078C1.26631 0.0877974 1.05435 0 0.833333 0C0.61232 0 0.400358 0.0877974 0.244078 0.244078C0.0877974 0.400358 0 0.61232 0 0.833333L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H19.1667C19.3877 20 19.5996 19.9122 19.7559 19.7559C19.9122 19.5996 20 19.3877 20 19.1667C20 18.9457 19.9122 18.7337 19.7559 18.5774C19.5996 18.4211 19.3877 18.3333 19.1667 18.3333Z'
					fill='#222222'
				/>
				<path
					d='M5.00008 16.6665C5.22109 16.6665 5.43305 16.5787 5.58933 16.4224C5.74561 16.2661 5.83341 16.0542 5.83341 15.8332V9.99984C5.83341 9.77882 5.74561 9.56686 5.58933 9.41058C5.43305 9.2543 5.22109 9.1665 5.00008 9.1665C4.77907 9.1665 4.56711 9.2543 4.41083 9.41058C4.25455 9.56686 4.16675 9.77882 4.16675 9.99984V15.8332C4.16675 16.0542 4.25455 16.2661 4.41083 16.4224C4.56711 16.5787 4.77907 16.6665 5.00008 16.6665Z'
					fill='#222222'
				/>
				<path
					d='M8.33325 8.33333V15.8333C8.33325 16.0543 8.42105 16.2663 8.57733 16.4226C8.73361 16.5789 8.94558 16.6667 9.16659 16.6667C9.38761 16.6667 9.59957 16.5789 9.75585 16.4226C9.91213 16.2663 9.99993 16.0543 9.99993 15.8333V8.33333C9.99993 8.11232 9.91213 7.90036 9.75585 7.74408C9.59957 7.5878 9.38761 7.5 9.16659 7.5C8.94558 7.5 8.73361 7.5878 8.57733 7.74408C8.42105 7.90036 8.33325 8.11232 8.33325 8.33333Z'
					fill='#222222'
				/>
				<path
					d='M12.5 10.8333V15.8333C12.5 16.0543 12.5878 16.2663 12.7441 16.4226C12.9004 16.5789 13.1123 16.6667 13.3333 16.6667C13.5544 16.6667 13.7663 16.5789 13.9226 16.4226C14.0789 16.2663 14.1667 16.0543 14.1667 15.8333V10.8333C14.1667 10.6123 14.0789 10.4004 13.9226 10.2441C13.7663 10.0878 13.5544 10 13.3333 10C13.1123 10 12.9004 10.0878 12.7441 10.2441C12.5878 10.4004 12.5 10.6123 12.5 10.8333Z'
					fill='#222222'
				/>
				<path
					d='M16.6667 7.49984V15.8332C16.6667 16.0542 16.7545 16.2661 16.9108 16.4224C17.0671 16.5787 17.2791 16.6665 17.5001 16.6665C17.7211 16.6665 17.9331 16.5787 18.0893 16.4224C18.2456 16.2661 18.3334 16.0542 18.3334 15.8332V7.49984C18.3334 7.27882 18.2456 7.06686 18.0893 6.91058C17.9331 6.7543 17.7211 6.6665 17.5001 6.6665C17.2791 6.6665 17.0671 6.7543 16.9108 6.91058C16.7545 7.06686 16.6667 7.27882 16.6667 7.49984Z'
					fill='#222222'
				/>
				<path
					d='M4.9999 7.50001C5.2209 7.49997 5.43283 7.41214 5.58907 7.25585L8.5774 4.26751C8.73624 4.1162 8.9472 4.0318 9.16657 4.0318C9.38594 4.0318 9.5969 4.1162 9.75574 4.26751L11.5657 6.07751C12.0346 6.54619 12.6703 6.80948 13.3332 6.80948C13.9961 6.80948 14.6319 6.54619 15.1007 6.07751L19.7557 1.42251C19.9075 1.26535 19.9915 1.05484 19.9896 0.836346C19.9877 0.617849 19.9001 0.408838 19.7456 0.254332C19.5911 0.0998249 19.3821 0.0121841 19.1636 0.0102854C18.9451 0.00838673 18.7346 0.0923821 18.5774 0.24418L13.9224 4.89835C13.7661 5.05457 13.5542 5.14234 13.3332 5.14234C13.1123 5.14234 12.9003 5.05457 12.7441 4.89835L10.9341 3.08918C10.4653 2.6205 9.82948 2.35721 9.16657 2.35721C8.50366 2.35721 7.86789 2.6205 7.39907 3.08918L4.41074 6.07751C4.29423 6.19406 4.21489 6.34253 4.18275 6.50415C4.15061 6.66578 4.16711 6.83331 4.23017 6.98556C4.29323 7.13781 4.40001 7.26794 4.53702 7.35952C4.67403 7.45109 4.83511 7.49998 4.9999 7.50001Z'
					fill='#222222'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1426_456'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}

import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import SupplierList from "components/mobile-components/SupplierList";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Brand from "services/Brand";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";
import MyOrderStatus from "components/mobile-components/MyOrderList/MyOrderStatus";
import SupplierFilter from "components/mobile-components/SupplierList/SupplierFilter";
import Search from "components/mobile-components/Common/Search";
import { setUserOrderType } from "redux/container/orderDetailsSlice";
import SupplerListBottomSheet from "components/mobile-components/SupplierList/SupplierBottomSheet";

export default function SupplierListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [brandList, setBrandList] = useState([]);
	const [myBrand, setMyBrand] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [loading, setLoading] = useState(true);
	const [productLastResponse, setProductLastResponse] = useState([]);
	const dispatch = useDispatch();
	const [orderStatus, setOrderStatus] = useState([]);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getMyBrandList = async () => {
		setPageCount(2);
		setBrandList([]);
		setLoading(true);
		if (searchQ) {
			let response = await Brand.getMyDistributorListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else {
			let response = await Brand.getMyDistributorList();
			if (response.status === 200) {
				setBrandList(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};
	// const getAllBrandList = async () => {
	// 	setPageCount(2);
	// 	setBrandList([]);
	// 	setLoading(true);
	// 	if (searchQ) {
	// 		let response = await Brand.getAllBrandListSearch(searchQ);
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setBrandList(response.results);
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	} else {
	// 		let response = await Brand.getAllBrandList();
	// 		if (response.status === 200) {
	// 			setLoading(false);
	// 			setBrandList(response.results);
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	getMyBrandList();
	// }, []);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	let lodeMoreData = async () => {
		if (searchQ) {
			// const response = await Brand.getMySupplierBrandListSearch(
			// 	searchQ,
			// 	pageCount
			// );
			// if (response.status === 200) {
			// 	setPageCount((prev) => prev + 1);
			// 	setBrandList([...brandList, ...response.results]);
			// }
			if (myBrand) {
				const response = await Brand.getMyDistributorListSearch(searchQ);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			}
			// else {
			// 	const response = await Brand.getAllBrandListSearch(searchQ, pageCount);
			// 	if (response.status === 200) {
			// 		setPageCount((prev) => prev + 1);
			// 		setBrandList([...brandList, ...response.results]);
			// 	}
			// }
		} else {
			// const response = await Brand.getMySupplierBrandList(pageCount);
			// if (response.status === 200) {
			// 	setPageCount((prev) => prev + 1);
			// 	setBrandList([...brandList, ...response.results]);
			// }
			if (myBrand) {
				const response = await Brand.getMyDistributorList();
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			}
			//  else {
			// 	const response = await Brand.getAllBrandList(pageCount);
			// 	if (response.status === 200) {
			// 		setPageCount((prev) => prev + 1);
			// 		setBrandList([...brandList, ...response.results]);
			// 	}
			// }
		}
	};

	let singleBrandSearch = async () => {
		if (myBrand) {
			let response = await Brand.getMySupplierBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let res = await dispatch(searchOrder(searchQ, 500, 1));
			if (res.status === 200) {
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	// Order Section
	let orderListPage = useSelector((state) => state.order.page);

	let getInitOrderList = async () => {
		setLoading(true);
		let response = await dispatch(fetchOrder("buyer", ""));
		if (response.status === 200) {
			dispatch(setUserOrderType("buyer"));
			setLoading(false);
			setProductLastResponse(response.data);
		} else {
			setLoading(false);
		}
	};

	let getOrderList = async (value) => {
		if (hasMoreData) {
			let response = await dispatch(
				fetchOrder("buyer", value, orderListPage)
			);
			if (response.status === 200) {
				dispatch(setUserOrderType("buyer"));
				setProductLastResponse(response.data);
				if (!response.data.length) {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (myBrand) {
				getMyBrandList();
			} else {
				getInitOrderList();
			}
		}
	}, [searchQ, myBrand]);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.mysuppliers.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from={myBrand && "supplierList"}
					/>

					<section className='pt-14 px-2'>
						<div className='pt-2'>
							<Search
								placeholder={
									myBrand
										? ekkLanguage.brandList.searchBrand
										: ekkLanguage.orderDetails.orderSearchLabel
								}
								search={searchQ}
								setSearch={setSearchQ}
								singleSearch={singleBrandSearch}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						</div>
						<SupplierFilter
							setMyBrand={setMyBrand}
							getAllBrandList={getInitOrderList}
							getMyBrandList={getMyBrandList}
							ekkLanguage={ekkLanguage}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}></SupplierFilter>
						{myBrand ? (
							<SupplierList
								ekkLanguage={ekkLanguage}
								loading={loading}
								brandList={brandList}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						) : (
							<MyOrderStatus
								buyer={"buyer"}
								setOrderStatus={setOrderStatus}
								loading={loading}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						)}
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								getOrderList(orderStatus);
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 500);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
					<SupplerListBottomSheet />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

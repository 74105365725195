import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SingleSupplier({ item, from }) {
	const navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div
			className='rounded py-1 w-full'
			onClick={() =>
				navigate(`/supplier-detail/${item.id}/${item.business_relation_id}`)
			}>
			{item?.images?.length ? (
				<div className='h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]'>
					<img className='' src={item?.images[0]} alt='logo'></img>
				</div>
			) : (
				<div className='h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]'>
					<img
						className=''
						src='/assets/images/Icon/brandBig.png'
						alt='logo'></img>
				</div>
			)}
			<div className='flex flex-col justify-center px-1'>
				<h4 className='text-14 text-black-1000 font-bold py-[2px]'>
					{item?.business_name}
				</h4>
				<div className='flex justify-between items-center'>
					<p className='text-10 text-black-500'>
						{item?.publish_products_count > 1
							? `${item?.publish_products_count} Products`
							: `${item?.publish_products_count} Product`}
					</p>
					{item?.relationship_status === "Invited" && (
						<div className='flex items-center'>
							<p className='text-12 font-bold text-success ml-0.5'>
								{ekkLanguage.createSupplier.invited}
							</p>
						</div>
					)}

					{/* <div className='flex items-center'>
						{" "}
						<img className="h-[10px] w-[10px]" src='/assets/images/Icon/pin.png' alt='' />
						<p className='text-10 text-[#FF261A] ml-0.5'>3.5km</p>
					</div> */}
				</div>
			</div>
		</div>
	);
}

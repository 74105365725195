import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BusinessProfile from "components/mobile-components/BusinessProfile";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";

export default function BusinessProfilePage() {
	let business = getUserBusiness();
	let [businessData, setBusinessData] = useState({});
	const [menuCollapse, setMenuCollapse] = useState(true);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);
	//get business data
	let getBusiness = async () => {
		let response = await Business.getBusiness(business?.id);
		if (response.status === 200) {
			setBusinessData(response.results);
			setUserBusiness({
				...business,
				business_status: response.results.business_status,
			});
		}
	};
	useEffect(() => {
		getBusiness();
	}, []);

	//get business data
	let getBranchList = async () => {
		let response = await Business.getBranchList();
		if (response.status === 200) {
			setBusinessData(response.data);
		}
	};
	// useEffect(() => {
	// 	getBranchList();
	// }, []);

	//get business data
	let getPaymentDetails = async () => {
		let response = await Business.getPaymentData(business?._id);
		if (response.status === 200) {
			// setBusinessData(response.data);
		}
	};
	// useEffect(() => {
	// 	getPaymentDetails();
	// }, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.pageTitle}
						// burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from='businessProfile'
					/>
					<section>
						<BusinessProfile businessData={businessData} />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import {
	IonApp,
	IonContent,
	IonLoading,
	IonPage,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOrderDetailsByOrderId,
	fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";
import SelectTimeDate from "components/mobile-components/OrderDetails/SelectTimeDate";
import FinanceSupportCard from "components/mobile-components/OrderDetails/LoanCard";
import { setPaymentInfo } from "redux/container/payEkkbazSlice";
import OrderStatus from "components/mobile-components/OrderDetails/OrderStatus";
import Business from "services/Business";

export default function PurchaseOrderDetailsPage() {
	let paymentInfo = useSelector((state) => state.payEkkbaz.paymentInfo);
	const from = useSelector((state) => state.order.from);
	const { orderUserType } = useParams();
	let { id } = useParams();

	const dispatch = useDispatch();
	const response = useSelector((state) => state.order);
	const [order, setOrder] = useState({});
	let [collection, setCollection] = useState([]);
	let [loanDetails, setLoanDetails] = useState({});
	let [loanPaymentList, setLoanPaymentList] = useState([]);
	let [defaultPenalty, setDefaultPenalty] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useIonViewDidEnter(() => {
		if (from === "orderList") {
			dispatch(fetchOrderSingle(id));
		} else {
			dispatch(fetchOrderDetailsByOrderId(id));
		}
	});
	useIonViewWillEnter(() => {
		if (from === "orderList") {
			dispatch(fetchOrderSingle(id));
		} else {
			dispatch(fetchOrderDetailsByOrderId(id));
		}
	});

	const fetchOderCollectionAmount = async () => {
		if (from === "orderList") {
			let response = await Orders.orderAmountCollection(id);
			if (response?.status === 200) {
				setCollection(response.data);
			}
		} else {
			let res = await Orders.orderAmountCollection(
				response?.singleOrder?._id
			);
			if (res?.status === 200) {
				setCollection(res.data);
			}
		}
	};
	const fetchOder = async () => {
		if (from === "orderList") {
			let response = await Orders.singleOrder(id);
			if (response?.status === 200) {
				setOrder(response.data);
				if (response?.data?.order_id) {
					let res = await Orders.getLoanDetails(response?.data?.order_id);
					dispatch(
						setPaymentInfo({
							...paymentInfo,
							loanTrackingId: res?.result?.loanTrackingId,
							payableAmount: res?.result?.buyer_down_payment,
						})
					);
					if (res?.status === 200) {
						setLoanDetails(res.result);
						let loanResponse = await Orders.sellerLoanPaymentDetails(
							res?.result?.loanTrackingId
						);
						if (loanResponse.status === 200) {
							setLoanPaymentList(loanResponse.results);
						}
					} else {
					}
				}
			}
		} else {
			let response = await Orders.orderDetailsByOrderId(id);
			if (response?.status === 200) {
				setOrder(response.data);
				if (response?.data?.order_id) {
					let res = await Orders.getLoanDetails(response?.data?.order_id);
					dispatch(
						setPaymentInfo({
							...paymentInfo,
							loanTrackingId: res?.result?.loanTrackingId,
						})
					);
					if (res?.status === 200) {
						setLoanDetails(res.result);
						let loanResponse = await Orders.sellerLoanPaymentDetails(
							res?.result?.loanTrackingId
						);
						if (loanResponse.status === 200) {
							setLoanPaymentList(loanResponse.results);
						}
					} else {
					}
				}
			}
		}
	};
	useEffect(() => {
		if (from === "orderList") {
			dispatch(fetchOrderSingle(id));
			fetchOderCollectionAmount(id);
		} else {
			dispatch(fetchOrderDetailsByOrderId(id));
			if (response?.singleOrder?.order_id === id) {
				fetchOderCollectionAmount(response?.singleOrder?._id);
			}
		}
		fetchOder();
		getDefaultPenalty();
	}, [response?.singleOrder?.order_id, id]);

	const getDefaultPenalty = async () => {
		let res = await Business.getDefaultPenalty(
			response?.singleOrder?.order_id
		);
		console.log(response);
		if (res?.status === 200) {
			setDefaultPenalty(res.result);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={
							orderUserType === "seller"
								? response?.singleOrder?.created_by_business
										?.business_name
								: response?.singleOrder?.seller?.business_name
						}
						from={
							response.singleOrder?.order_status === "Placed"
								? `${orderUserType === "buyer" && "orderDetailsPage"}`
								: `orderDetailsPage`
						}
					/>
					{response.status === "loading" ? (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList.loader}
						/>
					) : (
						<>
							<OrderMetaBar order={response.singleOrder} />
							<section className='px-2 pt-24'>
								<OrderStatus
									order={response.singleOrder}
									fsInfo={loanDetails}
								/>
								{/* <OrderOnwerState order={response.singleOrder} /> */}

								<OrderItemCard order={response.singleOrder} />
								<DeliveryDetailsCard order={response.singleOrder} />
								<CostSummeryCard order={response.singleOrder} />
								{!response.singleOrder?.hasLoan && (
									<PaymentDetailsCard order={response.singleOrder} />
								)}
								{orderUserType === "buyer" && loanDetails?.orderId && (
									<FinanceSupportCard
										loanDetails={loanDetails}
										order={response.singleOrder}
										collection={loanPaymentList}
										defaultPenalty={
											defaultPenalty
										}></FinanceSupportCard>
								)}
								{/* {orderUserType === "buyer" && loanPaymentList && (
									<LoanCollectionCard
										collection={loanPaymentList}
										order={response.singleOrder}></LoanCollectionCard>
								)} */}
								{!response.singleOrder?.hasLoan && (
									<PaymentSettlementCard collection={collection} />
								)}
								{(response.singleOrder?.order_status === "Placed") |
									(response.singleOrder?.order_status ===
										"Accepted") && orderUserType === "seller" ? (
									<SelectTimeDate
										order={response.singleOrder}
										accepted={
											response.singleOrder?.order_status ===
											"Accepted"
										}
									/>
								) : (
									""
								)}
								{/* <SettlementCard order={response.singleOrder} />
								<CloseSettlementCard order={response.singleOrder} /> */}
								<TimeLine order={response.singleOrder} />
								<BottomSheet
									orderUserType={orderUserType}
									order={response.singleOrder}
									loanDetails={loanDetails}
								/>
							</section>
						</>
					)}

					{/* <TimeLineTextBox order={response.singleOrder} /> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Admin from "assets/images/icon/svg/Admin";
import Download from "assets/images/icon/svg/Download";
import HeartOutline from "assets/images/icon/svg/HeartOutline";
import Profit from "assets/images/icon/svg/Profit";
import Share from "assets/images/icon/svg/Share";
import Star from "assets/images/icon/svg/Star";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import RecommendProduct from "../Home/RecommendProduct";
import ProductCard from "./ProductCard";

export default function ProductDetailsPreview({ product }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let profit = Number(product?.consumer_price) - Number(product?.base_price);

	return (
		<div className='porduct-details pb-24'>
			<IonGrid className='pb-10'>
				<IonRow className='px-2'>
					<IonCol size='12'>
						<div>
							<h5 className='font-semibold text-20 text-dark pt-2'>
								{product?.title}
							</h5>
						</div>
					</IonCol>
					<IonCol size='12' className='pb-2'>
						<div className='d-flex items-baseline'>
							<div>
								<h5 className='font-medium text-primary text-32 font-sans flex justify-between'>
									{product?.country?.currency_symbol_native}{" "}
									{digitChanger(product?.base_price)}
									<span
										className={`${
											product?.inventory_quantity > 0
												? "text-success border-success"
												: "text-primary border-primary"
										} border-2  rounded-[30px] px-5 font-bold text-16 h-[40px] leading-9`}>
										{product?.inventory_quantity > 0
											? ekkLanguage.productDetails.inStock
											: ekkLanguage.productDetails.outStock}
									</span>
								</h5>
								{/* {product?.type === "offer" && (
									<h5
										className='font-semibold text-20 text-C6C6C6 line-through font-sans'
										style={{ color: "rgba(0,0,0,.5)" }}>
										{product?.country?.currency_symbol_native}{" "}
										{digitChanger(product?.consumer_price)}
									</h5>
								)} */}
							</div>
						</div>
					</IonCol>

					<IonCol size='12' className='pb-6'>
						<ul className='flex justify-between'>
							<li>
								<div>
									<h6 className='text-14 text-000054 pb-1'>
										{ekkLanguage.productDetails.moqLabel}
									</h6>
									<p className='text-primary font-bold text-12'>
										{" "}
										<span className='inline-block align-middle'>
											<Download />
										</span>{" "}
										{digitChanger(product?.minimum_order_quantity)}
									</p>
								</div>
							</li>
							<li>
								<div>
									<h6 className='text-14 text-000054 pb-1'>
										{ekkLanguage.productDetails.consumerLabel}
									</h6>
									<p className='text-primary font-bold text-12'>
										<span className='inline-block align-middle'>
											<Admin />
										</span>{" "}
										{product?.country?.currency_symbol_native}{" "}
										{digitChanger(product?.consumer_price)}
									</p>
								</div>
							</li>
							<li>
								<div>
									<h6 className='text-14 text-000054 pb-1'>
										{ekkLanguage.productDetails.profitLabel}
									</h6>
									<p className='text-success font-bold text-12'>
										<span className='inline-block align-middle'>
											<Profit />
										</span>{" "}
										{product?.country?.currency_symbol_native}{" "}
										{product?.consumer_price &&
											product?.base_price &&
											digitChanger(numberWithCommas(profit))}
									</p>
								</div>
							</li>
						</ul>
					</IonCol>

					<IonCol size='12'>
						<SectionTitle
							title={ekkLanguage.productDetails.productDetailTitle}
							innerPage={true}
						/>
						<div className='product-specific-details'>
							<IonRow>
								{product?.type === "offer" && (
									<IonCol size='12'>
										<div className='flex relative pl-2 mb-1'>
											<p className='text-000054 text-12 pr-1'>
												{ekkLanguage.productDetails.offerStart}:
											</p>
											<span className='text-12'>
												{" "}
												{digitChanger(
													moment(product?.offer_start).format(
														"DD-MM-YYYY"
													)
												)}
											</span>
										</div>
									</IonCol>
								)}{" "}
								{product?.type === "offer" && (
									<IonCol size='12'>
										<div className='flex relative pl-2 mb-1'>
											<p className='text-000054 text-12 pr-1'>
												{ekkLanguage.productDetails.offerEnd}:
											</p>
											<span className='text-12'>
												{digitChanger(
													moment(product?.offer_end).format(
														"DD-MM-YYYY"
													)
												)}
											</span>
										</div>
									</IonCol>
								)}
								{product?.expire_date && (
									<IonCol size='12'>
										<div className='flex relative pl-2 mb-1'>
											<p className='text-000054 text-12 pr-1'>
												{ekkLanguage.productDetails.expireData}:
											</p>
											<span className='text-12'>
												{" "}
												{digitChanger(
													moment(product?.expire_date).format(
														"DD/MM/YYYY"
													)
												)}
											</span>
										</div>
									</IonCol>
								)}
								{product?.manufacture_date && (
									<IonCol size='12'>
										<div className='flex relative pl-2 mb-1'>
											<p className='text-000054 text-12 pr-1'>
												{ekkLanguage.productDetails.manufactureData}
												:
											</p>
											<span className='text-12'>
												{" "}
												{digitChanger(
													moment(product?.manufacture_date).format(
														"DD/MM/YYYY"
													)
												)}
											</span>
										</div>
									</IonCol>
								)}
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.skuId}:
										</p>
										<span className='text-12'>
											{" "}
											{product?.ekkbaz_sku_id}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.searchTram}:
										</p>
										<span className='text-12'>
											{product?.eng_name}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.productDetailTitle}
											:
										</p>
										<span className='text-12'> {product?.type}</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.productWeight}:
										</p>
										<span className='text-12'>
											{digitChanger(product?.weight)}{" "}
											{product?.weight_unit}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.packingType}:
										</p>
										<span className='text-12'>
											{product?.pack_type}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.importedBy}:
										</p>
										<span className='text-12'>
											{product?.imported_by_business_name}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.manufacturedBy}:
										</p>
										<span className='text-12'>
											{product?.manufactured_by_business_name}
										</span>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex relative pl-2 mb-1'>
										<p className='text-000054 text-12 pr-1'>
											{ekkLanguage.productDetails.country}:
										</p>
										<span className='text-12'>
											{product?.manufacturing_country}
										</span>
									</div>
								</IonCol>
							</IonRow>
						</div>
					</IonCol>
					<IonCol size='12'>
						<SectionTitle
							title={ekkLanguage.productDetails.descriptionLabel}
							innerPage={true}
						/>
						<div className='product-description'>
							<p className='text-12 text-222222 mb-2'>
								{product?.description}
							</p>
						</div>
					</IonCol>
				</IonRow>
				{product?.type === "offer" && (
					<div className='pb-10 px-4'>
						<SectionTitle
							title={ekkLanguage.productList.pageTitle}
							innerPage={true}
						/>
						<ProductCard
							productItems={product?.offer_items}
							product={product}
						/>
					</div>
				)}

				{/* <RecommendProduct
					recommendData={recommendData}
					title='Related Product'
				/> */}
			</IonGrid>
		</div>
	);
}

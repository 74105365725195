import React from "react";
import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";

export default function SenderBankInfoCard({ senderBank }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='pb-5'>
			<div className='mt-5 bg-white'>
				<div
					className='border-b-2 py-[10px] flex justify-between items-center px-4'
					style={{ bordreColor: "#f2f3f7" }}>
					<h3 className='text-black-1000 font-bold text-16 flex'>
						<img
							src='/assets/images/send.png'
							alt='icon'
							className='h-5 w-5 mr-[10px]'
						/>{" "}
						{ekkLanguage.payment.senderInfo}
					</h3>
				</div>
				<div className=' px-4'>
					{Object.keys(senderBank).length ? (
						<table className='table-fixed w-full'>
							<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.bank}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.bank?.name}
									</td>
								</tr>
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.acName}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.account_name}
									</td>
								</tr>
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.acNumber}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.account_no}
									</td>
								</tr>
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.branch}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.branch}
									</td>
								</tr>
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.address}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.address}
									</td>
								</tr>
								{/* <tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.city}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{senderBank?.address}
								</td>
							</tr> */}
								{/* <tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.postal}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									
								</td>
							</tr> */}
								<tr>
									<td className='text-black-500 text-14 font-semibold py-[6px]'>
										{ekkLanguage.payment.country}
									</td>
									<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
										{senderBank?.country}
									</td>
								</tr>
							</tbody>
						</table>
					) : (
						<h6 className='font-semibold text-14 py-4'>
							{ekkLanguage.payment.senderWarning}
						</h6>
					)}
				</div>
			</div>
		</div>
	);
}

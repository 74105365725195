import { IonButton } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Check from "assets/images/icon/svg/Check";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Add from "assets/images/icon/svg/Add";
import Cross from "assets/images/icon/svg/Cross";
import Business from "services/Business";
import { emptyKYC, setKYC } from "redux/container/businessSettingsSlice";
import { getLocalUser, getUserBusiness, setUserBusiness } from "config/user";
import Payment from "assets/images/icon/svg/Payment";

export default function RentalInfo() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const business = getLocalUser();
	const dispatch = useDispatch();

	const deleteQueryHandler = () => {
		dispatch(
			setKYC({
				rent_amount: "",
			})
		);
	};

	async function kycDataSubmit() {
		let query = {
			...bs.data,
			business: business?.id,
		};
		try {
			const response = bs.data?.kycUpdate
				? await Business.updateKYC(bs.data?.kycId, query)
				: await Business.createKYC(query);
			if (response.status === 200) {
				localStorage.setItem("auth", true);

				dispatch(
					setKYC({
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				chainNextHandler();
			} else if (response.status === 201) {
				localStorage.setItem("auth", true);
				dispatch(
					setKYC({
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				chainNextHandler();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const RentalStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white p-2 block rounded-[5px] px-4 py-[10px] mb-5`}
			style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<img src='assets/images/calendar.png' alt='icon' />
					<p
						className={`text-16 "text-black-500 font-medium ml-2 font-bold`}>
						{bs.data?.rent_start_date ? (
							moment(bs.data?.rent_start_date).format("DD-MM-YYYY")
						) : (
							<p className='font-bold'>
								{" "}
								{ekkLanguage.kycVerification.startDate}
							</p>
						)}
					</p>
				</div>
				<div>
					<Add color='#F94B41' />
				</div>
			</div>
		</div>
	));

	let [isBankAccount, setIsBankAccount] = useState(false);
	let [isPaymentTerm, setIsPaymentTerm] = useState(false);
	const banksSearch = async () => {
		let response = await Business.getBankList();

		if (response.status === 200) {
			setIsBankAccount(response.results?.length > 0);
		} else {
			setIsBankAccount(false);
		}
	};

	let getAllPaymentInfo = async function () {
		let results = await Payment.getAllPaymentTerms();
		let paymentTerms = await Payment.sellerPaymentTerms();
		if (results?.status === 200) {
			let ids = [];
			results?.results.map((item1) => {
				let matchChecker = paymentTerms.results.find(
					(item2) => item2.id === item1.id
				);
				if (matchChecker) {
					ids.push(item1.id);
					return { ...item1, is_active: true };
				} else {
					return { ...item1, is_active: false };
				}
			});
			setIsPaymentTerm(ids.length > 1);
		}
	};
	useEffect(() => {
		banksSearch();
		getAllPaymentInfo();
	}, []);

	const chainNextHandler = () => {
		if (!isBankAccount) {
			navigate("/add-default-bank-account");
		} else {
			if (!isPaymentTerm) {
				navigate("/init-payment-terms-settings");
			} else {
				navigate("/business-success");
			}
		}
	};

	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='pt-5 px-4 pt-6 pb-0'>
				<p className='text-16 font-semibold text-black-1000 pb-3'>
					{ekkLanguage.kycVerification.startDate}
				</p>

				{/* manufacture date */}
				<div>
					<DatePicker
						showYearDropdown
						showMonthDropdown
						selected={
							bs.data?.rent_start_date
								? moment(bs.data?.rent_start_date)._d
								: moment(new Date())._d
						}
						onChange={(date) => {
							dispatch(
								setKYC({
									rent_start_date: date,
								})
							);
						}}
						customInput={<RentalStartDate />}
						withPortal
					/>
				</div>

				<div className='pb-[50px]'>
					<p className='text-16 font-semibold text-black-1000 pb-3 '>
						{ekkLanguage.kycVerification.rentPerMonth}
					</p>

					<div className='relative w-full'>
						<input
							className={`bg-white w-full h-[50px] rounded-[7px] pl-5 search-input border common-search $`}
							type='number'
							placeholder={
								ekkLanguage.kycVerification.rentPerMonthPlaceholder
							}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={bs.data?.rent_amount}
							onChange={(e) => {
								dispatch(
									setKYC({
										rent_amount: e.target.value,
									})
								);
							}}></input>
						{bs.data?.rent_amount && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</div>
				{bs.data?.rent_start_date && bs.data?.rent_amount ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> {ekkLanguage.kycVerification.complete}
						</p>

						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.click} "
							<span style={{ color: "#00B224" }}>
								{ekkLanguage.kycVerification.submit}
							</span>
							" {ekkLanguage.kycVerification.button}
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.select}{" "}
							<b className='text-primary font-semibold'>
								“{ekkLanguage.kycVerification.startDate}”
							</b>{" "}
							{ekkLanguage.kycVerification.fillup}{" "}
							<b className='text-primary font-semibold'>
								“{ekkLanguage.kycVerification.rentPerMonth}”
							</b>{" "}
							{ekkLanguage.kycVerification.amount}.
						</p>
					</div>
				)}
				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={
							!bs.data?.rent_start_date | !bs.data?.rent_amount
								? true
								: false
						}
						color={
							!bs.data?.rent_start_date | !bs.data?.rent_amount
								? "dark"
								: "success"
						}
						className='font-bold h-[50px] w-full'
						onClick={() => kycDataSubmit()}>
						{ekkLanguage.businessCreateName.submit}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
					<IonButton
						color='white'
						className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
						onClick={() => chainNextHandler()}>
						{ekkLanguage.kycVerification.giveLater}
						<span className='pl-2'>
							<ArrayRight color='red' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}

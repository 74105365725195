import { createSlice } from "@reduxjs/toolkit";

export const payEkkbazSlice = createSlice({
	name: "payEkkbaz",
	initialState: {
		paymentDate: "",
		paymentInfo: {},
	},
	reducers: {
		paymentData: (state) => {
			return state;
		},
		paymentDate: (state, action) => {
			state.paymentDate = action.payload;
		},
		paymentInfo: (state, action) => {
			state.paymentInfo = action.payload;
		},
		clearPaymentCard: (state, action) => {
			state.paymentDate = "";
			state.paymentInfo = {};
		},
	},
});

// Action creators are generated for each case reducer function
export const { paymentDate } = payEkkbazSlice.actions;
export const { actions, reducer } = payEkkbazSlice;

export const setPaymentInfo = (values) => async (dispatch, getState) => {
	dispatch(actions.paymentInfo(values));
};
export const setEmptyPaymentInfo = () => async (dispatch, getState) => {
	dispatch(actions.clearPaymentCard());
};

export default reducer;

import { IonButton, IonSpinner } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "./ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";
import Cross from "assets/images/icon/svg/Cross";

export default function TradeLicense() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState(bs.data?.trade_img ?? "");
	const [loader, setLoader] = useState(false);
	const business = getUserBusiness();

	let dispatch = useDispatch();

	const nextHandler = async () => {
		if (!files1[0].preview && bs.data?.trade_no) {
			return navigate("/utility-verification");
		}
		setLoader(true);
		let frontPart = new FormData();
		frontPart.append("key", files1[0]);

		if (files1.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			if (response.status === 201) {
				setLoader(false);
				dispatch(
					setKYC({
						trade_no: bs.data?.trade_no,
						trade_img: response.results[0],
					})
				);

				let query = {
					trade_no: bs.data?.trade_no,
					trade_img: response.results[0],
					business: business?.id,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							trade_no: bs.data?.trade_no,
							trade_img: response.results[0],
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/utility-verification");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/utility-verification");
				}
			} else {
				setLoader(false);
			}
		}
	};
	const deleteQueryHandler = () => {
		dispatch(
			setKYC({
				trade_no: "",
			})
		);
	};
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='py-5 px-4'>
				<p className='text-16 font-semibold text-black-1000 pb-3 '>
					{ekkLanguage.kycVerification.tradeLicenseNo}
				</p>

				<div className='relative w-full'>
					<input
						className={`bg-white w-full h-[50px] rounded-[7px] pl-5 search-input border common-search $`}
						type='string'
						placeholder={
							ekkLanguage.kycVerification.tradeLicensePlaceholder
						}
						style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.05) " }}
						value={bs.data?.trade_no}
						onChange={(e) => {
							dispatch(
								setKYC({
									trade_no: e.target.value,
								})
							);
						}}></input>
					{bs.data?.trade_no && (
						<span
							className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
							onClick={deleteQueryHandler}>
							<Cross />
						</span>
					)}
				</div>
			</div>
			<div className='pb-5 px-4'>
				<div className='pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>
						{ekkLanguage.kycVerification.tradeLicense}
					</h4>
					<span
						className={`h-10 w-10 flex items-center justify-center ${
							files1 ? "bg-primary" : "bg-white"
						} rounded-[5px]`}
						onClick={() => setFiles1("")}>
						<Delete color={files1 ? "white" : "red"} />
					</span>
				</div>

				<ImageUploader
					files={files1}
					setFiles={setFiles1}
					bHeight={438}
					placeholder={ekkLanguage.kycVerification.uploadTradeLicense}
					imgSource={"/assets/images/trade_license.png"}
				/>
			</div>

			<div className='px-4'>
				{/* {files1.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> {ekkLanguage.kycVerification.complete}
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.pleaseCheck} "
							<span style={{ color: "#00B224" }}>
								{ekkLanguage.kycVerification.next}
							</span>
							" {ekkLanguage.kycVerification.button}
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.upload}{" "}
							<b className='text-primary font-semibold'>
								“{ekkLanguage.kycVerification.tradeLicense}”
							</b>{" "}
							{ekkLanguage.kycVerification.copy}.
						</p>
					</div>
				)} */}

				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={
							(files1 && bs.data?.trade_no ? false : true) | loader
						}
						color={files1 && bs.data?.trade_no ? "success" : "dark"}
						className='font-bold h-[50px] w-full'
						onClick={nextHandler}>
						{loader ? (
							<IonSpinner></IonSpinner>
						) : (
							<>
								{ekkLanguage.businessCreateName.btnLabel}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</>
						)}
					</IonButton>
					{/* {!loader && (
						<IonButton
							color='white'
							className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
							onClick={() => navigate("/utility-verification")}>
							{ekkLanguage.kycVerification.giveLater}
							<span className='pl-2'>
								<ArrayRight color='red' />
							</span>
						</IonButton>
					)} */}
				</div>
			</div>
		</div>
	);
}

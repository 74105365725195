import LeftArrow from "assets/images/icon/svg/LeftArrow";
import Menu from "assets/images/icon/svg/Menu";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DotMenu from "assets/images/icon/svg/DotMenu";
import { clearAuth } from "config/authFetch";
import {
	setDashboardBottomSheet,
	eraseMapLocation,
	setBottomSheet,
	setBrandDetails,
	setBrandList,
	setBusinessBottomSheet,
	setEditEmployees,
	setMyEmployees,
	setMyProduct,
	setProductDetails,
	setAddBankBottomSheet,
	setSupplierDetails,
	setSupplierList,
	// // eraseMapLocation,
	// setBottomSheet,
	// setBrandDetails,
	// setBrandList,
	// setBusinessBottomSheet,
	// setEditEmployees,
	// setMyEmployees,
	// setMyProduct,
	// setProductDetails,
} from "redux/container/userSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { getLocalUser, getUser, setCreateUserBusiness } from "config/user";
import { actions, logout } from "redux/container/authSlice";
import SupplierBell from "assets/images/icon/svg/SupplierBell";
import { selectTotalUnreadCount } from "redux/container/notificationSlice";
import { setDriverData } from "redux/container/driverSlice";
import { digitChanger } from "helpers/helper";
import { clearPaymentCard } from "redux/container/paymentMethodSlice";
import { clearCart } from "redux/container/shoppingCartSlice";
import { removeFsCoupon, setPaymentInfo } from "redux/container/loanSlice";
import Mic from "assets/images/icon/svg/Mic";
import RightArrow from "assets/images/icon/svg/RightArrow";
import Settings from "assets/images/icon/svg/Settings";
import User from "assets/images/icon/svg/User";

// import SupplierBell from "assets/images/icon/svg/SupplierBell";

export default function BazAiNav({
	title,
	burgerMenu,
	menuIconClick,
	from,
	type,
	showLeft,
	showRight,
	setShowRight,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const unreadCount = useSelector(selectTotalUnreadCount);
	const user = getUser();
	const [profileNav, setProfileNav] = useState(false);

	const divRef = useRef(null);

	const onCloseModal = () => {
		setIsOpen(false);
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const handleClickOutside = (event) => {
		if (divRef.current && !divRef.current.contains(event.target)) {
			setProfileNav(false);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<>
			<div
				style={{
					transition: ".3s all",
					background: "#b3e6fa",
				}}
				className={`z-40 flex px-6 items-center justify-between py-2 fixed left-0 top-0 w-full`}>
				<div
					style={
						showLeft
							? {
									transition: ".3s all",
							  }
							: {
									transition: ".3s all",
							  }
					}
					className={`flex items-center justify-center`}>
					<div
						style={{ background: "#F94B41" }}
						className='flex items-center justify-center h-10 w-10 mr-4 rounded-[10px] '
						onClick={menuIconClick}>
						<Menu color='white' />
					</div>
					<h5 className='text-dark-1000 text-18 font-bold'>{title}</h5>
				</div>
				<div>
					<div className={`flex items-center`}>
						<div
							onClick={() => {
								setShowRight(!showRight);
							}}
							className='h-11 w-11 rounded-[10px] mr-2  flex justify-center items-center relative cursor-pointer'>
							<SupplierBell />
							{unreadCount ? (
								<div className='text-8 text-primary rounded-full h-[15px] w-[15px] flex items-center justify-center font-bold absolute top-1.5 right-2.5 bg-white'>
									<p className='mx-[1px] mt-[0.75px] mb-[0px] font-bold'>
										{digitChanger(unreadCount)}
									</p>
								</div>
							) : (
								""
							)}
						</div>
						<div className='h-7 w-7 flex justify-center items-center relative cursor-pointer'>
							<img
								onClick={() => setProfileNav(!profileNav)}
								className='rounded-full'
								src={
									user?.profile_image
										? user?.profile_image
										: "assets/images/avatar.png"
								}
								alt='pImage'
							/>
						</div>
						{profileNav && (
							<div
								ref={divRef}
								className='popover fixed right-5 top-[50px] z-50 rounded-[15px] py-4 px-5 bg-white border'
								style={{
									minWidth: "200px",
									boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
								}}>
								<ul>
									<li>
										<Link
											to='/profile'
											className='mb-4 flex items-center justify-between'>
											<div className='flex items-center'>
												<span
													className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
													style={{ height: 40, width: 40 }}>
													<User />
												</span>
												<h6 className='text-14 text-222222 font-semibold'>
													Profile
												</h6>
											</div>
											<div className='bg-white' slot='content'>
												<RightArrow />
											</div>
										</Link>
									</li>
									<li>
										<Link
											to='/#!'
											onClick={() => {
												dispatch(logout());
											}}
											className='flex items-center justify-between'>
											<div className='flex items-center'>
												<span
													className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
													style={{ height: 40, width: 40 }}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='17'
														height='16'
														viewBox='0 0 17 16'
														fill='none'>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M6.42151 7.4225C6.09339 7.4225 5.83394 7.6775 5.83394 8C5.83394 8.315 6.09339 8.5775 6.42151 8.5775H11V12.1625C11 14 9.48147 15.5 7.60429 15.5H3.88808C2.01853 15.5 0.5 14.0075 0.5 12.17V3.8375C0.5 1.9925 2.02616 0.5 3.89571 0.5H7.61955C9.48147 0.5 11 1.9925 11 3.83V7.4225H6.42151ZM13.7226 5.40515L15.9127 7.58765C16.0252 7.70015 16.0851 7.84265 16.0851 8.00015C16.0851 8.15015 16.0252 8.30015 15.9127 8.40515L13.7226 10.5877C13.6101 10.7002 13.4601 10.7601 13.3176 10.7601C13.1676 10.7601 13.0176 10.7002 12.9051 10.5877C12.6802 10.3626 12.6802 9.99515 12.9051 9.77015L14.1051 8.57765H11.0001V7.42265H14.1051L12.9051 6.23015C12.6802 6.00515 12.6802 5.63765 12.9051 5.41265C13.1301 5.18015 13.4977 5.18015 13.7226 5.40515Z'
															fill='#222222'
														/>
													</svg>
												</span>
												<h6 className='text-14 text-222222 font-semibold'>
													Logout
												</h6>
											</div>
											<div className='bg-white' slot='content'>
												<RightArrow />
											</div>
										</Link>
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>

			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				id='pwa-modal'
				style={customStyles}>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOrder.alert}
					</h6>
					<p className='text-12 font-medium pb-[10px] text-black-500'>
						{ekkLanguage.createOrder.backButtonMessage}
					</p>
					<div className='flex justify-end pt-3'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white text-14'
							onClick={() => {
								localStorage.removeItem("cart");
								localStorage.removeItem("ps");
								dispatch(clearCart());
								dispatch(clearPaymentCard());
								dispatch(setPaymentInfo({}));
								navigate(`/supplier-list`);
								dispatch(removeFsCoupon());
							}}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

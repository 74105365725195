import { IonApp, IonContent, IonPage } from "@ionic/react";
import InitPaymentSettings from "components/mobile-components/BusinessProfile/InitPaymentSettings";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Business from "services/Business";

export default function InitPaymentSettingsPage() {
	let { pathname } = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let business = getUserBusiness();
	let [businessData, setBusinessData] = useState({});
	const [menuCollapse, setMenuCollapse] = useState(true);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	let businessInfo = JSON.parse(localStorage.getItem("setUser"));

	//get business data
	let getBusiness = async () => {
		let response = await Business.getBusiness(
			pathname === "/init-payment-terms-settings"
				? businessInfo["associated_businesses"]?.[0]?.id
				: business?.id
		);
		if (response.status === 200) {
			setBusinessData(response.results);
			setUserBusiness({
				...business,
				business_status: response.results.business_status,
			});
		}
	};
	useEffect(() => {
		let intervale = setTimeout(() => {
			getBusiness();
		}, 500);
		return () => clearTimeout(intervale);
	}, []);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.paymentSettings.pageTitle} />
					<InitPaymentSettings
						businessData={businessData}></InitPaymentSettings>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

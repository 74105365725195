import { IonCol } from "@ionic/react";
import Add2 from "assets/images/icon/svg/Add2";
import Cross from "assets/images/icon/svg/Cross";
import Minus2 from "assets/images/icon/svg/Minus2";
import { digitChanger, numberWithCommas } from "helpers/helper";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
	deliverItemDecrease,
	deliverItemIncrease,
	productRemove,
} from "redux/container/orderSlice";

export default function SingleProductForCard({ image, item, setProductList }) {
	let quantity = item.quantity;
	const singleOrder = useSelector(
		(state) => state.order?.singleOrder?.order_items
	);
	const orderUserType = useSelector(
		(state) => state.orderDetails.userOrderType
	);

	let orderItemsQty = singleOrder?.find((el) => el._id === item._id).quantity;

	item = item?.catalog;
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [qty, setQty] = useState(quantity);
	const dispatch = useDispatch();
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const onCloseModalDelete = () => setIsOpenDelete(false);
	const onOpenModalDelete = () => {
		setIsOpenDelete(true);
	};
	const deleteHandler = async () => {
		dispatch(productRemove(item._id));
		onCloseModalDelete();
	};
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);

	return (
		<>
			<IonCol size='12' className='p-0' key={item.id}>
				<div className='border rounded-[10px] p-2 mt-5 border-black-100 overflow-hidden relative'>
					{orderUserType === "seller" && (
						<span
							className=' absolute right-2 top-2 font-semibold cursor-pointer'
							onClick={onOpenModalDelete}>
							<Cross />
						</span>
					)}
					<div className='flex pb-4'>
						<div
							style={{ width: 66, height: 66 }}
							className=' bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
							<img
								src={image}
								alt='porductImage'
								style={{ objectFit: "contain" }}
							/>
						</div>
						<div className='ml-3 flex-1 pr-4'>
							<h2 className='text-14 font-semibold mb-3 text-black'>
								{item?.product?.title}
							</h2>
							<div className='flex justify-between items-center'>
								<div>
									<h3 className='text-222222 font-semibold text-12'>
										{" "}
										{item?.country?.currency_symbol_native}{" "}
										{digitChanger(item?.base_price)} x{" "}
										{digitChanger(quantity)}
										<span className='text-primary text-16 font-bold'>
											= {item?.country?.currency_symbol_native}{" "}
											{digitChanger(
												numberWithCommas(
													item?.base_price * quantity
												)
											)}
										</span>
									</h3>
									{orderUserType === "seller" && (
										<div className='flex items-center'>
											<img
												src='/assets/images/warehouse.png'
												alt='icon'
											/>
											<span className='font-bold text-10 text-primary pl-1'>
												{digitChanger(item?.inventory_available)}
											</span>
										</div>
									)}
								</div>

								{orderUserType === "seller" && (
									<div
										className='flex rounded-full items-center'
										style={{
											boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
										}}>
										<div>
											<span
												className='w-8 h-7 flex justify-center items-center rounded-md cursor-pointer'
												onClick={() => {
													quantity > 1 &&
														dispatch(
															deliverItemDecrease(item._id)
														);
												}}>
												<Minus2
													color={
														quantity === 1 ? "#e5e7eb" : "#FF564C"
													}
												/>
											</span>
										</div>

										<input
											ref={numberInputRef}
											className={`appearance-none h-9 leading-6 text-center w-10 text-14`}
											type='number'
											value={quantity}
											readOnly={true}
											// onChange={(e) => {
											// 	if (e.target.value > -1) {
											// 		setQty(e.target.value);
											// 	} else {
											// 		setQty(0);
											// 	}
											// }}
										></input>

										<div>
											<span
												className='w-8 h-7 flex justify-center items-center rounded-md cursor-pointer'
												onClick={() => {
													orderItemsQty > quantity &&
														dispatch(
															deliverItemIncrease(item._id)
														);
												}}>
												<Add2
													color={
														orderItemsQty === quantity
															? "#e5e7eb"
															: "#FF564C"
													}
												/>
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</IonCol>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpenDelete}
				onRequestClose={onCloseModalDelete}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<img src='/assets/images/Icon/delete.png' alt='icon' />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.productDetails.deleteText}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-white bg-success border-solid'
							onClick={onCloseModalDelete}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`$ font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={deleteHandler}>
							{ekkLanguage.orderDetails.yesBtn}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

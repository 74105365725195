import React, { useEffect, useState } from "react";
import {
	IonAccordion,
	IonAccordionGroup,
	IonApp,
	IonButton,
	IonCheckbox,
	IonContent,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Blog from "services/Blog";
import { getLanguage } from "config/language";

export default function KycTermsConditionPage() {
	const navigate = useNavigate();
	const lan = getLanguage();
	const [isChecked, setIsChecked] = useState(false);
	const [loading, setLoading] = useState(true);
	const [termDetails, setTermDetails] = useState([]);
	const [privacyDetails, setPrivacyDetails] = useState([]);
	const [refundDetails, setRefundDetails] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const handleOnChange = (e) => {
		setIsChecked(!isChecked);
	};

	useEffect(() => {
		const getTermCodition = async () => {
			let response1 = await Blog.getTermConditon(lan.toUpperCase());
			if (response1.status === 200) {
				setTermDetails(response1.results);
			}
			let response2 = await Blog.getPrivacyPolicy(lan.toUpperCase());
			if (response2.status === 200) {
				setPrivacyDetails(response2.results);
			}
			let response3 = await Blog.getRefundPolicy(lan.toUpperCase());
			if (response3.status === 200) {
				setRefundDetails(response3.results);
				setLoading(false);
			}
		};
		getTermCodition();
	}, [lan]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<div className='pt-14 px-2'>
						<h4 className='font-bold text-black-1000 text-16 text-center my-5'>
							{ekkLanguage.kycVerification.termsConditionPageTitle}
						</h4>
						{/* list  */}
						{loading ? (
							<IonLoading
								isOpen={true}
								message={ekkLanguage.orderList.loader}></IonLoading>
						) : (
							<>
								<div>
									<IonAccordionGroup expand='inset'>
										<IonAccordion value='first'>
											<IonItem slot='header' color='light'>
												<IonLabel className='font-semibold'>
													<span>
														{
															ekkLanguage.kycVerification
																.termsLabel
														}
													</span>
												</IonLabel>
											</IonItem>
											<div
												className='ion-padding'
												dangerouslySetInnerHTML={{
													__html: termDetails[0]?.content,
												}}
												slot='content'
											/>
										</IonAccordion>
										<IonAccordion value='second'>
											<IonItem slot='header' color='light'>
												<IonLabel className='font-semibold'>
													<span>
														{
															ekkLanguage.kycVerification
																.privacyLabel
														}
													</span>
												</IonLabel>
											</IonItem>
											<div
												className='ion-padding'
												dangerouslySetInnerHTML={{
													__html: privacyDetails[0]?.content,
												}}
												slot='content'
											/>
										</IonAccordion>
										<IonAccordion value='third'>
											<IonItem slot='header' color='light'>
												<IonLabel className='font-semibold'>
													<span>
														{
															ekkLanguage.kycVerification
																.refundPolicy
														}
													</span>
												</IonLabel>
											</IonItem>
											<div
												className='ion-padding'
												dangerouslySetInnerHTML={{
													__html: refundDetails[0]?.content,
												}}
												slot='content'
											/>
										</IonAccordion>
									</IonAccordionGroup>
								</div>
								{/* Check box for accept all  */}
								<div className='trams-condition pt-10 pb-5 sm:pb-[20px] px-4'>
									<label className='flex cursor-pointer my-2'>
										<IonCheckbox
											color='success'
											size='small'
											slot='start'
											value='terms'
											className='mr-2 checkbox-square'
											checked={isChecked}
											onIonChange={handleOnChange}></IonCheckbox>

										<p className='flex-1 text-14 text-[#262626] text-justify font-medium'>
											{ekkLanguage.kycVerification.acceptCheckbox}
										</p>
									</label>
								</div>
								<div className='pt-32 text-center pb-10 px-4'>
									<IonButton
										disabled={isChecked ? false : true}
										color={"success"}
										className='font-bold h-[50px] w-full'
										onClick={() => {
											// navigate("/add-default-bank-account");
											navigate("/card-add-on-registration");
										}}
										// onClick={nextHandler}
									>
										{ekkLanguage.businessCreateName.btnLabel}
										<span className='pl-2'>
											<ArrayRight />
										</span>
									</IonButton>
								</div>
							</>
						)}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React from "react";
import { useNavigate } from "react-router-dom";

export default function SelectInput({
  placeholder,
  navigationLink,
  errorMessage,
  label,
  star,
  extraLabel,
  ...inputExtras
}) {
  const navigate = useNavigate();
  return (
    <div className="mb-1 mt-2 relative cursor-pointer">
      {typeof label === "object" && (
        <label className="flex tracking-wide text-grey-darker text-16 font-bold mb-2">
          {label?.source && (
            <img className="mr-[10px] w-5 h-5" src={label.source} alt="" />
          )}{" "}
          <span className={`${!label?.source && "pl-[2px]"}`}>
            {label.label}
          </span>{" "}
          {star && <span className="text-primary ml-1">*</span>}
          {extraLabel && (
            <span className="ml-1 text-12 text-[#00000080]">
              ({extraLabel})
            </span>
          )}
        </label>
      )}

      <input
        placeholder={placeholder}
        className={`appearance-none block w-full bg-grey-lighter text-grey-darker border text-14 font-semibold rounded py-4 pl-4 leading-tight focus:outline-none focus:bg-white cursor-pointer`}
        onClick={() => {
          navigate(navigationLink);
        }}
        {...inputExtras}
      />
      <span className="absolute py-[14px] pl-3 font-bold top-[40%] right-5">
        <img src="/assets/images/rightArrow.png" alt="" />
      </span>

      <div className="flex items-center pl-1">
        {errorMessage && (
          <p className="text-primary mt-1 text-xs font-bold">{errorMessage} </p>
        )}
      </div>
    </div>
  );
}

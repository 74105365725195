import React from "react";
import { useSelector } from "react-redux";

export default function ReceiverBankInfoCard({ receiverBank }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='pb-5'>
			<div className=' bg-white'>
				<div
					className='border-b-2 py-[10px] flex justify-between items-center px-4'
					style={{ bordreColor: "#f2f3f7" }}>
					<h3 className='text-black-1000 font-bold text-16 flex'>
						<img
							src='/assets/images/receive.png'
							alt='icon'
							className='h-5 w-5 mr-[10px]'
						/>{" "}
						{ekkLanguage.payment.receiverInfo}
					</h3>
				</div>
				<div className='px-4'>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.bank}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.bank_name}
								</td>
							</tr>
							<tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.acName}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.account_name}
								</td>
							</tr>
							<tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.acNumber}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.account_no}
								</td>
							</tr>
							<tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.branch}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.branch}
								</td>
							</tr>
							{/* <tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.address}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.branch}
								</td>
							</tr> */}
							{/* <tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.city}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
								
								</td>
							</tr> */}
							{/* <tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.postal}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
								</td>
							</tr> */}
							<tr>
								<td className='text-black-500 text-14 font-semibold py-[6px]'>
									{ekkLanguage.payment.country}
								</td>
								<td className='text-black-1000 text-14 font-semibold py-[6px] text-right'>
									{receiverBank?.country}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBrandList } from "redux/container/userSlice";

export default function CreateBrandSuccessPage() {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.createNewBrand.congratulation}
									</h2>
									<p className='text-18 text-black-500 font-semibold mb-5'>
										{
											ekkLanguage.createNewBrand
												.congratulationParagraph
										}
									</p>

									<Link
										to='/my-brand'
										className='w-full'
										onClick={() => dispatch(setBrandList(false))}>
										<IonButton
											className='rounded-[10px] h-12 w-full text-white font-bold bg-primary'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createNewBrand.continue}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React, { useState } from "react";
import {
	IonApp,
	IonButton,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { useNavigate } from "react-router-dom";
import Delete from "assets/images/icon/svg/Delete";
import { getUserBusiness, setUserBusiness } from "config/user";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import SelectInput from "components/global-components/common/SelectInput";
import BrandImageUploader from "components/mobile-components/VerifyBusiness/BrandImageUploader";
import { setSelectedBrands } from "redux/container/userSlice";

export default function BrandAgreementVerificationPage() {
	const bs = useSelector((state) => state.businessSettings);
	const [loader, setLoader] = useState(false);
	const [brandsError, setBrandsError] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const selectedBrandList = useSelector((state) => state.user.selectedBrands);
	let brands =
		selectedBrandList?.length > 0
			? selectedBrandList.map((brand) => brand.brand_name).toString()
			: "";
	const navigate = useNavigate();
	let dispatch = useDispatch();
	const business = getUserBusiness();

	const nextHandler = async () => {
		setLoader(true);
		let brands_agree = selectedBrandList.map((brand) => {
			if (brand.agreeUrl !== "") {
				return {
					brand_name: brand.brand_name,
					agreeUrl: brand.agreeUrl,
					brand_image: brand.images,
					brand_id: brand.id,
					seller_name: brand.seller_name,
				};
			} else {
				return null;
			}
		});
		brands_agree = brands_agree.filter((brand) => brand !== null);
		// if (!files1[0].preview) {
		// 	return navigate("/ebs-agreement-paper");
		// }

		dispatch(
			setKYC({
				brand_arg: brands_agree,
			})
		);
		let query = {
			brands_arg: brands_agree,
			business: business?.id,
		};
		const res = bs.data?.kycUpdate
			? await Business.updateKYC(bs.data?.kycId, {
					brands_arg: brands_agree,
			  })
			: await Business.createKYC(query);
		if (res.status === 200) {
			setLoader(false);
			dispatch(
				setKYC({
					kycUpdate: true,
					business_status: "Pending",
				})
			);
			setUserBusiness({
				...business,
				business_status: "Pending",
			});
			navigate("/ebs-agreement-paper");
		} else if (res.status === 201) {
			setLoader(false);
			dispatch(
				setKYC({
					kycId: res.results?.id,
					kycUpdate: true,
					business_status: "Pending",
				})
			);
			setUserBusiness({
				...business,
				business_status: "Pending",
			});
			navigate("/ebs-agreement-paper");
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessProfile.verifyBusinessMenu}
					/>
					<div className='pt-14 px-4'>
						<div className='py-2'>
							<SelectInput
								label={{
									label: ekkLanguage.kycVerification.brandSelectLabel,
								}}
								navigationLink='/select-brands'
								type='text'
								name='brands'
								value={brands}
								placeholder={
									ekkLanguage.kycVerification.brandSelectPlaceholder
								}
								errorMessage={brandsError}
							/>
						</div>
						<div className='pb-5'>
							{selectedBrandList?.length > 0 && (
								<div className='pt-4 pb-[5px]'>
									<h4 className='font-bold text-black text-16 text-center pb-4'>
										{ekkLanguage.kycVerification.brandAgreementLabel}
									</h4>
								</div>
							)}

							{selectedBrandList?.length > 0 &&
								selectedBrandList?.map((brandListItem) => {
									return (
										<div className='pb-5'>
											<div className='flex items-center mb-4'>
												{brandListItem?.images?.length ? (
													<div className='h-10 w-10 rounded-full overflow-hidden'>
														<img
															src={brandListItem?.images[0]}
															alt='logo'></img>
													</div>
												) : (
													<div className='h-10 w-10 bg-black-333 rounded-full'>
														<h3 className='h-10 w-10 flex justify-center items-center font-bold text-24 text-white'>
															{brandListItem?.brand_name?.[0].toUpperCase()}
														</h3>
													</div>
												)}
												<h4 className='ml-2 font-bold text-16'>
													{brandListItem?.brand_name}
												</h4>
											</div>
											<div className='mt-2 relative'>
												{brandListItem?.files?.length ? (
													<span
														className={`h-10 w-10 flex items-center justify-center z-10 ${
															brandListItem?.files
																? "bg-primary"
																: "bg-white"
														} rounded-[5px] absolute right-2 top-2`}
														onClick={() => {
															let updateItem = {
																...brandListItem,
																files: [],
																agreeUrl: "",
															};

															let updateList =
																selectedBrandList.map((el) =>
																	el.id === brandListItem.id
																		? updateItem
																		: el
																);
															dispatch(
																setSelectedBrands(updateList)
															);
														}}>
														<Delete
															color={
																brandListItem?.files
																	? "white"
																	: "red"
															}
														/>
													</span>
												) : undefined}

												<BrandImageUploader
													loader={loader}
													setLoader={setLoader}
													item={brandListItem}
													bHeight={438}
													placeholder={
														ekkLanguage.kycVerification
															.brandAgreementPlaceholder
													}
													imgSource={
														"/assets/images/brand_paper.png"
													}
												/>
											</div>
										</div>
									);
								})}
						</div>
						<div className='pt-20 text-center pb-10'>
							{selectedBrandList?.length > 0 && (
								<IonButton
									disabled={loader}
									color={
										selectedBrandList?.length > 0 ? "success" : "dark"
									}
									className='font-bold h-[50px] w-full'
									onClick={nextHandler}>
									{loader ? (
										<IonSpinner></IonSpinner>
									) : (
										<>
											{ekkLanguage.businessCreateName.btnLabel}
											<span className='pl-2'>
												<ArrayRight />
											</span>
										</>
									)}
								</IonButton>
							)}

							{!loader && (
								<IonButton
									style={
										!selectedBrandList?.length
											? {
													position: "absolute",
													width: "calc(100% - 32px)",
													bottom: 50,
													left: 16,
											  }
											: {}
									}
									color='white'
									className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
									onClick={() => navigate("/ebs-agreement-paper")}>
									{ekkLanguage.kycVerification.giveLater}
									<span className='pl-2'>
										<ArrayRight color='red' />
									</span>
								</IonButton>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

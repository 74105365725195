import React from "react";

export default function Mic({ color = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
    >
      <path
        d="M10.0006 22.8331V19.4087"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0004 15.0853V15.0853C7.56928 15.0853 5.6001 13.1068 5.6001 10.6654V5.58753C5.6001 3.1461 7.56928 1.1665 10.0004 1.1665C12.4304 1.1665 14.3996 3.1461 14.3996 5.58753V10.6654C14.3996 13.1068 12.4304 15.0853 10.0004 15.0853Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6668 10.7007C18.6668 15.5094 14.7864 19.4082 10.0002 19.4082C5.21284 19.4082 1.3335 15.5094 1.3335 10.7007"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2417 6.3187H14.397"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1597 9.93442H14.3989"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

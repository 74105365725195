import { IonSpinner } from "@ionic/react";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import Business from "services/Business";
import User from "services/User";

export default function ProfileEdit({
	businessData,
	setBusinessData,
	name = "name",
	loading,
	setLoading,
}) {
	const [files, setFiles] = useState([]);
	const [value, setValue] = useState(null);
	const user = getUser();
	let dispatch = useDispatch();
	let business = getUserBusiness();

	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);

	useEffect(() => {
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			setLoading(true);
			let response = User.toBucketImage(formData);
			response.then((res) => {
				setLoading(false);
				if (res.status === 201) {
					var reqbody = {
						images: [res.results[0]],
					};
					let resp = Business.updateUserBusiness(business?.id, reqbody);
					resp.then((r) => {
						if (r.status === 200) {
							// setUserBusiness(r?.results);
							setBusinessData(r?.results);
						}
					});
				}
				setValue(name, res.results[0]);
			});
		}
	}, [files, name, business?.id, setBusinessData]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});

	return (
		<>
			{businessData?.images?.length ? (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white relative overflow-hidden'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						{loading ? (
							<div className='flex items-center justify-center h-[156px] m-auto'>
								<IonSpinner name='crescent'></IonSpinner>
							</div>
						) : (
							<img
								src={businessData?.images[0]}
								alt='addImage'
								className='m-auto mb-2'
							/>
						)}
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<span
								className='absolute h-10 w-10 rounded-full text-center flex items-center justify-center right-3 top-3 border-4 border-E6E6E6'
								style={{
									background: "#5A7EFF",
									lineHeight: "28px",
								}}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='17'
									height='16'
									viewBox='0 0 17 16'
									fill='none'>
									<path
										d='M15.7732 4.7772C16.0563 4.5658 16.0231 4.12507 15.7732 3.87522L12.7573 0.859259C12.5074 0.609414 12.1052 0.609414 11.8553 0.859259L6.96398 5.7421L2.07921 10.6184C1.82714 12.1821 1.56848 13.7524 1.31641 15.3162L5.99657 14.5358C8.52671 12.0056 9.72234 10.8145 10.9291 9.61228C12.1358 8.41005 13.3227 7.22774 15.7732 4.7772V4.7772Z'
										stroke='white'
										strokeWidth='0.91'
										strokeMiterlimit='6.2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M14.2627 6.28811L14.3159 6.2349C14.4812 6.06957 14.4812 5.80152 14.3159 5.6362L10.9499 2.27018C10.7846 2.10485 10.5165 2.10485 10.3512 2.27018L10.298 2.32339C10.1327 2.48872 10.1327 2.75677 10.298 2.92209L13.664 6.28811C13.8293 6.45344 14.0973 6.45344 14.2627 6.28811Z'
										fill='white'
									/>
									<path
										d='M4.11325 15.2453L1.42269 12.5547L1.31641 15.3139L4.11325 15.2453Z'
										fill='white'
									/>
									<path
										d='M6.36228 14.1377L6.4155 14.0845C6.58082 13.9192 6.58082 13.6511 6.4155 13.4858L3.04951 10.1198C2.88419 9.95446 2.61614 9.95446 2.45082 10.1198L2.3976 10.173C2.23228 10.3383 2.23228 10.6064 2.3976 10.7717L5.76359 14.1377C5.92891 14.303 6.19696 14.303 6.36228 14.1377Z'
										fill='white'
									/>
								</svg>
							</span>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<img
								src='assets/images/add-image.png'
								alt='addImage'
								className='m-auto mb-2'
							/>
							<p className='text-16 font-normal text-black-500'>
								Upload Image
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

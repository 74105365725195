import { IonCol, IonRow } from "@ionic/react";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";
import SingleProductForCard from "./SingleProductForCard";
// import SingleProductForCard from "./SingleProductForCard";

export default function OrderItemCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const placedOrderItems = useSelector(
		(state) => state.order?.deliveredItems?.productItems
	);

	let imageFunc = (item) => {
		if (Array.isArray(item?.catalog?.product?.images)) {
			if (item?.catalog?.product?.images.length !== 0) {
				return item?.catalog?.product?.images[0];
			} else {
				if (item?.catalog?.product.type === "variant") {
					return "/assets/images/varient_default.png";
				} else if (item?.catalog?.product.type === "pack") {
					return "/assets/images/pack_default.png";
				} else if (item?.catalog?.product.type === "offer") {
					return "/assets/images/offer_default.png";
				}
			}
		} else {
			if (item?.catalog?.product.type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (item?.catalog?.product.type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (item?.catalog?.product.type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};

	return (
		<div className='px-2 pt-1 pb-5'>
			<Card title={ekkLanguage.orderDetails.orderItemLabel}>
				<div>
					<IonRow>
						{order?.order_items
							? order?.order_items.map((item, index) => {
									return (
										<div key={index}>
											{order?.order_status !== "Placed" && (
												<IonCol
													size='12'
													className='p-0'
													key={index}>
													<div className='p-2 mt-2'>
														<div className='flex mb-2 border-b'>
															<div
																style={{
																	width: 65,
																	height: 65,
																}}
																className='mb-2 bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
																<img
																	src={imageFunc(item)}
																	alt='Ordered'
																/>
															</div>
															<div className='ml-3 flex-1 '>
																<h2 className='text-black-1000 text-14 font-semibold mb-2 text-black'>
																	{
																		item?.catalog?.product
																			?.title
																	}
																</h2>
																<h3 className='text-222222 text-14 font-bold mb-2'>
																	{`${
																		order?.country
																			? order?.country
																					?.currency_symbol_native
																			: ""
																	} ${digitChanger(
																		numberWithCommas(
																			item?.unit_price
																		)
																	)} x ${digitChanger(
																		item?.quantity
																	)} `}

																	<span className='text-primary text-14 font-bold mb-2'>
																		={" "}
																		{order?.country &&
																			order?.country
																				?.currency_symbol_native}
																		{digitChanger(
																			numberWithCommas(
																				item?.quantity *
																					item?.unit_price
																			)
																		)}
																	</span>
																</h3>
															</div>
														</div>
													</div>
												</IonCol>
											)}
										</div>
									);
							  })
							: ""}
					</IonRow>
					<IonRow>
						{placedOrderItems
							? placedOrderItems.map((item, index) => {
									let image;
									if (item?.catalog?.product?.images?.length) {
										image = item?.catalog?.product?.images[0];
									} else {
										if (item?.catalog?.product?.type === "variant") {
											image = "/assets/images/varient_default.png";
										} else if (
											item?.catalog?.product?.type === "pack"
										) {
											image = "/assets/images/pack_default.png";
										} else if (
											item?.catalog?.product?.type === "offer"
										) {
											image = "/assets/images/offer_default.png";
										}
									}
									return (
										<div key={index} className='w-full'>
											{order?.order_status === "Placed" && (
												<SingleProductForCard
													item={item}
													image={image}
													key={index}
												/>
											)}
										</div>
									);
							  })
							: ""}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}

import React from "react";

export default function Verify() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M3.87459 10.3306C3.53396 10.3307 3.20728 10.1953 2.96662 9.95428L0.22153 7.2102C-0.0738435 6.91473 -0.0738435 6.43578 0.22153 6.14031C0.516999 5.84494 0.995953 5.84494 1.29142 6.14031L3.87459 8.72348L10.7086 1.8895C11.004 1.59413 11.483 1.59413 11.7785 1.8895C12.0738 2.18497 12.0738 2.66392 11.7785 2.95939L4.78256 9.95428C4.5419 10.1953 4.21523 10.3307 3.87459 10.3306Z"
        fill="white"
      />
    </svg>
  );
}
